import { useContext, useEffect, useState } from "react";
import { TbDatabaseExport } from "react-icons/tb";
import { Oval } from 'react-loader-spinner';
import Select from "react-select";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery";
import axiosInstance from "../../../api/axoiss";
import Can from "../../../components/authorizationComponents/Can";
import DataContext from "../../../context/ItemMasterContext";
import { formateGivenStringdate } from "../../../utils/Utils";
import CustomDatePicker from "../../DateSelector/CustomDatePicker";
import TablePagination from "../Pagination";
import {
    convertDateTimeToDateString,
    copyTextToClipboard,
    customMultiSelectStyle,
    customSelectStyle,
    traverseArrayToFetchData,
    traverseObjectToFetchData
} from "../TableUtils/TableUtils";
import CustomFilter from "./CustomFilter/customFilter";


const TableListView = ({ headers, query, addComponent, loadComponent, titleName, exportData, heightAdjust, initialPageNumber, initialPageSize,
    getCurrentPageNumber, getCurrentPageSize, selectedColumns, getSelectedColumns, customComponent,
    selectedFilters, getSelectedFilters, rowSelect, permission, addSerialNumber, customFilter }) => {
    const { screenSize } = useContext(DataContext)

    const [tableRowData, setTableRowData] = useState(null);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPreviousPage, setHasPreviousPage] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(initialPageNumber);
    const [pageSize, setPageSize] = useState(initialPageSize);
    const [filtersData, setFiltersData] = useState(selectedFilters);
    const [filterFieldOptions, setFilterFieldOptions] = useState(null);
    const [sortingOrderIcon, setSortingOrderIcon] = useState('fa-arrow-up');
    const [descendingSortOrder, setDescendingSortOrder] = useState(false);
    const [isShowhowSorting, setShowSorting] = useState(false);
    const [columnsEnabled, setColumnsEnabled] = useState(selectedColumns);
    const [columnsEnabledSelectValue, setcolumnsEnabledSelectValue] = useState([])
    const [columnsOptions, setColumnsOptions] = useState([]);
    const [selectedNumberFilters, setSelectedNumberFilters] = useState(null)
    const [selectedNumberFilterInput, setSelectedNumberFilterInput] = useState(null);
    const [selectedRows, setSelectedRows] = useState({});
    const [selectAllRows, setSelectAllRows] = useState(false)
    const [totalQty, setTotalQty] = useState(0);
    const [isSubAssembly, setShowSubAssembly] = useState(false)
    const [isHovered, setIsHovered] = useState({ isShow: false, index: "" });


    let numberFilters = [
        { key: 'fa-less-than', label: 'Less than' },
        { key: 'fa-greater-than', label: 'Greater than' },
        { key: 'fa-less-than-equal', label: 'Less than & Equal' },
        { key: 'fa-greater-than-equal', label: 'Greater than & Equal' },
        { key: 'fa-sliders', label: 'Between' },
    ]

    const splitPermission = permission?.split('_') || [];
    const [I, a, ...restParts] = splitPermission;
    const aAttribute = restParts.length ? `${a}_${restParts.join('_')}` : a;


    // returns nothing; fetch data and updates state
    const handleFetchData = (filter_data = filtersData) => {

        /**
         *  gets graphql query of the processing request.
         *  process axios post request and updates the related states based on the response.
         */
        // setTableRowData(null)
        if (QueryData[query]) {
            let boolean_data = []
            boolean_data = headers?.filter(item => item?.bool).map(item => item.field)
            if (titleName === 'Manufacturing Order') {
                if (!filter_data) {
                    filter_data = {}
                }
                filter_data['isSubProductionOrder'] = isSubAssembly
                boolean_data.push('isSubProductionOrder')
            }
            let item_master_query = QueryData[query](pageNumber, pageSize, filter_data, boolean_data);
            axiosInstance.post("/itemmaster/graphql", { query: item_master_query })
                .then((res) => {
                    const keys = Object.keys(res.data.data);
                    const firstKey = keys.length > 0 ? keys[0] : null;
                    let posts = res.data.data[firstKey].items;
                    let total_s = res?.data?.data?.[firstKey]?.totalQty;
                    if (total_s) {
                        setTotalQty(total_s)
                    } else {
                        setTotalQty(0)
                    }
                    if (posts?.length > 0) {
                        posts = posts.filter(item => item !== null)
                    }
                    let page_info = res.data.data[firstKey].pageInfo;
                    setTableRowData(posts);
                    setTotalItems(page_info.totalItems)
                    setHasNextPage(page_info.hasNextPage)
                    setHasPreviousPage(page_info.hasPreviousPage)
                    setTotalPages(page_info.totalPages)
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    setTableRowData(null)
                    setTotalQty(0)
                });
        } else {
            setTableRowData(null)
            setTotalQty(0)
        }
    }



    // returns nothing; handles filter for type input 
    const handleFilterOnInputDataChange = (e, header) => {
        /**
         * for input search - when user types on select input box, it fetches related data and updates 
         * dropdown option for that select element. ex. part code and part name - Item master
         */

        let header_field = header?.field
        let filter_query = header?.query ? header?.query : query
        if (e.trim() !== '') {
            let boolean_data = headers?.filter(item => item?.bool).map(item => item.field)
            let search_term = e.trim().replace(/"/g, '\\"');
            let filter_object_key = header?.customFilterField ? header?.customFilterField : header?.queryField ? header?.queryField : header_field
            let master_filter_query = QueryData[filter_query](pageNumber, 200, { [filter_object_key]: search_term }, boolean_data)
            axiosInstance.post("/itemmaster/graphql", { query: master_filter_query })
                .then((res) => {
                    const keys = Object.keys(res.data.data);
                    const firstKey = keys.length > 0 ? keys[0] : null;
                    const posts = res.data.data[firstKey].items;
                    let options_data = []
                    let fetched_custom_filter_field_option_ids = []
                    if (header?.customFilterField) {
                        posts.map(item => {
                            if (Array.isArray(item?.[header_field])) {
                                item?.[header_field]?.map(sub_item => {
                                    if (!fetched_custom_filter_field_option_ids.includes(sub_item[header?.customFilterField])) {
                                        let filter_option_object = { label: sub_item[header?.customFilterField], value: sub_item.id }
                                        fetched_custom_filter_field_option_ids.push(sub_item[header?.customFilterField])
                                        options_data.push(filter_option_object)
                                    }
                                    return null;
                                })
                            } else {
                                let option_item_obj = { label: item?.[header_field]?.[header?.customFilterField], value: item?.[header_field]?.id }
                                options_data.push(option_item_obj)
                            }
                            return null;
                        })
                    } else if (header?.label) {
                        if (header?.label.includes('.')){
                            // item[header?.field]
                            let sub_label = header?.label.split('.')
                            options_data = posts.map(item => ({ label: [sub_label[0]][sub_label[1]], value: item.id }))
                        } else {
                            options_data = posts.map(item => ({ label: item[header?.label], value: item.id }))
                            if (options_data.length > 0 && !options_data?.[0]?.['label']) {
                                options_data = posts.map(item => ({ label: item[header?.field][header.label], value: item.id }))
                            }
                        }
                    } else {
                        options_data = posts.map(item => ({ label: item?.[header_field], value: item.id }))
                    }
                    let existing_filter_options = { ...filterFieldOptions }

                    existing_filter_options[header_field] = options_data
                    setFilterFieldOptions(existing_filter_options)
                })
                .catch((error) => {
                    let existing_filter_options = { ...filterFieldOptions }
                    existing_filter_options[header_field] = []
                    setFilterFieldOptions(existing_filter_options)
                });
        } else {
            let existing_filter_options = { ...filterFieldOptions }
            existing_filter_options[header_field] = []
            setFilterFieldOptions(existing_filter_options)
        }
    }

    // returns nothing; update search term filter 
    const handleFilterInputChange = (e, header) => {
        /**
         * updates seleted filters to the filtersData state and call the 
         * handleFetchData function to fetch data based on filter 
         */
        let filters_data = { ...filtersData }
        let header_field = header?.customFilterField ? header?.customFilterField : header?.queryField ? header?.queryField : header?.field
        if (!e) {
            delete filters_data[header_field]
            setFiltersData(filters_data)
            getSelectedFilters(filters_data)
        } else {
            let { label, value } = e
            if (label === '') {
                delete filters_data[header_field]
                setFiltersData(filters_data)
                getSelectedFilters(filters_data)
            } else {
                let bool_values = ['Yes', 'No']
                if (bool_values.find(item => item === label)) {
                    filters_data[header_field] = { ...e, key: "value" }
                    setFiltersData(filters_data)
                    getSelectedFilters(filters_data)
                } else {
                    // if(header?.queryField && header?.queryField === "value"){
                    if (header?.key) {
                        let value_header_field = header?.customFilterField ? header?.customFilterField : header?.field
                        filters_data[value_header_field] = { ...e, key: header?.key }
                        setFiltersData(filters_data)
                        getSelectedFilters(filters_data)
                    } else {
                        label = String(label).replace(/"/g, '\\"');
                        filters_data[header_field] = { label: label, value: value, key: "label" }
                        setFiltersData(filters_data)
                        getSelectedFilters(filters_data)
                    }
                }
            }
        }
        getCurrentPageNumber(1)
        setPageNumber(1)
       

        handleFetchData(filters_data)
    }

    // returns "item" or <input /> element based on data
    const handleTdItemData = (item, header_item) => {
        /*
            checks input data for table row column
            if it is a string returns value in header key
            if it is a object returns value based on given label
            if it is a boolean returns input checkbox with checked true or false
            if it is a boolean returns yes or no
        */
        let item_value = item?.[header_item?.field];

        if (header_item?.bool) {
            item_value = item_value ? 'yes' : 'No'
        } else if (Array.isArray(item_value) && header_item?.label) {
            item_value = traverseArrayToFetchData(item_value, header_item?.label)
        } else if (typeof item_value === "object" && header_item?.label) {
            item_value = traverseObjectToFetchData(item_value, header_item?.label)
            if (header_item?.isDateField) {
                item_value = convertDateTimeToDateString(item_value)
            }
        } else if (header_item?.isDateField) {
            item_value = convertDateTimeToDateString(item_value)
        }
        // console.log(item_value);
        return item_value;
    };

    // returns nothing; updates state
    const handlePageSizeChange = (page_size) => {
        /**
         updates state of page size
         */
        setPageSize(page_size)
        getCurrentPageSize(page_size)
    }

    // returns nothing; updates state
    const getSelectedPageNumber = (page_number) => {
        /**
         * updates user clicked page number to pageNumber state
         */
        setPageNumber(page_number)
        getCurrentPageNumber(page_number)
    }

    // returns nothing; updates state of sorting order and its icon display
    const handleSoringOrderChange = (header_item) => {
        /**
         * updates sort icon display and order by , descending filters
         * calls handleFetchData function to fetch data
         */
        let field_name = header_item?.customFilterField ? header_item?.customFilterField : header_item?.queryField ? header_item?.queryField : header_item?.field
        // let field_name = header_item?.field
        if (sortingOrderIcon.includes('down')) {
            setSortingOrderIcon('fa-arrow-up')
            setDescendingSortOrder(false)
        } else {
            setSortingOrderIcon('fa-arrow-down')
            setDescendingSortOrder(true)
        }
        let filter_data = { ...filtersData }
        filter_data['orderBy'] = field_name
        filter_data['descending'] = !descendingSortOrder
        setFiltersData(filter_data)
        getSelectedFilters(filter_data)
        handleFilterOptionsGet()
        handleFetchData(filter_data)
    }

    // returns nothing; updates state of sorting icons show and hide. if false , clears sorting
    const handleShowAndClearSorting = () => {
        /**
         * handle enable sorting .
         * when user initially clicks , it shows sorting icon in each column. 
         * and if clicked again, hide sorting icon in each column, then clears sorting
         */
        let sort_status = isShowhowSorting
        setShowSorting(!isShowhowSorting)
        if (!sort_status === false) {
            let filter_data = { ...filtersData }
            delete filter_data['orderBy']
            delete filter_data['descending']
            setFiltersData(filter_data)
            getSelectedFilters(filter_data)
            handleFilterOptionsGet()
            handleFetchData(filter_data)
        }
    }

    // returns nothing; clears selected filters
    const handleClearFilters = () => {
        /**
         * clears selected values in filteres. and calls handleFetchData with empty filter to
         * fetch all data
         */
        let elements = document.querySelectorAll("div.css-1dimb5e-singleValue")
        for (let item of elements) {
            item.textContent = ''
        }
        let number_field = document.querySelector("input.number-field-custom-table-filter")
        if (number_field) {
            number_field.value = ''
        }
        let number_filter_popup_element = document.querySelectorAll('div[id^="number"]');
        number_filter_popup_element.forEach(element => {
            element.style.display = 'none';
        });
        let date_filter_popup_element = document.querySelectorAll('div[id^="date"]');
        date_filter_popup_element.forEach(element => {
            element.style.display = 'none';
        });
        let number_input_filter_popup_element = document.querySelectorAll('input[id^="number_input_"]');
        number_input_filter_popup_element.forEach(element => {
            element.value = '';
        });
        let date_input_filter_popup_element = document.querySelectorAll('input[id^="date_input_"]');
        date_input_filter_popup_element.forEach(element => {
            element.value = '';
        });
        setSelectAllRows(false)
        setSelectedRows({})
        setSelectedNumberFilters(null)
        setSelectedNumberFilterInput(null)
        setFiltersData(null)
        getSelectedFilters(null)
        handleFilterOptionsGet()
        handleFetchData({})
    }

    //returns nothing; updates state of filter
    const handleFilterOptionsGet = () => {
        // get column's field names which are boolean field
        let boolean_data = headers?.filter(item => item?.bool).map(item => item.field)
        let filter_options = {}
        // updates boolean field filter dropdown options
        for (let key_name of boolean_data) {
            filter_options[key_name] = [{ value: true, label: 'Yes' }, { value: false, label: 'No' }]
        }
        // fetches dropdown options based on query passed from header config
        for (let header_item of headers) {
            if (header_item.query && header_item.option) {
                let option_query = QueryData[header_item.query]()
                axiosInstance.post("/itemmaster/graphql", { query: option_query }).then(res => {
                    const keys = Object.keys(res.data.data);
                    const firstKey = keys.length > 0 ? keys[0] : null;
                    let posts = res.data.data[firstKey].items;
                    let field_filter_options = []
                    if (header_item?.label){
                        field_filter_options = posts.map(item => ({ label: item[header_item?.label], value: item?.id }))
                    } else {
                        field_filter_options = posts.map(item => ({ label: item[header_item?.field], value: item?.id }))
                    }
                    filter_options[header_item.field] = field_filter_options
                }).catch(err => {
                    filter_options[header_item.field] = []
                })
            }

        }
        setFilterFieldOptions(filter_options)
    }

    // returns nothing; updates state of enabled columns
    const handleColumnFieldCustomizationChange = (option) => {
        console.log(columnsOptions.map(item => item.value));

        if (option.length === 0) {
            setcolumnsEnabledSelectValue([])
            setColumnsEnabled(columnsOptions.map(item => item.value))
            getSelectedColumns(columnsOptions.map(item => item.value))
        } else {
            setcolumnsEnabledSelectValue(option.map(item =>({
                value:item.value,
                label:item.value,
            })))
            setColumnsEnabled(option.map(item => item.value))
            getSelectedColumns(option.map(item => item.value))
        }
    }

    // returns nothing; handles number filter popup
    const handleNumberFiltersPopup = (field) => {
        let number_filter_popup_element = document.getElementById(`number_${field}`)
        number_filter_popup_element.style.display = 'block';
    }

    // returns nothing; closes opened number filter popup
    const handleCloseNumberFilterPopup = (field) => {
        let number_filter_popup_element = document.getElementById(`number_${field}`)
        number_filter_popup_element.style.display = 'none';
    }

    const handleNumberFilterFieldUpdate = (key, field, value, start = null, end = null) => {
        let filters_data = { ...filtersData }
        if (key === 'fa-less-than') {
            filters_data[`${field}Lt`] = value
            // delete other number filter queries
            delete filters_data[`${field}Gt`];
            delete filters_data[`${field}Lte`];
            delete filters_data[`${field}Gte`];
            delete filters_data[`${field}Start`]
            delete filters_data[`${field}End`]
        } else if (key === 'fa-greater-than') {
            filters_data[`${field}Gt`] = value
            // delete other number filter queries
            delete filters_data[`${field}Lt`];
            delete filters_data[`${field}Lte`];
            delete filters_data[`${field}Gte`];
            delete filters_data[`${field}Start`]
            delete filters_data[`${field}End`]
        } else if (key === 'fa-less-than-equal') {
            filters_data[`${field}Lte`] = value
            // delete other number filter queries
            delete filters_data[`${field}Lt`];
            delete filters_data[`${field}Gt`];
            delete filters_data[`${field}Gte`];
            delete filters_data[`${field}Start`]
            delete filters_data[`${field}End`]
        } else if (key === 'fa-greater-than-equal') {
            filters_data[`${field}Gte`] = value
            // delete other number filter queries
            delete filters_data[`${field}Lt`];
            delete filters_data[`${field}Gt`];
            delete filters_data[`${field}Lte`];
            delete filters_data[`${field}Start`]
            delete filters_data[`${field}End`]
        } else {
            filters_data[`${field}Start`] = start
            filters_data[`${field}End`] = end

            // delete other number filter queries
            delete filters_data[`${field}Lt`];
            delete filters_data[`${field}Gt`];
            delete filters_data[`${field}Lte`];
            delete filters_data[`${field}Gte`];
        }
        setFiltersData(filters_data) // updates filter data state
        getSelectedFilters(filters_data)
        handleFetchData(filters_data) // calls the fetch data function
    }

    // returns nothing; number filter option handler
    const handleNumberRadioFieldChange = (e, field_name) => {
        let filter_id = e.target.id;
        let prev_state = { ...selectedNumberFilters }
        prev_state[field_name] = filter_id
        /**
         * if input number already entered
         * updates the filter state with new query
         */
        if (selectedNumberFilterInput?.hasOwnProperty(field_name)) {
            if (typeof selectedNumberFilterInput[field_name] === 'object' && filter_id !== 'fa-sliders') {
                let input_prev_state = { ...selectedNumberFilterInput }
                delete input_prev_state[field_name]
                setSelectedNumberFilterInput(input_prev_state)
            } else {
                handleNumberFilterFieldUpdate(filter_id, field_name, selectedNumberFilterInput[field_name])
            }
        }
        setSelectedNumberFilters(prev_state)
    }

    // returns nothing; number filter input onchange handler
    const handleNumberInputFieldChange = (e, field_name) => {
        let value = e.target.value.trim();
        let prev_state = { ...selectedNumberFilterInput };
        if (value !== '') {
            const parsedValue = parseInt(value); // converts str to number
            if (!isNaN(parsedValue)) {
                prev_state[field_name] = parseInt(value)
                setSelectedNumberFilterInput(prev_state)
                /**
                 * if number filter query is selected, the updates the filter state 
                 * with new filter
                 */
                if (selectedNumberFilters?.hasOwnProperty(field_name)) {
                    let filter_id = selectedNumberFilters[field_name]
                    handleNumberFilterFieldUpdate(filter_id, field_name, parseInt(value))
                }
            } else {
                toast.error('Invalid Input!. Please Enter Number', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                delete prev_state[field_name];
                setSelectedNumberFilterInput(prev_state);
                handleFetchData({})
            }
        } else {
            /**
             * delete filter query if input is empty
             */
            delete prev_state[field_name];
            setSelectedNumberFilterInput(prev_state);
            handleFetchData({})
        }
    }

    // returns nothing; "between" input handler 
    const handleNumberFilterFieldChangeForBetween = (e, field_name, position) => {
        let value = e.target.value.trim()
        let prev_state = { ...selectedNumberFilterInput };
        if (value !== '') {
            const parsedValue = parseInt(value); // converts str to number
            if (!isNaN(parsedValue)) {
                if (prev_state[field_name]) {
                    if (typeof prev_state[field_name] === 'object') {
                        prev_state[field_name][position] = parsedValue
                    } else {
                        prev_state[field_name] = {}
                        prev_state[field_name][position] = parsedValue
                    }
                } else {
                    let data_obj = { [position]: parsedValue }
                    prev_state[field_name] = data_obj
                }
                let is_start_and_end_value_available = Object.keys(prev_state[field_name]).length === 2 ? true : false
                setSelectedNumberFilterInput(prev_state)
                if (selectedNumberFilters?.hasOwnProperty(field_name) && is_start_and_end_value_available) {
                    let filter_id = selectedNumberFilters[field_name]
                    handleNumberFilterFieldUpdate(filter_id, field_name, 0, prev_state[field_name]?.start, prev_state[field_name]?.end)
                }
            } else {
                toast.error('Invalid Input!. Please Enter Number', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                delete prev_state[field_name][position]
                setSelectedNumberFilterInput(prev_state)
                if (Object.keys(prev_state[field_name]).length < 2) {
                    handleFetchData({})
                }
            }
        } else {
            delete prev_state[field_name][position]
            setSelectedNumberFilterInput(prev_state)
            if (Object.keys(prev_state[field_name]).length < 2) {
                handleFetchData({})
            }
        }
    }

    // unfinished
    const handleRowStyleOnMouseOver = (e, sub_index) => {
        // e.target.style.textDecoration = 'underline' 
        setIsHovered({ isShow: true, index: sub_index })
    }

    // unfinished
    const handleRowClearStyleOnMouseLeave = (e) => {
        e.target.style.textDecoration = ''
        setIsHovered({ isShow: false, index: "" })
    }

    // returns nothing; clear number filters
    const handleClearNumberFilters = (field) => {
        let number_filter_popup_element = document.getElementById(`number_${field}`)
        number_filter_popup_element.style.display = 'none';
        document.getElementById(`number_input_${field}`).value = ''
        let filters_data = { ...filtersData }
        delete filters_data[`${field}Lt`]
        delete filters_data[`${field}Gt`];
        delete filters_data[`${field}Lte`];
        delete filters_data[`${field}Gte`];
        delete filters_data[`${field}Start`]
        delete filters_data[`${field}End`]
        let number_filters = { ...selectedNumberFilters }
        delete number_filters[field]
        let number_filter_input = { ...selectedNumberFilterInput }
        delete number_filter_input[field]
        setSelectedNumberFilters(number_filters)
        setSelectedNumberFilterInput(number_filter_input)
        setFiltersData(filters_data)
        handleFilterOptionsGet()
        handleFetchData(filters_data)
    }

    // returns nothing; shows datepicker component
    const showDatePickerPopup = (field) => {
        // console.log(field)
        let date_range_picker_element = document.getElementById(`date_${field}`)
        date_range_picker_element.style.display = 'block'
        // console.log(date_range_picker_element)
    }

    // returns nothing; closes datepicker component
    const closeDatePickerPopup = (field) => {
        // console.log(field)
        let date_range_picker_element = document.getElementById(`date_${field}`)
        date_range_picker_element.style.display = 'none'
        // console.log(date_range_picker_element)
    }

    // returns nothig; updates state of date filter related to its field
    const handleGetSelectedDate = (data, header) => {
        let selected_start_date = formateGivenStringdate(data.startDate)
        let selected_end_date = formateGivenStringdate(data.endDate)
        let date_obj = {
            start: selected_start_date,
            end: selected_end_date,
            key: 'value',
            value: `${selected_start_date} - ${selected_end_date}`
        }
        let filters_data = { ...filtersData }
        filters_data[header] = date_obj
        setFiltersData(filters_data)
        closeDatePickerPopup(header)
        handleFilterOptionsGet()
        handleFetchData(filters_data)
    }

    // returns nothing; clears value of date input field and state based on selected field using id
    const handleClearDateInputField = (field) => {
        document.getElementById(`date_input_${field}`).value = ''
        let date_range_picker_element = document.getElementById(`date_${field}`)
        date_range_picker_element.style.display = 'none'
        let filter_data = { ...filtersData }
        delete filter_data[field]
        setFiltersData(filter_data)
        handleFilterOptionsGet()
        handleFetchData(filter_data)
    }

    // returns nothing; selects/deselects all rows in the current page and updates state of selected rows
    const handleUpdateAllRowSelect = (event) => {
        setSelectAllRows(!selectAllRows);
        if (!event.target.checked) {
            let select_rows = {}
            for (let item of tableRowData) {
                select_rows[item?.id] = false
            }
            setSelectedRows(select_rows)
        } else {
            if (tableRowData) {
                let select_rows = {}
                for (let item of tableRowData) {
                    select_rows[item?.id] = true
                }
                setSelectedRows(select_rows)
            } else {
                let select_rows = {}
                for (let item of tableRowData) {
                    select_rows[item?.id] = false
                }
                setSelectedRows(select_rows)
            }
        }
    }

    // returns nothing; updates state of selected rows
    const handleSelectRowItem = (event, row_data) => {
        let has_selected = event.target.checked;
        let selected_row_id = row_data?.['id'];
        if (has_selected) {
            setSelectedRows(prevState => ({
                ...prevState,[selected_row_id]: row_data
            }));
        } else {
            setSelectedRows(prevState => {
                const { [selected_row_id]: row_data, ...rest } = prevState;
                return rest;
            })
        }

        let all_check_box_values = []
        const checkboxes = document.querySelectorAll('[class^=select_check_box_]');
        for (const checkbox of checkboxes) {
            if (checkbox.checked) {
                all_check_box_values.push(true)
            } else {
                all_check_box_values.push(false)
            }
        }
        let anyChecked = false;
        anyChecked = all_check_box_values.every(value => value === true);
        setSelectAllRows(anyChecked)
    }

    useEffect(() => {

        if (query) {
            handleFilterOptionsGet()
            // updates column names 
            setColumnsOptions(headers?.map(item => ({ label: item.header, value: item.field })))
            setColumnsEnabled(selectedColumns)
            setFiltersData(selectedFilters)

            // let flex_items = headers?.map(item => item?.flex)
            // let column_width_list = calculateColumnWidth(flex_items, headers.length);
            // setColumnWidthList(column_width_list)
            handleFetchData()
        }
    }, [pageNumber, pageSize, loadComponent, isSubAssembly])


    // const convertToCSV = (data) => {
    //     const array = [Object.keys(data[0])].concat(data);
    //     console.log(array);

    //     return array
    //         .map((row) => {
    //             return Object.values(row)
    //                 .map(value => `"${value}"`)
    //                 .join(',');
    //         })
    //         .join('\n');
    // };

    // const downloadCSV = () => {
    //     if (tableRowData.length === 0) {
    //         alert('No data available to download.');
    //         return;
    //     }
    //     const csv = convertToCSV(tableRowData);
    //     const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    //     const link = document.createElement('a');
    //     link.href = URL.createObjectURL(blob);
    //     link.setAttribute('download', 'data.csv');
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    // };


    return (
        <div>
            <div className="card ms-5 me-2 my-2 py-2 py-lg-3  Larger shadow">
                <div className="d-flex justify-content-between align-items-center mx-3">
                    <div>
                        <b className="fs-4" style={{ color: "#000000" }} >{titleName}</b>
                    </div>
                    <div className="d-flex justify-content-between align-items-center" >
                        <div className="editListView">
                            {customFilter &&
                                <CustomFilter
                                    filtersData={filtersData}
                                    columnsEnabled={columnsEnabled}
                                    titleName={titleName}
                                    setFiltersData={setFiltersData}
                                    setColumnsEnabled={setColumnsEnabled}
                                    selectedFilters={selectedFilters}
                                    selectedColumns={selectedColumns}
                                    handleFetchData={handleFetchData}
                                    setcolumnsEnabledSelectValue={setcolumnsEnabledSelectValue}
                                />
                            }
                        </div>
                        <div className="mx-4">
                            {titleName === "Stock Statement" && <span className="me-2">Total Qty: {totalQty}</span>}
                            {titleName === "Manufacturing Order" &&
                                <span className="me-2">
                                    <input type="checkbox" name="subassembly" onChange={(e) => { setShowSubAssembly(e.target.checked) }} /> Sub Assembly
                                </span>
                            }
                            {rowSelect && <button
                                className="btn btn-sm btn-outline-secondary me-4"
                                onClick={() => { customComponent(selectedRows, filtersData) }}
                            >
                                <i className="fa-regular fa-pen-to-square"></i>
                            </button>}
                            {exportData ? <TbDatabaseExport style={{ scale: "2" }} className="mr-3" onClick={() => { exportData(tableRowData) }}>Data</TbDatabaseExport> : ""}
                            <Can I={I} a={aAttribute}>
                                <button
                                    className="btn btn-sm btn-outline-primary px-4 py-2"
                                    onClick={addComponent}
                                >
                                    <i className="fa-solid fa-plus me-2"></i>
                                    New
                                </button>
                            </Can>

                        </div>
                        <div style={{ width: '12rem', textAlign: 'left' }}>
                            <Select
                                name='column-customize'
                                placeholder="Select Columns"
                                options={columnsOptions}
                                value={columnsEnabledSelectValue}
                                styles={customMultiSelectStyle}
                                isClearable
                                components={{ DropdownIndicator: () => null }}
                                isMulti
                                onChange={(option) => { handleColumnFieldCustomizationChange(option) }}
                            />
                        </div>
                        <span onClick={() => { handleShowAndClearSorting() }} title="Enable/Disable Sorting">
                            <i className={`fa-solid fa-arrow-up-wide-short fs-5 mx-3`} style={{ color: "#5CADA9" }} role="button"></i>
                        </span>
                        <span onClick={() => { handleClearFilters() }} title="Clear Filters">
                            <i className="fa-solid fa-filter-circle-xmark fs-5" role="button" style={{ color: "#5CADA9" }}></i>
                        </span>
                    </div>
                </div>
            </div>
            <div style={{ fontSize: '1rem', zIndex: 0 }} className="card ms-5 me-2 my-2">
                <div style={{ backgroundColor: '#FAFAFA' }}>
                    <div className="custom-table-responsive" style={{ height: heightAdjust ? heightAdjust : '38rem' }}>
                        <table className="table">
                            <thead className="thead-fixed py-4 shadow-sm">
                                <tr>
                                    {addSerialNumber && <th style={{
                                        width: '1.5rem',
                                        borderStyle: 'none',
                                        fontSize: '1rem',
                                        wordWrap: 'none',
                                    }}>
                                        S.No
                                    </th>}

                                    {rowSelect && <th style={{
                                        width: '3rem',
                                        borderStyle: 'none',
                                        fontSize: '1rem',
                                        wordWrap: 'none',
                                    }}></th>}
                                    {headers && headers.map((item, index) => {
                                        if (columnsEnabled?.includes(item.field)) {
                                            return <th key={index}
                                                style={{
                                                    width: `${item.flex}rem`,
                                                    borderStyle: 'none',
                                                    fontSize: '1rem',
                                                    wordWrap: 'none',
                                                    textAlign: 'center'
                                                }}
                                                className="pt-2"
                                            >
                                                {item.header}
                                                {isShowhowSorting && <i className={`fa-solid ${sortingOrderIcon} ms-2`}
                                                    style={{ fontSize: '.8rem', cursor: 'pointer', color: '#a7a7a7' }}
                                                    onClick={() => { handleSoringOrderChange(item) }}
                                                ></i>}
                                            </th>
                                        }
                                    })}
                                </tr>
                                <tr>
                                    {rowSelect && tableRowData && <td style={{
                                        width: '3rem',
                                        borderStyle: 'none',
                                        fontSize: '1rem',
                                        wordWrap: 'none',
                                    }}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={selectAllRows}
                                            onChange={(e) => handleUpdateAllRowSelect(e)}
                                        />
                                    </td>}
                                    {addSerialNumber && <td>  </td>}

                                    {headers && headers.map((header_item, index) => {
                                            if (columnsEnabled.includes(header_item.field)) {
                                                return <td key={index} style={{ borderStyle: 'none', paddingBottom: '0rem', textAlign: 'left' }}>
                                                    <div>
                                                        {header_item.option && <Select
                                                            onChange={(e) => { handleFilterInputChange(e, header_item) }}
                                                            name={header_item.field}
                                                            options={filterFieldOptions ? filterFieldOptions[header_item.field] : []}
                                                            styles={customSelectStyle}
                                                            isClearable
                                                            components={{ DropdownIndicator: () => null }}
                                                            value={filtersData?.[header_item?.customFilterField ? header_item?.customFilterField : header_item?.queryField ? header_item?.queryField : header_item?.field] ? filtersData[header_item?.customFilterField ? header_item?.customFilterField : header_item?.queryField ? header_item?.queryField : header_item?.field] : null}
                                                        />}

                                                        {!header_item.option && header_item.isDateField && <>
                                                            <div className="form-group d-flex align-items-center">
                                                                <input
                                                                    type="text"
                                                                    id={`date_input_${header_item.field}`}
                                                                    className="form-control py-2"
                                                                    style={{ fontSize: '.7rem',  height:"2.5rem" }}
                                                                    placeholder="Select..."
                                                                    onFocus={() => { showDatePickerPopup(header_item.field) }}
                                                                    defaultValue={filtersData?.[header_item.field] ? `${filtersData[header_item.field].start} - ${filtersData[header_item.field].end}` : ''}
                                                                // value = {filtersData?.[header_item.field] ? `${filtersData[header_item.field].start} - ${filtersData[header_item.field].end}`: ''}
                                                                />
                                                                {filtersData?.[header_item.field] && <i className="fa-solid fa-xmark fw-bold"
                                                                    style={{
                                                                        position: 'relative',
                                                                        right: '1rem',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => { handleClearDateInputField(header_item.field) }}
                                                                ></i>}
                                                            </div>
                                                            <div
                                                                id={`date_${header_item.field}`}
                                                                style={{
                                                                    position: 'absolute',
                                                                    zIndex: 1,
                                                                    width: '22rem',
                                                                    display: 'none',
                                                                    right: '0rem',
                                                                }}
                                                                className="border shadow-sm bg-white p-2 mt-2"
                                                            >
                                                                <div className="d-flex justify-content-end">
                                                                    <span className="mx-3" style={{ cursor: 'pointer' }} onClick={() => { closeDatePickerPopup(header_item.field) }}>
                                                                        <i className="fa-solid fa-xmark fs-6"></i>
                                                                    </span>
                                                                </div>
                                                                <CustomDatePicker
                                                                    headerField={header_item.field}
                                                                    handleGetSelectedDate={(date, field) => { handleGetSelectedDate(date, field) }}
                                                                />
                                                            </div>
                                                        </>}
                                                        {!header_item.option && !header_item.isDateField && header_item.inputType === 'text' && <Select
                                                            onInputChange={(e) => { handleFilterOnInputDataChange(e, header_item) }}
                                                            onChange={(e) => { handleFilterInputChange(e, header_item) }}
                                                            name={header_item.field}
                                                            options={filterFieldOptions ? filterFieldOptions[header_item.field] : []}
                                                            styles={customSelectStyle}
                                                            isClearable
                                                            components={{ DropdownIndicator: () => null }}
                                                            value={filtersData?.[header_item?.customFilterField ? header_item?.customFilterField : header_item?.queryField ? header_item?.queryField : header_item?.field] ? filtersData[header_item?.customFilterField ? header_item?.customFilterField : header_item?.queryField ? header_item?.queryField : header_item?.field] : null}
                                                        />}

                                                        {!header_item.option && !header_item.isDateField && header_item.inputType === 'number' && <>
                                                            <div className="form-group d-flex justify-content-between align-items-center">
                                                                <input
                                                                    type="text"
                                                                    className="form-control py-2 number-field-custom-table-filter"
                                                                    placeholder="Type"
                                                                    style={{ fontSize: '.8rem' , height:"2.8rem"}}
                                                                    value={filtersData?.[header_item.field] ? filtersData[header_item.field].value : ''}
                                                                    onChange={(e) => { handleFilterInputChange({ label: e.target.value, value: e.target.value }, header_item) }}
                                                                />
                                                                <i
                                                                    title="Number Filters"
                                                                    className="fa-solid fa-sort-down fs-4 ms-2"
                                                                    role="button"
                                                                    onClick={() => { handleNumberFiltersPopup(header_item.field) }}
                                                                ></i>
                                                            </div>

                                                            <div
                                                                id={`number_${header_item.field}`}
                                                                style={{
                                                                    position: 'absolute',
                                                                    zIndex: 1,
                                                                    width: '15rem',
                                                                    display: 'none'
                                                                }}
                                                                className="border shadow-sm bg-white p-2 mt-2"
                                                            >
                                                                <div>
                                                                    <div className="d-flex justify-content-end">
                                                                        <span title="close" style={{ cursor: 'pointer' }}
                                                                            onClick={() => { handleCloseNumberFilterPopup(header_item.field) }}>
                                                                            <i className="fa-solid fa-x me-2"></i>
                                                                        </span>
                                                                    </div>
                                                                    {numberFilters.map((number_filter_item, num_index) => {
                                                                        return <div className="form-check" key={num_index}>
                                                                            <input className="form-check-input" type="radio" name={`number_filter_${header_item.field}`} id={number_filter_item.key}
                                                                                onChange={(e) => { handleNumberRadioFieldChange(e, header_item.field) }}
                                                                                checked={selectedNumberFilters?.[header_item.field] === number_filter_item.key ? true : false}
                                                                            />
                                                                            <label className="form-check-label" htmlFor={number_filter_item.key}>
                                                                                <i className={`fa-solid ${number_filter_item.key} mx-2`}></i> - <span>{number_filter_item.label}</span>
                                                                            </label>
                                                                        </div>
                                                                    }
                                                                    )
                                                                    }
                                                                    {selectedNumberFilters?.[header_item.field] !== 'fa-sliders' && <div className="mt-2 d-flex align-items-center">
                                                                        <input className="w-75 mx-auto py-1"
                                                                            id={`number_input_${header_item.field}`}
                                                                            onChange={(e) => { handleNumberInputFieldChange(e, header_item.field) }}
                                                                            value={selectedNumberFilterInput?.[header_item.field]}
                                                                        />
                                                                        <button className="btn btn-sm btn-outline-danger"
                                                                            onClick={() => { handleClearNumberFilters(header_item.field) }}
                                                                        >Clear</button>
                                                                    </div>}
                                                                    {selectedNumberFilters?.[header_item.field] === 'fa-sliders' && <div className="mt-2 d-flex align-items-center">
                                                                        <input className="w-25 mx-auto py-1"
                                                                            id={`number_input_${header_item.field}`}
                                                                            onChange={(e) => { handleNumberFilterFieldChangeForBetween(e, header_item.field, 'start') }}
                                                                            value={selectedNumberFilterInput?.[header_item.field]?.start}
                                                                        />
                                                                        <span className="mx-2">And</span>
                                                                        <input className="w-25 mx-auto"
                                                                            id={`number_input_${header_item.field}`}
                                                                            onChange={(e) => { handleNumberFilterFieldChangeForBetween(e, header_item.field, 'end') }}
                                                                            value={selectedNumberFilterInput?.[header_item.field]?.end}
                                                                        />
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </>}
                                                    </div>
                                                </td>
                                            }
                                        })
                                    }
                                </tr>
                            </thead>
                            {tableRowData && tableRowData.length > 0 && <tbody>
                                {tableRowData && tableRowData.map((item, index) => {
                                    return <tr key={index}>
                                        {addSerialNumber && <td>{index + 1}</td>}
                                        {rowSelect && tableRowData && <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedRows ? selectedRows?.[item?.['id']] : false}
                                                onChange={(e) => handleSelectRowItem(e, item)}
                                                className={`select_check_box_${item?.['id']}`}
                                            />
                                        </td>}
                                        {
                                            headers.map((header_item, sub_index) => {
                                                if (columnsEnabled.includes(header_item.field)) {
                                                    if (header_item.renderComponent) {
                                                        return <td
                                                            className="py-2"
                                                            key={sub_index}
                                                            style={{
                                                                textDecoration: 'none',
                                                                cursor: 'pointer',
                                                                letterSpacing: '.2px',
                                                                textAlign: `${header_item.align ? header_item.align : 'left'}`,
                                                                backgroundColor: `${isHovered?.isShow && isHovered?.index === index ? "rgb(92, 173, 169)" : ""}`
                                                            }}
                                                            onMouseOver={(e) => { handleRowStyleOnMouseOver(e, index) }}
                                                            onMouseLeave={(e) => { handleRowClearStyleOnMouseLeave(e, index) }}
                                                            onDoubleClick={copyTextToClipboard}
                                                            onClick={() => {
                                                                header_item.renderComponent(item);
                                                            }}
                                                        >   {handleTdItemData(item, header_item)}</td>
                                                    } else {
                                                        return <td
                                                            className="py-2"
                                                            key={sub_index}
                                                            style={{
                                                                letterSpacing: '.2px',
                                                                cursor: 'pointer',
                                                                textAlign: `${header_item.align ? header_item.align : 'left'}`,
                                                                backgroundColor: `${isHovered?.isShow && isHovered?.index === index ? "rgb(92, 173, 169)" : ""}`
                                                            }}
                                                            onMouseOver={(e) => { handleRowStyleOnMouseOver(e, index) }}
                                                            onMouseLeave={(e) => { handleRowClearStyleOnMouseLeave(e, index) }}
                                                            onDoubleClick={copyTextToClipboard}
                                                            onClick={() => { headers?.map(item_ => { item_?.renderComponent ? item_.renderComponent(item) : <></> }) }}
                                                        >   {handleTdItemData(item, header_item)}</td>
                                                    }
                                                }
                                            })
                                        }
                                    </tr>
                                })}
                            </tbody>}
                        </table>
                        {!tableRowData && <div className="d-flex justify-content-center align-items-center mx-auto" style={{ width: '100%', height: '20rem' }}>
                            <div>
                                <Oval
                                    visible={true}
                                    height="80"
                                    width="80"
                                    color="#4fa94d"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            </div>
                        </div>}
                    </div>
                    {tableRowData && <TablePagination
                        handlePageSizeChange={(data) => {handlePageSizeChange(data)}}
                        getSelectedPageNumber={(data) => {getSelectedPageNumber(data)}}
                        pageNumber={pageNumber}
                        totalPages={totalPages}
                        totalItems={totalItems}
                        hasNextPage={hasNextPage}
                        hasPreviousPage={hasPreviousPage}
                        pageSize={pageSize}
                    />}
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}


export default TableListView;