import { AgGridReact } from 'ag-grid-react';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineCaretLeft, AiOutlineCaretRight, AiOutlineShoppingCart } from 'react-icons/ai';
import { LuGift } from "react-icons/lu";
import { PiPrinterLight } from "react-icons/pi";
import { TiCancel } from 'react-icons/ti';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { ToastContainer, } from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import HierarchicalTreeView from '../../../components/customTreeViewComponent/HierarchicalTreeView';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import DiscountModel from '../../../components/DiscountModel/DiscountModel';
import BallTriangleComponent from '../../../components/loading/BallTriangle';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import TaxModel from '../../../components/Tax/TaxModel';
import WaringModel from '../../../components/Warings/WaringModel';
import WaringModelWithCopy from '../../../components/Warings/WaringModelWithCopy';
import DataContext from '../../../context/ItemMasterContext';
import { DepartmentQuery, stockIdFetchQuery, SupplierOptionPerameter } from '../../../GraphQLQueries/ItemmasterQuery';
import { leadTimeLine } from '../../../GraphQLQueries/LeadQueryAndMutations/LeadQuery';
import { TermsConditionsFetchQuery } from '../../../GraphQLQueries/PurchaseItemMutations';
import { copyQuotasionToSalesOrder2, getItemComboUnsavedDetails } from '../../../GraphQLQueries/QuatationqueryAndMutatiom/QuatationItemModalMutation';
import {
  GeneratePdfForSalesOrder,
  salesOrder2Amand, salesOrder2AmandCancel, salesOrder2BulkUpdateForTaxmutations, salesOrder2CanceledMutation, salesOrder2ClearDiscount, salesOrder2CreateMutation,
  salesOrder2CurrencyConvertions,
  salesOrder2DeleteMutation,
  salesOrder2FinalTotalDiscount,
  salesOrder2ItemcomboItemDetailsCreateMutations, salesOrder2ItemDetailsDeleteMutation, salesOrder2otherincomeCharges,
  salesOrder2OtherIncomeChargesDeleteMutation, salesOrder2OverallDiscountPercentage, salesOrder2OverallOverallDiscountValue
} from '../../../GraphQLQueries/SalesOrderQueryAndMutation/salesOrderMuatations';
import { SalesOrderEditQuery } from '../../../GraphQLQueries/SalesOrderQueryAndMutation/SalesOrderQuery';
import { userPermissionforOptions } from '../../../GraphQLQueries/userQueryandMutations/userQuerys';
import StaanTab from '../../../Packages/StaanTables1.2';
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import { calculateTax, customSelectStyle, DateFormate, getStates, onCellDoubleClicked, removeEmptyValueInObject, roundToNearest10, ShowErrorNoties, textEditormodules, TimeFormate } from '../../../utils/Utils';
import { SalesOrder2schema, SalesOrderOtherIncomeCharges } from '../../../validations/SalesOrderValidation';
import StockStatementDetails from '../../StockStatement/Details/StockStatementDetails';
import SalesOrderItem from './SalesOrderItem';
import SalesOrderItemCombo from './SalesOrderItemCombo';



function WarningmodelSalesorder(show, error, setSalesOrderValues) {
  const renderErrorMessages = () => {
    if (typeof error === 'string') {
      try {
        // If error is a string, try to parse it into an object
        error = JSON.parse(error);
      } catch (e) {
        console.log("Error is not a valid JSON string", e);
      }
    }

    if (typeof error === 'object' && error !== null) {
      // Separate LEAD entries and others
      const leadEntries = Object.entries(error).filter(([key]) => key.startsWith('LEAD'));
      const otherEntries = Object.entries(error).filter(([key]) => !key.startsWith('LEAD'));
      // Calculate the total for otherEntries
      const totalOtherEntries = otherEntries.reduce((acc, [key, value]) => acc + value, 0);
      return (
        <><div className="row">
          <h6 style={{ color: "red" }}>Sales Order before tax value - does not match with the Lead value!
          </h6>
          <div className="col-6">
            {/* LEAD table */}
            <div>
              <h6>LEAD</h6>
              <table>
                <tbody>
                  {leadEntries.map(([key, value], index) => (
                    <tr key={key}>
                      <td>{index + 1}. {key}</td>
                      <td>: {value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6">
            {/* Other entries table */}
            <div>
              <h6>SO Taxable Value</h6>
              <table>
                <tbody>
                  {otherEntries.map(([key, value], index) => (
                    <tr key={key}>
                      <td>{index + 1}. {key}</td>
                      <td> : {value}</td>
                    </tr>
                  ))}
                  {/* Display total below otherEntries */}
                  <tr>
                    <td><strong>Total: </strong></td>
                    <td>{totalOtherEntries}</td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
        </>
      );
    }

    return <div>{error || 'No error message available'}</div>;
  };

  return (
    <Modal show={show} onHide={() => { setSalesOrderValues(prev => ({ ...prev, isshowWaring: false })) }}>
      <Modal.Header closeButton>
        <Modal.Title>Mismatch Value</Modal.Title>
      </Modal.Header>
      <Modal.Body>{renderErrorMessages()}</Modal.Body>
    </Modal>
  );

}

const SalesOrderForm = ({index,  setLoad,  editData,  leadData, quotationData}) => {
  const {otherIncomeChargelist, userId, handltoastDeleteConfomationShow, prefetchValue, openSalesOrderModal,openQuotationModal, 
    closeSalesOrderModal, openLeadModal} = useContext(DataContext)
  const [initialSalesOrder, setInitialSalesOrder] = useState({
    id: "",
    active: true,
    salesOrderNumber: "",
    status: "",
    salesPerson: "",
    dueDate: "",
    currency: "",
    creditPeriod: "",
    paymentTerms: "",
    customerPoNo: " ",
    customerPoDate: " ",
    leadNo: "",
    quotations: "",
    department: "",
    remarks: " ",
    buyer: " ",
    childCount: " ",
    buyerAddress: " ",
    buyerContactPerson: " ",
    buyerGstinType: " ",
    buyerGstin: " ",
    buyerState: " ",
    buyerPlaceOfSupply: " ",
    consignee: " ",
    consigneeAddress: " ",
    consigneeContactPerson: " ",
    consigneeGstinType: " ",
    consigneeGstin: " ",
    consigneeState: " ",
    consigneePlaceOfSupply: " ",
    itemDetails: "",
    otherIncomeCharge: "",
    overallDiscountPercentage: "",
    overallDiscountValue: "",
    discountFinalTotal: "",
    igst: "",
    sgst: "",
    cgst: "",
    itemTotalBeforTax: "",
    otherChargesBeforTax: "",
    taxTotal: "",
    roundOff: "",
    netAmount: "",
    termsConditions: "",
    termsConditionsText: "",
    modifiedBy: "",
    createdBy: "",
  })
  const [timeLineValue, setTimeLineValue] = useState({treeData:[], isShow:false})
  const [isCancelWarningShow, setIsCancelWarningShow] = useState(false)
  const [isDeleteWarningShow, setIsDeleteWarningShow] = useState(false)
  const [formKey, setFormKey] = useState(0)
  const [isShowItemDetails, setIsShowItemDetails] = useState(false)
  const [salesOrderItemDetails, setSalesOrderItemDetails] = useState([])
  const [salesOrderItemDetailsEdit, setSalesOrderItemDetailsEdit] = useState(null)
  const [itemComboDataList, setItemComboDataList] = useState([])
  const [salesPersonList, setSalesPersonList] = useState([])
  const [otherIncomechargesList, setOtherIncomechargesList] = useState([])
  const [edit, setEdit] = useState(true)
  const [isDiscountWaring, setDiscountWaring] = useState(false)
  const [isBulkDeleteWaring, setIsBulkDeleteWaring] = useState(false)
  const [isShowTax, setIsShowTax] = useState(false)
  const [isShowDiscount, setIsShowDiscount] = useState(false)
  const [isShowItemComboModel, setIsShowItemComboModel] = useState(false)
  const [isShowOutOfRange, setIsShowOutOfRange] = useState(false)
  const [selectedStockStatementDetails, setSelectedStockStatementDetails] = useState([])
  const [isShowStockStatementModal, setShowStockStatementModal] = useState(false)
  const [customerDetailsLists, setCustomerDetailsLists] = useState({
    "buyerNameList": [], "buyerNumberList": [],
    "buyerAddressList": [], "buyerContactList": [], "buyerStatesList": [],
    "consigneeList": [], "consigneeNumberList": [], "consigneeAddressList": [],
    "consigneeContactList": [], "consigneeStatesList": [], "otherIncomeChargelist": [], "termsConditionsList": [], "DepartmentList": []
  })

  const [positionOfVirsion, setPositionOfVirsion] = useState({ IsFirst: "", IsLast: "" })
  const [discountValue, setDiscountValue] = useState({
    discountError: "", discountPercentage: "", discountValue: "",
    discountTotalValue: "", isDiscountApplied: false
  })
  const [salesOrderValues, setSalesOrderValues] = useState({
    salesPerson: "",
    buyer: "", buyerNumber: "", buyerAddress: "", buyerContactPerson: "",
    buyerPlaceOfSupply: "",
    consignee: "", consigneeNumber: "", consigneeAddress: "", consigneeContactPerson: "", consigneePlaceOfSupply: "", otherIncomeCharge: "",
    totaltax: "", item_details_value: "", other_income_value: "", netAmount: "", roundOff: "", sgst: "", cgst: "", igst: "",
    termsCondition: "", termsConditionsText: "", Department: "", createdBy: "", createdAt: "", allowCommanDisCount: false,
    outOfRangeValues: [], actualNetAmount: "", lastUpdatedItemDetails: "", stringItemCombo: "", isLoading: false, isAmend: false,
    deleteItemDetailData: { Name: "", id: "" },
    deleteOtherExpensesData: { Name: "", id: "" }, setSelectedPartNo: "", versionList: [], currentVersionId: "", parentOrder: "", isDuplicate: "",
    currency: "", currencySymbol: "", lead: { id: "", leadNo: "" }, quotations: "", actualigstResult: "", actualcgstResult: "", actualsgstResult: "", waring: "",
    isshowWaring: false
  })

  //To Add Serial Number in Item Details
  const SerialNumberRenderer = (props) => {
    return <span>{props.rowIndex + 1}</span>;
  };
  //this funtionc show the Discount Properly
  const ratecellrender = params => {
    let discount_percentage = params.data?.discountPercentage !== undefined ? params.data.discountPercentage : 0
    let discount_value = params.data?.discountValue !== undefined ? params.data.discountValue : 0
    let final_value = params.data.finalValue !== undefined ? params.data.finalValue : 0


    return (
      <>
        {params.data.rate}
        {Number(discount_percentage) !== 0
          ? -params.data.discountPercentage +
          '  %  = ' +
          Number(params?.data?.afterDiscountValueForPerItem).toFixed(2)
          : Number(discount_value) !== 0
            ? '-' +
            Number(params.data.discountValue).toFixed(2) +
            ' = ' +
            Number(params?.data?.afterDiscountValueForPerItem).toFixed(2)
            : Number(final_value) !== 0
              ? ' - ' +
              Number(params?.data?.afterDiscountValueForPerItem).toFixed(2) +
              ' = ' +
              Number(params?.data?.finalValue).toFixed(2)
              : ''}
      </>
    )
  }

  const handleCloseStockStatementModal = () => {
    setShowStockStatementModal(false)
  }

  const handleShowStockStatementModal = data => {
    axiosInstance
      .post('/itemmaster/graphql', {
        query: stockIdFetchQuery(data?.['itemmaster']?.['id'])
      })
      .then(res => {
        let stock_ids = []
        stock_ids = res?.data?.data?.stockIds?.items
        let construct_stock_dict = {
          stockId: [],
          itemPartCode: data?.partCode?.itemPartCode,
          itemName: data?.partCode?.itemName,
          description: data?.description,
          batchNumber: '',
          serial: ''
        }
        if (stock_ids.length > 0) {
          construct_stock_dict['partCode'] =
            stock_ids[0]?.partNumber?.['itemPartCode']
          construct_stock_dict['partName'] =
            stock_ids[0]?.partNumber?.['itemName']
          construct_stock_dict['description'] =
            stock_ids[0]?.partNumber?.['description']
          construct_stock_dict['isBatch'] =
            stock_ids[0]?.partNumber?.['batchNumber']
          construct_stock_dict['isSerial'] =
            stock_ids[0]?.partNumber?.['serial']
        }
        construct_stock_dict['stockId'] = stock_ids?.map(item => item.id)
        setSelectedStockStatementDetails(construct_stock_dict)
        setShowStockStatementModal(true)
      })
      .catch(error => {
        // Handle errors here
        console.error('Error fetching data:', error)
      })
    setSalesOrderValues(prve => ({ ...prve, setSelectedPartNo: data?.['itemmaster']?.['id'] }))
  }

  // Item Details Actions
  const actionsButton = ({ value, data }) => {
    // Assign the value to the higher-scoped itemId
    const handleClick = () => {
      setSalesOrderItemDetailsEdit(data)
      setIsShowItemDetails(true)

    }

    const handledeleteConfomation = () => {
      setSalesOrderValues(prve => ({ ...prve, deleteItemDetailData: { Name: data?.itemmaster?.itemPartCode, id: data.id } }))
      handltoastDeleteConfomationShow()
    }

    const openTheItemCombo = async () => {
      if (data?.itemCombo && data?.itemComboItemDetails?.length === 0) {
        const reqBody = {
          itemmaster: data?.itemmaster?.id,
          amount: String(data?.amount),
          qty: String(data?.qty)
        }
        const response = await axiosInstance.post(`/itemmaster/graphql`, {
          query: getItemComboUnsavedDetails(reqBody)
        });

        const responseData = response?.data?.data?.getItemComboItemDetails?.itemComboData;
        setSalesOrderValues(prve => ({
          ...prve, lastUpdatedItemDetails: data, stringItemCombo: responseData
        }))
        setIsShowItemComboModel(true)


      }
      setItemComboDataList(data?.itemComboItemDetails?.map((item) => ({
        edit: true,
        isSelected: true,
        afterDiscountValueForPerItem: item?.afterDiscountValueForPerItem,
        amount: item?.amount,
        id: item?.id,
        display: item?.display,
        isMandatory: item?.isMandatory,
        qty: item?.qty,
        rate: item?.rate,
        itemmasterId: item?.itemmaster?.id,
        partCode: item?.itemmaster?.itemPartCode,
        partName: item?.itemmaster?.itemName,
        uom: {
          label: item?.uom?.name,
          value: item?.uom?.id
        }
      })))
      setIsShowItemComboModel(true)
    }
    const showStock = () => {
      handleShowStockStatementModal(data)
    }

    return (
      <>
        <button
          type='button'
          className='btn btn-outline-success btn-sm px-3 mx-2'
          onClick={handleClick}
          disabled={edit}

        >
          <i className='fa-solid fa-pen'></i>
        </button>
        <button
          type='button'
          className='btn btn-outline-danger btn-sm px-3 mx-2'
          onClick={() => { handledeleteConfomation() }}
          disabled={edit}
        >
          <i className='fa-solid fa-trash '></i>
        </button>
        {data?.itemCombo && data?.itemComboItemDetails?.length > 0 ? <button type='button' className='btn btn-outline-success btn-sm px-3 mx-2' onClick={openTheItemCombo} disabled={edit}>
          <LuGift />
        </button> : data?.itemCombo ? <button type='button' className='btn btn-outline-warning btn-sm px-3 mx-2' onClick={openTheItemCombo} disabled={edit}>
          <LuGift />
        </button> : ""}
        <AiOutlineShoppingCart
          style={{ cursor: 'pointer' }}
          className='fs-3'
          onClick={() => {
            showStock()
          }}
        />


      </>
    )
  }



  // define the column for item details
  const COLUMNS = [
    {
      headerName: 'S.No',
      field: 'sNo',
      flex: .7,
      cellRenderer: 'serialNumberRenderer', // Use custom renderer
      onCellDoubleClicked: onCellDoubleClicked
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 2,
      onCellDoubleClicked: onCellDoubleClicked
    },
    {
      headerName: 'SO Qty',
      field: 'qty',
      flex: .8,
      onCellDoubleClicked: onCellDoubleClicked
    },
    {
      headerName: 'UOM',
      field: 'uom.name',
      flex: .8,
      onCellDoubleClicked: onCellDoubleClicked
    },
    {
      headerName: 'Rate',
      field: 'rate',
      flex: 1,
      cellRenderer: "ratecellrender",
      onCellDoubleClicked: onCellDoubleClicked
    },
    {
      headerName: 'HSN',
      field: 'hsn.hsnCode',
      flex: 1,
      onCellDoubleClicked: onCellDoubleClicked
    },
    {
      headerName: 'Tax',
      field: 'tax',
      flex: .7,
      onCellDoubleClicked: onCellDoubleClicked
    },
    {
      headerName: 'Amount',
      field: 'amount',
      flex: 1,
      onCellDoubleClicked: onCellDoubleClicked
    },
    {
      headerName: 'Action',
      cellRenderer: actionsButton,
      flex: 2,
    },
  ]
  // define the for ag-grid table in whats header
  const DEFAULT_COL_DEF ={
    filter:false, floatingFilter:false,
    resizable: true,
  }

  // assignee COMPONENTS to ad-grid
  const COMPONENTS = {
    serialNumberRenderer: SerialNumberRenderer,
    actionsButton: actionsButton,
    ratecellrender: ratecellrender
  };

  // define the column for other Charges
  const OTHER_INCOME_CHARGES_COLUMNDEFS = [
    {
      header: 'Account',
      fieldname: 'otherIncomeChargesId',
      object: "name",
      width: '20',

    },
    {
      header: 'Tax',
      fieldname: 'tax',
      width: '11',

    },
    {
      header: "Amount",
      fieldname: 'amount',
      temple: "temple_1",
      width: '20'
    }
  ]

  // Redux
  const dispatch = useDispatch();
  const Options = useSelector(state => state.itemGroup.data);

  // get data by Redux
  useEffect(() => {
    if (Options.name === "SalesOrderSalesperson") {
      setSalesPersonList(Options?.value?.items?.map((user) => ({ value: user?.userId?.id, label: user?.userId?.username })))
    }
    if (Options.name === "SalesOrderCustomer") {
      setCustomerDetailsLists(prve => ({
        ...prve, buyerNameList: Options?.value?.items.map((customer) => ({
          value: customer?.id, label: customer?.companyName, supplierNo: customer?.supplierNo,
          contact: customer?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
          address: customer?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
          gstin: customer?.gstin, gstinType: customer?.gstinType?.gstType
        }))
      }))

    } if (Options.name === "SalesOrderCustomerNumber") {
      setCustomerDetailsLists(prve => ({
        ...prve, buyerNumberList: Options?.value?.items.map((customer) => ({
          value: customer?.id, label: customer?.supplierNo, supplierName: customer?.companyName,
          contact: customer?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
          address: customer?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
          gstin: customer?.gstin, gstinType: customer?.gstinType?.gstType
        }))
      }))
    }
    if (Options.name === "SalesOrderConsignee") {
      setCustomerDetailsLists(prve => ({
        ...prve, consigneeList: Options?.value?.items.map((customer) => ({
          value: customer?.id, label: customer?.companyName, supplierNo: customer?.supplierNo,
          contact: customer?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
          address: customer?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
          gstin: customer?.gstin, gstinType: customer?.gstinType?.gstType
        }))
      }))
    }
    if (Options.name === "SalesOrderConsigneeNumber") {
      setCustomerDetailsLists(prve => ({
        ...prve, otherIncomeChargelist: Options?.value?.items.map((customer) => ({
          value: customer?.id, label: customer?.supplierNo, supplierName: customer?.companyName,
          contact: customer?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
          address: customer?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
          gstin: customer?.gstin, gstinType: customer?.gstinType?.gstType
        }))
      }))
    }
    if (Options.name === "SalesOrderTermsConditionsFetchQuery") {
      setCustomerDetailsLists(prve => ({
        ...prve, termsConditionsList: Options?.value?.items.map((tc) => ({
          value: tc?.id, label: tc?.name,
          tc: tc?.tc
        }))
      }))
    }
    if (Options.name === "SalesOrderdepartmant") {
      setCustomerDetailsLists(prve => ({
        ...prve, DepartmentList: Options?.value?.items?.map(item => ({ value: item.id, label: item.name }))
      }))

    }
    if (Options.name === "SalesOrderCustomerForLEad") {

      setCustomerDetailsLists(prve => ({
        ...prve, buyerNameList: Options?.value?.items.map((customer) => ({
          value: customer?.id, label: customer?.companyName, supplierNo: customer?.supplierNo,
          contact: customer?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
          address: customer?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
          gstin: customer?.gstin, gstinType: customer?.gstinType?.gstType
        })),
        buyerNumberList: Options?.value?.items.map((customer) => ({
          value: customer?.id, label: customer?.supplierNo, supplierName: customer?.companyName,
          contact: customer?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
          address: customer?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
          gstin: customer?.gstin, gstinType: customer?.gstinType?.gstType
        })),
        buyerAddressList: Options?.value?.items?.[0]?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
        buyerContactList: Options?.value?.items?.[0]?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
        buyerStatesList: getStates(Options?.value?.items?.[0]?.address.filter((address) => address?.default === true)?.[0]?.country)
      }))

      let selectData = Options?.value?.items?.[0]
      let selectAddress = Options?.value?.items?.[0]?.address.filter((address) => address?.default === true)?.[0]
      let selectContact = Options?.value?.items?.[0]?.contact.filter((Contact) => Contact?.default === true)?.[0]
      // fullAddredd?. 
      setSalesOrderValues(prve => ({
        ...prve,
        buyer: { value: selectData?.id, label: selectData?.companyName, supplierNo: selectData?.supplierNo, },
        buyerNumber: { value: selectData?.id, label: selectData?.supplierNo, supplierNo: selectData?.companyName },
        buyerAddress: { value: selectAddress?.id, label: selectAddress?.addressType, fullAddredd: selectAddress },
        buyerContactPerson: { value: selectContact?.id, label: selectContact?.contactPersonName, mobile: selectContact?.phoneNumber, Email: selectContact?.email },
        buyerPlaceOfSupply: { value: selectAddress?.state, label: selectAddress?.state },
      }))
      setInitialSalesOrder((prevValues) => ({
        ...prevValues,
        buyer: Number(selectData?.id),
        buyerGstinType: Options?.value?.items?.[0]?.gstinType?.gstType,
        buyerGstin: Options?.value?.items?.[0]?.gstin,
        buyerState: selectAddress?.state,
        buyerPlaceOfSupply: selectAddress?.state,
        buyerAddress: Number(selectAddress?.id),
        buyerContactPerson: Number(selectContact?.id)
      }));
    } if (Options?.name === "copyQuotasionToSalesOrder2") {

      setSalesOrderItemDetails(Options?.value?.itemDetails)
      setOtherIncomechargesList(Options?.value?.otherIncomeCharge?.map(item => ({ ...item, id_: item?.id })))

    }

  }, [dispatch, Options])


  // return null; save
  const handleSubmit = async (values, { resetForm, setErrors }) => {
    const itemDetailsds = salesOrderItemDetails?.map(item => parseInt(item.id, 10));
    values['itemDetails'] = itemDetailsds
    const otherIncomeIds = otherIncomechargesList?.map(item => parseInt(item.id, 10));
    values['otherIncomeCharge'] = otherIncomeIds
    values['igst'] = salesOrderValues?.igst
    values['sgst'] = salesOrderValues?.sgst
    values['cgst'] = salesOrderValues?.cgst
    values['itemTotalBeforTax'] = salesOrderValues?.item_details_value
    values['otherChargesBeforTax'] = salesOrderValues?.other_income_value
    values['taxTotal'] = salesOrderValues?.totaltax
    values['roundOff'] = salesOrderValues?.roundOff
    values['netAmount'] = salesOrderValues?.netAmount
    values['overallDiscountPercentage'] = discountValue?.discountPercentage
    values['overallDiscountValue'] = discountValue?.discountValue
    values['discountFinalTotal'] = discountValue?.discountTotalValue
    values['childCount'] = ""
    if (values['id'] === "") {
      values['createdBy'] = Number(userId)
    }
    if (values['status'] === "") {
      values['status'] = "Draft"
    }
    delete values?.['salesOrderNumber']
    delete values?.['createdAt']
    delete values?.['createdTime']
    delete values?.['active']
    if (salesOrderValues?.isAmend) {
      values['id'] = ""
      values['createdBy'] = Number(userId)
      values['modifiedBy'] = ""
      values['parentOrder'] = salesOrderValues?.parentOrder ? Number(salesOrderValues?.parentOrder) : ""
      values['childCount'] = initialSalesOrder?.childCount >= 0 ? Number(initialSalesOrder?.childCount) + 1 : 1
      values['status'] = 'Submit'
    }

    let allow_null = ['id', "modifiedBy", "leadNo", "quotations", "department", "overallDiscountPercentage",
      "overallDiscountValue", "discountFinalTotal", "childCount"]
    let clearedValue = removeEmptyValueInObject(values, allow_null)
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: salesOrder2CreateMutation(clearedValue) });
      const responseData = response?.['data']?.['data']?.['salesOrder2CreateMutation']
      if (responseData.success) {
        updateTheStates(responseData?.SalesOrder2)
        resetForm()
        if (values?.['status'] === "Submit") {
          setEdit(true)
          showErrorToast(true, "success", "", "Submit Successfully")
        } else {
          showErrorToast(true, "success", "", "Draft Successfully")
        }
        if (responseData?.version?.versionList) {
          setSalesOrderValues(prve => ({ ...prve, versionList: responseData?.version?.versionList }))
        }
      } else {
        if (responseData?.waring) {
          console.log(responseData?.waring);

          setSalesOrderValues(prev => ({ ...prev, "waring": responseData?.waring, isshowWaring: true }))
        }
        if (responseData?.outOfRangeErrors) {
          setIsShowOutOfRange(true)
          setSalesOrderValues(prev => ({ ...prev, "outOfRangeValues": responseData?.outOfRangeErrors }))
        } else if (responseData.errors) {
          let errors = ShowErrorNoties(responseData.errors)
          showErrorToast(true, "error", errors,)
        }
      }

    } catch (error) {
      showErrorToast(true, "error", ShowErrorNoties(error))
    }
  }

  const handleSubmitOtherExpenses = async (values, { setSubmitting, resetForm, setErrors, setFieldError }, toggle, setOpenRowIndex) => {
    values['states'] = salesOrderValues?.buyerAddress?.fullAddredd?.state
    values['currency'] = salesOrderValues?.currency?.value ? Number(salesOrderValues?.currency?.value) : ""

    try {
      let clearedValue = removeEmptyValueInObject(values, ["id", "modifiedBy", "currency"])
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: salesOrder2otherincomeCharges(clearedValue) });
      const responseData = response?.['data']?.['data']?.['salesOrder2OtherIncomeChargesCreateMutations']
      responseData['salesOrder2OtherincomeCharges']['id_'] = responseData?.salesOrder2OtherincomeCharges?.id
      let newId = Number(responseData?.salesOrder2OtherincomeCharges?.id); // Convert to number
      let oldId = otherIncomechargesList?.map(item => Number(item?.id_)) || []; // Ensure oldId is an array
      if (!isNaN(newId)) { // Check if newId is a valid number
        oldId.push(newId); // Add newId to oldId
      }
      let modified_data = [...new Set(oldId)]; // Store the modified array
      setOtherIncomechargesList(prev => {
        const newItem = responseData?.salesOrder2OtherincomeCharges
        if (!newItem) return prev; // Exit early if there's no new item
        const existingIndex = prev.findIndex(item => item?.id === newItem.id);
        if (existingIndex !== -1) {
          // Replace the existing item
          const newList = [...prev];
          newList[existingIndex] = newItem;

          return newList;
        } else {
          // Add the new item
          return [...prev, newItem];
        }

      });
      if (discountValue?.isDiscountApplied) {
        let allItemDetailsIds = salesOrderItemDetails?.map(item => Number(item?.id))
        let allOtherIncomeChargesIds = modified_data
        ClearDisCount(allItemDetailsIds, allOtherIncomeChargesIds)
      }
      resetForm()
      toggle()
      setOpenRowIndex()
    } catch (error) {

    }


  }
  // save the item Combo in bulk data
  const saveItemCombo = async (data, itemDetails, closeItemCombo) => {
    let modifiedData = data?.map((item) => {
      if (item?.isSelected) {
        delete item?.['index'];
        delete item?.['partCode'];
        delete item?.['partName'];

        let modifiedValue = {
          ...item,
          createdBy: Number(userId),
          id: null,
          itemmaster: Number(item?.itemmasterId),
          modifiedBy: null,
          parentItemdetailId: Number(itemDetails?.id),
          uom: Number(item?.uom?.value),
          afterDiscountValueForPerItem: item?.afterDiscountValuePerItem,
          amount: Number(item?.amount).toFixed(2),
          qty: Number(item?.qty),
        };

        // Clean up unwanted properties
        delete modifiedValue?.['afterDiscountValuePerItem'];
        delete modifiedValue?.['itemmasterId'];
        delete modifiedValue?.['isSelected'];

        console.log(modifiedValue);

        return modifiedValue; // Return the modified value when isSelected is true
      }

      return null; // Return null (or undefined) when isSelected is false
    }).filter(item => item !== null); // Filter out null values


    // Convert modifiedData to a proper GraphQL string
    const itemsString = JSON.stringify(modifiedData)
      .replace(/"([^"]+)":/g, '$1:') // Remove quotes from keys
      .replace(/null/g, 'null'); // Ensure null values are not quoted
    try {
      const respones = await axiosInstance.post("/itemmaster/graphql", { query: salesOrder2ItemcomboItemDetailsCreateMutations(itemsString) })
      const responseData = respones.data.data.salesOrder2ItemcomboItemDetailsCreateMutations
      if (responseData?.success) {
        //   return the parent component the update it
        setSalesOrderItemDetails(prev => {
          const newItem = responseData?.parent;
          if (!newItem) return prev; // Exit early if there's no new item
          const existingIndex = prev.findIndex(item => item?.id === newItem.id);
          if (existingIndex !== -1) {
            // Replace the existing item
            const newList = [...prev];
            newList[existingIndex] = newItem;
            return newList;
          } else {
            // Add the new item
            return [...prev, newItem];
          }
        });
        setIsShowItemComboModel(false)
        closeItemCombo()
      } else {
        console.log(responseData?.errors);

      }

    } catch (error) {
      console.log(error);


    }
  }

  // Other Income charges input sections
  const OtherIncomechargesInputFrom = (toggle, setOpenRowIndex, rowdata) => {

    const initialValues = {
      id: rowdata?.id_ ? Number(rowdata?.id_) : "",
      otherIncomeChargesId: rowdata?.otherIncomeChargesId?.id ? Number(rowdata?.otherIncomeChargesId?.id) : "",
      amount: rowdata?.amount ? rowdata?.amount : "",
      tax: rowdata?.tax ? Number(rowdata?.tax) : "",
      createdBy: rowdata?.id ? Number(rowdata?.createdBy?.id ?? userId) : Number(userId),
      modifiedBy: rowdata?.id ? Number(userId) : "",
    }
    const [otherIncomeChargesValues, setOtherIncomeChargesValues] = useState(
      rowdata?.otherIncomeChargesId?.id ? { value: rowdata?.otherIncomeChargesId?.id, label: rowdata?.otherIncomeChargesId?.name } :
        { value: "", label: "" }
    )

    return (
      <>
        <Formik initialValues={initialValues}
          validationSchema={SalesOrderOtherIncomeCharges}
          onSubmit={(values, formikProps) => handleSubmitOtherExpenses(values, formikProps, toggle, setOpenRowIndex,)}
        >
          {({ errors, submitForm, touched, setFieldValue, values, resetForm }) => (
            <>
              <Form>
                <div className="row">
                  <div className="col-6 staan-input-group">
                    <Select
                      name='otherIncomeChargesId'
                      options={otherIncomeChargelist}
                      value={otherIncomeChargesValues}
                      isSearchable={true}
                      onChange={(option) => {
                        setFieldValue("otherIncomeChargesId", option?.value ? Number(option?.value) : null)
                        setOtherIncomeChargesValues(option ? option : null)
                        setFieldValue("tax", option?.tax ? option.tax : null)
                      }}
                      className='my-3'
                      styles={customSelectStyle}
                    />
                    <label className='staan-form-input-label pt-1 px-1 mx-2'  >Account </label>
                    {touched.otherIncomeChargesId && errors.otherIncomeChargesId && <small>{errors.otherIncomeChargesId}</small>}
                  </div>
                  <div className="col-2 staan-input-group">
                    <Field type='text' name='tax' disabled className='w-100 staan-form-input' />
                    <label htmlFor="tax" className='staan-form-input-label pt-1 px-1 mx-2'>Tax</label>
                    {touched.tax && errors.tax && <small>{errors.tax}</small>}
                  </div>
                  <div className="col-2 staan-input-group">
                    <Field type='text' name='amount' className='w-100 staan-form-input' />
                    <label htmlFor="amount" className='staan-form-input-label pt-1 px-1 mx-2'>Amount</label>
                    {touched.amount && errors.amount && <small>{errors.amount}</small>}
                  </div>
                  <div className='col-1 mt-3'>
                    <button type="button" className="save-button" onMouseDown={() => { submitForm() }}  >
                      <i className="fa-regular fa-floppy-disk" ></i>
                    </button>
                  </div>
                  <div className='col-1 mt-3'>
                    <button type="button" className="delete-button" onMouseDown={(event) => {
                      event.preventDefault();
                      toggle()
                      setOpenRowIndex()
                    }}>

                      <i className="fa-solid fa-trash"></i>
                    </button >
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>


      </>
    )

  }

  // basic calculations
  useEffect(() => {
    if (salesOrderItemDetails.length > 0) {
      let item_value = 0;
      let other_income_value = 0
      let actual_item_value = 0
      let actual_other_income_value = 0
      const igstList = {};
      const cgstList = {};
      const sgstList = {};
      // This actual tax with discount
      const actualIgstList = {}
      const actualCgstList = {}
      const actualSgstList = {}
      // Calculate total amount and populate tax lists
      salesOrderItemDetails?.forEach(itemValue => {
        item_value += Number(itemValue.amount);
        actual_item_value += Number((itemValue.rate) * (itemValue?.qty));
        if (itemValue.igst) {
          igstList[itemValue.igst] = (igstList[itemValue.igst] || []).concat(itemValue.amount);
          actualIgstList[itemValue.igst] = (actualIgstList[itemValue.igst] || []).concat((itemValue.rate) * (itemValue?.qty));
        }
        if (itemValue.cgst) {
          cgstList[itemValue.cgst] = (cgstList[itemValue.cgst] || []).concat(itemValue.amount);
          actualCgstList[itemValue.cgst] = (actualCgstList[itemValue.cgst] || []).concat((itemValue.rate) * (itemValue?.qty));
        }
        if (itemValue.sgst) {
          sgstList[itemValue.sgst] = (sgstList[itemValue.sgst] || []).concat(itemValue.amount);
          actualSgstList[itemValue.sgst] = (actualSgstList[itemValue.sgst] || []).concat((itemValue.rate) * (itemValue?.qty));
        }
      });

      otherIncomechargesList?.forEach(itemValue => {
        let other_income_amount = itemValue?.afterDiscountValue ? Number(itemValue?.afterDiscountValue) : Number(itemValue.amount);
        other_income_value += other_income_amount
        actual_other_income_value += itemValue.amount
        if (itemValue.igst) {
          igstList[itemValue.igst] = (igstList[itemValue.igst] || []).concat(other_income_amount);
          actualIgstList[itemValue.igst] = (actualIgstList[itemValue.igst] || []).concat(other_income_amount);
        }
        if (itemValue.cgst) {
          cgstList[itemValue.cgst] = (cgstList[itemValue.cgst] || []).concat(other_income_amount);
          actualCgstList[itemValue.cgst] = (actualCgstList[itemValue.cgst] || []).concat(other_income_amount);

        }
        if (itemValue.sgst) {
          sgstList[itemValue.sgst] = (sgstList[itemValue.sgst] || []).concat(other_income_amount);
          actualSgstList[itemValue.sgst] = (actualSgstList[itemValue.sgst] || []).concat(other_income_amount);
        }
      });




      // // Calculate taxes
      const { taxResult: igstResult, taxValues: igstaxvalues } = calculateTax(igstList);
      const { taxResult: cgstResult, taxValues: cgstaxvalues } = calculateTax(cgstList);
      const { taxResult: sgstResult, taxValues: sgstaxvalues } = calculateTax(sgstList);

      // // actual taxes
      const { taxResult: actualigstResult, taxValues: actualigstaxvalues } = calculateTax(actualIgstList);
      const { taxResult: actualcgstResult, taxValues: actualcgstaxvalues } = calculateTax(actualCgstList);
      const { taxResult: actualsgstResult, taxValues: actualgstaxvalues } = calculateTax(actualSgstList);


      let actualNetAmount = Number(actualigstaxvalues) + Number(actualcgstaxvalues) + Number(actualgstaxvalues) + Number(actual_item_value) + Number(actual_other_income_value)
      const newFinalAmount = roundToNearest10(igstaxvalues + cgstaxvalues + sgstaxvalues + item_value + other_income_value).toFixed(2)
      let totalTax = (igstaxvalues + cgstaxvalues + sgstaxvalues).toFixed(2)
      let roundOff = (newFinalAmount - (igstaxvalues + cgstaxvalues + sgstaxvalues + item_value + other_income_value)).toFixed(2)
      setSalesOrderValues(prve => ({
        ...prve, totaltax: totalTax, item_details_value: item_value.toFixed(2),
        other_income_value: other_income_value.toFixed(2), netAmount: newFinalAmount,
        roundOff: roundOff, sgst: sgstResult, cgst: cgstResult, igst: igstResult,
        actualNetAmount: roundToNearest10(actualNetAmount), actualigstResult: actualigstResult,
        actualcgstResult: actualcgstResult, actualsgstResult: actualsgstResult
      }))

    }
  }, [salesOrderItemDetails, otherIncomechargesList]);

  // Update the Value in inputs
  function updateTheStates(reponseData) {
    setInitialSalesOrder({
      id: Number(reponseData?.id),
      active: reponseData?.active,
      salesOrderNumber: reponseData?.salesOrderNo?.linkedModelId,
      createdAt: DateFormate(reponseData?.CreatedAt),
      createdTime: TimeFormate(reponseData?.CreatedAt),
      status: reponseData?.status?.name,
      salesPerson: Number(reponseData?.salesPerson?.id),
      dueDate: reponseData?.dueDate,
      creditPeriod: reponseData?.creditPeriod,
      paymentTerms: reponseData?.paymentTerms,
      customerPoNo: reponseData?.customerPoNo,
      customerPoDate: reponseData?.customerPoDate,
      department: Number(reponseData?.department?.id),
      childCount: reponseData?.childCount,
      leadNo: reponseData?.leadNo?.id ? Number(reponseData?.leadNo?.id) : "",
      quotations: reponseData?.quotations?.id ? Number(reponseData?.quotations?.id) : "",
      currency: reponseData?.currency?.id ? Number(reponseData?.currency?.id) : "",

      // remarks : reponseData?.,
      buyer: Number(reponseData?.buyer?.id),
      buyerAddress: Number(reponseData?.buyerAddress?.id),
      buyerContactPerson: Number(reponseData?.buyerContactPerson?.id),
      buyerGstinType: reponseData?.buyerGstinType,
      buyerGstin: reponseData?.buyerGstin,
      buyerState: reponseData?.buyerState,
      buyerPlaceOfSupply: reponseData?.buyerPlaceOfSupply,
      consignee: Number(reponseData?.consignee?.id),
      consigneeAddress: Number(reponseData?.consigneeAddress?.id),
      consigneeContactPerson: Number(reponseData?.consigneeContactPerson?.id),
      consigneeGstinType: reponseData?.consigneeGstinType,
      consigneeGstin: reponseData?.consigneeGstin,
      consigneeState: reponseData?.consigneeState,
      consigneePlaceOfSupply: reponseData?.consigneePlaceOfSupply,
      itemDetails: reponseData?.itemDetails?.map(item => Number(item?.id)),
      otherIncomeCharge: reponseData?.otherIncomeCharge?.map(item => Number(item?.id)),
      overallDiscountPercentage: "",
      overallDiscountValue: "",
      discountFinalTotal: "",
      igst: reponseData?.igst,
      sgst: reponseData?.sgst,
      cgst: reponseData?.cgst,
      itemTotalBeforTax: reponseData?.itemTotalBeforTax,
      otherChargesBeforTax: reponseData?.otherChargesBeforTax,
      taxTotal: reponseData?.taxTotal,
      roundOff: reponseData?.roundOff,
      netAmount: reponseData?.netAmount,
      termsConditions: Number(reponseData?.termsConditions?.id),
      termsConditionsText: reponseData?.termsConditionsText,
      modifiedBy: Number(userId),
      createdBy: Number(reponseData?.createdBy?.id),
    })
    setSalesOrderItemDetails(reponseData?.itemDetails)
    setOtherIncomechargesList(reponseData?.otherIncomeCharge?.map(item => ({ ...item, id_: item?.id })))

    setSalesOrderValues({
      lead: { id: reponseData?.leadNo?.id, leadNo: reponseData?.leadNo?.leadNo },
      currentVersionId: reponseData?.id,
      createdBy: reponseData?.createdBy,
      currency: {
        value: reponseData?.currency?.id, label: reponseData?.currency?.Currency?.name, currencySymbol: reponseData?.currency?.Currency?.currencySymbol,
        rate: reponseData?.currency?.rate
      },
      currencySymbol: reponseData?.currency?.Currency?.currencySymbol,
      quotations: { value: reponseData?.quotations?.id, label: reponseData?.quotations?.quotationNo?.linkedModelId },
      salesPerson: { value: reponseData?.salesPerson?.id, label: reponseData?.salesPerson?.username },
      Department: { value: reponseData?.department?.id, label: reponseData?.department?.name },
      buyer: { value: reponseData?.buyer?.id, label: reponseData?.buyer?.companyName },
      buyerNumber: { value: reponseData?.buyer?.id, label: reponseData?.buyer?.supplierNo },
      buyerAddress: { value: reponseData?.buyerAddress?.id, label: reponseData?.buyerAddress?.addressType, fullAddredd: reponseData?.buyerAddress },
      buyerContactPerson: {
        value: reponseData?.buyerContactPerson?.id, label: reponseData?.buyerContactPerson?.contactPersonName,
        mobile: reponseData?.buyerContactPerson?.phoneNumber, Email: reponseData?.buyerContactPerson?.email
      },
      buyerPlaceOfSupply: { values: reponseData?.buyerPlaceOfSupply, label: reponseData?.buyerPlaceOfSupply },
      consignee: { value: reponseData?.consignee?.id, label: reponseData?.consignee?.companyName },
      consigneeNumber: { value: reponseData?.consignee?.id, label: reponseData?.consignee?.supplierNo },
      consigneeAddress: { value: reponseData?.consigneeAddress?.id, label: reponseData?.consigneeAddress?.addressType, fullAddredd: reponseData?.consigneeAddress },
      consigneeContactPerson: {
        value: reponseData?.consigneeContactPerson?.id, label: reponseData?.consigneeContactPerson?.contactPersonName,
        mobile: reponseData?.buyerContactPerson?.phoneNumber, Email: reponseData?.buyerContactPerson?.email
      },
      consigneePlaceOfSupply: { values: reponseData?.consigneePlaceOfSupply, label: reponseData?.consigneePlaceOfSupply },
      totaltax: reponseData?.taxTotal, item_details_value: reponseData?.itemTotalBeforTax,
      other_income_value: reponseData?.otherChargesBeforTax, netAmount: reponseData?.netAmount,
      roundOff: reponseData?.roundOff, sgst: reponseData?.sgst, cgst: reponseData?.cgst, igst: reponseData?.igst,
      termsCondition: { value: reponseData?.termsConditions?.id, label: reponseData?.termsConditions?.name },
      termsConditionsText: reponseData?.termsConditionsText,
      allowCommanDisCount: reponseData?.overallDiscountPercentage || reponseData?.overallDiscountValue || reponseData?.discountFinalTotal ? true : false,
    })
    setDiscountValue({
      discountError: "",
      discountPercentage: reponseData?.overallDiscountPercentage ? reponseData?.overallDiscountPercentage : "",
      discountValue: reponseData?.overallDiscountValue ? reponseData?.overallDiscountValue : "",
      discountTotalValue: reponseData?.discountFinalTotal ? reponseData?.discountFinalTotal : "",
      isDiscountApplied: reponseData?.overallDiscountPercentage || reponseData?.overallDiscountValue || reponseData?.discountFinalTotal ? true : false
    })
    setFormKey(prve => prve + 1)
    setCustomerDetailsLists(prve => ({
      ...prve, buyerStatesList: getStates(reponseData?.buyerAddress?.country),
      consigneeStatesList: getStates(reponseData?.consigneeAddress?.country),
      buyerContactList: reponseData?.buyer?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
      buyerAddressList: reponseData?.buyer?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
      consigneeContactList: reponseData?.consignee?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
      consigneeAddressList: reponseData?.consignee?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ }))
    }))
  }

  // on edit fetch The Value from dataBase
  async function fetchEditData(id) {
    setSalesOrderValues(prve => ({ ...prve, isLoading: true }))
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: SalesOrderEditQuery(`id:${id}`) });
      const reponseData = response?.data?.data?.allSalesorder2?.items?.[0]
      let versionList = response?.data?.data?.allSalesorder2?.version?.versionList
      if (reponseData) {
        updateTheStates(reponseData)
        setSalesOrderValues(prve => ({ ...prve, versionList: versionList }))
      }
    } catch (error) {
      // let errorFilter = ShowErrorNoties(error)
    }
    setSalesOrderValues(prve => ({ ...prve, isLoading: false }))
  }

  // retun null ; set the Quotations data
  function CallFromQuotation(quotationData) {
    setSalesOrderValues(prve => ({
      ...prve, quotations: { value: quotationData?.Quotation?.id, label: quotationData?.Quotation?.QuatationNumber },
      Department: { value: quotationData?.Department?.value, label: quotationData?.Department?.label },
      salesPerson: { value: quotationData?.SalesPerson?.value, label: quotationData?.SalesPerson?.label },
      lead: quotationData?.lead, currency: quotationData?.currency ? quotationData?.currency : "",
      currencySymbol: quotationData?.currency?.Symbol
    }))
    setInitialSalesOrder(prve => ({
      ...prve, salesPerson: Number(quotationData?.SalesPerson?.value),
      quotations: Number(quotationData?.Quotation?.id),
      department: Number(quotationData?.Department?.value),
      leadNo: quotationData?.lead?.id ? Number(quotationData?.lead?.id) : "",
      currency: quotationData?.currency ? Number(quotationData?.currency?.value) : "",
    }))
    if (quotationData?.overallDiscountPercentage || quotationData?.overallDiscountValue || quotationData?.discountFinalTotal) {
      setDiscountValue({
        discountError: "",
        discountPercentage: quotationData?.overallDiscountPercentage ? quotationData?.overallDiscountPercentage : "",
        discountValue: quotationData?.overallDiscountValue ? quotationData?.overallDiscountValue : "",
        discountTotalValue: quotationData?.discountFinalTotal ? quotationData?.discountFinalTotal : "",
        isDiscountApplied: true
      })
      setSalesOrderValues(prve => ({ ...prve, allowCommanDisCount: true }))
    }

    dispatch(fetchOptionsData(SupplierOptionPerameter(`id:${quotationData?.CustomerCode?.value}, customer:true, isLead:null`), "SalesOrderCustomerForLEad"))
    dispatch(fetchOptionsData(copyQuotasionToSalesOrder2(`id:${quotationData?.Quotation?.id}, userId:${userId}`), "copyQuotasionToSalesOrder2"))
  }

  // retun null ; set the Leead data
  function CallFromLead(params) {
    setSalesOrderValues(prve => ({
      ...prve, lead: { id: params?.id, leadNo: params?.leadNo },
      salesPerson: { value: params?.salesPerson?.id, label: params?.salesPerson?.username },
    }))
    setInitialSalesOrder(prve => ({ ...prve, leadNo: Number(params?.id), salesPerson: Number(params?.salesPerson?.id) }))
    if (params?.customer?.id) {
      dispatch(fetchOptionsData(SupplierOptionPerameter(`id:${params?.customer?.id}, customer:true, isLead:null`), "SalesOrderCustomerForLEad"))
    }
  }

  //call edit data Functions
  useEffect(()=>{ 
    
    if (  editData !== null && editData){
      fetchEditData(editData)
      setEdit(true)
    } else if (quotationData) {
      CallFromQuotation(quotationData)
      setEdit(false)
    } else if (leadData) {
      CallFromLead(leadData)
      setEdit(false)


    } else {
      setEdit(false)
    }
    if (prefetchValue?.currencyExchangeList?.length > 0 &&   quotationData === undefined){
      let currencyDefaultValue = prefetchValue?.currencyExchangeList?.filter((currency)=>currency?.label ==="Rupee")
      setSalesOrderValues(prve=>({...prve, currency:currencyDefaultValue?.[0]}))
      setInitialSalesOrder(prve =>({...prve,currency:currencyDefaultValue?.[0]?.value ? Number(currencyDefaultValue?.[0]?.value):""}))
    }
  },[  editData])

  function BeforeCloseCheckData(params) {
    if (initialSalesOrder?.id === "" && (salesOrderItemDetails?.length > 0 || otherIncomechargesList?.length > 0)) {
      setIsBulkDeleteWaring(true)
    } else {
      close()
    }
  }

  // on close clear all data
  function close(params) {
    if (setLoad) {
      setLoad()
    }
    setEdit(true)
    
    closeSalesOrderModal(index)
    setInitialSalesOrder({
      id: "",
      active: true,
      salesOrderNumber: "",
      status: "",
      salesPerson: "",
      dueDate: "",
      creditPeriod: "",
      currency: "",
      paymentTerms: "",
      customerPoNo: " ",
      customerPoDate: " ",
      childCount: "",
      leadNo: "",
      quotations: "",
      department: "",
      remarks: " ",
      buyer: " ",
      buyerAddress: " ",
      buyerContactPerson: " ",
      buyerGstinType: " ",
      buyerGstin: " ",
      buyerState: " ",
      buyerPlaceOfSupply: " ",
      consignee: " ",
      consigneeAddress: " ",
      consigneeContactPerson: " ",
      consigneeGstinType: " ",
      consigneeGstin: " ",
      consigneeState: " ",
      consigneePlaceOfSupply: " ",
      itemDetails: "",
      otherIncomeCharge: "",
      overallDiscountPercentage: "",
      overallDiscountValue: "",
      discountFinalTotal: "",
      igst: "",
      sgst: "",
      cgst: "",
      itemTotalBeforTax: "",
      otherChargesBeforTax: "",
      taxTotal: "",
      roundOff: "",
      netAmount: "",
      termsConditions: "",
      termsConditionsText: "",
      modifiedBy: "",
      createdBy: Number(userId),
    })
    setSalesOrderItemDetails([])
    setOtherIncomechargesList([])
    setSalesOrderValues({
      salesPerson: "",
      buyer: "", buyerNumber: "", buyerAddress: "", buyerContactPerson: "",
      buyerPlaceOfSupply: "",
      consignee: "", consigneeNumber: "", consigneeAddress: "", consigneeContactPerson: "", consigneePlaceOfSupply: "", otherIncomeCharge: "",
      totaltax: "", item_details_value: "", other_income_value: "", netAmount: "", roundOff: "", sgst: "", cgst: "", igst: "",
      termsCondition: "", termsConditionsText: "", Department: "", createdBy: "", createdAt: "", allowCommanDisCount: false, outOfRangeValues: [],
      lastUpdatedItemDetails: "", stringItemCombo: "", isLoading: false, isAmend: false, deleteItemDetailData: { Name: "", id: "" },
      deleteOtherExpensesData: { Name: "", id: "" }, setSelectedPartNo: "", versionList: [], currentVersionId: "", parentOrder: "", isDuplicate: "",
      currency: "", currencySymbol: "", actualigstResult: "", actualcgstResult: "", actualsgstResult: "",
    })
    setDiscountValue({
      discountError: "",
      discountPercentage: quotationData?.overallDiscountPercentage ? quotationData?.overallDiscountPercentage : "",
      discountValue: quotationData?.overallDiscountValue ? quotationData?.overallDiscountValue : "",
      discountTotalValue: quotationData?.discountFinalTotal ? quotationData?.discountFinalTotal : "",
      isDiscountApplied: false
    })
    
    
    setFormKey(prve=>prve+1)
    setCustomerDetailsLists({"buyerNameList":[],"buyerNumberList":[],
      "buyerAddressList":[], "buyerContactList":[], "buyerStatesList" :[],
      "consigneeList":[], "consigneeNumberList":[], "consigneeAddressList":[],
    "consigneeContactList":[], "consigneeStatesList":[], "otherIncomeChargelist":[], "termsConditionsList":[], "DepartmentList":[] })
  }

  // disCountModel
  async function applyDisCount() {
    let itemIdList = salesOrderItemDetails?.map((item) => Number(item?.id))
    let otherIncomeChargeIdList = otherIncomechargesList?.map((item) => Number(item?.id))
    let parame = `itemIdList:[${itemIdList}], otherIncomeChargeIdList:[${otherIncomeChargeIdList}], `
    let queryWithParameter = ""

    if (discountValue?.discountPercentage) {
      parame += `percentage: ${discountValue?.discountPercentage}`
      queryWithParameter = salesOrder2OverallDiscountPercentage(parame)

    } else if (discountValue?.discountValue) {
      parame += `totalToSubtract: ${discountValue?.discountValue} totalValue:"${salesOrderValues?.netAmount}"`
      queryWithParameter = salesOrder2OverallOverallDiscountValue(parame)

    } else if (discountValue?.discountTotalValue) {
      parame += `finalTotal: ${discountValue?.discountTotalValue} currentTotal: "${salesOrderValues?.netAmount}"`
      queryWithParameter = salesOrder2FinalTotalDiscount(parame)
    }
    if (queryWithParameter) {
      try {
        const response = await axiosInstance.post(`/itemmaster/graphql`, { query: queryWithParameter });
        const reponseData = response?.data?.data
        if (discountValue?.discountPercentage) {
          setSalesOrderItemDetails(reponseData?.salesOrder2OverallDiscountPercentage?.SalesOrderItemDetails)
          setOtherIncomechargesList(reponseData?.salesOrder2OverallDiscountPercentage?.SalesOrderOtherincomeChanges?.map(item => ({ ...item, id_: item?.id })))
        } else if (discountValue?.discountValue) {
          setSalesOrderItemDetails(reponseData?.SalesOrder2_OverallDiscountValue?.SalesOrderItemDetails)
          setOtherIncomechargesList(reponseData?.SalesOrder2_OverallDiscountValue?.SalesOrderOtherincomeChanges?.map(item => ({ ...item, id_: item?.id })))
        } else if (discountValue?.discountTotalValue) {
          setSalesOrderItemDetails(reponseData?.salesOrder2FinalTotalDiscount?.SalesOrderItemDetails)
          setOtherIncomechargesList(reponseData?.salesOrder2FinalTotalDiscount?.SalesOrderOtherincomeChanges?.map(item => ({ ...item, id_: item?.id })))
        }

        showErrorToast(true, 'success', "", "Discount Applied Successfully")
        setDiscountValue(prev => ({ ...prev, "isDiscountApplied": true }))
      } catch (error) {
        let errorFilter = ShowErrorNoties(error)
        showErrorToast(true, 'error', errorFilter, "")

      }
    }
  }

  // clear disCount
  async function ClearDisCount(allItemDetailsIds, allOtherIncomeChargesIds) {
    let itemIdList = []
    let otherIncomeChargeIdList = []
    if (allItemDetailsIds) {
      itemIdList = allItemDetailsIds
      otherIncomeChargeIdList = allOtherIncomeChargesIds
    } else {
      itemIdList = salesOrderItemDetails?.map((item) => Number(item?.id))
      otherIncomeChargeIdList = otherIncomechargesList?.map((item) => Number(item?.id))
    }
    let parame = `itemIdList:[${itemIdList}], otherIncomeChargeIdList:[${otherIncomeChargeIdList}], `
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, {
        query:
          salesOrder2ClearDiscount(parame)
      });
      const reponseData = response?.data?.data
      setSalesOrderItemDetails(reponseData?.salesOrder2ClearDiscount?.SalesOrderItemDetails)
      setOtherIncomechargesList(reponseData?.salesOrder2ClearDiscount?.SalesOrderOtherincomeChanges?.map(item => ({ ...item, id_: item?.id })))
      showErrorToast(true, 'success', "", "Discount Cleared Successfully")
      setDiscountValue(prev => ({
        ...prev, "isDiscountApplied": false, discountPercentage: "", discountValue: "",
        discountTotalValue: ""
      }))
    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorFilter, "")

    }

  }

  // onAmend or dupcate create the item details or other incomeCharge data
  async function CreateDuplicateData() {
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: salesOrder2Amand(`id:${Number(initialSalesOrder?.id)}, userId:${userId} `) });
      const reponseData = response?.data?.data?.salesOrder2Amand
      if (reponseData?.success) {
        setSalesOrderItemDetails(reponseData?.itemDetails)
        setOtherIncomechargesList(reponseData?.otherIncomeCharge?.map(item => ({ ...item, id_: item?.id })))
        showErrorToast(true, 'success', "", "Duplicate ItemDetails And Other income Charges Created Successfully")
      } else {
        let errorFilter = ShowErrorNoties(reponseData?.errors)
        showErrorToast(true, 'error', errorFilter)
      }
    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorFilter)


    }
    setSalesOrderValues(prve => ({ ...prve, isLoading: false }))
    setEdit(false)

  }

  // retun null ; dulicate The data
  function duplicaTheData(isDup) {
    setSalesOrderValues(prve => ({ ...prve, isLoading: true, createdBy: null, parentOrder: initialSalesOrder?.id, isDuplicate: isDup }))
    setOtherIncomechargesList([])
    setSalesOrderItemDetails([])
    setInitialSalesOrder(prve => ({ ...prve, status: "", createdBy: null, createdAt: "", id: "" }))
    CreateDuplicateData()

  }

  // retun objects; on change address or buyer bulk update the tax
  async function salesOrder2BulkUpdateForTax(state) {
    let itemIdList = salesOrderItemDetails?.map(item => {
      const id = Number(item?.id);
      return isNaN(id) ? null : id; // Return null for invalid IDs
    }).filter(id => id !== null) || []; // Filter out nulls and default to an empty array

    let otherIncomeChargeIdList = otherIncomechargesList?.map(item => {
      const id = Number(item?.id);
      return isNaN(id) ? null : id; // Return null for invalid IDs
    }).filter(id => id !== null) || []; // Filter out nulls and default to an empty array
    let parame = `itemDetialsIds:[${itemIdList}], otherIncomeChargesIds:[${otherIncomeChargeIdList}],states:"${state ? state : null}"`

    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, {
        query:
          salesOrder2BulkUpdateForTaxmutations(parame)
      });
      const reponseData = response?.data?.data
      setSalesOrderItemDetails(reponseData?.salesOrder2BulkUpdateForTax?.SalesOrderItemDetails)
      setOtherIncomechargesList(reponseData?.salesOrder2BulkUpdateForTax?.SalesOrderOtherincomeChanges?.map(item => ({ ...item, id_: item?.id })))
      showErrorToast(true, 'success', "", "Tax Values Updated Successfully")
    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorFilter, "")
    }
  }
  // retun null; after delete the data clear from list in item details
  const handleDeleteItemDetails = (id) => {
    if (id) {
      setSalesOrderItemDetails(prev => {
        return prev.filter(item => item?.id !== id);
      });
      let modifiedData = salesOrderItemDetails?.filter(item => item?.id !== id).map(item => Number(item?.id))
      setSalesOrderValues(prve => ({
        ...prve,
        deleteItemDetailData: { Name: "", id: "" }
      }))
      if (discountValue?.isDiscountApplied) {
        let allItemDetailsIds = modifiedData
        let allOtherIncomeChargesIds = otherIncomechargesList?.map(item => Number(item?.id))
        if (allItemDetailsIds?.length > 0) {
          ClearDisCount(allItemDetailsIds, allOtherIncomeChargesIds)
        }
      }
    }

  }
  // retun null;in other income charges on click delete button call the waring
  const getDeleteRowId = async (data) => {
    if (data?.id) {
      handltoastDeleteConfomationShow()
      setSalesOrderValues(prve => ({ ...prve, deleteOtherExpensesData: { Name: data?.otherIncomeChargesId?.name, id: data?.id } }))

    }
  }

  // retun null; after delete the data clear from listin other income charges
  const handleDeleteOtherIncomeCharges = (id) => {
    if (id) {
      setOtherIncomechargesList(prev => {
        return prev.filter(item => item.id !== id);
      });
      setSalesOrderValues(prve => ({
        ...prve,
        deleteOtherExpensesData: { Name: "", id: "" }
      }))
      let allOtherIncomeChargesIds = otherIncomechargesList.filter(item => item.id !== id).map(item => Number(item?.id_))
      let allItemDetailsIds = salesOrderItemDetails.map(item => Number(item?.id))
      ClearDisCount(allItemDetailsIds, allOtherIncomeChargesIds)
    }
  }

  // retun null ; use to change next virsion
  const forWord = () => {
    for (let x in salesOrderValues?.versionList) {
      if (Number(salesOrderValues.currentVersionId) === Number(salesOrderValues?.versionList[x])) {
        let nextIndex = Number(x) + 1;
        if (nextIndex < salesOrderValues?.versionList?.length) {
          // close()
          setSalesOrderValues(prve => ({ ...prve, "currentVersionId": salesOrderValues?.versionList[nextIndex] }))
          fetchEditData(salesOrderValues?.versionList[nextIndex])
          if (nextIndex === salesOrderValues?.versionList.length - 1) {
            setPositionOfVirsion(prve => ({ ...prve, "IsFirst": false, "IsLast": true }))
          } else {
            setPositionOfVirsion(prve => ({ ...prve, "IsFirst": false, "IsLast": false }))
          }
        }
        break;
      }
    }
  };
  // retun null ; use to change prve virsion
  const backWord = () => {
    for (let x in salesOrderValues?.versionList) {
      if (Number(salesOrderValues.currentVersionId) === Number(salesOrderValues?.versionList[x])) {
        let prevIndex = Number(x) - 1;
        if (prevIndex >= 0) {
          // close()
          setSalesOrderValues(prve => ({ ...prve, "currentVersionId": salesOrderValues?.versionList[prevIndex] }))


          fetchEditData(salesOrderValues?.versionList[prevIndex])
          if (prevIndex === 0) {
            setPositionOfVirsion(prve => ({ ...prve, "IsFirst": true, "IsLast": false }))
          } else {
            setPositionOfVirsion(prve => ({ ...prve, "IsFirst": false, "IsLast": false }))
          }
        }
        break;
      }
    }
  };
  // retun null; some item was created without save close the form delete the that created data
  async function bulkDeleteItemDetailsOtherIncomeCharges(params) {
    setSalesOrderValues(prve => ({ ...prve, isLoading: true }))
    try {
      let itemIdList = salesOrderItemDetails?.map((item) => Number(item?.id))
      let otherIncomeChargeIdList = otherIncomechargesList?.map((item) => Number(item?.id))
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: salesOrder2AmandCancel(`itemDetails: [${itemIdList}], otherIncomeCharge: [${otherIncomeChargeIdList}]`) })
      let reponseData = response?.['data']?.['data']?.['salesOrder2AmandCancel']
      if (reponseData?.success) {
        close()
      } else {
        showErrorToast(true, "error", reponseData?.errors)
      }
    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, "error", errorFilter)
    }
    setSalesOrderValues(prve => ({ ...prve, isLoading: false }))
  }

  // return null; Cancel the SalesOrder
  async function CancelTheSalesOrder() {
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: salesOrder2CanceledMutation(`id:${initialSalesOrder?.id}`) })
      let reponseData = response?.['data']?.['data']?.['salesOrder2CanceledMutation']
      if (reponseData?.success) {
        setInitialSalesOrder(prev => ({ ...prev, status: "Canceled" }))
      } else {
        showErrorToast(true, "error", reponseData?.errors)
      }
    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, "error", errorFilter)
    }

  }

  // return null; Delete the SalesOrder
  async function DeleteTheSalesOrder() {
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: salesOrder2DeleteMutation(`id:${initialSalesOrder?.id}`) })
      let reponseData = response?.['data']?.['data']?.['salesOrder2DeleteMutation']
      if (reponseData?.success) {
        close()
      } else {
        showErrorToast(true, "error", reponseData?.errors)
      }
    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, "error", errorFilter)
    }

  }

  // retun Boolen; on change Currency  update the amount and rate
  async function salesOrder2CurrencyConvertion(CurrencyId) {
    let itemIdList = salesOrderItemDetails?.map(item => {
      const id = Number(item?.id);
      return isNaN(id) ? null : id; // Return null for invalid IDs
    }).filter(id => id !== null) || []; // Filter out nulls and default to an empty array

    let otherIncomeChargeIdList = otherIncomechargesList?.map(item => {
      const id = Number(item?.id);
      return isNaN(id) ? null : id; // Return null for invalid IDs
    }).filter(id => id !== null) || []; // Filter out nulls and default to an empty array
    let parame = `itemIdList:[${itemIdList}], otherIncomeChargeIdList:[${otherIncomeChargeIdList}], currentCurrency:${CurrencyId ? (CurrencyId) : null}
    , preCurrency: ${salesOrderValues?.currency?.value ? salesOrderValues?.currency?.value : null}`



    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, {
        query:
          salesOrder2CurrencyConvertions(parame)
      });
      const reponseData = response?.data?.data
      if (reponseData?.salesOrder2CurrencyConvertions?.success) {
        setSalesOrderItemDetails(reponseData?.salesOrder2CurrencyConvertions?.SalesOrderItemDetails)
        setOtherIncomechargesList(reponseData?.salesOrder2CurrencyConvertions?.SalesOrderOtherincomeChanges?.map(item => ({ ...item, id_: item?.id })))

        showErrorToast(true, 'success', "", "Currency Updated Successfully")
        return true
      } else {
        let errorFilter = ShowErrorNoties(reponseData?.salesOrder2CurrencyConvertions?.errors)
        showErrorToast(true, 'error', errorFilter, "")
        return false
      }
    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorFilter, "")
      return false
    }
  }

  async function GetSalesOrderPDF(params) {
    setSalesOrderValues(prve => ({ ...prve, isLoading: true }))
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, {
        query:
          GeneratePdfForSalesOrder(`id:${initialSalesOrder?.id}`)
      });
      const reponseData = response?.data?.data
      if (reponseData?.GeneratePdfForSalesOrder?.success) {
        const base64Data = reponseData.GeneratePdfForSalesOrder.pdfData;

        // Convert base64 string to a Blob
        const byteCharacters = atob(base64Data);


        const byteNumbers = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([byteNumbers], { type: 'application/pdf' });
        // vnd.openxmlformats-officedocument.wordprocessingml.document 
        // Create a link element for downloading
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.target = '_blank';
        link.click(); // Trigger the download

        // Clean up
        window.URL.revokeObjectURL(link.href);
      }

    } catch (error) {

      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorFilter, "")
      return false
    }
    setSalesOrderValues(prve => ({ ...prve, isLoading: false }))
  }

  // fetch time line Datas
  async function fetchTimelineData(id){
    // setLoading(true)
    try{
      const respones = await axiosInstance.post(`/itemmaster/graphql`, {
        query:  leadTimeLine(id)
      })
      let timeLime = respones?.data?.data?.leadTimeLine
      setTimeLineValue(prev =>({...prev,treeData:[timeLime] , isShow:true}))
    } catch(error){
      console.log(error);
    }
    // setLoading(false)
  }

  function getOpenmodelId(id, model){
    
    if (id && model==="Sales Order"){
      openSalesOrderModal(id)
    } else if(id && model==="Quotation"){
      openQuotationModal(id)
    }  else if(id && model==="Lead"){
      openLeadModal(id)
    }
    
  }
  return (
    <>
    <ToastContainer/>
    {
      salesOrderValues?.isshowWaring   && WarningmodelSalesorder(salesOrderValues?.isshowWaring, salesOrderValues?.waring, setSalesOrderValues)
    }
    <HierarchicalTreeView data={timeLineValue?.treeData} show={timeLineValue?.isShow} getOpenmodelId={getOpenmodelId} currentID={initialSalesOrder.id}
    close={()=>{setTimeLineValue((prev=>({...prev, isShow:false})))}}/>
    {<BallTriangleComponent isshow={salesOrderValues?.isLoading}/>}
    <Modal key={index} show={true} fullscreen onHide={BeforeCloseCheckData }>
          <div className='itemMaster_Top mx-3 mt-3 d-flex justify-content-center card'   style={{ width: '98%' }}>
            <div className="row  align-items-center  justify-content-between ">
              {/* justify-content-between */}
              <div className="col-4  d-flex align-items-center  justify-content-between">
                <h3 className='mx-2 commanModelTitleColor'>
                  <i className='fa-solid fa-arrow-left fa-sm me-2 commanModelTitleColor' onClick={BeforeCloseCheckData}></i>
                  Sales Order {initialSalesOrder?.status ?  <span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen'> {initialSalesOrder?.status} </span>:""}
                  {salesOrderValues?.isAmend ? <span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen'> Amend </span>:
                  salesOrderValues?.isDuplicate? <span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen'> Duplicate</span>:""}
                  <sapn className="commanModelTitleColor cursor-pointer" onClick={()=>{fetchTimelineData(initialSalesOrder?.leadNo)}}>Tree View</sapn>
                </h3>
                
              </div>
              <div className="col-3 px-4  " hidden={salesOrderValues?.isAmend? true: salesOrderValues?.isDuplicate? true :
                salesOrderValues?.versionList?.length <= 1 ? true    : false}>
                    <p className='text-start  p-0 m-0 d-flex justify-content-center'><span className=''>Version</span></p>
                    <div className='d-flex justify-content-center'>
                      <AiOutlineCaretLeft size={30} onClick={backWord} style={{color:positionOfVirsion?.IsFirst? "#ffff":""}} disabled={positionOfVirsion?.IsFirst}/>
                      <span className='fs-5 px-1 ms-1  '>{initialSalesOrder?.salesOrderNumber}</span>
                      <AiOutlineCaretRight size={30} onClick={forWord} style={{color:positionOfVirsion?.IsLast? "#ffff":""}} disabled={positionOfVirsion?.IsLast}/>
                    </div>
              </div>
              <div className="col-5">
                    <div className="row">
                        <div className="col-9">
                          <h6 className='text-end'   >
                            { salesOrderValues?.createdBy?.username ?  `Created By : ${salesOrderValues?.createdBy?.username}`: ""}
                          </h6>
                          <h6 className='text-end ' onClick={()=>{console.log(initialSalesOrder);
                          }}>
                              {initialSalesOrder?.createdAt ? `Last Modified Date : ${initialSalesOrder?.createdAt} (${initialSalesOrder?.createdTime})`: ""}
                          </h6>
                        </div>
                        <div className="col-3 text-end">
                            <i className="fa-solid fa-pen fs-5 text-primary pe-3"
                            hidden={!initialSalesOrder?.active || (!edit) || initialSalesOrder?.status === "Submit" ? true :false}
                            onClick={()=>{setEdit(false)}}
                                ></i>
                              <PiPrinterLight
                            className='fs-3'
                            onClick={GetSalesOrderPDF}
                            hidden={initialSalesOrder?.status === "Submit"? false: initialSalesOrder?.status === "Draft" ? false : true}
                             />
                            <TiCancel
                            hidden={!initialSalesOrder?.active || (!edit) || initialSalesOrder?.status === "Canceled" ? true :false}
                            data-bs-toggle="tooltip" data-bs-placement="top" title="Cancle"
                            className='fs-2 pb-1'
                            style={{ color: '#7f7f7f', cursor: "pointer" }}
                            onClick={()=>{setIsCancelWarningShow(true)}}
                                
                            />
                            <i className="fa-solid fa-copy  fs-5 pt-1 mx-3 text-success"  onClick={()=>{  duplicaTheData(true)}}
                            hidden={!initialSalesOrder?.active || (!edit)} data-bs-toggle="tooltip" data-bs-placement="top" title="Duplicate" role="button" style={{cursor: "pointer"}}></i>
                            <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  "
                            onClick={()=>{setIsDeleteWarningShow(true)}}
                            hidden={initialSalesOrder?.status ==="Canceled"? !initialSalesOrder?.active  || !(edit): true} 
                            ></i>
                          
                        </div>
                    </div>
              </div>
            </div>
          </div>
        <Modal.Body>
          <div style={{ width: "98%" }}>
            <Formik initialValues={initialSalesOrder}
              onSubmit={handleSubmit}
              validationSchema={SalesOrder2schema}
              enableReinitialize key={formKey}
            >
              {({ errors, submitForm, touched, setFieldValue, values, setValues }) => {
                return (
                  <Form>
                    {/*Basic Details */}
                    <div className="row ms-3 mt-2  ">
                      {/* left Side */}
                      <div className="col card  shadow   py-3">
                        {/* <p>{initialSalesOrder?.salesOrderNumber?.linkedModelId}</p> */}
                        <div className="row">
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='salesOrderNumber' id="salesOrderNumber" disabled values={initialSalesOrder?.salesOrderNumber} className='w-100 staan-form-input' />
                            <label htmlFor="salesOrderNumber" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                              console.log(initialSalesOrder)
                            }} >Sales Order No</label>
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='createdAt' id='createdAt' value={initialSalesOrder?.createdAt} disabled className='w-100 staan-form-input' />
                            <label htmlFor="createdAt" className='staan-form-input-label pt-1 px-1 mx-2'>Sales Order Date</label>
                          </div>
                          <div className="col-6 staan-input-group">
                            <Select
                              onInputChange={(e) => {
                                e ?
                                  dispatch(
                                    fetchOptionsData(userPermissionforOptions(`userName:"${e}", isSales: true`), "SalesOrderSalesperson")
                                  ) : setSalesPersonList([])
                              }}
                              options={salesPersonList}
                              name='salesPerson'
                              value={salesOrderValues?.salesPerson}
                              className='mt-3'
                              isDisabled={edit}
                              onChange={(option) => {
                                setFieldValue('salesPerson', option ? Number(option?.value) : null)
                                setSalesOrderValues(prve => ({
                                  ...prve,
                                  salesPerson: option ? option : null
                                }))
                              }}
                              styles={customSelectStyle}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Sales Person<span className='text-danger'>*</span></label>
                            {touched.salesPerson && errors.salesPerson && <small>{errors.salesPerson}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='date' name='dueDate' id='dueDate' disabled={edit} className='w-100 staan-form-input' />
                            <label htmlFor="dueDate" className='staan-form-input-label pt-1 px-1 mx-2'>Due Date<span className='text-danger'>*</span></label>
                            {touched.dueDate && errors.dueDate && <small>{errors.dueDate}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='number' name='creditPeriod' disabled={edit} id='creditPeriod' placeholder='Enter Credit Period' className='w-100 staan-form-input' />
                            <label htmlFor="creditPeriod" className='staan-form-input-label pt-1 px-1 mx-2'>Credit Period<span className='text-danger'>*</span></label>
                            {touched.creditPeriod && errors.creditPeriod && <small>{errors.creditPeriod}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='paymentTerms' id='paymentTerms' disabled={edit} placeholder='Enter Payment Terms' className='w-100 staan-form-input' />
                            <label htmlFor="paymentTerms" className='staan-form-input-label pt-1 px-1 mx-2'>Payment Terms<span className='text-danger'>*</span></label>
                            {touched.paymentTerms && errors.paymentTerms && <small>{errors.paymentTerms}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='customerPoNo' id="customerPoNo" disabled={edit} placeholder='Enter Customer Po No' className='w-100 staan-form-input' />
                            <label htmlFor="customerPoNo" className='staan-form-input-label pt-1 px-1 mx-2'>Customer PO No<span className='text-danger'>*</span></label>
                            {touched.customerPoNo && errors.customerPoNo && <small>{errors.customerPoNo}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='date' name='customerPoDate' id='customerPoDate' disabled={edit} className='w-100 staan-form-input' />
                            <label htmlFor="customerPoDate" className='staan-form-input-label pt-1 px-1 mx-2'>Customer PO Date<span className='text-danger'>*</span></label>
                            {touched.customerPoDate && errors.customerPoDate && <small>{errors.customerPoDate}</small>}
                          </div>
                          <div
                            className='col-6 staan-input-group'>
                            <Select
                              onInputChange={e => {
                                e.trim()
                                  ? dispatch(
                                    fetchOptionsData(
                                      DepartmentQuery(
                                        `name: "${e.trim()}"`
                                      ),
                                      'SalesOrderdepartmant'
                                    )
                                  )
                                  : setCustomerDetailsLists(prve => ({
                                    ...prve, DepartmentList: []
                                  }))
                              }}
                              isDisabled={initialSalesOrder?.id}
                              name='department'
                              value={salesOrderValues?.Department}
                              options={customerDetailsLists?.DepartmentList}
                              onChange={option => {
                                setFieldValue(
                                  'department',
                                  option ? Number(option.value) : null
                                )
                                setSalesOrderValues(prve => ({
                                  ...prve,
                                  Department: option ? option : null
                                }))

                              }}
                              className='mt-3'
                            />
                            <label

                              className='staan-form-input-label pt-1 px-1 mx-2'
                            >
                              Department<span className='text-danger'>*</span>
                            </label>
                            {touched.department && errors.department && (
                              <small>{errors.department}</small>
                            )}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='ProductionOrderNo' disabled id='ProductionOrderNo' className='w-100 staan-form-input' />
                            <label htmlFor="ProductionOrderNo" className='staan-form-input-label pt-1 px-1 mx-2'>Production Order No</label>
                            {/* {  touched. &&  errors.customerPoNo && <small>{errors.customerPoNo}</small>} */}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='DevelopmentOrderNo' id="DevelopmentOrderNo" disabled className='w-100 staan-form-input' />
                            <label htmlFor="DevelopmentOrderNo" className='staan-form-input-label pt-1 px-1 mx-2'>Development Order No</label>
                            {/* {  touched. &&  errors.customerPoNo && <small>{errors.customerPoNo}</small>} */}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='leadNo' id='leadNo' value={salesOrderValues?.lead?.leadNo} disabled className='w-100 staan-form-input' />
                            <label htmlFor="leadNo" className='staan-form-input-label pt-1 px-1 mx-2'>lead No</label>
                            {touched.leadNo && errors.leadNo && <small>{errors.leadNo}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Field type='text' name='quotations' id="quotations" value={salesOrderValues?.quotations?.label} disabled className='w-100 staan-form-input' />
                            <label htmlFor="quotations" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                              console.log(salesOrderValues)
                            }}>Quotations No</label>
                            {touched.quotations && errors.quotations && <small>{errors.quotations}</small>}
                          </div>
                          <div className="col-3 staan-input-group"  >
                            <Select
                              options={prefetchValue?.currencyExchangeList}
                              isClearable
                              name='Currency'
                              value={salesOrderValues?.currency}
                              className='mt-3'
                              isDisabled={edit}
                              onChange={async (option) => {
                                if ((salesOrderItemDetails?.length > 0 || otherIncomechargesList?.length > 0) && option) {
                                  const currencyResponse = await salesOrder2CurrencyConvertion(Number(option?.value));
                                  if (currencyResponse) {
                                    setSalesOrderValues(prev => ({ ...prev, currency: option }));
                                    setFieldValue('currency', Number(option?.value));
                                  }
                                } else {
                                  setSalesOrderValues(prev => ({ ...prev, currency: option }));
                                  setFieldValue('currency', Number(option?.value));
                                }
                              }}
                              styles={customSelectStyle}
                            />
                            <label className='staan-form-input-label pt-1  px-1 mx-2'>Currency<span className='text-danger'>*</span></label>
                            {touched.Currency && errors.Currency && <small>{errors.Currency}</small>}
                          </div>
                          <div className="col-3 staan-input-group">
                            <Field type='text' name='Exchange rate' id="Exchange rate" value={salesOrderValues?.currency?.rate} disabled className='w-100 staan-form-input' />
                            <label htmlFor="Exchange rate" className='staan-form-input-label pt-1 px-1 mx-2'>Exchange rate</label>

                          </div>
                        </div>

                      </div>
                      {/*Customer Details*/}
                      <div className="col card  shadow  mx-2 py-2">
                        {/* Buyer GST Details */}
                        <p className='fw-bolder fs-6 commanModelTitleColor'>Buyer Details</p>
                        <div className="row">
                          <div className="col-6 staan-input-group">
                            <Select
                              onInputChange={(e) => {
                                e ?
                                  dispatch(fetchOptionsData(SupplierOptionPerameter(`companyName:"${e}", customer:true`), "SalesOrderCustomer"))
                                  : setCustomerDetailsLists(prve => ({
                                    ...prve, buyerNameList: []
                                  }))
                              }}
                              onChange={(option => {
                                setCustomerDetailsLists(prve => ({
                                  ...prve, buyerContactList: option?.contact, buyerAddressList: option?.address, buyerStatesList: []
                                }))
                                setValues((prevValues) => ({
                                  ...prevValues,
                                  buyer: Number(option?.value),
                                  buyerGstinType: option?.gstinType ? option?.gstinType : "",
                                  buyerGstin: option?.gstin ? option?.gstin : "",
                                  buyerState: "",
                                  buyerPlaceOfSupply: null
                                }));

                                setSalesOrderValues(prve => ({
                                  ...prve,
                                  buyerAddress: null, buyerContactPerson: null,
                                  buyer: option ? option : null, buyerNumber: option ? { value: option?.id, label: option?.supplierNo } : null
                                }))
                                if ((salesOrderItemDetails?.length > 0 || otherIncomechargesList?.length > 0) && option) {
                                  salesOrder2BulkUpdateForTax(null)
                                }
                              })}
                              isDisabled={edit}
                              isClearable
                              value={salesOrderValues?.buyer}
                              options={customerDetailsLists?.buyerNameList}
                              name='buyer'
                              className='mt-3'
                              styles={customSelectStyle}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Buyer<span className='text-danger'>*</span></label>
                            {touched.buyer && errors.buyer && <small>{errors.buyer}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Select
                              options={customerDetailsLists?.buyerNumberList}
                              onInputChange={(e) => {
                                e ?
                                  dispatch(fetchOptionsData(SupplierOptionPerameter(`supplierNo:"${e}", customer:true`), "SalesOrderCustomerNumber"))
                                  : setCustomerDetailsLists(prve => ({
                                    ...prve, buyerNumberList: []
                                  }))
                              }}
                              onChange={(option => {
                                setSalesOrderValues(prve => ({
                                  ...prve,
                                  buyerAddress: null, buyerContactPerson: null, buyerPlaceOfSupply: null,
                                  buyerNumber: option ? option : null, buyer: option ? { value: option?.id, label: option?.supplierName } : null
                                }))
                                setValues((prevValues) => ({
                                  ...prevValues,
                                  buyerNumber: option?.value,
                                  buyerGstinType: option?.gstinType ? option?.gstinType : "",
                                  buyerGstin: option?.gstin ? option?.gstin : "",
                                  buyerState: "",
                                  buyerPlaceOfSupply: null
                                }));
                                setCustomerDetailsLists(prve => ({
                                  ...prve, buyerContactList: option?.contact, buyerAddressList: option?.address, buyerStatesList: []
                                }))
                                if (salesOrderItemDetails?.length > 0 || otherIncomechargesList?.length > 0) {
                                  salesOrder2BulkUpdateForTax(null)
                                }
                              })}
                              isDisabled={edit}
                              isClearable
                              value={salesOrderValues?.buyerNumber}
                              name='buyerNumber'
                              className='mt-3'
                              styles={customSelectStyle}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Buyer Number<span className='text-danger'>*</span></label>
                            {touched.buyerNumber && errors.buyerNumber && <small>{errors.buyerNumber}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Select
                              options={customerDetailsLists?.buyerAddressList}
                              value={salesOrderValues?.buyerAddress}
                              isDisabled={edit}
                              onChange={(option => {
                                setSalesOrderValues(prve => ({ ...prve, buyerAddress: option ? option : null }))
                                setFieldValue('buyerState', option?.fullAddredd?.state)
                                setFieldValue("buyerAddress", Number(option?.value))
                                setCustomerDetailsLists(prve => ({
                                  ...prve, buyerStatesList: getStates(option?.fullAddredd?.country),
                                }))
                                // select the buyerPlaceOfSupply
                                setSalesOrderValues(prve => ({ ...prve, buyerPlaceOfSupply: option ? { values: option?.fullAddredd?.state, label: option?.fullAddredd?.state } : null }))
                                setFieldValue("buyerPlaceOfSupply", option ? option?.fullAddredd?.state : null)
                                if ((salesOrderItemDetails?.length > 0) && option) {
                                  salesOrder2BulkUpdateForTax(option?.fullAddredd?.state)
                                }
                              })}
                              name='buyerAddress'
                              className='mt-3'
                              styles={customSelectStyle}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Buyer Address<span className='text-danger'>*</span></label>
                            {touched.buyerAddress && errors.buyerAddress && <small>{errors.buyerAddress}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Select
                              options={customerDetailsLists?.buyerContactList}
                              value={salesOrderValues?.buyerContactPerson}
                              onChange={(option => {
                                setSalesOrderValues(prve => ({ ...prve, buyerContactPerson: option ? option : null }))
                                setFieldValue("buyerContactPerson", Number(option?.value))
                              })}

                              isDisabled={edit}
                              name='buyerContactPerson'
                              className='mt-3'
                              styles={customSelectStyle}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Contact Person<span className='text-danger'>*</span></label>
                            {touched.buyerContactPerson && errors.buyerContactPerson && <small>{errors.buyerContactPerson}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <p className='p-2   fs-6 fw-bolder'>Address</p>
                            {salesOrderValues?.buyerAddress?.fullAddredd ?
                              <>
                                <p className='ps-2 lh-1 '>{salesOrderValues?.buyerAddress?.fullAddredd?.addressLine1} {salesOrderValues?.buyerAddress?.fullAddredd?.addressLine2},</p>
                                <p className='ps-2 lh-1 '>{salesOrderValues?.buyerAddress?.fullAddredd?.city} {salesOrderValues?.buyerAddress?.fullAddredd?.state},</p>
                                <p className='ps-2 lh-1 '>{salesOrderValues?.buyerAddress?.fullAddredd?.country}</p>
                                <p className='ps-2 lh-1 '>Pincode   :{salesOrderValues?.buyerAddress?.fullAddredd?.pincode}</p>
                              </> :
                              <></>
                            }
                          </div>
                          <div className="col-6">
                            <p className='p-2 fs-6 fw-bolder'>Contact </p>
                            {salesOrderValues?.buyerContactPerson ?
                              <>

                                <p className='ps-2 lh-1'>Mobile : {salesOrderValues?.buyerContactPerson?.mobile} </p>
                                <p className='ps-2 lh-1'> Email  : {salesOrderValues?.buyerContactPerson?.Email} </p>
                              </> :
                              ""
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col card  shadow  mx-2 py-2">
                        {/* Consignee Details */}
                        <p className=' fw-bolder fs-6 commanModelTitleColor'>Consignee Details</p>
                        <div className="row">
                          <div className="col-6 staan-input-group">
                            <Select
                              isClearable
                              onInputChange={(e) => {
                                e ?
                                  dispatch(fetchOptionsData(SupplierOptionPerameter(`companyName:"${e}", customer:true`), "SalesOrderConsignee"))
                                  : setCustomerDetailsLists(prve => ({
                                    ...prve, consigneeList: []
                                  }))
                              }}
                              onChange={(option => {
                                setCustomerDetailsLists(prve => ({
                                  ...prve, consigneeContactList: option?.contact, consigneeAddressList: option?.address
                                }))
                                setValues((prevValues) => ({
                                  ...prevValues,
                                  consignee: Number(option?.value),
                                  consigneeGstinType: option?.gstinType ? option?.gstinType : "",
                                  consigneeGstin: option?.gstin ? option?.gstin : "",
                                  consigneeState: "",
                                }));
                                setSalesOrderValues(prve => ({
                                  ...prve,
                                  consigneeAddress: null, consigneeContactPerson: null,
                                  consignee: option ? option : null, consigneeNumber: option ? { value: option?.id, label: option?.supplierNo } : null
                                }))

                              })}
                              isDisabled={edit}
                              options={customerDetailsLists?.consigneeList}
                              value={salesOrderValues?.consignee}
                              name='consignee'
                              className='mt-3'
                              styles={customSelectStyle}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Consignee<span className='text-danger'>*</span></label>
                            {touched.consignee && errors.consignee && <small>{errors.consignee}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Select
                              onInputChange={(e) => {
                                e ?
                                  dispatch(fetchOptionsData(SupplierOptionPerameter(`companyName:"${e}", customer:true`), "SalesOrderConsigneeNumber"))
                                  : setCustomerDetailsLists(prve => ({
                                    ...prve, consigneeNumberList: []
                                  }))
                              }}
                              onChange={(option => {
                                setSalesOrderValues(prve => ({
                                  ...prve,
                                  consigneeAddress: null, consigneeContactPerson: null,
                                  consigneeNumber: option ? option : null, consignee: option ? { value: option?.id, label: option?.supplierName } : null
                                }))

                                setCustomerDetailsLists(prve => ({
                                  ...prve, consigneeContactList: option?.contact, consigneeAddressList: option?.address
                                }))
                                setValues((prevValues) => ({
                                  ...prevValues,
                                  consigneeNumber: option?.value,
                                  consigneeGstinType: option?.gstinType ? option?.gstinType : "",
                                  consigneeGstin: option?.gstin ? option?.gstin : "",
                                  consigneeState: "",
                                }));
                              })}
                              options={customerDetailsLists?.consigneeNumberList}
                              value={salesOrderValues?.consigneeNumber}
                              isClearable
                              isDisabled={edit}
                              name='consigneeNumber'
                              className='mt-3'
                              styles={customSelectStyle}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Consignee Number<span className='text-danger'>*</span></label>
                            {touched.consigneeNumber && errors.consigneeNumber && <small>{errors.consigneeNumber}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Select
                              options={customerDetailsLists?.consigneeAddressList}
                              name='consigneeAddress'
                              className='mt-3'
                              value={salesOrderValues?.consigneeAddress}
                              styles={customSelectStyle}
                              isDisabled={edit}
                              onChange={(option => {
                                setSalesOrderValues(prve => ({ ...prve, consigneeAddress: option ? option : null }))
                                setFieldValue('consigneeState', option?.fullAddredd?.state)
                                setFieldValue('consigneeAddress', Number(option?.value))
                                setCustomerDetailsLists(prve => ({
                                  ...prve, consigneeStatesList: getStates(option?.fullAddredd?.country),
                                }))
                                // update the consigneePlaceOfSupply
                                setSalesOrderValues(prve => ({ ...prve, consigneePlaceOfSupply: option ? { values: option?.fullAddredd?.state, label: option?.fullAddredd?.state } : null }))
                                setFieldValue("consigneePlaceOfSupply", option ? option?.fullAddredd?.state : null)
                              })}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Consignee Address<span className='text-danger'>*</span></label>
                            {touched.consigneeAddress && errors.consigneeAddress && <small>{errors.consigneeAddress}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <Select
                              options={customerDetailsLists?.consigneeContactList}
                              name='consigneeContactPerson'
                              value={salesOrderValues?.consigneeContactPerson}
                              className='mt-3'
                              isDisabled={edit}
                              styles={customSelectStyle}
                              onChange={(option => {
                                setSalesOrderValues(prve => ({ ...prve, consigneeContactPerson: option ? option : null }))
                                setFieldValue('consigneeContactPerson', Number(option?.value))
                              })}
                            />
                            <label className='staan-form-input-label  px-1 mx-2'>Contact Person<span className='text-danger'>*</span></label>
                            {touched.consigneeContactPerson && errors.consigneeContactPerson && <small>{errors.consigneeContactPerson}</small>}
                          </div>
                          <div className="col-6 staan-input-group">
                            <p className='p-2 fs-6 fw-bolder'>Address</p>
                            {salesOrderValues?.consigneeAddress?.fullAddredd ?
                              <>
                                <p className='ps-2 lh-1'>{salesOrderValues?.consigneeAddress?.fullAddredd?.addressLine1} {salesOrderValues?.consigneeAddress?.fullAddredd?.addressLine2},</p>
                                <p className='ps-2 lh-1'>{salesOrderValues?.consigneeAddress?.fullAddredd?.city} {salesOrderValues?.consigneeAddress?.fullAddredd?.state},</p>
                                <p className='ps-2 lh-1'> {salesOrderValues?.consigneeAddress?.fullAddredd?.country}</p>
                                <p className='ps-2 lh-1'>Pincode   :{salesOrderValues?.consigneeAddress?.fullAddredd?.pincode}</p>
                              </>
                              : ""
                            }

                          </div>
                          <div className="col-6">
                            <p className='p-2 fs-6 fw-bolder'>Contact </p>
                            {salesOrderValues?.consigneeContactPerson ?
                              <>

                                <p className='ps-2 lh-1'>Mobile : {salesOrderValues?.consigneeContactPerson?.mobile} </p>
                                <p className='ps-2 lh-1'> Email  : {salesOrderValues?.consigneeContactPerson?.Email} </p>
                              </>
                              : ""
                            }

                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="row  ms-3 mt-3">
                      <div className="col card  shadow  mx-2 py-2">
                        <p className='fw-bolder fs-6 commanModelTitleColor'>Buyer GST Details</p>
                        <div className="row">
                          <div className="col staan-input-group">
                            <Field type='text' name='buyerGstinType' id='buyerGstinType' disabled className='w-100 staan-form-input' />
                            <label htmlFor="buyerGstinType" className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN Type</label>
                            {touched.buyerGstinType && errors.buyerGstinType && <small>{errors.buyerGstinType}</small>}
                          </div>
                          <div className="col staan-input-group">
                            <Field type='text' name='buyerGstin' id='buyerGstin' disabled className='w-100 staan-form-input' />
                            <label htmlFor="buyerGstin" className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN</label>
                            {touched.buyerGstin && errors.buyerGstin && <small>{errors.buyerGstin}</small>}

                          </div>
                          <div className="col staan-input-group">
                            <Field type='text' name='buyerState' id='buyerState' disabled className='w-100 staan-form-input' />
                            <label htmlFor="buyerState" className='staan-form-input-label pt-1 px-1 mx-2'>State</label>
                            {touched.buyerState && errors.buyerState && <small>{errors.buyerState}</small>}

                          </div>
                          <div className="col staan-input-group">
                            <Select
                              options={customerDetailsLists?.buyerStatesList}
                              name='buyerPlaceOfSupply'
                              className='my-3'
                              value={salesOrderValues?.buyerPlaceOfSupply}
                              styles={customSelectStyle}
                              isDisabled={edit}
                              onChange={(option) => {
                                setSalesOrderValues(prve => ({ ...prve, buyerPlaceOfSupply: option ? option : null }))
                                setFieldValue("buyerPlaceOfSupply", option ? option?.label : null)
                              }}
                            />
                            <label className='staan-form-input-label pt-1 px-1 mx-2'>Place Of Supply<span className='text-danger'>*</span></label>
                            {touched.buyerPlaceOfSupply && errors.buyerPlaceOfSupply && <small>{errors.buyerPlaceOfSupply}</small>}

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row  ms-3 mt-3">
                      <div className="col card  shadow  mx-2 py-2">
                        <p className='fw-bolder fs-6 commanModelTitleColor'>Consignee GST Details</p>
                        <div className="row">
                          <div className="col staan-input-group">
                            <Field type='text' name='consigneeGstinType' id='consigneeGstinType' disabled className='w-100 staan-form-input' />
                            <label htmlFor="consigneeGstinType" className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN Type</label>
                            {touched.consigneeGstinType && errors.consigneeGstinType && <small>{errors.consigneeGstinType}</small>}
                          </div>
                          <div className="col staan-input-group">
                            <Field type='text' name='consigneeGstin' id='consigneeGstin' disabled className='w-100 staan-form-input' />
                            <label htmlFor="consigneeGstin" className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN</label>
                            {touched.consigneeGstin && errors.consigneeGstin && <small>{errors.consigneeGstin}</small>}

                          </div>
                          <div className="col staan-input-group">
                            <Field type='text' name='consigneeState' disabled id='consigneeState' className='w-100 staan-form-input' />
                            <label htmlFor="consigneeState" className='staan-form-input-label pt-1 px-1 mx-2'>State</label>
                            {touched.consigneeState && errors.consigneeState && <small>{errors.consigneeState}</small>}
                          </div>
                          <div className="col staan-input-group">

                            <Select
                              options={customerDetailsLists?.consigneeStatesList}
                              name='consigneePlaceOfSupply'
                              className='my-3'
                              value={salesOrderValues?.consigneePlaceOfSupply}
                              styles={customSelectStyle}
                              isDisabled={edit}
                              onChange={(option) => {
                                setSalesOrderValues(prve => ({ ...prve, consigneePlaceOfSupply: option ? option : null }))
                                setFieldValue("consigneePlaceOfSupply", option ? option?.label : null)
                              }}
                            />
                            <label className='staan-form-input-label pt-1 px-1 mx-2'>Place Of Supply<span className='text-danger'>*</span></label>
                            {touched.consigneePlaceOfSupply && errors.consigneePlaceOfSupply && <small>{errors.consigneePlaceOfSupply}</small>}

                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Item Details */}
                    <div className="row card  shadow ms-3 mt-3 py-3">
                      <div className="row">
                        <div className="col-4">
                          <p className='fw-bolder fs-6 commanModelTitleColor m-2' onClick={() => {
                            console.log(discountValue, salesOrderValues);
                          }}>Item Details</p>
                        </div>
                        <div className="col-4">
                          <span className='me-3'>
                            {/* <i className='fa-solid fa-cart-shopping me-2' style={{ cursor: 'pointer', color: 'brown' }}></i> */}
                            <button type='button' className='btn btn-outline-warning btn-sm px-3 mx-2 py-2' onClick={() => { console.log(salesOrderValues?.setSelectedPartNo, selectedStockStatementDetails, isShowStockStatementModal) }}    >
                              <LuGift />
                            </button>
                            Item Combo Not Saved
                          </span>
                          <span className='me-3'>
                            {/* <i className='fa-solid fa-cart-shopping me-2' style={{ cursor: 'pointer', color: 'green' }}></i> */}
                            <button type='button' className='btn btn-outline-success btn-sm px-3 mx-2 py-2'     >
                              <LuGift />
                            </button>
                            Item Combo Saved
                          </span>
                        </div>
                        <div className="col-4 text-end">
                          <button type="button" className="btn btn-outline-primary px-4" disabled={edit} onClick={() => { setIsShowItemDetails(true) }}> <i className='bx bxs-plus-circle me-2'></i>New</button>
                        </div>
                      </div>
                      <div className="ag-theme-alpine mt-2" style={{ height: '40rem', width: '100%' }}>
                        <AgGridReact
                          rowData={salesOrderItemDetails}
                          columnDefs={COLUMNS}
                          defaultColDef={DEFAULT_COL_DEF}
                          components={COMPONENTS}
                        />
                      </div>

                    </div>
                    {/* Terms & Conditions ,Other Charges and Amount   */}
                    <div className="row justify-content-between ms-3 mt-4">
                      {/* Terms & Conditions */}
                      <div className="col card shadow-lg">
                        <div className="row">
                          <div className="col-6 staan-input-group">
                            <Select
                              onInputChange={(e) => {
                                e ?
                                  dispatch(fetchOptionsData(TermsConditionsFetchQuery(`pageSize:200, name:"${e}", module:"Sales_order"`), "SalesOrderTermsConditionsFetchQuery"))
                                  : setCustomerDetailsLists(prve => ({
                                    ...prve, termsConditionsList: []
                                  }))
                              }}
                              options={customerDetailsLists?.termsConditionsList}

                              value={salesOrderValues?.termsCondition}
                              name='termsConditions'
                              className='my-3'
                              isDisabled={edit}
                              onChange={(option) => {
                                setValues((prevValues) => ({
                                  ...prevValues,
                                  termsConditions: option ? Number(option?.value) : "",
                                  termsConditionsText: option ? option?.tc : ""
                                }));
                                setSalesOrderValues(prve => ({
                                  ...prve,
                                  termsCondition: option,
                                  termsConditionsText: option?.tc
                                }))
                              }}
                              styles={customSelectStyle}
                            />
                            <label className='staan-form-input-label pt-1 px-1 mx-2'>Terms & Conditions<span className='text-danger'>*</span></label>
                            {touched.termsConditions && errors.termsConditions && <small>{errors.termsConditions}</small>}
                          </div>
                          <div className="col-12 staan-input-group">
                            <ReactQuill
                              value={salesOrderValues?.termsConditionsText}
                              readOnly={edit}
                              onChange={(e) => {
                                setFieldValue("termsConditionsText", e ? e : "")
                                setSalesOrderValues(prve => ({
                                  ...prve,
                                  termsConditionsText: e
                                }))
                              }}
                              modules={textEditormodules} />
                            {touched.termsConditionsText && errors.termsConditionsText && <small>{errors.termsConditionsText}</small>}
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        {/* Other Charges */}
                        <div className="card shadow-lg  p-3">
                          <h6 className='commanModelTitleColor p-2'>Other Expenses</h6>
                          <StaanTab
                            row={otherIncomechargesList}
                            column={OTHER_INCOME_CHARGES_COLUMNDEFS}
                            inputSection={OtherIncomechargesInputFrom}
                            isdisabled={edit}
                            getDeleteRowId={getDeleteRowId}
                          />
                        </div>
                        {/* Amount */}
                        <div className="card shadow-lg mt-3">
                          <table className="table ">
                            <tbody>
                              <tr  >
                                <td className='text-end p-2'  >Item Before Tax</td>
                                <td className='text-end p-2'>{salesOrderValues?.currency?.currencySymbol}{salesOrderValues?.item_details_value ? salesOrderValues?.item_details_value : "0.00"}</td>
                              </tr>
                              <tr  >
                                <td className='text-end p-2'>Other Charges Before Tax</td>
                                <td className='text-end p-2'>{salesOrderValues?.currency?.currencySymbol}{salesOrderValues?.other_income_value ? salesOrderValues?.other_income_value : "0.00"}</td>
                              </tr>
                              <tr  >
                                <td className='text-end p-2'>Taxable Value</td>
                                <td className='text-end p-2'>{salesOrderValues?.currency?.currencySymbol}{Number(salesOrderValues?.other_income_value) + Number(salesOrderValues?.item_details_value) ?
                                  (Number(salesOrderValues?.other_income_value) + Number(salesOrderValues?.item_details_value)).toFixed(2)  : "0.00"}</td>
                              </tr>
                              <tr  >
                                <td className='text-end cursor-pointer text-primary p-2' onClick={() => { setIsShowTax(true) }}  >Tax Total</td>
                                <td className='text-end p-2'>{salesOrderValues?.currency?.currencySymbol}{salesOrderValues?.totaltax ? salesOrderValues?.totaltax : "0.00"}</td>
                              </tr>
                              <tr  >
                                <td className='text-end cursor-pointer text-primary p-2'   ><span>
                                  <input type="checkbox" name="allowCommanDiscount" checked={salesOrderValues?.allowCommanDisCount} onClick={() => {
                                    setDiscountWaring(true)
                                  }} /></span> <span onClick={() => {
                                    salesOrderValues?.allowCommanDisCount ? setIsShowDiscount(true) : showErrorToast(true, "warn", "Click The Check Box")
                                  }}>DisCount</span></td>
                                <td className='text-end p-2'>{salesOrderValues?.currency?.currencySymbol}{(salesOrderValues?.actualNetAmount - salesOrderValues?.netAmount).toFixed(2) ?
                                  (Number(salesOrderValues?.actualNetAmount) - Number(salesOrderValues?.netAmount)).toFixed(2) : "0.00"}</td>
                              </tr>
                              <tr  >
                                <td className='text-end p-2'  >Round of</td>
                                <td className='text-end p-2'>{salesOrderValues?.currency?.currencySymbol}{salesOrderValues?.roundOff ? salesOrderValues?.roundOff : "0.00"}</td>
                              </tr>
                              <tr className='p-1'>
                                <td className='text-end p-2  fw-bold'>Nett Amount</td>
                                <td className='text-end p-2'>{salesOrderValues?.currency?.currencySymbol}{salesOrderValues?.netAmount ? salesOrderValues?.netAmount : "0.00"}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                    {/* button */}
                    <div className="row mt-3 ms-3 justify-content-evenly" hidden={!initialSalesOrder?.active}  >
                      <div className="col-1">
                        <button type="submit" disabled={salesOrderValues?.isAmend ? true : initialSalesOrder?.status === "Draft" ? false : initialSalesOrder?.status === "" ? false : true} style={{ width: "6rem" }} className="btn btn-outline-secondary  px-4"> Draft </button>
                      </div>
                      <div className="col-1">
                        <button type="button" disabled={initialSalesOrder?.status === "Draft" ? false : salesOrderValues?.isAmend ? false : true} style={{ width: "6rem" }} onClick={() => { setFieldValue('status', "Submit"); submitForm() }} className="btn btn-outline-success px-3" >Submit</button>
                      </div>
                      <div className="col-1">
                        <button type="submit" disabled={!(initialSalesOrder?.status === "Submit")} onClick={() => { setSalesOrderValues(prve => ({ ...prve, isAmend: true })); duplicaTheData(false) }} style={{ width: "6rem" }} className="btn btn-outline-primary px-3"  >Amend</button>
                      </div>
                      <div className="col-1">
                        <button type="button" disabled style={{ width: "6rem" }} className="btn btn-outline-info px-4" >DC</button>
                      </div>
                    </div>
                    {isCancelWarningShow &&
                      <WaringModel
                        state={isCancelWarningShow}
                        setstate={setIsCancelWarningShow}
                        title={'Confirm'}
                        text={"Confirm to cancel"}
                        Confirmtion={value => {
                          if (value) {
                            CancelTheSalesOrder(value)
                          }
                        }}
                      />}
                    {isDeleteWarningShow &&
                      <WaringModel
                        state={isDeleteWarningShow}
                        setstate={setIsDeleteWarningShow}
                        title={'Confirm'}
                        text={"Confirm to delete"}
                        Confirmtion={value => {
                          if (value) {
                            DeleteTheSalesOrder(value)
                          }
                        }}
                      />}
                    {isShowItemDetails &&
                      <SalesOrderItem
                        isShow={isShowItemDetails}
                        setIsShow={setIsShowItemDetails}
                        buyerState={values?.buyerState}
                        salesOrderItemDetails={salesOrderItemDetails}
                        setSalesOrderItemDetails={setSalesOrderItemDetails}
                        salesOrderItemDetailsEdit={salesOrderItemDetailsEdit}
                        setSalesOrderItemDetailsEdit={setSalesOrderItemDetailsEdit}
                        salesOrderValues={salesOrderValues}
                        setSalesOrderValues={setSalesOrderValues}
                        setIsShowItemComboModel={setIsShowItemComboModel}
                        discountValue={discountValue}
                        otherIncomechargesList={otherIncomechargesList}
                        ClearDisCount={ClearDisCount}
                      />
                    }
                    {isShowTax &&
                      <TaxModel
                        isShow={isShowTax}
                        setIsShow={setIsShowTax}
                        igstTax={salesOrderValues?.igst}
                        sgstTax={salesOrderValues?.sgst}
                        cgstTax={salesOrderValues?.cgst}
                      />
                    }
                    {isShowDiscount &&
                      <DiscountModel
                        isShow={isShowDiscount}
                        setIsShow={setIsShowDiscount}
                        discountValue={discountValue}
                        setDiscountValue={setDiscountValue}
                        applyDisCount={applyDisCount}
                        ClearDisCount={ClearDisCount}
                        netAmount={salesOrderValues?.netAmount}
                      />
                    }
                    {isDiscountWaring &&
                      <WaringModel
                        state={isDiscountWaring}
                        setstate={setDiscountWaring}
                        title={'Confirm'}
                        text={'Confirm to Clear Discount'}
                        Confirmtion={value => {
                          if (value) {
                            setSalesOrderValues(prev => ({ ...prev, allowCommanDisCount: !salesOrderValues?.allowCommanDisCount }))
                            ClearDisCount()
                          }
                        }}
                      />
                    }
                    {isBulkDeleteWaring &&
                      <WaringModel
                        state={isBulkDeleteWaring}
                        setstate={setIsBulkDeleteWaring}
                        title={'Confirm'}
                        text={'Confirm to bulk delete'}
                        Confirmtion={value => {
                          if (value) {
                            bulkDeleteItemDetailsOtherIncomeCharges()
                          }
                        }}
                      />}
                    {isShowOutOfRange &&
                      <WaringModelWithCopy
                        state={isShowOutOfRange}
                        setState={setIsShowOutOfRange}
                        title={"Value Out Of Range"}
                        text={salesOrderValues?.outOfRangeValues}
                        Copy={true}
                      />}
                    {salesOrderValues?.deleteItemDetailData?.Name &&
                      <DeleteConformation
                        handleDeletedId={(id) => { handleDeleteItemDetails(id) }}
                        deleteData={salesOrderValues?.deleteItemDetailData}
                        fullquery={salesOrder2ItemDetailsDeleteMutation(`id:${salesOrderValues?.deleteItemDetailData?.id}`)}
                      />}

                    {salesOrderValues?.deleteOtherExpensesData?.Name &&
                      <DeleteConformation
                        handleDeletedId={(id) => { handleDeleteOtherIncomeCharges(id) }}
                        deleteData={salesOrderValues?.deleteOtherExpensesData}
                        fullquery={salesOrder2OtherIncomeChargesDeleteMutation(`id:${salesOrderValues?.deleteOtherExpensesData?.id}`)}
                      />
                    }
                    {isShowItemComboModel &&
                      <SalesOrderItemCombo
                        IsshowItemCombo={isShowItemComboModel}
                        setIsShowItemComboModel={setIsShowItemComboModel}
                        itemComboDataList={itemComboDataList}
                        setItemComboDataList={setItemComboDataList}
                        salesOrderValues={salesOrderValues}
                        setSalesOrderValues={setSalesOrderValues}
                        saveItemCombo={saveItemCombo}
                      />}
                    {isShowStockStatementModal &&
                      <StockStatementDetails
                        isShowSelectedStockStatement={isShowStockStatementModal}
                        handleCloseSelectedStockStatement={handleCloseStockStatementModal}
                        stockStatement={selectedStockStatementDetails}
                        part_id={salesOrderValues?.setSelectedPartNo}
                        from={'pos'}
                      />}

                  </Form>
                )
              }}

            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SalesOrderForm