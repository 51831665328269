import React, { useContext, useState } from 'react';
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import DataContext from '../../context/ItemMasterContext';
// import AddUom from '../../components/model/AddUom';  
import TableView from '../../Packages/Custom/DataTable';
import AddUom from '../ItemMaster/model/AddUom';
const Unit = () => {
    const {  handlUOMShow  } = useContext(DataContext)
     
    const [uomEditId , setuomEditId] = useState('')

    const [isLoad, setLoad] = useState(false)
  

    const CustomAddModalComponent = () => { 
      handlUOMShow()
    } 
    const CustomViewModalComponent = (data) => {
      handlUOMShow()
      setuomEditId(data)
      setLoad(!isLoad);
    }
    const headers = [
      {
        header: 'Name',
        field: 'name', 
        flex: 10,   
       
        // query: 'uomOptionFetchQuery',
        inputType: 'text',
        renderComponent: CustomViewModalComponent,
      },
      {
        header: 'E Way Bill Uom',
        field: 'eWayBillUom',  
        query: 'EWayBill',
        flex: 10,  
        inputType: 'text',
      },
      {
        header: 'Description',
        field: 'descriptionText', 
        flex: 10,
        inputType: 'text',
      },
    ]


  return (
          <>
           <div className=' index_page_aliment_warp'>
              <div className='index_page_aliment_sidebar'>
                  <SideNavbar />
              </div>
              <div className='index_page_aliment_table'>
                <TableView
                headers = {headers}
                query = {'UomTableQuery'}
                addComponent = {CustomAddModalComponent}
                loadComponent = {isLoad}
                titleName = {'UOM'}
                heightAdjust = {'40rem'}
                permission={"Save_UOM"}
                customFilter={true}
              />
              </div>
          </div>
          <AddUom uomEditId = {uomEditId} 
          setuomEditId = {setuomEditId}
          setLoad = {()=>{setLoad(!isLoad)}}
          /></>
  )
}

export default Unit