import React,{useState} from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import TableView from '../../Packages/Custom/DataTable'
import PurchaseRetunForm from './Models/PurchaseRetunForm'

const PurchaseReturnChallan = () => {
    const [isLoad, setLoad] = useState(false)
    const [isShowing, setIsShowing] =  useState(false)
    const [edit ,setEdit] =  useState(null)
    const CustomAddModalComponent = () => {
      // setIsShowing(true)
    }
    const CustomViewModalComponent =(data)=>{ 
      setEdit(data)
      setIsShowing(true)
    }
    const headers = [
        {
          header: 'Purchase Return Challan No',
          field: 'purchaseReturnChallanNo', 
          flex: 10,
          label : "linkedModelId",
        //   query: 'accountsMasterOptionQueryForTable',
          inputType: 'text',
          renderComponent: CustomViewModalComponent,
        },
        {
          header: 'Date',
          field: 'createdAt', 
          flex: 10,
        //   query: 'accountsMasterOptionQueryForTable',
          inputType: 'text', 
          isDateField: true
        }, 
    ]
  
  return (
     <>
        <SideNavbar/>
        <div id='custom-table-itemmaster'>
            <TableView
            headers={headers}
            query={"PurchaseReturnQuery"}
            addComponent={CustomAddModalComponent}
            loadComponent={isLoad}
            titleName={"Purchase Return Challan"}
            heightAdjust={"50rem"}
            customFilter={true}
            />
        </div>
        <PurchaseRetunForm
        isShowing = {isShowing}
        setIsShowing = {setIsShowing}
        initialData = {{initialPurchase : null , ItemDetails: '' }}
        edit = {edit}
        setEdit = {setEdit}
        setLoad = {setLoad}
        />
        
     </>
  )
}

export default PurchaseReturnChallan