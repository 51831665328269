import { useState } from "react";
import { defaultPageSize } from "../TableUtils/TableUtils";
import TableListView from "./TableListView";

const TableView = ({headers, query, addComponent, loadComponent, titleName, heightAdjust, rowSelect, customComponent, permission, exportData, addSerialNumber, currentPageSize,customFilter}) => {
    const [pageNumber, setPageNumber] = useState(1);

    const [pageSize, setPageSize] = useState(currentPageSize? currentPageSize : defaultPageSize);
    const [selectedColumns, setSelectedColumns] = useState(headers?.map(item => item.field));
    const [selectedFilters, setSelectedFilters] = useState(null)
    const getCurrentPageNumber = (num) => {
        setPageNumber(num)
    }
    const getCurrentPageSize = (num) => {
        setPageSize(num)
    }

    const getSelectedColumns = (cols) => {
        setSelectedColumns(cols)
    }

    const getSelectedFilters = (filters) => {
        setSelectedFilters(filters)
    }

    return(
        <div style={{fontSize:"14px !important"}}>
            <TableListView 
                addSerialNumber={addSerialNumber}
                headers = {headers}
                query = {query}
                rowSelect = {rowSelect}
                addComponent = {addComponent}
                permission = {permission}
                loadComponent = {loadComponent}
                customComponent = {customComponent}
                titleName = {titleName}
                exportData={exportData}
                heightAdjust = {heightAdjust}
                initialPageNumber = {pageNumber}
                initialPageSize = {pageSize}
                selectedColumns = {selectedColumns}
                selectedFilters = {selectedFilters}
                getCurrentPageNumber = {(page)=>{getCurrentPageNumber(page)}}
                getCurrentPageSize = {(size)=>{getCurrentPageSize(size)}}
                getSelectedColumns = {(cols)=>{getSelectedColumns(cols)}}
                getSelectedFilters = {(filters)=>{getSelectedFilters(filters)}}
                customFilter={customFilter}
            />
        </div>
    )
}

export default TableView;