export const leadCurrencyList = () => {
  return `
        query MyQuery {
          currencyMaster {
            items {
              id
              name
            }
          }
        }
      `
}


export const otherIncomeCharges = (data) => {
  // id: 1
  return `
       query MyQuery {
  otherIncomeCharges(${data}) {
    items{
        name
      id
      active
      account{
        id
        accountsName
      }
      hsn{
        id
        hsnCode
        effectiveDate
      }
    
      createdBy{
        id
        username
      }
      historyDetails{
        id
        Action
        ColumnName
        PreviousState
        UpdatedState
        modifiedDate
        SavedBy{
            id
            username
        }
        }
    }
  }
}
      `
}