import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import "./HierarchicalTreeView.css";
    const TreeNode = ({ node, toggleNode, getOpenmodelId, currentID }) => {
        return (
        <li>
            <div
            onClick={(e) => {
                e.preventDefault(); // Prevent page refresh
                // toggleNode(node.label);
                getOpenmodelId(node?.id, node?.label)
            }} className={Number(currentID)===Number(node?.id) ? "currentModel":`${currentID}`}>
            
                <span>{node?.label}</span>
                <br/>
                <span>{node?.status}</span>
                <br/>
                <span>{node?.Number}</span>
                <br/>
                <span>{node?.date}</span>
           
            
            </div>
            {node?.children && node?.isShow && (
            <ul>
                {node?.children?.map((child, index) => (
                <TreeNode key={index} node={child} toggleNode={toggleNode} getOpenmodelId={getOpenmodelId} currentID={currentID} />
                ))}
            </ul>
            )}
        </li>
        );
    };

    const Tree = ({ data, getOpenmodelId, currentID }) => {
        const [treeData, setTreeData] = useState(data);
        
    
        const toggleNode = (label) => {
        const toggleRecursively = (nodes) =>
            nodes?.map((node) => {
            if (node?.label === label) {
                return { ...node, isShow: !node.isShow };
            }
            if (node?.children) {
                return { ...node, children: toggleRecursively(node.children) };
            }
            return node;
            });
    
        setTreeData(toggleRecursively(treeData));
        };
    
        return (
        <div className="tree">
            <ul>
            {treeData?.map((node, index) => (
                <TreeNode key={index} node={node} toggleNode={toggleNode} getOpenmodelId={getOpenmodelId}
                currentID={currentID} />
            ))}
            </ul>
        </div>
        );
    };

    
    
    const HierarchicalTreeView = ({data, show, close, getOpenmodelId, currentID}) => {
      
      
        
    return (
        <>
             <Modal show={show} size="xl"  onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Time Line</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Tree data={data} getOpenmodelId={getOpenmodelId} currentID={currentID} />
        </Modal.Body>
      </Modal>
        </>
        
    )
    }
    
    export default HierarchicalTreeView