import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { TbHistory } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery";
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import axiosInstance from '../../../api/axoiss';
import Can from '../../../components/authorizationComponents/Can';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import DataContext from '../../../context/ItemMasterContext';
import { OnchangeGetData, ShowErrorNoties, checkChangesInputs, currentPath, customSelectStyle, getAccountGroupOptions, removeEmptyValueInObject } from '../../../utils/Utils';
import { AccountGroupSchema } from '../../../validations/itemmaster';
import HistoryData from './HistoryData';

const AddAccountGroup = ({AccountEdit, setAccountEdit, setLoad}) => {
    const {ItemAccountGroupAdd ,handleAccountGroupClose,  historyIds, setHistoryIds,
      AccountGroupType,   setItemAccountGroupSelect, userId, handltoastDeleteConfomationShow} = useContext(DataContext)
    const dispatch = useDispatch();
    
    const AccountGroupEditData = useSelector(state => state.itemGroup.data);
    const [edit, setEdit]= useState(true)
    const [error , setError] = useState('')
    const url = "/itemmaster/AccountsGroup"
    const currentURL = currentPath(window.location.href)
    const [formKey, setFormKey] = useState(0);
    const [deletedata, setdeleteData] = useState({
      Name:"",
      id: ""
     })
     const [selectaccountgroupType, setSelectaccountgroupType] = useState()
    const handleSelectType=(option)=>{ 
      if (option){
        setSelectaccountgroupType({value: option.value, label :option.label})
      } else{
        setSelectaccountgroupType({value: "", label :""})
      }
     
    }
    const [initialgroup, setinitialgroup]= useState({
        Accounts_Group_Name : "",
        Accounts_Type : "",
        Group_Active : true, 
        HistoryDetails : "",
        modified_by: "",
        createdby: "",
    }) 
      
 
    async function fetchData(id){
      if(id){
        dispatch(fetchOptionsData(QueryData.AccountGroupEdit(id), "AccountGroupEdit"))
      }
      
    } 

   // in edit it will initial the value
    useEffect(()=>{
      if (AccountGroupEditData.name === "AccountGroupEdit"){  
        let responceData = AccountGroupEditData.value.items     
        initialgroup.Accounts_Group_Name = responceData[0]['accountsGroupName']
        initialgroup.Accounts_Type = responceData[0]['accountsType']['id']
        initialgroup.Group_Active = responceData[0]['groupActive'] 
        initialgroup.HistoryDetails = responceData[0]['historyDetails']
        initialgroup.createdby = responceData[0]['createdBy']?.['id']
        if(initialgroup.Accounts_Type){ 
          setSelectaccountgroupType({value: responceData[0]['accountsType']['id'], label :responceData[0]['accountsType']['name']})
        } 
        setFormKey((prevKey) => prevKey + 1);   
    }
    },[AccountGroupEditData])

    //to set Edit status
    useEffect(()=>{
      if(AccountEdit === ""){
      
        setinitialgroup({
          Accounts_Group_Name : "",
          Accounts_Type : "",
          Group_Active : true,
          modified_by : "",
          createdby : ""
      }) 
        setEdit(false)
      } else{
        setEdit(true)
      }
      if(AccountEdit){
        fetchData(AccountEdit.id) 
      }
    },[AccountEdit])

    //retun 0;  using to clear all value 
    function reset_form(){ 
      if (AccountEdit){
        setAccountEdit()
       
      }
      setSelectaccountgroupType({value: "", label :""})
      initialgroup.Accounts_Group_Name =  ''
      initialgroup.Accounts_Type = ""
      initialgroup.Group_Active = true
      initialgroup.modified_by = ""  
      initialgroup.createdby =""
  
    }
 
    // retun nothing ; on delete it will show the waring
    const handledeleteConfomation = (data) => {
      // pass delete value call the model 
      handltoastDeleteConfomationShow();
      setdeleteData({
        Name:data.accountsGroupName,
        id: data.id
      }); 
    };
    
    // retun nothing ; Saved and Edit functions 
    const handleSubmit= async (values, {setSubmitting, resetForm})=>{
      /**
       *  
       */
      const save_AccountGroup ={
        id : AccountEdit? Number(AccountEdit.id)  : "",
        accountsGroupName: values['Accounts_Group_Name'],
        accountsType: values['Accounts_Type']? Number(values['Accounts_Type']): "",
        groupActive: values['Group_Active'],
        modifiedBy:  AccountEdit ? Number(userId) : "",
        createdBy : AccountEdit ? initialgroup.createdby ?  Number(initialgroup.createdby): Number(userId) : Number(userId)
      }  
      let variables = removeEmptyValueInObject(save_AccountGroup)
      const mutations = ` mutation accountsGroup {
        accountsGroupCreateMutation(`+variables+`){    
         accountsGroups{
           id
           accountsGroupName
         }
         success
        errors
        }
        }` 
      if(AccountEdit){ 
        try{
          const respones = await  axiosInstance.post('/itemmaster/graphql', {query: mutations });
          if (respones.data.data.accountsGroupCreateMutation.success){
                setAccountEdit('')
                handleAccountGroupClose()
                resetForm()
                reset_form()
                setSubmitting(false)
                toast.success('Saved Successfully', {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  transition: Bounce,
                  });
                if (currentURL === "account_Group"){
                  setLoad()
                }
          }else{
            let error = respones.data.data.accountsGroupCreateMutation.errors
            let errorData = ShowErrorNoties(error) 
              toast.error( errorData , {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
           }
        } catch(errors){
            let errorData = ShowErrorNoties(errors) 
            toast.error( errorData , {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              }); 
        }
     
    
      }else{ 
        try{
          const respones = await  axiosInstance.post('/itemmaster/graphql', {query: mutations });
          if(respones.data.data.accountsGroupCreateMutation.success){ 
           let responseData = respones.data.data.accountsGroupCreateMutation.accountsGroups
  
           setItemAccountGroupSelect({id:responseData["id"],
           lable: responseData["accountsGroupName"]})
           resetForm()
           handleAccountGroupClose()
       
           setError('')
           reset_form()
           toast.success('Saved Successfully', {
             position: "bottom-right",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "light",
             transition: Bounce,
             });
           if (currentURL === "account_Group"){
             setLoad()
           }
           
          } else{
           let error = respones.data.data.accountsGroupCreateMutation.errors
           let errorData = ShowErrorNoties(error) 
             toast.error( errorData , {
               position: "bottom-right",
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined,
               theme: "light",
               transition: Bounce,
               });
          }
        } catch(errors){
          let errorData = ShowErrorNoties(errors) 
          toast.error( errorData , {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            }); 
        }
        
      }

    }


    const [optionDataSell, setOptionDataSell] = useState([]);
    const [optionDataPurchase, setOptionDataPurchase] = useState([]);
    const [optionDataAll, setOptionDataAll] = useState([]);
    
    // retun 0;  to get Options value
    async function getOptions() {
      let responseData = await OnchangeGetData(QueryData['AccountGrouptypeOptionQuery']());
      if (responseData.success) {
       let [ optionDataSell_, optionDataPurchase_, optionDataAll_ ] = getAccountGroupOptions(responseData.data.accountsGroupType.items);
          setOptionDataSell(optionDataSell_);
          setOptionDataPurchase(optionDataPurchase_);
          setOptionDataAll(optionDataAll_);
      }
    }
  
    // to call getOptions function
    useEffect(()=>{
      getOptions()
    }, [])
   
   
    // clear confomations
    const [waringToCloseModel, setWaringToCloseModel] = useState(false)
    const handleCloseWaring=()=>{
      setWaringToCloseModel(false)
    }
    const handleShowWaring =()=>{
      setWaringToCloseModel(true)
    }
    let currentValues = {};
    const WaringToClose = ()=>{
      const cleaeTheData=()=>{
        handleAccountGroupClose(); 
        setError()
        reset_form()
        setWaringToCloseModel()
      }
      return(
        <>
         <Modal
        show={waringToCloseModel}
        onHide={handleCloseWaring}
        backdrop="static"
        keyboard={false}
    

      >
        <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          Do you want to leave without saving?
          </Modal.Body>
          <Modal.Footer>
          <button type="button" className="btn btn-outline-warning btn-sm" onClick={handleCloseWaring}>No</button>
          <button type="button" className="btn btn-outline-success btn-sm" onClick={cleaeTheData}>Yes</button>
          </Modal.Footer>
      </Modal>
        </>
      )
    } 
    //retun 0; on close value is if changed it show ShowWaring else reset the input
    function Close() { 
      /*
       *  compare the initialgroup and currentValues it  equal it close and reset the value 
       *  else it not it show Waring
       */
      let isDuplicate =  checkChangesInputs([initialgroup, currentValues])
      if(!isDuplicate){
        handleAccountGroupClose(); 
        setError()
        reset_form()
      } else{
        handleShowWaring()
      }
      
    }
    //  after delete clear the data
    const handleDeletedId = (deleted_id) =>{ 
      if (deleted_id){
        handleAccountGroupClose()
        setError()
        reset_form()
        setEdit(false)
        setLoad()
      } 
    }
    
  return (
    <>
    {WaringToClose()}
    <ToastContainer/>
    <HistoryData
     historyIds = {historyIds}
     setHistoryIds= {setHistoryIds}
    />
    <Modal show={ItemAccountGroupAdd} onHide={Close} size="lg"
      backdrop="static" keyboard={false}>
      <div  className="row">
        <div className="col-5 m-3" style={{color:"#5CADA9"}}> <Modal.Title>
        <i className='fa-solid fa-arrow-left fa-sm me-2' 
                onClick={() => { Close()}}
            ></i>
          Account Group</Modal.Title></div>
          <div className="col-6 mt-4 text-end" >
            <Can I={"Edit"} a={"Account_Group"}>
                <i className="fa-solid fa-pen fs-5 text-primary pe-3" hidden={!(AccountEdit && edit)} onClick={()=>{setEdit(!edit)}}  ></i>
            </Can>
            <Can I={"Delete"} a={"Account_Group"}>
                <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  " hidden={!(AccountEdit && edit)} onClick={()=>{handledeleteConfomation(AccountEdit)}} ></i> 
            </Can>
            <Can I={"Historys"} a={"Account_Group"}>
                <TbHistory type="button" className='fs-4 ms-3' data-bs-toggle="offcanvas" hidden={!(AccountEdit && edit)} data-bs-target="#History" onClick={()=>{setHistoryIds(initialgroup.HistoryDetails)}}/>
            </Can>
          </div>
        <hr/>
      </div> 
      <Modal.Body> 
      <div className='text-danger text-center' >{error ? error  : ""}</div>
      <Formik
      key={formKey}
      initialValues={initialgroup}
        validationSchema={AccountGroupSchema}
        onSubmit={handleSubmit}>
          {({errors, touched, isSubmitting, setFieldValue, values })=>{
              currentValues = values
          return(
            <Form>
            <div className='row mx-2'>
              <div className="col-6 staan-input-group">
                <Field type='text' name='Accounts_Group_Name' disabled = {AccountEdit && edit} className='w-100 staan-form-input '/>
                <label htmlFor="Name" className='staan-form-input-label pt-2 px-1 mx-2'>Accounts Group Name</label>
                {  touched.Accounts_Group_Name &&  errors.Accounts_Group_Name && <small className='text-danger' >{errors.Accounts_Group_Name}</small>}
              </div>
              <div className="col-6 staan-input-group">
                  <Select
                    name="Accounts_Type" 
                    value={selectaccountgroupType}
                    isDisabled={AccountEdit && edit}
                    options={AccountGroupType === "sell" ? optionDataSell :AccountGroupType === "purchase" ?  optionDataPurchase: optionDataAll}
                    onChange={(option) =>{ setFieldValue('Accounts_Type', option ? option.value : null);
                    handleSelectType(option)}}
                    styles={customSelectStyle}
                    className='my-3'
                  />
                <label htmlFor="Accounts_Type" className='staan-form-input-label pt-1 px-1 mx-2'> Type </label>


              {touched.Accounts_Type && errors.Accounts_Type && <small>{errors.Accounts_Type}</small>}
              </div>
            </div>
            <div className="row mt-3 mx-2">
              <div className="col-6">
                <Field type="checkbox" name="Group_Active"  disabled = {AccountEdit && edit} className='me-2' />
                <label style={{fontSize: '.8rem'}}>
                    Group Active
                    </label>
                {touched.Group_Active && errors.Group_Active && <small>{errors.Group_Active}</small>}
              </div>
              <div className="col-6 text-end">
                <button type="button" className="btn btn-outline-danger btn-sm me-2" onClick={()=>{Close()}}>Cancel</button>
                <Can allow_list={["Save_Account_Group","Edit_Account_Group"]}>
                  <button type="submit" className="btn btn-sm px-3 btn-outline-success shadow-sm" disabled={isSubmitting}>
                     
                  Save
                  </button>
                </Can>
                
              </div>
            </div>
            </Form>
          )
          }}
      </Formik>
      </Modal.Body>
       
    </Modal> 
    {deletedata.Name && <DeleteConformation
    handleDeletedId =  {(id_)=>{handleDeletedId(id_)}}
    deleteData={deletedata}
    url={url}
    />}
    </>
  )
}

export default AddAccountGroup