import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { TiCancel } from 'react-icons/ti';
import Select from 'react-select';
import { toast } from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import BallTriangleLoading from "../../../components/loading/BallTriangle";
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import WaringModel from '../../../components/Warings/WaringModel';
import DataContext from '../../../context/ItemMasterContext';
import { employeeOptionQuery } from '../../../GraphQLQueries/EmployeeQueryAndMutations/EmployeeQuery';
import { expenseClaimCreateMutation, expenseClaimDeatilsBulkDeleteMutation, expenseClaimDeleteMutation, expenseClaimDetailsDeleteMutation, reconsiliationTableCreateMutation } from '../../../GraphQLQueries/ExpenseQueryandMutation/expenseMutation';
import { expenseClaimEditQuery, expenseReconciliationEditQuery } from '../../../GraphQLQueries/ExpenseQueryandMutation/expenseQuery';
import { customSelectStyle, removeEmptyValueInObject, ShowErrorNoties } from '../../../utils/Utils';
import { ExpenceClaimValidation, reconvalidation } from '../../../validations/ExpenceValidation';
import ClaimsModal from './claimsModalPopUp';
import CommanTable from '../../../Packages/Custom/CommanTable';


const ExpenseClaimModal = ({ isExpenseClaimsShow, setExpenseClaimsShow, setLoad, setClaimEditData, claimEditData }) => {
    const [initialExpenseClaim, setinitialExpenseClaim] = useState({
        claimNo: '',
        claimDate: '',
        employeeDetailID: '',
        employeeDetailName: '',
        doe: '',
        status: '',
        id: '',
        expenseClaimDate: '',
        approvedAmount: '',
        balanceAmount: '',
        reimburseAmount: '',
        createdBy: '',

    })
    const { handltoastDeleteConfomationShow, userId } = useContext(DataContext)

    const [claimValues, setClaimValues] = useState({
        employeeIdSelect: '',
        employeeNameSelect: '',
        employeeImage: '',
        deleteData: ''
    })
    const [employeeOptionList, setEmployeeOptionList] = useState({
        employeeIDList: [],
        employeeNameList: []
    })
    const [isAutoAdjust, setisAutoAdjust] = useState(true)
    const [IsShowClaimsDetail, setShowClaimsDetail] = useState(false)
    const [claimsDetailsData, setclaimsDetailsData] = useState([])
    const [editData, seteditData] = useState([])
    const [formKey, setFormKey] = useState(0)
    const [loading, setLoading] = useState(false)
    const [editView, seteditView] = useState(false)
    const [isDeleteWarningShow, setIsDeleteWarningShow] = useState(false)
    const [isBulkClaimDelete, setBulkClaimDelete] = useState(false)
    const [isShowExpenseReconciliation, setShowExpenseReconciliation] = useState(false)
    const [reconciliationData, setreconciliationData] = useState([])
    const [deleteData, setDeleteData] = useState({ Name: '', id: '' })
    const [isAutoAdjustWarning, setAutoAdjustWarning] = useState(false)

    useEffect(() => {
        if (isExpenseClaimsShow && claimEditData !== null && claimEditData?.id) {
            fetchEditData(claimEditData?.id)
            seteditView(true)
        }
        else {
            seteditView(false)
        }
    }, [isExpenseClaimsShow, claimEditData])

    async function fetchEditData(id) {
        setLoading(true)
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: expenseClaimEditQuery(`id:${id}`) });
            const reponseData = response?.data?.data?.allExpenseClaim?.items?.[0]
            if (reponseData) {
                console.log("reponseData", reponseData);

                setinitialExpenseClaim({
                    claimNo: reponseData?.expenseClaimNo ? reponseData?.expenseClaimNo : '',
                    claimDate: reponseData?.expenseClaimDate ? reponseData?.expenseClaimDate : '',
                    employeeDetailID: reponseData?.employeeId ? Number(reponseData?.employeeId?.id) : '',
                    employeeDetailName: reponseData?.employeeId ? Number(reponseData?.employeeId?.id) : '',
                    // doe:reponseData?.,
                    status: reponseData?.status ? reponseData?.status?.name : '',
                    id: reponseData?.id ? reponseData?.id : '',
                    expenseClaimDate: reponseData?.expenseClaimDate ? reponseData?.expenseClaimDate : '',
                    createdBy: reponseData?.createdBy ? reponseData?.createdBy?.id : '',
                    modifiedBy: reponseData?.modifiedBy ? reponseData?.modifiedBy?.id : '',
                    approvedAmount: reponseData?.totalApprovedAmount ? reponseData?.totalApprovedAmount : '',
                    balanceAmount: reponseData?.balanceAmount ? reponseData?.balanceAmount : '',
                    reimburseAmount: reponseData?.reimburseAmount ? reponseData?.reimburseAmount : '',
                })
                setClaimValues(prve => ({
                    ...prve,
                    employeeIdSelect: { value: reponseData?.employeeId ? reponseData?.employeeId?.id : '', label: reponseData?.employeeId ? reponseData?.employeeId?.employeeId : '' },
                    employeeNameSelect: { value: reponseData?.employeeId ? reponseData?.employeeId?.id : '', label: reponseData?.employeeId ? reponseData?.employeeId?.employeeName : '' },
                    employeeImage: reponseData?.employeeId ? reponseData?.employeeId?.userProfile?.image : '',
                }))
                setclaimsDetailsData(reponseData?.expenseClaimDetails)

                setFormKey(prve => prve + 1)
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
            setLoading(false)
            let errorData = ShowErrorNoties(error)
            showErrorToast(true, 'error', errorData)
        }

    }
    async function getOptions(e, name) {
        if (name === 'employeeID') {
            let peremeter = `employeeId:"${e}",employeeName:""`
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: employeeOptionQuery(peremeter) });
            const responseData = response?.data?.data?.allEmployee?.items
            console.log(response?.data);
            if (responseData?.length > 0) {
                setEmployeeOptionList(prevState => ({
                    ...prevState,
                    employeeIDList: responseData.map(item => ({
                        value: item?.id,
                        label: item?.employeeId,
                        employeeName: item?.employeeName,
                        image: item?.userProfile?.image
                    }))
                }));
            }
        }
        if (name === 'employeeName') {
            let peremeter = `employeeId:"",employeeName:"${e}"`
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: employeeOptionQuery(peremeter) });
            const responseData = response?.data?.data?.allEmployee?.items
            console.log(response?.data);
            if (responseData?.length > 0) {
                setEmployeeOptionList(prevState => ({
                    ...prevState,
                    employeeNameList: responseData.map(item => ({
                        value: item?.id,
                        label: item?.employeeName,
                        employeeId: item?.employeeId,
                        image: item?.userProfile?.image
                    }))
                }));
            }
        }

    }
    const handleDeleteClaimDetails = (id) => {
        if (id) {
            console.log("Delete Id", id);
            setclaimsDetailsData((prevState) =>
                prevState.filter((item) => item.id !== id)
            );

        }
    }
    async function DeleteClaim() {
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: expenseClaimDeleteMutation(`id:${initialExpenseClaim?.id}`) })
            let reponseData = response?.data?.data?.expenseClaimDeleteMutation
            if (reponseData?.success) {
                setLoad()
                setExpenseClaimsShow(false)
            } else {
                showErrorToast(true, "error", reponseData?.errors)
            }
        } catch (error) {
            let errorFilter = ShowErrorNoties(error)
            showErrorToast(true, "error", errorFilter)
        }

    }
    async function BulkDeleteClaim() {
        try {
            const ids = claimsDetailsData?.map((item) => Number(item?.id))
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: expenseClaimDeatilsBulkDeleteMutation(ids) })
            let reponseData = response?.data?.data?.expenseClaimDetailsDeleteMutation
            if (reponseData?.success) {
                close()
            } else {
                showErrorToast(true, "error", reponseData?.errors)
            }
        } catch (error) {
            let errorFilter = ShowErrorNoties(error)
            showErrorToast(true, "error", errorFilter)
        }

    }
    const handleopenModal = (data) => {
        if (data) {
            seteditData(data)
            setShowClaimsDetail(true)
        }

    }
    // const actionsButton = ({ value, data }) => {
    //     const handleClick = () => {
    //         seteditData(data)
    //         setShowClaimsDetail(true)
    //     }
    //     const handledeleteConfomation = () => {
    //         setClaimValues(prve => ({ ...prve, deleteData: { Name: data?.expenseCategories?.expenseCategoryName, id: data.id } }))
    //         handltoastDeleteConfomationShow()
    //     }
    //     return (
    //         <>
    //             <button
    //                 type="button"
    //                 className="btn btn-outline-success btn-sm px-3 mx-2"
    //                 onClick={handleClick}
    //                 disabled={editView}
    //             >
    //                 <i className="fa-solid fa-pen"></i>
    //             </button>
    //             <button
    //                 type="button"
    //                 className="btn btn-outline-danger btn-sm px-3 mx-2"
    //                 onClick={() => { handledeleteConfomation() }}
    //                 disabled={editView}
    //             >
    //                 <i className="fa-solid fa-trash"></i>
    //             </button>

    //         </>
    //     );
    // };
    // const documentIcon = ({ value, data }) => {
    //     // Get the array of files from the pdfUrl field
    //     const fileUrls = data.pdfUrl || [];

    //     // Function to determine the icon and color for each file
    //     const getIcon = (fileUrl, fileId) => {
    //         const fileExtension = fileUrl.split('.').pop().toLowerCase();

    //         // Set a larger icon size (fontSize)
    //         const iconStyle = { fontSize: '1em' };  // You can adjust this size as needed

    //         // Tooltip text will show the file URL
    //         const tooltip = (
    //             <Tooltip id={`tooltip-${fileId}`} style={{ fontSize: '12px' }}>
    //                 {fileUrl}
    //             </Tooltip>
    //         );

    //         // Generate the download link
    //         const downloadLink = fileUrl;

    //         // Render the appropriate button for each file type
    //         switch (fileExtension) {
    //             case 'pdf':
    //                 return (
    //                     <OverlayTrigger overlay={tooltip} placement="top">
    //                         <a href={downloadLink} download>
    //                             <Button variant="danger" style={{ padding: '5px' }}>
    //                                 <FaFilePdf style={iconStyle} />
    //                             </Button>
    //                         </a>
    //                     </OverlayTrigger>
    //                 );
    //             case 'doc':
    //             case 'docx':
    //                 return (
    //                     <OverlayTrigger overlay={tooltip} placement="top">
    //                         <a href={downloadLink} download>
    //                             <Button variant="primary" style={{ padding: '5px' }}>
    //                                 <FaFileWord style={iconStyle} title="Word Document" />
    //                             </Button>
    //                         </a>
    //                     </OverlayTrigger>
    //                 );
    //             case 'xls':
    //             case 'xlsx':
    //                 return (
    //                     <OverlayTrigger overlay={tooltip} placement="top">
    //                         <a href={downloadLink} download>
    //                             <Button variant="success" style={{ padding: '5px' }}>
    //                                 <FaFileExcel style={iconStyle} title="Excel Document" />
    //                             </Button>
    //                         </a>
    //                     </OverlayTrigger>
    //                 );
    //             case 'jpg':
    //             case 'jpeg':
    //             case 'png':
    //             case 'gif':
    //                 return (
    //                     <OverlayTrigger overlay={tooltip} placement="top">
    //                         <a href={downloadLink} download>
    //                             <Button variant="secondary" style={{ padding: '5px' }}>
    //                                 <FaImage style={iconStyle} title="Image File" />
    //                             </Button>
    //                         </a>
    //                     </OverlayTrigger>
    //                 );
    //             default:
    //                 return (
    //                     <OverlayTrigger overlay={tooltip} placement="top">
    //                         <a href={downloadLink} download>
    //                             <Button variant="secondary" style={{ padding: '5px' }}>
    //                                 <FaFile style={iconStyle} title="Unknown File Type" />
    //                             </Button>
    //                         </a>
    //                     </OverlayTrigger>
    //                 );
    //         }
    //     };

    //     // Render icons for each file in the pdfUrl array
    //     return (
    //         <div>
    //             {fileUrls.map((file, index) => (
    //                 <div key={file.id} style={{ display: 'inline-block', marginRight: '8px' }}>
    //                     {getIcon(file.documentFile, file.id)}
    //                 </div>
    //             ))}
    //         </div>
    //     );
    // };



    // const COLUMNS = [
    //     {
    //         headerName: 'DOE',
    //         flex: .5,
    //         field: 'dateOfExp'
    //     },
    //     {
    //         headerName: 'Expense Category',
    //         flex: .10,
    //         field: 'expenseCategories.expenseCategoryName'
    //     },
    //     {
    //         headerName: 'Description',
    //         flex: .10,
    //         field: 'descriptions'
    //     },
    //     {
    //         headerName: 'Claim Amount',
    //         flex: .3,
    //         field: 'claimAmount'
    //     },
    //     {
    //         headerName: 'GST Inv',
    //         flex: .2,
    //         field: 'gstIn',
    //         option: true,
    //         bool: true,
    //         inputType: 'text',
    //         align: 'center',
    //     }, {
    //         headerName: 'Attach Invoice',
    //         flex: .2,
    //         cellRenderer: documentIcon,
    //     },
    //     {
    //         headerName: 'Approved Amount',
    //         flex: .2,
    //         field: 'approvedAmount'
    //     },
    //     {
    //         headerName: 'Reconciliation Amount',
    //         flex: .2,
    //         field: ''
    //     },
    //     {
    //         headerName: 'Action',
    //         cellRenderer: actionsButton,
    //         flex: .4,
    //     },


    // ]
    const COLUMNS = [
        {
            headerName: 'DOE',
            flex: .5,
            field: 'dateOfExp'
        },
        {
            headerName: 'Expense Category',
            flex: .10,
            field: 'expenseCategories',
            label: 'expenseCategoryName'
        },
        {
            headerName: 'Description',
            flex: .10,
            field: 'descriptions'
        },
        {
            headerName: 'Claim Amount',
            flex: .3,
            field: 'claimAmount',
            filter: true,
            filterType: 'number'
        },
        {
            headerName: 'GST Inv',
            flex: .2,
            field: 'gstIn',
            option: true,
            bool: true,
            inputType: 'text',
            align: 'center',
        }, {
            headerName: 'Attach Invoice',
            flex: .2,
            field: 'pdfUrl',
            label: 'documentFile',
            input: 'file'
        },
        {
            headerName: 'Approved Amount',
            flex: .2,
            field: 'approvedAmount',
            filter: true,
            filterType: 'number'
        },
        {
            headerName: 'Reconciliation Amount',
            flex: .2,
            field: ''
        },


    ]
    const ReconciliationColumns = [
        {
            headerName: 'Adv Rcvd Date',
            width: 20,
            field: 'date',
            label: ''
        },
        {
            headerName: 'Expense Request no',
            width: 20,
            field: 'expenseRequestNo',
        },
        {
            headerName: 'Advance Amount',
            width: 20,
            field: 'advAmount',
            filter: true,
            filterType: 'number'
        },
        {
            headerName: 'Adjusted Amount',
            width: 20,
            input: true,
            field: 'adjustedAmount'
        },

    ]
    // const DEFAULT_COL_DEF = {
    //     filter: true, floatingFilter: true,
    //     resizable: true,
    // }

    const handleSubmit = async (values, { resetForm, setErrors }) => {
        const reqBody = {
            createdBy: values?.id ? Number(values?.createdBy) : Number(userId),
            employeeId: values?.employeeDetailID,
            expenseClaimDate: values?.claimDate ? values?.claimDate : '',
            expenseClaimDetails: claimsDetailsData.map(item => item.id),
            id: values?.id ? Number(values?.id) : '',
            modifiedBy: values?.modifiedBy ? Number(values?.modifiedBy) : '',
            status: values?.status
        }
        console.log("reqBody", reqBody);
        let allow_null = ['id', "modifiedBy"]
        let clearedValue = removeEmptyValueInObject(reqBody, allow_null)
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, {
                query: expenseClaimCreateMutation(clearedValue, allow_null)
            })
            const responseData = response?.data?.data?.expenseClaimCreateMutation
            if (responseData.success) {
                toast.success(`Expense Claim Detail Created Successfully...`, {
                    position: 'bottom-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light'
                })
                setLoad()
                setExpenseClaimsShow(false)

            }
            // close()

        }
        catch (error) {
            setLoading(false)
            let errorData = ShowErrorNoties(error)
            showErrorToast(true, 'error', errorData)
        }

    }

    function close() {
        setinitialExpenseClaim({
            claimNo: '',
            claimDate: '',
            employeeDetailID: '',
            employeeDetailName: '',
            doe: '',
            status: '',
            id: '',
            expenseClaimDate: '',
            createdBy: '',
            approvedAmount: '',
            reimburseAmount: '',
            balanceAmount: '',
        })
        setClaimValues({
            employeeIdSelect: '',
            employeeNameSelect: '',
            employeeImage: '',
            deleteData: ''
        })
        setEmployeeOptionList({
            employeeIDList: [],
            employeeNameList: []
        })
        setclaimsDetailsData([])
        setShowClaimsDetail(false)
        seteditData([])
        seteditView(false)
        setClaimEditData(null)
        setExpenseClaimsShow(false)
        setreconciliationData([])
    }
    function BeforeCloseCheckData(params) {
        if (initialExpenseClaim?.status === '' && claimsDetailsData?.length > 0) {
            setBulkClaimDelete(true)
        } else {
            close()
        }
    }
    async function fetchReconciliationData(id) {
        setLoading(true)
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: expenseReconciliationEditQuery(`employeeId:${id}`) });
            const reponseData = response?.data?.data?.expenseReconciliationCallFormExpenseClaim
            if (reponseData?.success) {
                console.log("reponseData", reponseData);
                const resData = reponseData?.PaymentVoucherInstence
                setreconciliationData(resData.map((item, index) => ({
                    index: index + 1,
                    date: item?.date,
                    expenseRequestNo: item?.expenseRequestId?.expenseRequestNo,
                    advAmount: item?.empAmount,
                    createdBy: item?.createdBy?.id,
                    modifiedBy: item?.modifiedBy?.id,
                    paymentVoucherId: item?.id
                })))


            }
        } catch (error) {

            let errorData = ShowErrorNoties(error)
            showErrorToast(true, 'error', errorData)
        }
        setLoading(false)
    }
    const handleShowExpenseReconciliation = (values) => {
        console.log("values", values?.employeeDetailID);
        fetchReconciliationData(values?.employeeDetailID)
        setShowExpenseReconciliation(true)
    }
    const calculateAutoAdjust = async (values) => {
        if (values) {
            let remainingClaimAmount = claimsDetailsData?.reduce((sum, claim) => {
                return sum + parseFloat(claim?.approvedAmount);
            }, 0);
            let totalAdjustedAmount = 0
            const updatedData = reconciliationData?.map((item) => {
                let newAdjustedAmount = 0;
                if (remainingClaimAmount >= 0) {
                    if (Number(item?.advAmount) <= remainingClaimAmount) {
                        newAdjustedAmount = Number(item?.advAmount);
                        totalAdjustedAmount += newAdjustedAmount
                        remainingClaimAmount -= item.advAmount;
                    } else {
                        newAdjustedAmount = remainingClaimAmount;
                        remainingClaimAmount = 0;
                    }
                    console.log("newAdjustedAmount", newAdjustedAmount);

                }
                if (remainingClaimAmount < 0) {
                    newAdjustedAmount = 0;
                }
                return {
                    ...item,
                    adjustedAmount: newAdjustedAmount
                };
            });
            console.log('Updated mock data after adjustment:', updatedData);
            console.log("remainging amount ---", remainingClaimAmount);
            let modifiedData = updatedData?.map((item) => {
                delete item?.['index']
                delete item?.['date']
                delete item?.['expenseRequestNo']
                delete item?.['advAmount']
                let modifiedValue = {
                    ...item,
                    adjustedAmount: item?.adjustedAmount ? item?.adjustedAmount : '',
                    createdBy: item?.createdBy ? Number(item?.createdBy) : Number(userId),
                    id: '',
                    modifiedBy: item?.modifiedBy ? Number(item?.modifiedBy) : Number(userId),
                    paymentVoucherId: item?.paymentVoucherId ? Number(item?.paymentVoucherId) : '',
                    claimId: initialExpenseClaim?.id ? Number(initialExpenseClaim?.id) : ''
                }
                return modifiedValue
            }).filter(item => item?.adjustedAmount > 0)
            const itemsString = JSON.stringify(modifiedData)
                .replace(/"([^"]+)":/g, '$1:')
                .replace(/null/g, 'null');
            console.log("itemsString", itemsString);
            const reqBodyForBalanceUpdate = {
                id: initialExpenseClaim?.id,
                reimburseAmount: totalAdjustedAmount,
                balanceAmount: initialExpenseClaim?.approvedAmount && Number(initialExpenseClaim?.approvedAmount) > 0
                    ? Math.abs(Number(initialExpenseClaim?.approvedAmount) - totalAdjustedAmount)
                    : 0
            }
            try {
                const response = await axiosInstance.post(`/itemmaster/graphql`, {
                    query: reconsiliationTableCreateMutation(`items:${itemsString}`, reqBodyForBalanceUpdate)
                })
                const responseData = response?.data?.data?.expenseReconciliationDetailsCreateMutation
                const responseDataForBalanceUpdate = response?.data?.data?.expenseClaimCreateMutation
                if (responseData?.success) {
                    const updateData = responseData?.expenseReconciliationDetailsInstance?.map((item, rowIndex) => ({
                        date: item?.paymentVoucherId?.date,
                        expenseRequestNo: item?.paymentVoucherId?.expenseRequestId?.expenseRequestNo,
                        advAmount: item?.paymentVoucherId?.empAmount,
                        createdBy: item?.createdBy?.id,
                        modifiedBy: item?.modifiedBy?.id,
                        paymentVoucherId: item?.paymentVoucherId?.id,
                        adjustedAmount: item?.adjustedAmount,
                        id: item?.id,
                        index: rowIndex + 1
                    }))
                    setreconciliationData(updateData);
                    setinitialExpenseClaim(prev => ({
                        ...prev,
                        balanceAmount: responseDataForBalanceUpdate?.ExpenseClaimInstance?.balanceAmount,
                        reimburseAmount: responseDataForBalanceUpdate?.ExpenseClaimInstance?.reimburseAmount,
                    }))
                    setisAutoAdjust(false)
                }
            }
            catch (error) {
                let errorData = ShowErrorNoties(error)
                showErrorToast(true, 'error', errorData)
            }
            console.log('Updated mock data after adjustment:', modifiedData);
        }
    }
    const handleautoAdjust = () => {
        setAutoAdjustWarning(true)
    }
    const getDeleteRowId = async (data) => {
        console.log("data", data);

        if (data?.id) {
            handltoastDeleteConfomationShow()
            setDeleteData({ Name: data?.expenseRequestNo, id: data?.id })

        }
    }
    const getDeleteRowClaimId = async (data) => {
        console.log("data", data);

        if (data?.id) {
            handltoastDeleteConfomationShow()
            setDeleteData({ Name: data?.expenseRequestNo, id: data?.id })

        }
    }
    const ReconciliationInputFrom = ({ item, toggle }) => {
        const [reconinitialValue, setreconinitialValue] = useState({
            id: item?.id ? item?.id : '',
            advRcvdDate: item?.date ? item?.date : '',
            expenseRequestno: item?.expenseRequestNo ? item?.expenseRequestNo : '',
            advAmount: item?.advAmount ? item?.advAmount : '',
            adjustedAmount: item?.adjustedAmount ? item?.adjustedAmount : ''
        })
        const handleReconSave = async (item, values) => {
            setLoading(true)
            console.log("values", values);
            console.log("item", item);
            let reqBody = {
                adjustedAmount: values?.adjustedAmount ? values?.adjustedAmount : '',
                createdBy: item?.createdBy ? Number(item?.createdBy) : Number(userId),
                id: item?.id ? item?.id : '',
                modifiedBy: item?.modifiedBy ? Number(item?.modifiedBy) : '',
                paymentVoucherId: item?.paymentVoucherId ? Number(item?.paymentVoucherId) : '',
                claimId: initialExpenseClaim?.id ? Number(initialExpenseClaim?.id) : ''

            }
            let ClearValue = removeEmptyValueInObject(reqBody, ['id', 'modifiedBy', "paymentVoucherId"])
            try {
                const response = await axiosInstance.post(`/itemmaster/graphql`, {
                    query: reconsiliationTableCreateMutation(`items:[{${ClearValue}}]`)
                })
                const responseData = response?.data?.data?.expenseReconciliationDetailsCreateMutation
                if (responseData?.success) {
                    const resData = responseData?.expenseReconciliationDetailsInstance[0]
                    console.log("resData----------", resData);
                    // console.log("rowIndex", rowIndex);

                    setreconciliationData((prevData) => {
                        // Find the index of the item to update
                        const updatedData = prevData.map((dataItem) => {
                            // If the id of the current item matches the resData id, update the item
                            if (dataItem?.index === item?.index) {
                                return {
                                    ...dataItem,
                                    date: resData?.paymentVoucherId?.date,
                                    expenseRequestNo: resData?.paymentVoucherId?.expenseRequestId?.expenseRequestNo,
                                    advAmount: resData?.paymentVoucherId?.empAmount,
                                    createdBy: resData?.createdBy?.id,
                                    modifiedBy: resData?.modifiedBy?.id,
                                    paymentVoucherId: resData?.paymentVoucherId?.id,
                                    adjustedAmount: resData?.adjustedAmount,
                                    id: resData?.id,
                                };
                            }
                            // If not a match, return the item unchanged
                            return dataItem;
                        });
                        console.log("updatedData", updatedData);
                        return updatedData;
                    });

                    setreconinitialValue(
                        {
                            id: '',
                            advRcvdDate: '',
                            expenseRequestno: '',
                            advAmount: '',
                            adjustedAmount: ''
                        }
                    )
                    setinitialExpenseClaim(prev => ({
                        ...prev,
                        balanceAmount: resData?.expenseclaimSet?.[0]?.balanceAmount,
                        reimburseAmount: resData?.expenseclaimSet?.[0]?.reimburseAmount,
                    }))
                    setisAutoAdjust(false)
                    toggle()

                }
            }
            catch (error) {
                console.log("error", error);

                let errorData = ShowErrorNoties(error)
                showErrorToast(true, 'error', errorData)
            }

            setLoading(false)
        }
        return (
            <>
                <Formik
                    initialValues={reconinitialValue}
                    validationSchema={reconvalidation}
                    enableReinitialize

                >
                    {({ touched, errors, values }) => (
                        <div className="row m-1">
                            <div className="staan-input-group" style={{ maxWidth: '18rem' }}>
                                <Field
                                    type="text"
                                    name="advRcvdDate"
                                    id="advRcvdDate"
                                    className="w-100 staan-form-input"
                                    disabled={true}
                                />
                                <label
                                    htmlFor="advRcvdDate"
                                    className="staan-form-input-label pt-1 px-1 mx-2" onClick={() => {
                                        console.log(values);
                                    }}
                                >
                                    Adv Rcvd Date<span className="text-danger">*</span>
                                </label>
                                {touched.advRcvdDate && errors.advRcvdDate && <small>{errors.advRcvdDate}</small>}
                            </div>
                            <div className="staan-input-group" style={{ maxWidth: '18rem' }}>
                                <Field
                                    type="text"
                                    name="expenseRequestno"
                                    id="expenseRequestno"
                                    className="w-100 staan-form-input"
                                    disabled={true}
                                />
                                <label
                                    htmlFor="expenseRequestno"
                                    className="staan-form-input-label pt-1 px-1 mx-2" >
                                    Expense Request no<span className="text-danger">*</span>
                                </label>
                                {touched.expenseRequestno && errors.expenseRequestno && <small>{errors.expenseRequestno}</small>}
                            </div>
                            <div className="staan-input-group" style={{ maxWidth: '18rem' }}>
                                <Field
                                    type="text"
                                    name="advAmount"
                                    id="advAmount"
                                    className="w-100 staan-form-input"
                                    disabled={true}
                                />
                                <label
                                    htmlFor="advAmount"
                                    className="staan-form-input-label pt-1 px-1 mx-2" >
                                    Advance Amount<span className="text-danger">*</span>
                                </label>
                                {touched.advAmount && errors.advAmount && <small>{errors.advAmount}</small>}
                            </div>
                            <div className="staan-input-group" style={{ maxWidth: '18rem' }}>
                                <Field
                                    type="text"
                                    name="adjustedAmount"
                                    id="adjustedAmount"
                                    className="w-100 staan-form-input"
                                />
                                <label
                                    htmlFor="adjustedAmount"
                                    className="staan-form-input-label pt-1 px-1 mx-2" onClick={() => {
                                        console.log(values);
                                    }}
                                >
                                    Adjusted Amount<span className="text-danger">*</span>
                                </label>
                                {touched.adjustedAmount && errors.adjustedAmount && <small>{errors.adjustedAmount}</small>}
                            </div>
                            <div className='col-3 mt-2  text-left' >
                                <button type="button" className="save-button" onClick={() => handleReconSave(item, values)}>
                                    <i className="fa-regular fa-floppy-disk"></i>
                                </button>
                                <button type="button" className="cancel-button" onClick={() => { toggle() }}>
                                    <i className="fa-solid fa-xmark"></i>
                                </button>
                            </div>

                        </div>

                    )}
                </Formik>
            </>
        )
    }
    return (
        <>
            {<BallTriangleLoading isshow={loading} />}
            <Modal show={isExpenseClaimsShow} fullscreen>
                <div className="row mx-1">
                    <div className="col-5 m-2" style={{ color: "#5CADA9" }}>
                        <Modal.Title>
                            <i className="fa-solid fa-arrow-left fa-sm me-2" onClick={BeforeCloseCheckData}></i>
                            Expense Claim{initialExpenseClaim?.status ? <span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen ml-2'> {initialExpenseClaim?.status} </span> : ""}
                        </Modal.Title>
                    </div>
                    <div className="col-6 mt-4 text-end">
                        <i className="fa-solid fa-pen fs-5 text-primary pe-3" hidden={!editView || initialExpenseClaim?.status === "Submit"} onClick={() => { seteditView(false) }}></i>
                        <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3" hidden={!editView || initialExpenseClaim?.status === "Submit"} onClick={() => { setIsDeleteWarningShow(true) }}></i>
                        <TiCancel
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Cancel"
                            className='fs-2 pb-1'
                            style={{ color: '#7f7f7f', cursor: "pointer" }}
                            hidden={!editView || initialExpenseClaim?.status === "Submit"}
                        >
                        </TiCancel>
                    </div>
                </div>
                <hr />
                <Modal.Body>
                    <Formik
                        initialValues={initialExpenseClaim}
                        validationSchema={ExpenceClaimValidation}
                        onSubmit={handleSubmit}
                        enableReinitialize key={formKey}
                    >
                        {({ errors, submitForm, touched, setFieldValue, values, resetForm }) => (
                            <>
                                <Form>
                                    <div className='row ms-1' style={{ width: '100%' }}>
                                        <div className="row">
                                            <div className='col-8'>
                                                <div className="row">
                                                    <div className="col-6 staan-input-group">
                                                        <Field
                                                            type='text'
                                                            name='claimNo'
                                                            id="claimNo"
                                                            values={initialExpenseClaim?.claimNo}
                                                            disabled={true}
                                                            className='w-100 staan-form-input'
                                                        />
                                                        <label htmlFor="claimNo" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                                            console.log("values", values);
                                                        }}>Expense Claim No</label>
                                                    </div>
                                                    <div className="col-6 staan-input-group">
                                                        <Field
                                                            type='date'
                                                            name='claimDate'
                                                            id="claimDate"
                                                            values={initialExpenseClaim?.claimDate}
                                                            disabled={editView}
                                                            className='w-100 staan-form-input'
                                                        />
                                                        <label htmlFor="claimDate" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                                            console.log("initialExpenseClaim", initialExpenseClaim);
                                                        }}>Expense Claim Date<span className='text-danger'>*</span></label>
                                                        {touched.claimDate && errors.claimDate && <small>{errors.claimDate}</small>}

                                                    </div>

                                                    <div className="col-6 staan-input-group">
                                                        <Select
                                                            onInputChange={(e) => {
                                                                e ? getOptions(e, "employeeID") :
                                                                    setEmployeeOptionList(prevState => ({
                                                                        ...prevState,
                                                                        employeeIDList: []
                                                                    }));
                                                            }}
                                                            options={employeeOptionList.employeeIDList}
                                                            name='employeeDetailID'
                                                            value={claimValues?.employeeIdSelect}
                                                            className='mt-3'
                                                            onChange={(option) => {
                                                                setFieldValue('employeeDetailID', option ? Number(option?.value) : null)
                                                                setFieldValue('employeeDetailName', option ? Number(option?.value) : null)
                                                                setClaimValues(prve => ({
                                                                    ...prve,
                                                                    employeeIdSelect: option ? option : null,
                                                                    employeeNameSelect: option ? { value: option?.value, label: option?.employeeName } : null,
                                                                    employeeImage: option ? option?.image ? option?.image : null : null
                                                                }))
                                                            }}
                                                            styles={customSelectStyle}
                                                            isDisabled={editView}
                                                        />
                                                        <label className='staan-form-input-label  px-1 mx-2' onClick={() => {
                                                            console.log(employeeOptionList.employeeIDList);
                                                        }}>Employee ID<span className='text-danger'>*</span></label>
                                                        {touched.employeeDetailID && errors.employeeDetailID && <small>{errors.employeeDetailID}</small>}
                                                    </div>
                                                    <div className="col-6 staan-input-group">
                                                        <Select
                                                            onInputChange={(e) => {
                                                                e ? getOptions(e, "employeeName") :
                                                                    setEmployeeOptionList(prevState => ({
                                                                        ...prevState,
                                                                        employeeNameList: []
                                                                    }));
                                                            }}
                                                            options={employeeOptionList.employeeNameList}
                                                            name='employeeDetailName'
                                                            value={claimValues?.employeeNameSelect}
                                                            className='mt-3'
                                                            onChange={(option) => {
                                                                setFieldValue('employeeDetailName', option ? Number(option?.value) : null)
                                                                setFieldValue('employeeDetailID', option ? Number(option?.value) : null)
                                                                setClaimValues(prve => ({
                                                                    ...prve,
                                                                    employeeNameSelect: option ? option : null,
                                                                    employeeIdSelect: option ? { value: option?.value, label: option?.employeeId } : null,
                                                                    employeeImage: option ? option?.image ? option?.image : null : null

                                                                }))
                                                            }}
                                                            styles={customSelectStyle}
                                                            isDisabled={editView}
                                                        />
                                                        <label className='staan-form-input-label  px-1 mx-2' onClick={() => {
                                                            console.log("claimValues", claimValues);
                                                        }}>Employee Name <span className='text-danger'>*</span></label>
                                                        {touched.employeeDetailName && errors.employeeDetailName && <small>{errors.employeeDetailName}</small>}
                                                    </div>

                                                </div>

                                            </div>
                                            <div className='col-4' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className="border rounded-circle document" style={{ width: "8rem", height: "8rem", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    {claimValues?.employeeImage ? (
                                                        <div style={{ width: "100%", height: "100%" }}>
                                                            <img
                                                                src={claimValues?.employeeImage}
                                                                alt={`${claimValues?.employeeNameSelect}'s profile`}
                                                                style={{
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    objectFit: "cover",
                                                                    borderRadius: "50%",
                                                                    padding: "1px"
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className='text-center' style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                                            <p className='pt-3' style={{ margin: 0 }}>Click Here To Select Image</p>
                                                            <p style={{ margin: 0, textAlign: "center" }}> Area : 160 X 160
                                                                <br />
                                                                Size : 2MB
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4 staan-input-group">
                                                <Field
                                                    type='text'
                                                    name='approvedAmount'
                                                    id="approvedAmount"
                                                    values={initialExpenseClaim?.approvedAmount}
                                                    disabled={true}
                                                    className='w-100 staan-form-input'
                                                />
                                                <label htmlFor="approvedAmount" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                                    console.log("values", values);
                                                }}>Approved Amount</label>
                                            </div>

                                            <div className="col-4 staan-input-group">
                                                <Field
                                                    type='text'
                                                    name='reimburseAmount'
                                                    id="reimburseAmount"
                                                    disabled={true}
                                                    values={initialExpenseClaim?.reimburseAmount}

                                                    className='w-100 staan-form-input'
                                                />
                                                <label htmlFor="reimburseAmount" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                                    console.log("values", values);
                                                }}>Reimburse Amount</label>
                                            </div>

                                            <div className="col-4 staan-input-group">
                                                <Field
                                                    type='text'
                                                    name='balanceAmount'
                                                    id="balanceAmount"
                                                    disabled={true}
                                                    values={initialExpenseClaim?.balanceAmount}

                                                    className='w-100 staan-form-input'
                                                />
                                                <label htmlFor="balanceAmount" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                                                    console.log("values", values);
                                                }}>Balance Amount</label>
                                            </div>
                                        </div>
                                        <div className='row  ms-3 mt-4 card shadow mx-2 pb-2'>
                                            <div className='row justify-content-between align-items-center'>
                                                <div className='col-2'>
                                                    <h6 className='commanModelTitleColor' onClick={() => {
                                                        console.log("claimsDetailsData", claimsDetailsData);
                                                    }}>Claims Details</h6>
                                                </div>
                                                <div className='col-3 text-end'>
                                                    <button
                                                        type='button'
                                                        className='btn btn-outline-primary mt-2 px-4'
                                                        onClick={() => { setShowClaimsDetail(true) }}
                                                        disabled={editView}
                                                    >
                                                        <i className='bx bxs-plus-circle me-2'></i>New
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                                className='ag-theme-alpine mt-2'
                                                style={{ height: '30rem', width: '100%' }}
                                            >

                                                <CommanTable
                                                    headers={COLUMNS}
                                                    rowData={claimsDetailsData}
                                                    getDeleteRowId={getDeleteRowClaimId}
                                                    isOpenModal={true}
                                                    handleopenModal={handleopenModal}

                                                />

                                            </div>

                                        </div>
                                        <div className="row m-3">
                                            <div className="text-end">
                                                <button type="button" className="btn btn-outline-danger btn-sm me-2 px-4" onClick={() => {
                                                    setFieldValue('status', "Draft"); submitForm()
                                                }}
                                                    disabled={editView || initialExpenseClaim?.status === ""}>
                                                    Draft
                                                </button>
                                                <button type="button" className="btn btn-sm btn-outline-success shadow-sm px-4"
                                                    onClick={() => {
                                                        setFieldValue('status', "Submit"); submitForm()
                                                    }}
                                                    disabled={!editView ? false : initialExpenseClaim?.status === "Draft" ? false : true}>
                                                    Submit
                                                </button>
                                                <button type="button" className="btn btn-sm btn-outline-info shadow-sm px-3 ml-2" disabled={initialExpenseClaim?.status === "Submit" ? false : true}
                                                    onClick={() => { handleShowExpenseReconciliation(values) }}
                                                >
                                                    Verified
                                                </button>

                                            </div>
                                        </div>
                                        {IsShowClaimsDetail &&
                                            <ClaimsModal
                                                IsShowClaimsDetail={IsShowClaimsDetail}
                                                setShowClaimsDetail={setShowClaimsDetail}
                                                setclaimsDetailsData={setclaimsDetailsData}
                                                editData={editData}
                                                seteditData={seteditData}
                                            />
                                        }
                                        {/* {isShowExpenseReconciliation &&
                                            <CommanTable
                                                headers={ReconciliationColumns}
                                                title={"Reconciliation Details"}
                                                rowData={reconciliationData}
                                                inputSection={ReconciliationInputFrom}
                                                autoAdjust={true}
                                                newRowAdd={false}
                                                getDeleteRowId={getDeleteRowId}
                                                handleautoAdjust={handleautoAdjust}
                                                isOpenModal={false}
                                                openModalState={setShowClaimsDetail}
                                                checkBox={true}

                                            />
                                        } */}
                                        {isShowExpenseReconciliation ? <>
                                            <div className='row  ms-3 mt-4 card shadow mx-2 pb-2'>
                                                <div className='row justify-content-between align-items-center'>
                                                    <CommanTable
                                                        headers={ReconciliationColumns}
                                                        title={"Reconciliation Details"}
                                                        rowData={reconciliationData}
                                                        inputSection={ReconciliationInputFrom}
                                                        autoAdjust={isAutoAdjust}
                                                        newRowAdd={false}
                                                        getDeleteRowId={getDeleteRowId}
                                                        handleautoAdjust={handleautoAdjust}
                                                        isOpenModal={false}
                                                        openModalState={setShowClaimsDetail}
                                                        checkBox={true}

                                                    />
                                                </div>
                                            </div>
                                        </> : <></>

                                        }
                                        {claimValues?.deleteData?.Name &&
                                            <DeleteConformation
                                                handleDeletedId={(id) => { handleDeleteClaimDetails(id) }}
                                                deleteData={claimValues?.deleteData}
                                                fullquery={expenseClaimDetailsDeleteMutation(`id:${claimValues?.deleteData?.id}`)}
                                            />
                                        }
                                        {isBulkClaimDelete &&
                                            <WaringModel
                                                state={isBulkClaimDelete}
                                                setstate={setBulkClaimDelete}
                                                title={'Confirm'}
                                                text={"Confirm to bulk delete"}
                                                Confirmtion={value => {
                                                    if (value) {
                                                        BulkDeleteClaim(value)
                                                    }
                                                }}
                                            />}
                                        {isDeleteWarningShow &&
                                            <WaringModel
                                                state={isDeleteWarningShow}
                                                setstate={setIsDeleteWarningShow}
                                                title={'Confirm'}
                                                text={"Confirm to delete"}
                                                Confirmtion={value => {
                                                    if (value) {
                                                        DeleteClaim(value)
                                                    }
                                                }}
                                            />}
                                        {isAutoAdjustWarning &&
                                            <WaringModel
                                                state={isAutoAdjustWarning}
                                                setstate={setAutoAdjustWarning}
                                                title={'Confirm'}
                                                text={"Once Adjusted Cannot be Changed!!!"}
                                                Confirmtion={value => {
                                                    if (value) {
                                                        calculateAutoAdjust(value)
                                                    }
                                                }}
                                            />}

                                        {deleteData?.id && (
                                            <DeleteConformation
                                                // handleDeletedId={id => handleDeletedIdotherincome(id)}
                                                deleteData={deleteData}
                                            // fullquery={otherIncomeChargesDeleteMutation(otherIncomeChargesvalues?.deleteData?.id)}
                                            />
                                        )}
                                        {/* {isShowExpenseReconciliation ? <ReconciliationTable claimFormData={reconciliationData} values={claimReconValue} /> : null} */}
                                    </div>
                                </Form>

                            </>
                        )}
                    </Formik>


                </Modal.Body>
            </Modal>

        </>
    );
}

export default ExpenseClaimModal;
