import { color } from 'd3';
import React, { useEffect, useState } from 'react';
import { Tooltip, OverlayTrigger, Button, Modal } from 'react-bootstrap';
import { FaFilePdf, FaFileWord, FaFileExcel, FaImage, FaFile, FaFilter } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const CommanTableView = ({ title, headers, rowData, inputSection, autoAdjust, handleautoAdjust, newRowAdd, getDeleteRowId, isOpenModal, handleopenModal, checkBox, handleChecked, cellRenderer }) => {
    const [isOpen, setOpenState] = useState(false);
    const [openRowIndex, setOpenRowIndex] = useState(0);
    const [autoAdjustChecked, setautoAdjustChecked] = useState(false);
    const [rowId, setRowId] = useState(1)
    const [tempRow, setTempRow] = useState([])
    const [checkedItems, setCheckedItems] = useState([]);
    function toggle(params) {
        setOpenState(!isOpen);
    }
    const addRow = () => {
        let newRow = {}
        headers.map((col) => { newRow[col?.field] = '' })
        newRow['index'] = rowId
        setTempRow([...tempRow, newRow])
        toggle()
        setOpenRowIndex(rowId)
    }
    const handleTableCurrentlyActive = () => {
        let current_actives = document.getElementsByClassName("fa-regular fa-floppy-disk");
        if (current_actives.length > 0) {
            alert('Please save any active row before proceeding')
        } else {
            addRow()
        }
    }
    useEffect(() => {
        rowData?.forEach((item, index) => { setRowId(Number(item?.index) + 1) });
        setTempRow(rowData);
    }, [rowData]);
    const handleCheckboxChange = (item, isChecked) => {
        if (isChecked) {
            // Add item to checkedItems
            const updatedCheckedItems = [...checkedItems, item];
            setCheckedItems(updatedCheckedItems);
            // Immediately notify the parent component with the updated checked items
            if (handleChecked) {
                handleChecked(updatedCheckedItems);
            }
        } else {
            // Remove item from checkedItems
            const updatedCheckedItems = checkedItems.filter(
                (checkedItem) => checkedItem.index !== item.index
            );
            setCheckedItems(updatedCheckedItems);
            // Immediately notify the parent component with the updated checked items
            if (handleChecked) {
                handleChecked(updatedCheckedItems);
            }
        }
    };
    const DocumentIcon = ({ value, data }) => {
        // Get the array of files from the pdfUrl field
        const fileUrls = data[value?.field]
        // Function to determine the icon and color for each file
        const getIcon = (fileUrl, fileId) => {
            const fileExtension = fileUrl.split('.').pop().toLowerCase();

            // Set a larger icon size (fontSize)
            const iconStyle = { fontSize: '1em', color: 'rgb(255, 255, 255)' };



            // Tooltip text will show the file URL
            const tooltip = (
                <Tooltip id={`tooltip-${fileId}`} style={{ fontSize: '12px' }}>
                    {fileUrl}
                </Tooltip>
            );

            // Generate the download link
            const downloadLink = fileUrl;

            // Render the appropriate button for each file type
            switch (fileExtension) {
                case 'pdf':
                    return (
                        <OverlayTrigger overlay={tooltip} placement="top">
                            <a href={downloadLink} download>
                                <Button variant="danger" className='icon-delete' style={{ padding: '5px', borderRadius: '4px' }}>
                                    <FaFilePdf icon="fa-regular fa-file-pdf" style={iconStyle} />
                                </Button>
                            </a>
                        </OverlayTrigger>
                    );
                case 'doc':
                case 'docx':
                    return (
                        <OverlayTrigger overlay={tooltip} placement="top">
                            <a href={downloadLink} download>
                                <Button variant="primary" style={{ padding: '5px', borderRadius: '4px' }}>
                                    <FaFileWord style={iconStyle} title="Word Document" />
                                </Button>
                            </a>
                        </OverlayTrigger>
                    );
                case 'xls':
                case 'xlsx':
                    return (
                        <OverlayTrigger overlay={tooltip} placement="top">
                            <a href={downloadLink} download>
                                <Button variant="success" style={{ padding: '5px', borderRadius: '4px' }}>
                                    <FaFileExcel style={iconStyle} title="Excel Document" />
                                </Button>
                            </a>
                        </OverlayTrigger>
                    );
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'gif':
                    return (
                        <OverlayTrigger overlay={tooltip} placement="top">
                            <a href={downloadLink} download>
                                <Button variant="secondary" style={{ padding: '5px', borderRadius: '4px' }}>
                                    <FaImage style={iconStyle} title="Image File" />
                                </Button>
                            </a>
                        </OverlayTrigger>
                    );
                default:
                    return (
                        <OverlayTrigger overlay={tooltip} placement="top">
                            <a href={downloadLink} download>
                                <Button variant="secondary" style={{ padding: '5px', borderRadius: '4px' }}>
                                    <FaFile style={iconStyle} title="Unknown File Type" />
                                </Button>
                            </a>
                        </OverlayTrigger>
                    );
            }
        };

        return (
            <div>
                {fileUrls?.map((file, index) => (
                    <div key={index} style={{ display: 'inline-block', marginRight: '8px' }}>
                        {value?.label ? getIcon(file[value?.label], file.id) : getIcon(file[value], file.id)}
                        {/* {getIcon(file.documentFile, file.id)} */}
                    </div>
                ))}
            </div>
        );
    };
    const [filterValue, setfilterValue] = useState({
        anchorEl: null, open: Boolean(null)
    })
    const [filterOptions] = useState([
        { label: 'Greater than 1000', value: 'greaterThan1000' },
        { label: 'Less than 1000', value: 'lessThan1000' },
        // { label: 'Equal To 1000', value: 'equalTo1000' },
        { label: 'Cancel', value: 'cancel' },
    ]);
    const handleClick = (event) => {
        setfilterValue(prev => ({
            ...prev,
            anchorEl: event.currentTarget,
            open: true
        }))
    };
    const handleClose = () => {
        setfilterValue(prev => ({
            ...prev,
            anchorEl: null,
            open: false
        }))
    };
    const handleFilterOption = (option, colField) => {
        if (option === 'greaterThan1000') {
            let filteredData = [...rowData];
            filteredData = filteredData.filter(item => Number(item[colField]) > 1000);
            const indexedRowData = filteredData.map((item, index) => ({
                ...item,
                index: index + 1
            }));
            setTempRow(indexedRowData);
        } else if (option === 'lessThan1000') {
            let filteredData = [...rowData];
            filteredData = filteredData.filter(item => Number(item[colField]) < 1000);
            const indexedRowData = filteredData.map((item, index) => ({
                ...item,
                index: index + 1
            }));
            setTempRow(indexedRowData);
        } else if (option === 'equalTo1000') {
            let filteredData = [...rowData];
            filteredData = filteredData.filter(item => Number(item[colField]) === 1000);
            const indexedRowData = filteredData.map((item, index) => ({
                ...item,
                index: index + 1
            }));
            setTempRow(indexedRowData);
        }
        else if (option === 'cancel') {
            const indexedRowData = rowData.map((item, index) => ({
                ...item,
                index: index + 1
            }));
            console.log("indexedRowData", indexedRowData);

            setTempRow(indexedRowData);
        }
        handleClose();
    };
    return (
        <>
            <div className="row">
                <div className="col-8" hidden={title ? false : true}>
                    <h6 className="commanModelTitleColor pt-3 pb-1">{title}</h6>
                </div>
                {autoAdjust ? <>
                    <div className="col-4 text-end">
                        <div className="form-check form-switch pt-3 pb-1">
                            <input
                                className='form-check-input'
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                                onChange={() => {
                                    handleautoAdjust(!autoAdjustChecked)
                                    setautoAdjustChecked(!autoAdjustChecked)
                                }
                                }

                            />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault" onClick={() => {
                                console.log("rowData", rowData, "headers", headers, "inputSection", inputSection);
                            }}>
                                Auto Adjust
                            </label>
                        </div>
                    </div></> : <></>}
                {newRowAdd ? <>
                    <div className="col-4 text-end">
                        <div >
                            <button
                                type='button'
                                className='btn btn-outline-primary px-4'
                                onClick={() => { handleTableCurrentlyActive() }}
                            >
                                <i className='bx bxs-plus-circle me-2'></i>New
                            </button>
                        </div>
                    </div>
                </> : <></>}
            </div>

            <div id="staan-input-table" style={{ height: '100%' }}>
                <table className="StaanTable">
                    <thead>
                        <tr>
                            {/* Dynamically render headers */}
                            {headers?.map((col, index) => (
                                <th
                                    key={index}
                                    style={{
                                        height: '2rem',
                                        width: col['width'] ? `${col['width']}rem` : 'fit-content',
                                        display: col['hide'] ? 'none' : 'table-cell'
                                    }}
                                >
                                    <div>
                                        <div>
                                            {col['headerName']}
                                            {col['filter'] && (
                                                <>
                                                    <IconButton
                                                        aria-label="more"
                                                        id="long-button"
                                                        aria-controls={filterValue?.open ? 'long-menu' : undefined}
                                                        aria-expanded={filterValue?.open ? 'true' : undefined}
                                                        aria-haspopup="true"
                                                        onClick={handleClick}
                                                    >
                                                        <FaFilter />
                                                    </IconButton>
                                                    <Menu
                                                        id="fade-menu"
                                                        MenuListProps={{
                                                            'aria-labelledby': 'fade-button',
                                                        }}
                                                        anchorEl={filterValue?.anchorEl}
                                                        open={filterValue?.open}
                                                        onClose={handleClose}
                                                    >
                                                        {filterOptions?.map((option) => (
                                                            <MenuItem
                                                                key={option.value}
                                                                onClick={() => handleFilterOption(option.value, col['field'])}
                                                            >
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </Menu>
                                                </>
                                            )}

                                        </div>
                                    </div>


                                </th>

                            ))}
                            {/* Add static 'Actions' column */}
                            <th onClick={() => {
                                console.log("tempRow", tempRow, "openRowIndex", openRowIndex, "rowdata", rowData);
                            }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tempRow?.map((item, rowIndex) => (
                            isOpen && Number(item?.index) == Number(openRowIndex) ?
                                <tr key={rowIndex}>
                                    <td colSpan={12}>
                                        {React.createElement(inputSection, { item, toggle })}
                                    </td>
                                </tr>

                                :
                                <tr key={rowIndex}>
                                    {headers?.map((col, colIndex) => (
                                        <td style={{ Width: col?.['flex'] ? `${col?.['flex']}rem` : 'fit-content', display: col?.['hide'] ? 'none' : 'table-cell' }}>
                                            {col?.input === 'file' ? (
                                                <DocumentIcon value={col} data={item} />
                                            ) : col?.input === 'checked' ? (
                                                <input
                                                    style={{
                                                        display: 'block',
                                                        marginLeft: 'auto',
                                                        marginRight: 'auto',
                                                    }}
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id={`checkbox-${item.index}`}
                                                    checked={checkedItems.some((checkedItem) => checkedItem.index === item.index)}
                                                    onChange={(e) => handleCheckboxChange(item, e.target.checked)}
                                                />
                                            ) : col?.cellRenderer ? (
                                                col.cellRenderer(item)
                                            ) : (
                                                col?.label
                                                    ? item[col?.['field']]?.[col?.['label']]
                                                    : item[col?.['field']]
                                            )}
                                        </td>
                                    ))}
                                    <td>
                                        <button type='button' className="edit-button" onClick={() => {
                                            if (isOpenModal) {
                                                handleopenModal(item);
                                            } else {
                                                setOpenRowIndex(item?.index);
                                                toggle();
                                            }
                                        }}><i className="fa-solid fa-pen-to-square"></i></button>
                                        <button type='button' className="delete-button" onClick={() => { getDeleteRowId(item) }}><i className="fa-solid fa-trash"></i></button>
                                    </td>
                                </tr>
                        ))}
                    </tbody>
                </table>
            </div >
        </>
    );
};

export default CommanTableView;
