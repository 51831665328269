import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { TbHistory } from 'react-icons/tb'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Bounce, ToastContainer, toast } from 'react-toastify'
import * as QueryData from '../../../GraphQLQueries/ItemmasterQuery'
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction'
import axiosInstance from '../../../api/axoiss'
import Can from '../../../components/authorizationComponents/Can'
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation'
import DataContext from '../../../context/ItemMasterContext'
import {
  OnchangeGetData,
  ShowErrorNoties,
  checkChangesInputs,
  currentPath,
  customSelectStyle,
  removeEmptyValueInObject
} from '../../../utils/Utils'
import { itemAccountSchema } from '../../../validations/itemmaster'
import HistoryData from './HistoryData'

const AddAccount = ({ accountEditId, setAccountEditId, setLoad }) => {
  const {
    ItemAccountAdd,
    handleAccountClose,
    handltoastDeleteConfomationShow,
    setItemAccountSalesSelect,
    setAccountData,
    setToastSave,
    handlAccountGroupShow,
    ItemAccountGroupSelect,
    setItemAccountSelect,
    AccountGroupType,
    userId,
    historyIds,
    setHistoryIds,
    accountGroupdata,
    setAccountGroupdata
  } = useContext(DataContext)
  const [error, setError] = useState('')
  const [edit, setEdit] = useState(true)
  const [deleteData, setdeleteData] = useState({ Name: '', id: '' })
  const url = '/itemmaster/AccountsMaster'
  const accountgroupRef = useRef(null)
  const [saparatedAccountGroup, setsaparatedAccountGroup] = useState([])
  const [selectAccountType, setSelectAccountType] = useState([])
  const [selectedAccountGroup, setSelectedAccountGroup] = useState()
  const [formKey, setFormKey] = useState(0)
  const AcountTypeoption = [
    { value: 'Bank', label: 'Bank' },
    { value: 'Tax', label: 'Tax' },
    { value: 'Cash', label: 'Cash' },
    { value: 'Swipe', label: 'Swipe' },
    { value: 'Employee', label: 'Employee' }
  ]
  const currentURL = currentPath(window.location.href)
  const dispatch = useDispatch()
  const AccountMasterEditData = useSelector(state => state.itemGroup.data)
  // initial the value
  const [initialAccount, setInitialAccount] = useState({
    Name: '',
    Active: true,
    Gst: false,
    tds: false,
    Group: '',
    AccountType: '',
    Allow_Payment: '',
    Allow_Receipt: '',
    Enforce_position_balance: '',
    HistoryDetails: '',
    modified_by: userId
  })

  // retun null onchange Account
  const handleSelectAccountType = option => {
    setSelectAccountType({ value: option?.value, label: option?.label })
  }

  // on Edit set initial value
  useEffect(() => {
    if (AccountMasterEditData.name === 'AccountMasterEdit') {
      let responceData = AccountMasterEditData?.value?.items
      initialAccount.Name = responceData?.[0]?.['accountsName']
      initialAccount.Group = responceData?.[0]?.['accountsGroupName']?.['id']
      initialAccount.Gst = responceData?.[0]?.['gstApplicable']
      initialAccount.tds = responceData?.[0]?.['tds']
      initialAccount.Active = responceData?.[0]?.['accountsActive']
      initialAccount.AccountType = responceData?.[0]?.['accountType']
        ? responceData[0]['accountType']
        : ''
      initialAccount.Allow_Payment = responceData?.[0]?.['allowPayment']
      initialAccount.Allow_Receipt = responceData?.[0]?.['allowReceipt']
      initialAccount.Enforce_position_balance =
        responceData?.[0]?.['enforcePositionBalance']
      initialAccount.HistoryDetails = responceData?.[0]?.['historyDetails']
      initialAccount.createdby = responceData?.[0]?.['createdBy']?.['id']
      setFormKey(prevKey => prevKey + 1)
      if (initialAccount.Group) {
        setSelectedAccountGroup({
          value: responceData?.[0]?.['accountsGroupName']['id'],
          label: responceData?.[0]?.['accountsGroupName']['accountsGroupName']
        })
      }
      if (initialAccount.AccountType) {
        setSelectAccountType({
          value: responceData?.[0]?.['accountType'],
          label: responceData?.[0]?.['accountType']
        })
      }
    }
  }, [AccountMasterEditData])

  async function fetchData (id) {
    if (id) {
      dispatch(
        fetchOptionsData(QueryData.AccountMasterEdit(id), 'AccountMasterEdit')
      )
    }
  }

  //  set edit State
  useEffect(() => {
    if (accountEditId === '') {
      setInitialAccount({
        Name: '',
        Active: true,
        Gst: false,
        tds: false,
        Group: '',
        AccountType: ''
      })
      setEdit(true)
    } else {
      setEdit(true)
    }
    if (accountEditId) {
      fetchData(accountEditId.id)
    }
  }, [accountEditId])

  // reset the Value
  function reset_form () {
    initialAccount.Name = ''
    initialAccount.Group = ''
    initialAccount.Gst = false
    initialAccount.tds = false
    initialAccount.Active = true
    initialAccount.AccountType = ''
    initialAccount.Allow_Payment = false
    initialAccount.Allow_Receipt = false
    initialAccount.Enforce_position_balance = false
    setSelectedAccountGroup({ value: '', label: '' })
    setSelectAccountType({ value: '', label: '' })
    if (setAccountEditId) {
      setAccountEditId()
    }

    // setEdit(true)
  }
  // check it on edit

  // retun 0;  to get Options value
  async function getOptions (e) {
    setsaparatedAccountGroup([])
    let search_term = e.trim().replace(/"/g, '\\"')

    if (search_term) {
      let responseData = await OnchangeGetData(
        QueryData['accountGroupOption'](search_term)
      )

      if (responseData.success) {
        setAccountGroupdata(responseData.data.accountsGroup.items)
      }
    } else {
      setAccountGroupdata([])
    }
  }

  // retun 0; call the getOptions to get Account Group
  useEffect(() => {
    /*
    call the getOptions to get AccountGroup after depence on accounts Type set the Options
    */
    const updatedAccountgroup = []

    if (accountGroupdata.length > 0) {
      for (let AccountGroupdata_ of accountGroupdata) {
        if (AccountGroupType === 'purchase') {
          if (
            AccountGroupdata_.accountsType?.name === 'Liabilities' ||
            AccountGroupdata_.accountsType?.name === 'Expenses'
          ) {
            updatedAccountgroup.push(AccountGroupdata_)
          }
        } else if (AccountGroupType === 'sell') {
          if (
            AccountGroupdata_.accountsType?.name === 'Asset' ||
            AccountGroupdata_.accountsType?.name === 'Income'
          ) {
            updatedAccountgroup.push(AccountGroupdata_)
          }
        } else if (AccountGroupType === 'all') {
          updatedAccountgroup.push(AccountGroupdata_)
        }
        setsaparatedAccountGroup(updatedAccountgroup)
      }
    }
  }, [AccountGroupType, accountGroupdata])

  // on Change select  Accountgroup
  const handleSelectAccountgroup = option => {
    if (option) {
      setSelectedAccountGroup({ value: option?.value, label: option?.label })
    } else {
      setSelectedAccountGroup({ value: '', label: '' })
    }
  }

  useEffect(() => {
    const selectedItemID = ItemAccountGroupSelect.id
    const selectedItemlable = ItemAccountGroupSelect.lable
    if (accountgroupRef.current) {
      accountgroupRef.current.setValue({
        value: selectedItemID,
        label: selectedItemlable
      })
    }
    setSelectedAccountGroup({ value: selectedItemID, label: selectedItemlable })
  }, [ItemAccountGroupSelect])

  // retun null ;  Submit functions
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const saveAccountMaster = {
      id: accountEditId ? Number(accountEditId.id) : '',
      accountsName: values['Name'],
      accountsActive: values['Active'],
      gstApplicable: values['Gst'],
      tds: values['tds'],
      accountType: values['AccountType'] ? values['AccountType'] : '',
      accountsGroupName: values['Group'] ? Number(values['Group']) : '',
      allowPayment: '',
      allowReceipt: '',
      enforcePositionBalance: '',
      modifiedBy: accountEditId ? Number(userId) : '',
      createdBy: accountEditId
        ? initialAccount.createdby
          ? Number(initialAccount.createdby)
          : Number(userId)
        : Number(userId)
    }
    if (
      values['AccountType'] === 'Bank' ||
      values['AccountType'] === 'Cash' ||
      values['AccountType'] === 'Swipe'
    ) {
      saveAccountMaster['allowPayment'] = values['Allow_Payment']
      saveAccountMaster['allowReceipt'] = values['Allow_Receipt']
      saveAccountMaster['enforcePositionBalance'] =
        values['Enforce_position_balance']
    } else {
      saveAccountMaster['allowPayment'] = false
      saveAccountMaster['allowReceipt'] = false
      saveAccountMaster['enforcePositionBalance'] = false
    }
    let allow_null = ['accountType', 'id', 'modifiedBy']
    let variable = removeEmptyValueInObject(saveAccountMaster, allow_null)
    const mutations =
      `mutation myquery{
      accountsMasterCreateMutation(` +
      variable +
      `){
        accountsMaster{
          id
          accountsName
          accountsGroupName{
            accountsType{
              name
            }
          }
        }
        success
        errors 
      }
    }`
    if (accountEditId) {
      try {
        const respones = await axiosInstance.post('/itemmaster/graphql', {
          query: mutations
        })
        const responseData = respones.data.data.accountsMasterCreateMutation
        if (responseData.success) {
          handleAccountClose()
          resetForm()
          reset_form()
          setError('')
          setSubmitting(false)
          if (currentURL === 'account') {
            setLoad()
          }
          setEdit(false)
        } else {
          let error = responseData.errors
          let errorData = ShowErrorNoties(error)
          toast.error(errorData, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce
          })
        }
      } catch (error) {
        let errorData = ShowErrorNoties(error)
        toast.error(errorData, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
      }
    } else {
      const respones = await axiosInstance.post('/itemmaster/graphql', {
        query: mutations
      })
      const responseData = respones.data.data.accountsMasterCreateMutation
      if (responseData.success) {
        if (AccountGroupType === 'purchase') {
          setItemAccountSelect({
            id: responseData['accountsMaster']['id'],
            lable: responseData['accountsMaster']['accountsName']
          })
        } else if (AccountGroupType === 'sell') {
          setItemAccountSalesSelect({
            id: responseData['accountsMaster']['id'],
            lable: responseData['accountsMaster']['accountsName']
          })
        }
        resetForm()
        handleAccountClose()
        setError('')
        reset_form()
        setSubmitting(false)
        if (currentURL === 'account') {
          setLoad()
        }
      } else {
        let error = responseData.errors
        let errorData = ShowErrorNoties(error)
        toast.error(errorData, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce
        })
      }
    }
  }

  // clear confomations
  const [waringToCloseModel, setWaringToCloseModel] = useState(false)

  const handleCloseWaring = () => {
    setWaringToCloseModel(false)
  }
  const handleShowWaring = () => {
    setWaringToCloseModel(true)
  }

  let currentValues = {}
  const WaringToClose = () => {
    const cleaeTheData = () => {
      handleAccountClose()
      setError()
      if (accountEditId) {
        setAccountEditId()
      }
      reset_form()
      setWaringToCloseModel()
    }
    return (
      <>
        <Modal
          show={waringToCloseModel}
          onHide={handleCloseWaring}
          backdrop='static'
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want to leave without saving?</Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-outline-warning btn-sm'
              onClick={handleCloseWaring}
            >
              No
            </button>
            <button
              type='button'
              className='btn btn-outline-success btn-sm'
              onClick={cleaeTheData}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

  //retun 0; on close value is if changed it show ShowWaring else reset the input
  function Close () {
    /*
     *  compare the initialgroup and currentValues it  equal it close and reset the value
     *  else it not it show Waring
     */
    let isDuplicate = checkChangesInputs([initialAccount, currentValues])
    if (!isDuplicate) {
      handleAccountClose()
      setError()
      if (accountEditId) {
        setAccountEditId()
      }
      reset_form()
    } else {
      handleShowWaring()
    }
  }
  //  after delete clear the data
  const handledeleteConfomation = data => {
    handltoastDeleteConfomationShow()
    setdeleteData({ Name: data.accountsName, id: data.id })
  }

  const handleDeletedId = deleted_id => {
    if (deleted_id) {
      handleAccountClose()
      setError()
      reset_form()
      setEdit(false)
      setLoad()
    }
  }

  return (
    <>
      {<ToastContainer />}
      {WaringToClose()}
      <HistoryData historyIds={historyIds} setHistoryIds={setHistoryIds} />
      <Modal show={ItemAccountAdd} onHide={Close} size='lg'>
        <div className='row'>
          <div className='col-5 m-3' style={{ color: '#5CADA9' }}>
            {' '}
            <Modal.Title>
              <i
                className='fa-solid fa-arrow-left fa-sm me-2'
                onClick={() => {
                  Close()
                }}
              ></i>
              Account
            </Modal.Title>
          </div>
          <div className='col-6 mt-4 text-end'>
            <Can I={'Edit'} a={'Accounts_Master'}>
              <i
                className='fa-solid fa-pen fs-5 text-primary pe-3'
                hidden={!(accountEditId && edit)}
                onClick={() => {
                  setEdit(!edit)
                }}
              ></i>
            </Can>
            <Can I={'Delete'} a={'Accounts_Master'}>
              <i
                className='fa-solid fa-trash text-danger fs-5 pe-3 ps-3  '
                hidden={!(accountEditId && edit)}
                onClick={() => {
                  handledeleteConfomation(accountEditId)
                }}
              ></i>
            </Can>
            <Can I={'Historys'} a={'Accounts_Master'}>
              <TbHistory
                type='button'
                className='fs-4 ms-3'
                data-bs-toggle='offcanvas'
                hidden={!(accountEditId && edit)}
                data-bs-target='#History'
                onClick={() => {
                  setHistoryIds(initialAccount.HistoryDetails)
                }}
              />
            </Can>
          </div>
          <hr />
        </div>
        <Modal.Body>
          <div className='text-danger text-center'>{error ? error : ''}</div>
          <Formik
            initialValues={initialAccount}
            validationSchema={itemAccountSchema}
            onSubmit={handleSubmit}
            key={formKey}
          >
            {({ errors, touched, isSubmitting, setFieldValue, values }) => {
              currentValues = values
              return (
                <Form>
                  <div className='row'>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='Name'
                        placeholder=''
                        disabled={accountEditId && edit}
                        className='w-100 staan-form-input '
                      />
                      <label
                        htmlFor='Name'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                        onClick={()=>{console.log(values)
                        }}
                      >
                        Account Name
                      </label>
                      {touched.Name && errors.Name && (
                        <small className='text-danger'>{errors.Name}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Select
                        name='Group'
                        isClearable
                        ref={accountgroupRef}
                        isDisabled={accountEditId && edit}
                        onInputChange={e => {
                          getOptions(e)
                        }}
                        options={saparatedAccountGroup.map(item => ({
                          value: item.id,
                          label: item.accountsGroupName
                        }))}
                        isSearchable={true}
                        value={selectedAccountGroup}
                        onChange={option => {
                          setFieldValue('Group', option ? option?.value : null)
                          handleSelectAccountgroup(option)
                        }}
                        styles={customSelectStyle}
                        className='my-3'
                      />
                      <label
                        htmlFor='Group'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Account Group
                        <Can I={"Create"} a={"Account_Group"}>
                          <i
                            role='button'
                            className='bx bx-plus-circle'
                            onClick={handlAccountGroupShow}
                          ></i>
                        </Can>
                        
                      </label>
                      {touched.Group && errors.Group && (
                        <small>{errors.Group}</small>
                      )}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-6 staan-input-group'>
                      <Select
                        name='AccountType'
                        options={AcountTypeoption}
                        isSearchable={true}
                        isClearable
                        isDisabled={accountEditId}
                        value={selectAccountType}
                        onChange={option => {
                          setFieldValue(
                            'AccountType',
                            option ? String(option?.value) : ''
                          )
                          handleSelectAccountType(option)
                        }}
                        styles={customSelectStyle}
                        className='my-3'
                      />
                      <label
                        htmlFor='type'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                        onClick={() => {
                          console.log(errors.AccountType)
                        }}
                      >
                        Account Type{' '}
                      </label>
                      {touched.AccountType && errors.AccountType && (
                        <small>{`${errors.AccountType}`}</small>
                      )}
                    </div>
                    <div className='col-2 pt-4 staan-input-group'>
                      <Field
                        type='checkbox'
                        name='Gst'
                        disabled={accountEditId}
                        className='me-2'
                      />
                      <label style={{ fontSize: '.8rem' }}>GST</label>
                      {touched.Gst && errors.Gst && <small>{errors.Gst}</small>}
                    </div>
                    <div className='col-2 pt-4 '>
                      <Field
                        type='checkbox'
                        name='tds'
                        disabled={accountEditId}
                        className='me-4'
                      />
                      <label style={{ fontSize: '.8rem' }}>TDS</label>
                      {touched.tds && errors.tds && <small>{errors.tds}</small>}
                    </div>
                    <div className='col-2 pt-4'>
                      <Field
                        type='checkbox'
                        name='Active'
                        disabled={accountEditId && edit}
                        className='me-4'
                      />
                      <label style={{ fontSize: '.8rem' }}>Active</label>
                      {touched.Active && errors.Active && (
                        <small>{errors.Active}</small>
                      )}
                    </div>
                  </div>
                  <div className='row'>
                    <div
                      className='col-4 my-4'
                      hidden={
                        selectAccountType.value === 'Bank'
                          ? false
                          : selectAccountType.value === 'Cash'
                          ? false
                          : selectAccountType.value === 'Swipe'
                          ? false
                          : true
                      }
                    >
                      <div className='form-check form-switch'>
                        <label
                          className='form-check-label '
                          htmlFor='flexSwitchCheckDefault'
                        >
                          Allow Payment
                        </label>
                        <Field
                          type='checkbox'
                          name='Allow_Payment'
                          disabled={accountEditId}
                          className='form-check-input text-end '
                        />
                        {touched.Allow_Payment && errors.Allow_Payment && (
                          <small>{errors.Allow_Payment}</small>
                        )}
                      </div>
                    </div>
                    <div
                      className='col-4 my-4'
                      hidden={
                        selectAccountType.value === 'Bank'
                          ? false
                          : selectAccountType.value === 'Cash'
                          ? false
                          : selectAccountType.value === 'Swipe'
                          ? false
                          : true
                      }
                    >
                      <div className='form-check form-switch'>
                        <label
                          className='form-check-label '
                          htmlFor='flexSwitchCheckDefault'
                        >
                          Allow Receipt
                        </label>
                        <Field
                          type='checkbox'
                          name='Allow_Receipt'
                          disabled={accountEditId}
                          className='form-check-input text-end '
                        />
                        {touched.Allow_Receipt && errors.Allow_Receipt && (
                          <small>{errors.Allow_Receipt}</small>
                        )}
                      </div>
                    </div>
                    <div
                      className='col-4 my-4'
                      hidden={
                        selectAccountType.value === 'Bank'
                          ? false
                          : selectAccountType.value === 'Cash'
                          ? false
                          : selectAccountType.value === 'Swipe'
                          ? false
                          : true
                      }
                    >
                      <div className='form-check form-switch'>
                        <label
                          className='form-check-label '
                          htmlFor='flexSwitchCheckDefault'
                        >
                          Enforce position balance
                        </label>
                        <Field
                          type='checkbox'
                          name='Enforce_position_balance'
                          disabled={accountEditId}
                          className='form-check-input text-end '
                        />
                        {touched.Enforce_position_balance &&
                          errors.Enforce_position_balance && (
                            <small>{errors.Enforce_position_balance}</small>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='text-end'>
                      <button
                        type='button'
                        className='btn btn-outline-danger btn-sm me-2'
                        onClick={() => {
                          Close()
                        }}
                      >
                        Cancel
                      </button>
                      <Can allow_list={["Save_Accounts_Master","Edit_Accounts_Master"]}>
                        <button
                          type='submit'
                          className='btn btn-sm btn-outline-success shadow-sm'
                          disabled={isSubmitting}
                        >
                          Save
                        </button>
                      </Can>
                    </div>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {deleteData.Name && (
        <DeleteConformation
          handleDeletedId={id_ => {
            handleDeletedId(id_)
          }}
          deleteData={deleteData}
          url={url}
        />
      )}
    </>
  )
}

export default AddAccount
