import React, { useState } from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import TableView from '../../Packages/Custom/DataTable'
import OtherExpensesModel from './Models/OtherExpensesModel'

const OtherExpenses = () => {
    const [isLoad, setLoad] = useState(false)
    const [otherExpensesIsShowing, setOtherExpensesIsShowing] = useState(false)
    const [otherExpensesEdit, setOtherExpensesEdit] = useState(null)
    const CustomViewModalComponent = (data)=>{
        setOtherExpensesIsShowing(true) 
        setOtherExpensesEdit(data); 
      }
    const headers = [
        {
          header: 'Name',
          field: 'name', 
          flex: 10,
        //   query: 'accountsMasterOptionQueryForTable',
          inputType: 'text',
          renderComponent: CustomViewModalComponent,
        },
        {
          header: 'Account',
          field: 'account',  
          label: "accountsName",
          flex: 10,
        //   query: 'accountGroupOptionforTable',
        //   inputType: 'text', 
        },
        {
            header: 'HSN',
            field: 'HSN',  
            label: "hsnCode",
            flex: 10,
          //   query: 'accountGroupOptionforTable',
          //   inputType: 'text', 
        }, 
      ]
      const CustomAddModalComponent = () => {
        setOtherExpensesIsShowing(true)
     }
  return (
     <>
      <div className=' index_page_aliment_warp'>
          <div className='index_page_aliment_sidebar'>
              <SideNavbar />
          </div>
          <div className='index_page_aliment_table'>
              <TableView
                  headers={headers}
                  query={"OtherExpensesTableQuery"}
                  addComponent={CustomAddModalComponent}
                  loadComponent={isLoad}
                  titleName={"Other Expenses"}
                  heightAdjust={"40rem"}
                  permission={"Save_Other_Expenses"}
                  customFilter={true}
              />
          </div>
      </div>
      
      {otherExpensesIsShowing && 
       <OtherExpensesModel
       otherExpensesIsShowing = {otherExpensesIsShowing}
       setOtherExpensesIsShowing = {setOtherExpensesIsShowing}
       setLoad = {()=>{setLoad(!isLoad)}}
       otherExpensesEdit = {otherExpensesEdit}
       setOtherExpensesEdit = {setOtherExpensesEdit}
       />}
     
     </>
  )
}

export default OtherExpenses