import React, { useState } from 'react';
import { SideNavbar } from "../../../components/sideNavbar/SideNavbar";
import TableView from '../../../Packages/Custom/DataTable';
import StockAddition from "../Addition";
import InventoryApprovals from "../InventoryApproval";

const InventoryHandler = () => {
    const [isLoad, setLoad] = useState(false);
    const [currentInventoryData, setCurrentInventoryData] = useState(null)
    const [isShowInventoryApprovalModal, setShowInventoryApprovalModal] = useState(false)
    const handleShowInventoryApprovalModal = () => setShowInventoryApprovalModal(true)
    const handleCloseInventoryApprovalModal = () => {
        localStorage.setItem('inventory_approval_ids', null)
        setShowInventoryApprovalModal(false)
        setCurrentInventoryData(null)
    }
    const [isShowStockAdditionModal, setShowStockAdditionModal] = useState(false)
    const handleShowStockAdditionModal = () => setShowStockAdditionModal(true)
    const handleCloseStockAdditionModal = () => setShowStockAdditionModal(false)
    const CustomViewModalComponent = (data) => {
        setCurrentInventoryData(data)
        let inventory_approval_ids = data['inventoryId'].map(item => parseInt(item.id))
        localStorage.setItem('inventory_approval_ids', inventory_approval_ids)
        handleShowInventoryApprovalModal()
        // setLoad(!isLoad); command by ej
    }

    const CustomAddModalComponent = () => {
        handleShowStockAdditionModal()
        // setLoad(!isLoad);
    }

    let column_details = [
        {
            header: "Id",
            flex: 5,
            field: 'inventoryHandlerId',
            inputType: 'text',
            query: 'InventoryHandlerAddIdOptionFetchQuery',
            label: 'inventoryHandlerId',
            renderComponent: CustomViewModalComponent,
        },
        {
            header: "Store",
            field: "store",
            flex: 5,
            inputType: 'text',
            label: 'storeName',
            query: 'storeFetchQuery',
            option: true,
        },
        {
            header: "Conference",
            field: "conference",
            flex: 5,
            inputType: 'text',
            label: 'name',
            // query: 'storeFetchQuery',
            option: true,
        },
        {
            header: "Created By",
            field: "savedBy",
            flex: 5,
            inputType: 'text',
            label: 'username',
            option: true,
            query: 'userOptionQuery',
        },
        {
            header: "Qty",
            field: "qtyofInventoryApproval",
            flex: 5,
            inputType: 'text',
            
            
        },
        {
            header: "Created Date",
            field: "createdAt",
            flex: 5,
            inputType: 'text',
            isDateField: true,
        },
    ]

    return(
        <>
            
            <div className="container-fluid index_page_aliment_warp">
                <div className="index_page_aliment_sidebar">
                    <SideNavbar/>
                </div>
                <div className="index_page_aliment_table">
                    <TableView 
                        headers = {column_details}
                        query = {'InventoryHandlerMasterQuery'}
                        loadComponent = {isLoad}
                        titleName = {'Stock Addition'}
                        heightAdjust = {'40rem'}
                        permission={"Save_Stock_Addition"}
                        addComponent = {CustomAddModalComponent}
                        customFilter={true}
                    />
                </div>
            </div>
            {
                    isShowInventoryApprovalModal && 
                    <InventoryApprovals 
                        show = {isShowInventoryApprovalModal}
                        close = {handleCloseInventoryApprovalModal}
                        data = {currentInventoryData}
                    />
                }
                {
                    isShowStockAdditionModal && 
                    <StockAddition 
                        show = {isShowStockAdditionModal}
                        close = {handleCloseStockAdditionModal}
                        setLoad = {setLoad}
                        isLoad = {isLoad}
                    />
                }
        </>
    )
}

export default InventoryHandler;