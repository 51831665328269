import React,{ useState} from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import TableView from '../../Packages/Custom/DataTable' 
import QIR from './Model/QIR'

const QirTable = () => {
    const [isLoad, setLoad] = useState(false)
    const [qirIsShowing, setQirIsShowing] = useState(false)
    const [qirEdit, setQirEdit] = useState(null)
    const CustomViewModalComponent =(data)=>{ 
        setQirEdit(data?.id)
        setQirIsShowing(true)
    }
    const headers = [
        {
          header: 'QIR No',
          field: 'qirNo', 
          flex: 10,
          label : "linkedModelId", 
        //   query: 'accountsMasterOptionQueryForTable',
          inputType: 'text',
          renderComponent: CustomViewModalComponent,
        },
        {
          header: 'QIR Date',
          field: 'qirDate', 
          flex: 10,  
        //   query: 'accountsMasterOptionQueryForTable',
          inputType: 'text',
          isDateField: true
        }, 
        // {
        //     header: 'GIN No',
        //     field: 'goodsreceiptnoteSet',
        //     flex: 10, 
        //     label : "ginNo.linkedModelId",
        //   //   query: 'accountsMasterOptionQueryForTable',
        //     inputType: 'text',
            
        //   },
        //   {
        //     header: 'GIN Date',
        //     field: 'goodsreceiptnoteSet', 
        //     flex: 10,
        //     label : "ginDate",
        //   //   query: 'accountsMasterOptionQueryForTable',
        //     inputType: 'text',
        //     isDateField: true
        //   },
          {
            header: 'Status',
            field: 'status', 
            flex: 10,
            label : "status",
            option: true,
            query: 'QirStatusQuery',
            inputType: 'text', 
          },
          {
            header: 'Created By',
            field: 'createdBy', 
            flex: 10,
            label : "username",
            query: 'userOptionQuery',
            inputType: 'text', 
          },
    ]
   
  
  return (
    <>
        <SideNavbar/>
            <div id="custom-table-itemmaster">
                <TableView
                headers={headers}
                query={"QirTableQuery"}
                addComponent={""}
                loadComponent={isLoad}
                titleName={"Quality Inspection Report"}
                heightAdjust={"45rem"}
                customFilter={true}
                />
            </div>
        {qirIsShowing && <QIR
        isShowqir={qirIsShowing}
        setIsShowqir={setQirIsShowing}
        ginItemsList={{"ginItemsList" : [], "supplierDatas": ""}}
        editViewId = {qirEdit}
        setQirEdit = {setQirEdit}
        setLoad={setLoad}
        />}
        
    </>
  )
}

export default QirTable