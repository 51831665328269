import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { FaSave } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { TiCancel } from 'react-icons/ti';
import axiosInstance from '../../../api/axoiss';
import BallTriangleLoading from "../../../components/loading/BallTriangle";
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import DataContext from '../../../context/ItemMasterContext';
import { quotationsItemComboCreateMutations } from '../../../GraphQLQueries/QuatationqueryAndMutatiom/QuatationItemModalMutation';
export const parseAndGroupItemComboData = (itemComboData) => {
    const parsedData = itemComboData.map((item, index) => {
      const data = typeof item === 'string' ? JSON.parse(item) : item;
      return {
        index: index,
        id: data?.id,
        itemmasterId: data?.itemmaster?.id,
        partCode: data?.itemmaster?.itemPartCode,
        partName: data?.itemmaster?.itemName,
        display: data?.display,
        isSelected: data?.isMandatory ? true : false,
        qty: data?.qty,
        rate: data?.rate,
        uom: {
          value: data?.uom?.id,
          label: data?.uom?.name
        },
        amount: data?.amount,
        afterDiscountValueForPerItem: data?.afterDiscountValueForPerItem,
        isMandatory: data?.isMandatory
      };
    }
  
    );
    return parsedData
  };
const ItemCombo = ({ IsshowItemCombo, setshowItemCombo, lastUpdatedItemDetails, stateValue, IsSaveItemCombo, itemComboEditData,itemComboData,setItemComboData,setItemDetailsData,setQuotationItemDetailsShow }) => {
    const { userId } = useContext(DataContext)
    const [loading, setLoading] = useState(false)
    const [itemComboValue, setItemComboValue] = useState({ currentEditIndex: null })
    const [previousData, setPreviousData] = useState(null);

    const totalCalculatedAmount = itemComboData?.reduce((sum, data) => {
        return sum + parseFloat(data.amount);
    }, 0);
    useEffect(() => {
       
        console.log("itemComboData------------",itemComboData);
        
      }, [itemComboData]);

    const handleSubmitItemCombo = async () => {
        setLoading(true)
        let modifiedData = itemComboData?.map((item) => {
            if (item?.isSelected) {
                delete item?.['index']
                delete item?.['partCode']
                delete item?.['partName']
                let modifiedValue = {
                    ...item,
                    createdBy: Number(userId),
                    id: null,
                    itemmaster: Number(item?.itemmasterId),
                    modifiedBy: null,
                    parentItemdetailId: Number(lastUpdatedItemDetails?.id),
                    uom: Number(item?.uom?.value),
                    afterDiscountValueForPerItem: item?.afterDiscountValuePerItem,
                    amount: Number(item?.amount).toFixed(2),
                    qty: Number(item?.qty).toFixed(2),
                    rate: Number(item?.rate).toFixed(2)
                }
                delete modifiedValue?.['afterDiscountValuePerItem']
                delete modifiedValue?.['itemmasterId']
                delete modifiedValue?.['isSelected']
                console.log(modifiedValue);

                return modifiedValue
            }
        }).filter(item => item !== undefined)
        // Convert modifiedData to a proper GraphQL string
        const itemsString = JSON.stringify(modifiedData)
            .replace(/"([^"]+)":/g, '$1:') // Remove quotes from keys
            .replace(/null/g, 'null'); // Ensure null values are not quoted
        try {
            const response = await axiosInstance.post("/itemmaster/graphql",
                { query: quotationsItemComboCreateMutations(itemsString) })
            const responseData = response?.data?.data?.quotationsItemComboItemDetailsCreateMutations;
            if (responseData?.success) {
                setItemDetailsData(prev => {
                    const newItem = responseData?.parent;
                    if (!newItem) return prev; // Exit early if there's no new item
                    const existingIndex = prev.findIndex(item => item?.id === newItem.id);
                    if (existingIndex !== -1) {
                        // Replace the existing item
                        const newList = [...prev];
                        newList[existingIndex] = newItem;
                        return newList;
                    } else {
                        // Add the new item
                        return [...prev, newItem];
                    }
                });
                setshowItemCombo(false)
                setQuotationItemDetailsShow(false)
            }

        } catch (error) {
            console.log(error);
        }
        setLoading(false)
    }

    const handleSelectItem = (selectedItem) => {
        let modified = itemComboData?.map(item => {
            // Check if the item matches the selected item
            if (item.index === selectedItem.index) {
                // Toggle the isSelected property
                return { ...item, isSelected: !item.isSelected };
            }

            // Return the item unchanged if it doesn't match
            return item;
        })
        setItemComboData(modified);


        calculateItemCombo(modified,
            lastUpdatedItemDetails?.amount,
            lastUpdatedItemDetails?.qty, setItemComboData)


    };

    const calculateItemCombo = (itemComboList_, amount, qty, setItemComboDataList) => {
        const itemComboData = [];
        const mandatoryItems = itemComboList_.filter(item => item?.isSelected);

        if (mandatoryItems.length > 0) {
            const totalValue = mandatoryItems.reduce((sum, item) => {
                return sum + (parseFloat(item.rate) * parseFloat(item.qty));
            }, 0);

            const roundedFinalTotal = Math.round(parseFloat(amount) / qty * 100) / 100;

            if (totalValue === 0) {
                console.log("Total checked value is zero; cannot calculate ratios.");
                return [itemComboData];
            }
            const totalDiscountNeeded = totalValue - roundedFinalTotal;
            const itemContributions = mandatoryItems?.map(item => {
                return parseFloat(item.rate) * parseFloat(item.qty);
            });

            const ratios = itemContributions?.map(contribution => contribution / totalValue);
            const discounts = ratios?.map(ratio => totalDiscountNeeded * ratio);
            itemComboList_.forEach((item, index) => {
                const originalPrice = parseFloat(item.rate) * parseFloat(item.qty);
                const data = {
                    index: index,
                    itemmasterId: item?.itemmasterId,
                    partCode: item?.partCode,
                    partName: item?.partName,
                    display: item?.display || null,
                    qty: item?.qty,
                    rate: item?.rate,
                    uom: {
                        value: item?.uom?.value,
                        label: item?.uom?.label
                    },
                    isMandatory: item?.isMandatory,
                };
                if (item?.isSelected) {
                    const discountIndex = mandatoryItems.findIndex(mItem => mItem.itemmasterId === item.itemmasterId);
                    console.log(item.itemmasterId, originalPrice, discounts[discountIndex]);
                    const discountedAmount = originalPrice - discounts[discountIndex];
                    const finalDiscountedAmount = Math.max(discountedAmount, 0).toFixed(3);
                    const afterDiscountValueForPerItem = (finalDiscountedAmount / parseFloat(item?.qty)).toFixed(3);
                    data.afterDiscountValueForPerItem = afterDiscountValueForPerItem;
                    data.amount = finalDiscountedAmount;
                    data.isSelected = true
                } else {
                    data.afterDiscountValueForPerItem = 0;
                    data.amount = "0";
                    data.isSelected = false
                }
                itemComboData.push(data);
            });
            const totalCalculatedAmount = itemComboData.reduce((sum, data) => {
                return sum + parseFloat(data.amount);
            }, 0);

            if (totalCalculatedAmount !== roundedFinalTotal) {
                console.warn(`Warning: The total calculated amount ${totalCalculatedAmount} does not match the parameter amount ${amount}.`);
            }
            setItemComboDataList(itemComboData);

        }

        return itemComboData;
    };
    const handleEditClick = (item) => {
        if (item?.isSelected) {
            setPreviousData(item);
            setItemComboValue(prev => ({ ...prev, currentEditIndex: item?.index }))
        } else {
            showErrorToast(true, "warn", "Select The Check Box", "")
        }

    };

    const handleSaveClick = (CurrentItem) => {
        setItemComboValue(prev => ({ ...prev, currentEditIndex: "" }));
        setPreviousData(null)
        calculateItemCombo(itemComboData,
            lastUpdatedItemDetails?.amount,
            lastUpdatedItemDetails?.qty, setItemComboData);
    };

    const handleCancelClick = () => {
        setItemComboData(prev =>
            prev?.map(item => {
                // Check if the item matches the selected one
                if (item.index === previousData.index) {
                    // Restore the original data from previousData
                    return { ...previousData };
                }
                // Return the item unchanged if it doesn't match
                return item;
            })
        );
        setItemComboValue(prev => ({ ...prev, currentEditIndex: "" }));
    };


    // Parse the new quantity and ensure it's a number and Update the quantity in the item,
    // Calculate the new amount,If afterDiscountValueForPerItem has a value, use it,Otherwise, use the rate
    const handleQtyChange = (CurrentItem, e) => {
        let QTY_ = e.target.value;

        setItemComboData(prev =>
            prev?.map(item => {
                // Check if the item matches the selected item
                if (item.index === CurrentItem.index) {
                    // Update the qty only
                    return { ...item, qty: QTY_ };
                }
                // Return the item unchanged if it doesn't match
                return item;
            })
        );
    };

    const uniqueDisplayNames = Array.from(new Set(itemComboData?.map(item => item.display)));

    return (
        <>
            {<BallTriangleLoading isshow={loading} />}
            <Modal show={IsshowItemCombo} size="xl">
                <Modal.Body style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div className='row align-items-center justify-content-between'>
                        <div className='col-4 commanModelTitleColor'>
                            <h3 className='mx-2'>
                                <i
                                    className='fa-solid fa-arrow-left fa-sm me-2'
                                    onClick={() => {
                                        setshowItemCombo(false);
                                        setQuotationItemDetailsShow(false)
                                        // setIsSaveItemCombo(false)
                                    }}
                                ></i>
                                Item Combo
                            </h3>
                        </div>
                    </div>
                    <div style={{ overflowY: 'auto', height: '40rem' }}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th onClick={() => {
                                        console.log("item combo data-----------", itemComboData);
                                    }}>Mandatory</th>
                                    <th onClick={() => {
                                        console.log("item edit data----------", itemComboEditData);
                                    }}>Part Code</th>
                                    <th>Part Name</th>
                                    <th>Rate</th>
                                    <th>Item Combo Rate</th>
                                    <th>Qty</th>
                                    <th>UOM</th>
                                    <th>Item Combo Amount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {uniqueDisplayNames?.map(displayName => (
                                    <React.Fragment key={displayName}> {/* Use displayName for key */}
                                        <tr>
                                            <td colSpan={12} style={{ fontWeight: 'bold' }}>
                                                {displayName}
                                            </td>
                                        </tr>
                                        {itemComboData
                                            ?.filter(item => item.display === displayName)
                                            ?.map(item => {
                                                const { partCode, partName, rate, isMandatory, qty, uom, amount, isSelected, index, afterDiscountValueForPerItem } = item; // Destructure item

                                                return (
                                                    <>
                                                        <tr key={partCode}> {/* Ensure partCode is unique */}
                                                            <td style={{ justifyContent: 'center', alignItems: 'center' }}>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isSelected}
                                                                    onChange={() => handleSelectItem(item)}
                                                                    disabled={isMandatory || item?.edit}
                                                                />
                                                            </td>
                                                            <td>{partCode}</td>
                                                            <td>{partName}</td>

                                                            <td>{rate}</td>
                                                            <td>{afterDiscountValueForPerItem}</td>
                                                            <td>
                                                                {isMandatory ? (
                                                                    qty
                                                                ) : (
                                                                    itemComboValue?.currentEditIndex === index ? (
                                                                        <input
                                                                            style={{ width: '12rem', padding: '0.5rem' }}
                                                                            type="number"
                                                                            value={qty}
                                                                            onChange={(e) => {
                                                                                handleQtyChange(item, e);
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        qty
                                                                    )
                                                                )}
                                                            </td>
                                                            <td>{uom?.label}</td>
                                                            <td>{amount}</td>
                                                            <td>
                                                                {isMandatory || item?.edit ? (
                                                                    <TiCancel className="text-danger" />
                                                                ) : (
                                                                    itemComboValue?.currentEditIndex === index ? (
                                                                        <>
                                                                            <FaSave
                                                                                onClick={() => handleSaveClick(item)}
                                                                                style={{ cursor: 'pointer', color: 'green' }}
                                                                            />
                                                                            <MdCancel
                                                                                onClick={handleCancelClick}
                                                                                style={{ cursor: 'pointer', color: 'black', marginLeft: '10px' }}
                                                                            />
                                                                        </>
                                                                    ) : (
                                                                        <i
                                                                            className='fa-solid fa-pen text-secondary'
                                                                            onClick={() => handleEditClick(item)}
                                                                            style={{ cursor: 'pointer' }}

                                                                        />
                                                                    )
                                                                )}
                                                            </td>
                                                        </tr>

                                                    </>

                                                );
                                            })}
                                    </React.Fragment>
                                ))}
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>TOTAL</td>
                                    <td>{totalCalculatedAmount}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="text-end mt-2" style={{ position: 'sticky', bottom: 0, background: 'white', padding: '10px' }} >
                        <button type="submit" className="btn btn-sm btn-success" disabled={itemComboData?.some(item => item.edit)}
                            onClick={handleSubmitItemCombo}>
                            Save
                        </button>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ItemCombo;
