import React, { useState} from 'react' 
import TableView from '../../Packages/Custom/DataTable';
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import AddMrp from './AddMrp';


const MRP = () => {
    const [isLoad, setLoad] = useState(false);
    const [showAddMrp, setShowAddMrp] = useState(false);

    const handleShowAddMrp = () => {
        setShowAddMrp(true);
    }

    const handleCloseAddMrp = () => {
        setShowAddMrp(false);
    }

    const CustomAddModalComponent = () => {
        handleShowAddMrp()
        setLoad(!isLoad)
    }

    const CustomViewModalComponent = (data) => {
        console.log(data)
    }

    const mrp_column_details = [
        {
            header: 'ID',
            field: 'id',
            flex: 5,
            inputType: 'number',
            align: 'center',
        },
        {
          header: 'BOM No',
          field: 'bomNo',
          flex: 8,
          inputType: 'text',
          query: 'bomNumberOptionQuery'
      },
        {
          header: 'BOM Name',
          field: 'bomName',
          flex: 10,
          inputType: 'text',
          renderComponent: CustomViewModalComponent,
        },
        {
          header: 'Status',
          field: "status", 
          label: "status",
          flex: 5,
          inputType: 'text',
          option: true,
          query: "bomStatusOptionQuery",
          key: "value",
        },
        {
          header: 'BOM Type',
          field:"bomType", 
          flex: 12,
          inputType: 'text',
          option: true,
          query: 'bomTypeOptionQuery',
          key: 'label'
        },
        {
          header: 'FG Name',
          field:"finishedGoods", 
          label: 'partNo.itemName',
          flex: 12,
          inputType: 'text',
          query: "finishedGoodsOptionQuery",
          key: 'value'
        },
        {
          header: 'Number of RM',
          field:"totalRawMaterial", 
          flex: 5,
          align: "center",
          inputType: 'number',
        },
        {
          header: 'Last Modified By',
          field:"modifiedBy", 
          label: 'username',
          flex: 7,
          inputType: 'text',
          option: true,
          query: "userOptionQuery",
          key: "value",
        },
        {
          header: 'Last Modified Date',
          field:"updatedAt", 
          flex: 5,
          inputType: 'text',
          isDateField: true,
        },
    ]

    return (
        <>
            <SideNavbar />
            <div id="custom-table-itemmaster">
                <TableView 
                    headers = {mrp_column_details}
                    // query = {'bomQuery'}
                    addComponent = {CustomAddModalComponent}
                    loadComponent = {isLoad}
                    titleName = {'Material Resource Planning'}
                    heightAdjust = {'40rem'} 
                    customFilter={true}
                />
            </div>
            {
                showAddMrp && 
                    <AddMrp  
                        show={showAddMrp}
                        close = {handleCloseAddMrp}
                    /> 
            }
        </>
    )
}

export default MRP;