import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import BallTriangleLoading from "../../../components/loading/BallTriangle";
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import DataContext from '../../../context/ItemMasterContext';
import { expenseClaimModalCreateMutation } from '../../../GraphQLQueries/ExpenseQueryandMutation/expenseMutation';
import { categoryOptionQuery } from '../../../GraphQLQueries/ExpenseQueryandMutation/expenseQuery';
import { deleteDocument } from '../../../GraphQLQueries/ItemMastermutation';
import { handleFileUploadToS3 } from '../../../utils/FileUploadToS3';
import { customSelectStyle, removeEmptyValueInObject, ShowErrorNoties } from '../../../utils/Utils';
import { ClaimDetailsPopUpValidation } from '../../../validations/ExpenceValidation';

const ClaimsModal = ({ IsShowClaimsDetail, setShowClaimsDetail, setclaimsDetailsData, editData, seteditData }) => {
    // const [isEditView, setEditview] = useState(false);
    // const { screenSize } = useContext(DataContext)
    const DocInputRef = useRef(null);
    const [docId, setDocId] = useState([])
    const { userId } = useContext(DataContext)

    const [initialClaimData, setinitialClaimData] = useState({
        doe: '',
        category: '',
        description: '',
        claimAmount: '',
        approvedAmount: '',
        createdBy: '',
        modifiedBy: '',
        gstIn: false,
        pdfUrl: '',
        id: ''
    })
    const [claimOptionList, setclaimOptionList] = useState({
        categoryList: []
    })
    const [claimSelectValues, setSelectClaimValues] = useState({
        categorySelect: ''
    })
    const [selectedDoc, setSelectedDoc] = useState([]);
    const [loading, setLoading] = useState(false)
    const [formKey, setFormKey] = useState(0)
    useEffect(() => {
        if (IsShowClaimsDetail && editData !== null && editData?.id) {
            console.log("editData", editData);
            setinitialClaimData({
                doe: editData?.dateOfExp,
                category: Number(editData?.expenseCategories?.id),
                description: editData?.descriptions,
                claimAmount: editData?.claimAmount,
                approvedAmount: editData?.approvedAmount,
                createdBy: editData?.createdBy?.id,
                modifiedBy: editData?.modifiedBy ? editData?.modifiedBy?.id : '',
                gstIn: editData?.gstIn,
                pdfUrl: '',
                id: editData?.id ? editData?.id : ''
            });
            setSelectClaimValues(prve => ({
                ...prve,
                categorySelect: { values: editData?.expenseCategories?.id, label: editData?.expenseCategories?.expenseCategoryName }
            }))
            let product_documents = editData?.pdfUrl || []
            product_documents = product_documents.map(item => {
                const splitPath = item.documentFile.split('/');
                const name = splitPath[splitPath.length - 1];
                return { ...item, name: name };
            });
            console.log("product_documents", product_documents);

            let product_doc_ids = product_documents.map(item => item.id)
            setSelectedDoc(product_documents)
            setDocId(product_doc_ids)
            setFormKey(prev => prev + 1)
        }
    }, [editData, IsShowClaimsDetail])
    const handleDocSelect = async (e) => {
        let selectedFiles = e.target.files
        console.log("selectedFiles", selectedFiles);

        if (selectedFiles.length > 4) {
            showErrorToast(true, "error", "You can only upload up to 4 documents.")
            return;
        }
        const maxFileSizeMB = 5;
        const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;
        let validFiles = [];

        for (let doc of selectedFiles) {
            if (doc.size > maxFileSizeBytes) {
                showErrorToast(true, "error", `${doc.name} exceeds the 5MB size limit.`)
                continue;
            }
            validFiles.push(doc);
        }
        if (validFiles.length === selectedFiles.length) {
            for (let doc of Array.from(e.target.files)) {
                try {
                    handleFileUploadToS3(doc, 'UserDocuments')
                        .then(uploaded_file_url => {
                            const mutation_query = `
                    mutation MyMutation($document: String!) {
                    documentCreateMutation(document: $document) {
                        documentType{
                        id 
                        }
                        success
                        errors
                    }
                    }`
                            const variables = {
                                document: uploaded_file_url,
                            };
                            axiosInstance.post('/itemmaster/graphql', {
                                query: mutation_query,
                                variables: variables,
                            })
                                .then(response => {
                                    const responseData = response?.data?.data?.documentCreateMutation;
                                    if (responseData?.success) {
                                        console.log("responseData", responseData);

                                        const document_id = responseData?.documentType?.id;
                                        let extracted_data = doc
                                        extracted_data['id'] = document_id
                                        setSelectedDoc(prev => [...prev, extracted_data]);
                                        console.log("docId", docId);
                                        setDocId(prev => {

                                            return [...prev, document_id];
                                        });
                                    } else {
                                        const filteredError = ShowErrorNoties(responseData.errors);
                                        showErrorToast(true, "error", filteredError)
                                    }
                                })
                                .catch(error => {
                                    const filteredError = ShowErrorNoties(error);
                                    showErrorToast(true, "error", filteredError)
                                });
                        })
                        .catch(err => {
                            const filteredError = ShowErrorNoties(err);
                            showErrorToast(true, "error", filteredError)
                        })
                } catch (error) {
                    const filteredError = ShowErrorNoties(error);
                    showErrorToast(true, "error", filteredError)
                }

            }
        } else {
            showErrorToast(true, "error", "Some files were invalid and were not uploaded. Please check the file size and try again.")
        }
    }
    const handleDeleteDocument = (document) => {
        if (document.id) {
            let delete_mutation_query = deleteDocument(document.id)
            axiosInstance.post("/itemmaster/graphql", { query: delete_mutation_query })
                .then(res => {
                    if (res?.data?.data?.documentDeleteMutation?.success) {
                        let document_list = [...selectedDoc]
                        document_list = document_list.filter(item => item?.id !== document?.id)
                        setSelectedDoc(document_list)
                        showErrorToast(true, "success", "Document deleted successfully!.")
                    } else {
                        showErrorToast(true, "error", "Failed to delete document!.")
                    }
                })
                .catch(err => {
                    showErrorToast(true, "error", "Failed to delete document!.")
                })
        }
    }
    async function getOptions(e, name) {
        if (name === 'expenseCategory') {
            let peremeter = `expenseCategoryName:"${e}"`
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: categoryOptionQuery(peremeter) });
            const responseData = response?.data?.data?.allExpenseCategories?.items
            console.log(responseData);
            const activeItems = responseData?.filter(item => item?.active);

            if (activeItems?.length > 0) {
                setclaimOptionList(prevState => ({
                    ...prevState,
                    categoryList: activeItems.map(item => ({
                        value: item?.id,
                        label: item?.expenseCategoryName,
                        gstIn: item?.accountName?.gstApplicable
                    }))
                }));
            }

        }
    }
    const handleClaimModalSubmit = async (values, { resetForm, setErrors }) => {
        if (values?.claimAmount) {
            const reqBody = {
                claimAmount: values?.claimAmount,
                approvedAmount: values?.approvedAmount ? values?.approvedAmount : '',
                createdBy: values?.createdBy ? Number(values.createdBy) : Number(userId),
                dateOfExp: values?.doe,
                descriptions: values?.description,
                expenseCategories: values?.category,
                gstIn: values?.gstIn,
                id: values.id ? values?.id : '',
                pdfUrl: docId?.map(id => Number(id))
            }
            console.log("reqBody", reqBody);

            if (values?.id) {
                reqBody.modifiedBy = values.modifiedBy ? Number(values.modifiedBy) : Number(userId)
            }
            let allow_null = ["id", "createdBy", "modifiedBy", "approvedAmount"]
            let clearedValue = removeEmptyValueInObject(reqBody, allow_null)
            setLoading(true)
            try {
                const response = await axiosInstance.post(`/itemmaster/graphql`, {
                    query: expenseClaimModalCreateMutation(clearedValue, allow_null)
                })
                const responseData = response?.data?.data?.expenseClaimDetailsCreateMutation
                console.log("responseData", responseData);

                if (responseData.success) {
                    const newObject = responseData?.ExpenseClaimDetailsInstance;
                    // Check if the object with the same id already exists in claimsDetailsData
                    setclaimsDetailsData((prevState) => {
                        // Find if an object with the same 'id' already exists
                        const existingObjectIndex = prevState.findIndex(
                            (item) => item.id === newObject.id
                        );

                        if (existingObjectIndex === -1) {
                            // If the object does not exist, add it to the array
                            return [...prevState, newObject];
                        } else {
                            // If the object exists, replace it with the new object
                            const updatedState = [...prevState];
                            updatedState[existingObjectIndex] = newObject; // Replace the existing object
                            return updatedState;
                        }
                    });
                    setLoading(false)
                    toast.success(`Expense Claim Detail Created Successfully...`, {
                        position: 'bottom-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'light'
                    })
                    // setLoad()
                    setShowClaimsDetail(false)
                }
                close()

            }
            catch (error) {
                setLoading(false)
                let errorData = ShowErrorNoties(error)
                showErrorToast(true, 'error', errorData)
            }
        }
    }
    function close() {
        setinitialClaimData({
            doe: '',
            category: '',
            description: '',
            claimAmount: '',
            approvedAmount: '',
            createdBy: '',
            modifiedBy: '',
            gstIn: false,
            pdfUrl: '',
            id: ''
        }
        )
        setclaimOptionList({
            categoryList: []
        })
        setSelectClaimValues({
            categorySelect: ''
        })
        setSelectedDoc([])
        seteditData([])
        setShowClaimsDetail(false)
    }
    return (
        <>
            {<BallTriangleLoading isshow={loading} />}
            <Modal show={IsShowClaimsDetail}
                keyboard={false}
                size="xl"
                centered>
                <Modal.Header  >
                    <Modal.Title style={{ color: '#5CADA9' }}>
                        <i
                            className='fa-solid fa-arrow-left fa-sm me-2 cursor-pointer'
                            onClick={() => { close() }}
                        ></i>
                        Expense Claim Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={initialClaimData}
                        validationSchema={ClaimDetailsPopUpValidation}
                        onSubmit={handleClaimModalSubmit}
                        enableReinitialize key={formKey}
                    >
                        {({ errors, submitForm, touched, setFieldValue, values, setValues }) => (
                            <>
                                <Form>
                                    <div className="row">
                                        <div className='col-6'>
                                            <div className="row">
                                                <div className="col-6 staan-input-group">
                                                    <Field type='date' name='doe' id='doe' className='w-100 staan-form-input' />
                                                    <label htmlFor="doe" className='staan-form-input-label pt-1 px-1 mx-2'>DOE<span className='text-danger'>*</span></label>
                                                    {touched.doe && errors.doe && <small>{errors.doe}</small>}
                                                </div>
                                                <div className="col-6 staan-input-group">
                                                    <Select
                                                        onInputChange={(e) => {
                                                            e ? getOptions(e, "expenseCategory") :
                                                                setclaimOptionList(prevState => ({
                                                                    ...prevState,
                                                                    categoryList: []
                                                                }));
                                                        }}
                                                        options={claimOptionList?.categoryList}
                                                        name='category'
                                                        value={claimSelectValues?.categorySelect}
                                                        className='mt-3'

                                                        onChange={(option) => {
                                                            setFieldValue('category', option ? Number(option?.value) : null)
                                                            setSelectClaimValues(prve => ({
                                                                ...prve,
                                                                categorySelect: option ? option : null
                                                            }))
                                                            setFieldValue('gstIn', option?.gstIn)
                                                            console.log(option);

                                                        }}
                                                        styles={customSelectStyle}
                                                    />
                                                    <label className='staan-form-input-label  px-1 mx-2' onClick={() => {
                                                        console.log(values);
                                                    }}>Expense Category<span className='text-danger'>*</span></label>
                                                    {touched.category && errors.category && <small>{errors.category}</small>}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 staan-input-group">
                                                    <Field as="textarea" name='description' id='description' className='w-100 staan-form-input' />
                                                    <label htmlFor="description" className='staan-form-input-label pt-1 px-1 mx-2'>Description<span className='text-danger'>*</span></label>
                                                    {touched.description && errors.description && <small>{errors.description}</small>}
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-6 staan-input-group">
                                                    <Field type='text' name='claimAmount' id='claimAmount' className='w-100 staan-form-input' />
                                                    <label htmlFor="claimAmount" className='staan-form-input-label pt-1 px-1 mx-2'>Claim Amount<span className='text-danger'>*</span></label>
                                                    {touched.claimAmount && errors.claimAmount && <small>{errors.claimAmount}</small>}
                                                </div>
                                                <div className="col-6 staan-input-group">
                                                    <Field type='text' name='approvedAmount' id='approvedAmount' className='w-100 staan-form-input' />
                                                    <label htmlFor="approvedAmount" className='staan-form-input-label pt-1 px-1 mx-2'>Approved Amount<span className='text-danger'>*</span></label>
                                                    {touched.approvedAmount && errors.approvedAmount && <small>{errors.approvedAmount}</small>}
                                                </div>
                                                <div className="col-6 staan-input-group">
                                                    <div className="form-check form-switch m-4">
                                                        <input
                                                            className='form-check-input'
                                                            type="checkbox"
                                                            id="flexSwitchCheckDefault"
                                                            checked={values.gstIn}
                                                            onChange={(e) => {
                                                                setFieldValue('gstIn', e.target.checked)
                                                            }}
                                                        />
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault" onClick={() => {
                                                            console.log("docId", docId, "selectedDoc", selectedDoc);
                                                        }}>
                                                            GST
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-6'>
                                            <div className='row'>
                                                <div className='col-6 mt-4'>
                                                    <div className='d-flex'>
                                                        <div className="border rounded document ml-4"
                                                            style={{
                                                                width: '20rem',
                                                                height: '8rem', // Adjust height as needed
                                                                overflow: "auto",
                                                                position: "relative"
                                                            }}
                                                            onClick={() => { DocInputRef.current.click() }}
                                                        >
                                                            <Field
                                                                onChange={(e) => { handleDocSelect(e) }}
                                                                innerRef={DocInputRef}
                                                                multiple accept=".csv,.xlsx,.pdf,.doc,.docx"
                                                                className="form-control form-control-sm" hidden id="formFileSm" type="file" name="fieldName" />
                                                            <div className='d-flex justify-content-center align-items-center' style={{ height: "100%" }}>
                                                                <div className='text-center'>
                                                                    <br />
                                                                    <p className='text-center'>Click Here To Attach File</p>
                                                                    <p>Max Size : 5MB</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='col-6 mt-4'>
                                                    <div style={{ width: '100%', height: '150px', overflowY: 'auto' }}>
                                                        <ul>
                                                            {
                                                                selectedDoc?.map((file, index) => {
                                                                    return <>
                                                                        <li key={index} className='d-flex justify-content-between mb-1 text-start'>
                                                                            <span><i className="fa-regular fa-circle"></i></span>
                                                                            <a href={file.documentFile} download={file.documentFile} style={{ cursor: 'pointer' }}>{decodeURI(file.name)}</a>
                                                                            <span
                                                                                className=' text-danger'
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => {
                                                                                    handleDeleteDocument(file)
                                                                                }}
                                                                            >
                                                                                <i className="fa-regular fa-trash-can"></i>
                                                                            </span>
                                                                        </li>
                                                                    </>
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row mt-3">
                                        <div className="text-center">
                                            <button type="button" className="btn btn-outline-danger btn-sm me-4 px-3" onClick={() => { close() }}>
                                                Cancel
                                            </button>
                                            <button type="submit" className="btn btn-sm btn-outline-success shadow-sm px-4">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal >
        </>
    );
};

export default ClaimsModal;
