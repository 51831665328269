import { Field, Form, Formik } from 'formik'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select'
import axiosInstance from '../../../api/axoiss'
import { countries_list } from '../../../components/CountryWithStates'
import DataContext from '../../../context/ItemMasterContext'
import { QueryFilter } from '../../../GraphQLQueries/ItemmasterQuery'
import { customSelectStyle } from '../../../utils/Utils'
import { AddressFromSchema } from '../../../validations/itemmaster'

const AddressFrom = ({
  addressadd,
  handleaddressFromAddClose,
  EditAddress,
  setEditAddress
  
    }) => {
    const { AddressPost, setAddressPost, setAddressdata } = useContext(DataContext)
    const [stateOptions, setStateOptions] = useState([])
    const AddresstypeRef = useRef(null)
    const Address_Line_1Ref = useRef(null)
    const Address_Line_2Ref = useRef(null)
    const CityRef = useRef(null)
    const pincodeRef = useRef(null)
    //
    const [initialValues, setinitialValues] = useState({
      address_type: '',
      Address_Line_1: '',
      Address_Line_2: '',
      City: '',
      pincode: '',
      State: '',
      Country: '',
      Default: false
    })

    const address_types = [
      { value: 'BILLING_ADDRESS', label: 'BILLING_ADDRESS' },
      { value: 'SHIPING_ADDRESS', label: 'SHIPING_ADDRESS' },
      { value: 'OTHERS', label: 'OTHERS' }
    ]

    const [addressTypeOption, setAddressTypeOption] = useState(address_types)

    useEffect(() => {
      let selected_address_types = AddressPost?.map(item => item.address_type);
      let filtered_address_type = address_types.filter(item => !selected_address_types.includes(item.value));
      setAddressTypeOption(filtered_address_type)
      if (EditAddress !== '' && EditAddress !== undefined) {
        setinitialValues({
          address_type: EditAddress['address_type'],
          Address_Line_1: EditAddress['Address_Line_1'],
          Address_Line_2: EditAddress['Address_Line_2'],
          City: EditAddress['City'],
          pincode: EditAddress['pincode'],
          State: EditAddress['State'],
          Country: EditAddress['Country'],
          Default: EditAddress['Default'],
        })
        // AddresstypeRef Address_Line_1Ref Address_Line_2Ref CityRef pincodeRef
        // AddresstypeRef.current.value = EditAddress['address_type']? EditAddress['address_type'] : EditAddress['address_type']
        // Address_Line_1Ref.current.value = EditAddress['Address_Line_1']? EditAddress['Address_Line_1'] : ""
        // //
        // Address_Line_2Ref.current.value = EditAddress['Address_Line_2'] ? EditAddress['Address_Line_2'] : ""
        // CityRef.current.value = EditAddress['City']?EditAddress['City'] :""
        // pincodeRef.current.value = EditAddress['pincode']? EditAddress['pincode'] : ""
        initialValues.address_type = EditAddress['address_type']
        initialValues.Address_Line_1 = EditAddress['Address_Line_1']
        initialValues.Address_Line_2 = EditAddress['Address_Line_2']
          ? EditAddress['Address_Line_2']
          : ''
        initialValues.City = EditAddress['City']
        initialValues.pincode = EditAddress['pincode']
        initialValues.State = EditAddress['State']
        initialValues.Country = EditAddress['Country']
        initialValues.Default = EditAddress['Default']
        setDefault(EditAddress['Default'])
        setAddresstype({
          value: EditAddress['address_type'],
          label: EditAddress['address_type']
        })
        setAddressLine1(EditAddress['Address_Line_1'])
        setAddressLine2(EditAddress['Address_Line_2'])
        setCity(EditAddress['City'])
        setpincode(EditAddress['pincode'])

        handleonChangeCountrylist({
          value: EditAddress['Country'],
          label: EditAddress['Country']
        })
        setSelectedState({
          value: EditAddress['State'],
          label: EditAddress['State']
        })
        setFormKey(prev=>prev+1)
      } else {
        setinitialValues({
          address_type: '',
          Address_Line_1: '',
          Address_Line_2: '',
          City: '',
          pincode: '',
          State: '',
          Country: ''
        })
        // initialValues.address_type =   ""
        // initialValues.Address_Line_1 =  ""
        // initialValues.Address_Line_2 =  ""
        // initialValues.City =  ""
        // initialValues.pincode =   ""
        // initialValues.State =  ""
      }
    }, [EditAddress])

    

    const [addresstype, setAddresstype] = useState('')
    const handleonChangeaddresstype = value => {
      setAddresstype(value)
    }
    const [AddressLine1, setAddressLine1] = useState('')
    const handleonChangeAddressLine1 = value => {
      setAddressLine1(value)
    }
    const [AddressLine2, setAddressLine2] = useState('')
    const handleonChangeAddressLine2 = value => {
      setAddressLine2(value)
    }

    const [City, setCity] = useState('')
    const handleonChangeCity = value => {
      setCity(value)
    }
    const [pincode, setpincode] = useState('')
    const handleonChangepincode = value => {
      setpincode(value)
    }

    const [Countrylist, setCountryList] = useState('')

    const handleonChangeCountrylist = Option => {
      const regionsList = countries_list.find(
        Country => Country.countryName === Option.value
      )?.regions

      setStateOptions(regionsList)

      setCountryList({ value: Option.value, label: Option.label })
    }

    const [selectedState, setSelectedState] = useState('')
    const handleonChangeState = Option => {
      setSelectedState({ value: Option.value, label: Option.label })
    }

    const [isDefault, setDefault] = useState(false)
    const handleDefaultChange = (e) => {
      setDefault(e.target.checked)
    }

    const CountryOptions = countries_list.map(Country => Country.countryName)

    function resetData () {
      setAddresstype('')
      setAddressLine1('')
      setAddressLine2('')
      setStateOptions([])
      setCity('')
      setpincode('')
      setCountryList('')
      setSelectedState('')
      setDefault(false)
      setEditAddress('')
    }

    const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
      let proccess_saved_data = {}
      if(values['address_type'] !== ''){
        proccess_saved_data['addressType'] = values['address_type'] 
      }
      if(values['Address_Line_1'] !== ''){
        proccess_saved_data['addressLine1'] = values['Address_Line_1'] 
      }
      if(values['Address_Line_2'] !== '' && values['Address_Line_2']){
        proccess_saved_data['addressLine2'] = values['Address_Line_2'] 
      }
      if(values['pincode'] !== ''){
        proccess_saved_data['pincode'] = String(values['pincode'])
      }
      if(values['Country'] !== '' && values['Country']){
        proccess_saved_data['country'] = String(values['Country'])
      }
      if(values['State'] !== '' && values['State']){
        proccess_saved_data['state'] = String(values['State'])
      }
      if(values['City'] !== '' && values['City']){
        proccess_saved_data['city'] = String(values['City'])
      }
      proccess_saved_data['default'] = isDefault
      if(isDefault){
        let address_ids = AddressPost?.map(item => item?.id)
        try{
          if(EditAddress !== '' && EditAddress !== undefined){
            address_ids = address_ids.filter(id => id !== EditAddress?.['id'])
          }
          address_ids = address_ids?.map(id => parseInt(id))

          let address_bulk_update_query = `mutation AddressFormBulkUpdate{
            supplierSubFormDefaultUpdateMutation(name: "CompanyAddress", idList: [${address_ids}]){
              success
              errors
            }
          }
          `
          await axiosInstance.post("/itemmaster/graphql", {query: address_bulk_update_query})
          setAddressPost((prevData) => {return prevData.map((item) =>  ({...item, 'default':  false, 'Default': false}))});
        }catch(e){
          console.log(e)
        }

      }
      if (EditAddress !== '' && EditAddress !== undefined) {
        try {
          proccess_saved_data['id'] = EditAddress['id']
          let update_mutation_query = `mutation companyAddressCreateMutation {
            companyAddressCreateMutation(`
            update_mutation_query += QueryFilter(proccess_saved_data, ['default'], ['id'])
            update_mutation_query+=`) {
              companyAddressItem {
                id
                addressType
                addressLine1
                addressLine2
                city
                country
                city
                state
                pincode
                default
              }
              errors
              success
            }
          }`

          const response = await axiosInstance.post("/itemmaster/graphql", {query: update_mutation_query})
          const responseData = response?.data?.data?.companyAddressCreateMutation?.companyAddressItem

          let updated_res_data  = {...responseData}
          updated_res_data['Address_Line_1'] = updated_res_data['addressLine1']
          updated_res_data['Address_Line_2'] = updated_res_data['addressLine2']
          updated_res_data['address_type'] = updated_res_data['addressType']
          updated_res_data['City'] = updated_res_data['city']
          updated_res_data['State'] = updated_res_data['state']
          updated_res_data['pincode'] = updated_res_data['pincode']
          updated_res_data['Default'] = updated_res_data['default']
          updated_res_data['Country'] = updated_res_data['country']
          setAddressPost((prevData) => {
            return prevData.map((item) => {
              return item.id === updated_res_data.id ? updated_res_data : item;
            });
          })
          setAddressdata((prevData) => {
            return prevData.map((item) => {
              return item.id === updated_res_data.id ? updated_res_data :  item;
            });
          })
          resetForm()
          resetData()
          handleaddressFromAddClose()
          setEditAddress('')
        } catch (error) {
          console.log(error)
        }
      } else {
        try {
          let update_mutation_query = `mutation companyAddressCreateMutation {
            companyAddressCreateMutation(`
            update_mutation_query += QueryFilter(proccess_saved_data, ['default'], ['id'])
            update_mutation_query+=`) {
              companyAddressItem {
                id
                addressType
                addressLine1
                addressLine2
                city
                country
                city
                state
                pincode
                default
              }
              errors
              success
            }
          }`
          const response = await axiosInstance.post("/itemmaster/graphql", {query: update_mutation_query})
          const responseData = response?.data?.data?.companyAddressCreateMutation?.companyAddressItem
          let updated_res_data  = {...responseData}
          updated_res_data['Address_Line_1'] = updated_res_data['addressLine1']
          updated_res_data['Address_Line_2'] = updated_res_data['addressLine2']
          updated_res_data['address_type'] = updated_res_data['addressType']
          updated_res_data['City'] = updated_res_data['city']
          updated_res_data['State'] = updated_res_data['state']
          updated_res_data['pincode'] = updated_res_data['pincode']
          updated_res_data['Default'] = updated_res_data['default']
          updated_res_data['Country'] = updated_res_data['country']
          setAddressPost(prevData => [ updated_res_data, ...prevData])
          setAddressdata(prevData => [ updated_res_data,...prevData])
          resetForm()
          resetData()
          handleaddressFromAddClose()
        } catch (error) {
          console.log(error)
        }
      }
    }
  const [formKey, setFormKey] = useState(0)
  return (
    <>
      <Modal show={addressadd} size='lg' 
        onHide={() => {
          handleaddressFromAddClose()
          resetData()
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Address Form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={AddressFromSchema}
            onSubmit={handleSubmit}
            enableReinitialize
            key={formKey}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                {/* New UI design starts */}
                <div>
                  <div className='row'>
                    <div className='col-6 staan-input-group'>
                      <Select
                        name='address_type'
                        options={addressTypeOption}
                        ref={AddresstypeRef}
                        isSearchable={true}
                        defaultValue={
                          EditAddress ? EditAddress['addressType'] : ''
                        }
                        value={addresstype}
                        onChange={selectedOption => {
                          handleonChangeaddresstype(selectedOption)
                          setFieldValue('address_type', selectedOption.value)
                        }}
                        styles={customSelectStyle}
                        className='my-3'
                      />
                      <label
                        htmlFor='Addresstype'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Address type
                      </label>
                      {touched.address_type && errors.address_type && (
                        <small>{errors.address_type}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='Address_Line_1'
                        placeholder=''
                        ref={Address_Line_1Ref}
                        value={AddressLine1}
                        defaultValue={
                          EditAddress ? EditAddress['addressLine1'] : ''
                        }
                        className='w-100 staan-form-input'
                        onChange={e => {
                          handleonChangeAddressLine1(e.target.value)
                          setFieldValue('Address_Line_1', e.target.value)
                        }}
                      />
                      <label
                        htmlFor='Address_Line_1'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        Address Line 1
                      </label>
                      {touched.Address_Line_1 && errors.Address_Line_1 && (
                        <small>{errors.Address_Line_1}</small>
                      )}
                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='Address_Line_2'
                        ref={Address_Line_2Ref}
                        placeholder=''
                        defaultValue={
                          EditAddress ? EditAddress['addressLine2'] : ''
                        }
                        value={AddressLine2}
                        className='w-100 staan-form-input'
                        onChange={e => {
                          handleonChangeAddressLine2(e.target.value)
                          setFieldValue('Address_Line_2', e.target.value)
                        }}
                      />
                      <label
                        htmlFor='Address_Line_2'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        Address Line 2
                      </label>
                      {touched.Address_Line_2 && errors.Address_Line_2 && (
                        <small>{errors.Address_Line_2}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='City'
                        placeholder=''
                        ref={CityRef}
                        value={City}
                        defaultValue={EditAddress ? EditAddress['city'] : ''}
                        className='w-100 staan-form-input'
                        onChange={e => {
                          handleonChangeCity(e.target.value)
                          setFieldValue('City', e.target.value)
                        }}
                      />
                      <label
                        htmlFor='City'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        City
                      </label>
                      {touched.City && errors.City && (
                        <small>{errors.City}</small>
                      )}
                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='number'
                        name='pincode'
                        defaultValue={EditAddress ? EditAddress['pincode'] : ''}
                        placeholder=''
                        ref={pincodeRef}
                        value={pincode}
                        className='w-100 staan-form-input'
                        onChange={e => {
                          handleonChangepincode(e.target.value)
                          setFieldValue('pincode', e.target.value)
                        }}
                      />
                      <label
                        htmlFor='pincode'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        Pincode
                      </label>
                      {touched.pincode && errors.pincode && (
                        <small>{errors.pincode}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Select
                        name='Country'
                        defaultValue={EditAddress ? EditAddress['country'] : ''}
                        isSearchable={true}
                        options={CountryOptions.map(country => ({
                          value: country,
                          label: country
                        }))}
                        value={Countrylist} // Use value prop to bind the value
                        onChange={selectedOption => {
                          handleonChangeCountrylist(selectedOption)
                          setFieldValue('Country', selectedOption.value)
                        }}
                        styles={customSelectStyle}
                        className='my-3'
                      />
                      <label
                        htmlFor='Country'
                        className='staan-form-input-label pt-2 px-1 mx-2'
                      >
                        Country
                      </label>
                      {touched.Country && errors.Country && (
                        <small>{errors.Country}</small>
                      )}
                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className='col-6 staan-input-group'>
                      <Select
                        name='State'
                        isSearchable={true}
                        defaultValue={EditAddress ? EditAddress['state'] : ''}
                        options={
                          stateOptions
                            ? stateOptions.map(state => ({
                                value: state.name,
                                label: state.name
                              }))
                            : []
                        }
                        value={selectedState} // Use value prop to bind the value
                        onChange={selectedOption => {
                          handleonChangeState(selectedOption)
                          setFieldValue('State', selectedOption.value)
                        }}
                        className='my-3'
                        styles={customSelectStyle}
                      />
                      <label
                        htmlFor='State'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        State
                      </label>
                      {touched.State && errors.State && (
                        <small>{errors.State}</small>
                      )}
                    </div>
                    <div className='col-3 staan-input-group d-flex align-items-center'>
                        <div>
                            <Field type="checkbox" name="Default"  className='me-2' checked={isDefault} 
                              onChange = {(e)=>{handleDefaultChange(e)}}
                            />
                              <label style={{fontSize: '.8rem'}} className='pb-2'>Default</label>
                        </div>
                    </div>
                    <div className='col-3 d-flex justify-content-end align-items-center'>
                      <div>
                        <button
                          type='submit'
                          className='btn btn-sm btn-outline-success'
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* New UI design ends */}
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddressFrom
