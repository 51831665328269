import React, {useState} from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import TableListView from '../../Packages/Custom/DataTable/TableListView'
import TableView from '../../Packages/Custom/DataTable'
import ContactFrom from '../ItemMaster/model/ContactFrom'

const ContactList = () => {
    const [isLoad, setLoad] = useState(false)
  
    const [Contactedit, setContactEdit] = useState('')
    const [showContact, setShowContact]= useState(false)
  
    const CustomViewModalComponent =(data)=>{
      data["Contact_Person_Name"] =  data['contactPersonName']
      data['Email'] = data?.['email']
      data['Salutation'] = data?.['salutation']
      data['Phone_number'] = data?.['phoneNumber']
      data['OtherNo'] = data?.['otherNo']
      data['WhatsappNo'] = data?.['whatsappNo']
      data['Default'] = data?.['default']
      data['OtherNo'] = data?.['otherNo']
      setContactEdit(data)
      
      setShowContact(true)
    }
    const CustomAddModalComponent = () => {
      setShowContact(true)
    }
    
    const headers = [
        {
          header: 'Name',
          field: 'contactPersonName',  
          flex: 10, 
          inputType: 'text',
          renderComponent: CustomViewModalComponent,
        },
        {
          header: 'Email',
          field: 'email',  
          flex: 10, 
          inputType: 'text',
        },
        {
          header: 'Phone Number',
          field: 'phoneNumber',  
          flex: 10, 
          inputType: 'text',
        },
        {
          header: 'whatsapp No',
          field: 'whatsappNo',  
          flex: 10, 
          inputType: 'text',
        },
        {
          header: 'Supplier ID',
          field: 'supplierformdataSet', 
          label: "supplierNo",
          flex: 10, 
          inputType: 'text',
          
        },
        {
          header: 'Contact Type',
          field: 'contactType',  
          label: "name",
          flex: 10, 
          inputType: 'text',
        },
      
      ]
  return (
    <>
    <div className='container-fluid' style={{width:"100%", position:"relative"}}>
        <div className='' style={{position:"fixed", top:"0", left:"0", width:"10%", zIndex:"20"}}>
                <SideNavbar />
        </div>
        <div className=' ' style={{position:"fixed", top:"0", left:"1rem",width:"95%" }}>
        <div id="custom-table-itemmaster">
            <TableView
              headers={headers}
              query = {'ContactTableQuery'}
              addComponent={CustomAddModalComponent}
              loadComponent={isLoad}
              titleName={"Contact"}
              // permission={"Create_Contact"}
              heightAdjust={"50rem"}
              customFilter={true}
            />
        </div>
        <ContactFrom
        ContactFromAdd= {showContact}
        setcontactFromAdd = {setShowContact}
        setLoad = {()=>{setLoad(!isLoad)}}
        EditContact = {Contactedit}
        setEditContact = {setContactEdit}
        condition = {true}
        
        /> 

    </div>

    </div> 
   


    </>
  )
}

export default ContactList
