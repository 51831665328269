export const customfiltercreatemutation = (data) => {
    return `
mutation MyMutation {
  editListViewCreateMutations(${data}) {
    errors
    success
    editListViewInstance {
      id
    }
  }
}
      `
}
export const customfilterDeletemutation = (data) => {
  return `
mutation MyMutation {
  editListViewDeleteMutations(${data}) {
    errors
    success
  }
}
    `
}

export const tableListQuery = (data) => {
  return `
query MyQuery {
  EditListViewType(${data}) {
    coloumnToDisplay
    filiterConditions
    id
    isDefault
    table
    updatedAt
    viewName
    visibleTo
     visibleToUser{
      id
      username
    }
    createdBy{
      id 
    }
    modifiedBy{
      id
    }
  }
}
    `
}