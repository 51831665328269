import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React, { useContext, useState } from 'react';
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import DataContext from '../../context/ItemMasterContext';
import TableView from '../../Packages/Custom/DataTable';
import StoreAdd from './Model/StoreAdd';
 
 
const Store = () => {
   const {  handlStoreAddShow} = useContext(DataContext) 
 
   const [storeEditId , setstoreEditId] = useState()
   const [isLoad, setLoad] = useState(false)
 
    
  const CustomViewModalComponent = (data) => {
    handlStoreAddShow(); 
    setstoreEditId(data.id);  
    setLoad(!isLoad);
  }
 
  const headers = [
    {
      header: 'Store Name',
      field: 'storeName',
      label : "storeName",
      flex: 10, 
      option: true,
      query: 'storeFetchWithKeepStockQuery',
      inputType: 'text',
      renderComponent: CustomViewModalComponent,
    },
    {
      header: 'Store Account',
      field: 'storeAccount',
      label : "accountsName",
      flex: 10,  
      query: 'accountsMasterOptionQueryForTable',
      inputType: 'text',
    },
    {
      header: 'Store InCharge',
      field: 'storeIncharge',
      label : "username",
      flex: 10, 
      option: true,
      query: 'userOptionQuery',
      inputType: 'text',
    },
    {
      header:'Keep Stock' , 
      field:"matained",  
      align: 'center',
      flex: 8,
      option: true,
      bool: true,
      inputType: 'text',
    },
    
  ]
 
  const CustomAddModalComponent = () => {
    handlStoreAddShow()
  }
   
  return (
    <>
      
      <div className=' index_page_aliment_warp'>
        <div className='index_page_aliment_sidebar'>
              <SideNavbar />
        </div>
        <div className='index_page_aliment_table'>
            <TableView
                  headers = {headers}
                  query = {'StoreQuery'}
                  addComponent = {CustomAddModalComponent}
                  loadComponent = {isLoad}
                  titleName = {'Store'}
                  heightAdjust = {'40rem'}
                  permission={"Save_Store"}
                  customFilter={true}
             />
        </div>
      </div>
        <StoreAdd storeEditId={storeEditId} 
        setstoreEditId={setstoreEditId}
        setLoad = {()=>{setLoad(!isLoad)}}
        />

      </>
  
  );
};

export default Store;
