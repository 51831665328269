import * as yup from "yup";


export const filterValidation = yup.object().shape({
    viewName:yup.string().required('View Name is required'),
    visibleTo:yup.string().required('View To is required'),
    visibleToUser: yup.array().when('visibleTo', {
        is: (val) => val === 'Select Users',
        then: (schema) => schema.required('Visible To User is required'),
        otherwise: (schema) => schema.notRequired(),
    }),
})