import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { supplierFormGstEffectiveDateCreateMutation } from '../../../GraphQLQueries/ItemMastermutation';
import { ContactFetch, QueryFilter, QueryFilterWithArray, fetchGstTypeQuery, userOptionQuery } from '../../../GraphQLQueries/ItemmasterQuery';
import { fetchGstType, fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import axiosInstance from '../../../api/axoiss';
import WaringModel from '../../../components/Warings/WaringModel';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import DataContext from '../../../context/ItemMasterContext';
import { DateFormate, DeleteEmptyKeys, ShowErrorNoties, convertToTitleCase, customSelectStyle, getCurrentDate, onCellDoubleClicked } from '../../../utils/Utils';
import { SupplierFromSchema } from '../../../validations/itemmaster';
import AddressFrom from './AddressFrom';
import ContactFrom from './ContactFrom';
import CustomerGroup from './CustomerGroup';
import SupplierGroup from './SupplierGroup';



const SupplierFrom = ({supplierEdit, setSupplierEdit, isLoad, setLoad, lead}) => {
    const {SupplierAdd, handleSupplierClose, handltoastDeleteConfomationShow,
        contanctPost, setContanctPost, AddressPost, setAddressPost, setsupplierdetails ,  costomerGroupdata, supplierGroupdata,
    handlesupplierGroupShow, handlecustomerGroupShow,  setSupplierSelect,customerGroupSelect, setCustomerGroupSelec, 
    supplierGroupSelect , setSupplierGroupSelect , userId, setcontactdata} = useContext(DataContext)
    
    const dispatch = useDispatch(); 
    const gstTypeList = useSelector(state => state.gstType.data);
    const getData = useSelector(state => state.itemGroup.data)
    
    
    const [formKey, setFormKey] = useState(0); 
    const gridApiRef = useRef(null); 
    const gridApiRef2 = useRef(null); 
    const [url_fix, seturl_fix] = useState('')
    const [userOption, setUserOptions] = useState([])
    const [isEditForm, setEditForm] = useState(true);
    const [effectiveDate, setEffectiveDate] =useState(getCurrentDate())
    const [isChangeGst, setIsChangeGst] = useState(false)
    const [initialEffectivedata , setInitialEffectivedata] = useState({gstType:"", gstin:""})
    const [contactList, setContactList] = useState([])
    const [newContactNumber, setNewContactNumber] = useState()

    // const 
    const handleEnableEditView = () => {
      setEditForm(!isEditForm)
    }

    const handleDisableEditView = () => {
      setEditForm(false)
    }

    const [initialValue, setInitialValue] = useState({
          supplierNo :"",
          Company_Name :"",
          Legal_Name: "",
          Customer: false,
          Supplier: false,
          Transporter: false,
          TransporterId: "",
          GSTIN_Type: "",
          GSTIN: "",
          
          TCS: "",
          Pan_no: "",
          active :true,
          customer_group: "",
          sales_person : "",
          customer_credited_period : "",
          credited_limit:  "",
          supplier_group: "",
          supplier_credited_period : "",
          contact: [
           
          ],
          address: [
             
          ]
    });

    useEffect(() => {
      dispatch(fetchGstType(fetchGstTypeQuery()))
      let user_query = userOptionQuery()
      axiosInstance.post("/itemmaster/graphql", {query: user_query})
      .then(res => {
          const responseData = res?.data?.data?.User?.items
          let user_options = responseData.map(item => ({label: item.username , value: item.id}));
          setUserOptions(user_options)
      })
      
      if(supplierEdit){
      handleDisableEditView() 
      
      setInitialValue({
        supplierNo:supplierEdit?.['supplierNo'],
        Company_Name :supplierEdit?.['companyName'],
        Legal_Name: supplierEdit?.['legalName'],
        Customer:  supplierEdit?.['customer'],
        Supplier:  supplierEdit?.['supplier'],
        Transporter:  supplierEdit?.['transporter'],
        TransporterId: supplierEdit?.['transporterId'],
        GSTIN_Type: supplierEdit['gstinType']?.['id'],
        GSTIN: supplierEdit?.['gstin'],
        TCS: supplierEdit?.['tcs'],
        Pan_no: supplierEdit?.['panNo'],
        active :supplierEdit?.['active'],
        customer_group: supplierEdit['customerGroup']?.['id'],
        sales_person : supplierEdit['salesPerson']?.['id'],
        customer_credited_period : supplierEdit['customerCreditedPeriod'] ?  supplierEdit['customerCreditedPeriod']  : "",
        credited_limit:  supplierEdit['creditedLimit'] ? supplierEdit['creditedLimit'] : "" ,
        supplier_group: supplierEdit['supplierGroup']?.['id'],
        Default: supplierEdit?.['defualt'],
        supplier_credited_period : supplierEdit['supplierCreditedPeriod'],
        contact: [
          supplierEdit?.['contact']
        ],
        address: [
          supplierEdit?.['address']
        ]
      })
     
      
      // initialValue.Company_Name = supplierEdit['companyName']
      // initialValue.Legal_Name = supplierEdit['legalName']
      // initialValue.Customer =  supplierEdit['customer']
      // initialValue.Supplier =  supplierEdit['supplier']
      // initialValue.Transporter = supplierEdit['transporter']
      // initialValue.TransporterId = supplierEdit['transporterId']
      // initialValue.GSTIN_Type = supplierEdit['gstinType']?.['id']
      // initialValue.GSTIN = supplierEdit['gstin']
      setInitialEffectivedata({gstType: initialValue.GSTIN_Type, gstin: initialValue.GSTIN})
      // initialValue.TCS = supplierEdit['tcs'] ? supplierEdit['tcs'] : ''
      // initialValue.Pan_no = supplierEdit['panNo'] ? supplierEdit['panNo'] : ''
      // initialValue.active = supplierEdit['active']
      // initialValue.contact = supplierEdit['contact']
      // initialValue.address = supplierEdit['address']
      // initialValue.Default = supplierEdit['defualt']
      // initialValue.customer_group = supplierEdit['customerGroup']?.['id']
      // initialValue.sales_person = supplierEdit['salesPerson']?.['id']
      // initialValue.customer_credited_period = supplierEdit['customerCreditedPeriod'] ?  supplierEdit['customerCreditedPeriod']  : ""
      // initialValue.credited_limit = supplierEdit['creditedLimit'] ? supplierEdit['creditedLimit'] : "" 
      // initialValue.supplier_credited_period = supplierEdit['supplierCreditedPeriod']
      // initialValue.supplier_group = supplierEdit['supplierGroup']?.['id']
      setFormKey(prev=>prev+1)
      setgsttype( { value: supplierEdit?.['gstinType']?.id , label: supplierEdit?.['gstinType']?.['gstType'] })
      setSelectTcs({ value: supplierEdit['tcs'] , label: supplierEdit['tcs'] })
      setCustomerGroupSelec({
        value: supplierEdit['customerGroup']?.['id'],
        label: supplierEdit['customerGroup']?.['name']
      });
      setSupplierGroupSelect({
        value:  supplierEdit['supplierGroup']?.['id'] , 
        label:  supplierEdit['supplierGroup']?.['name'] 
      })
      setSelectSalesperson({
        value: supplierEdit['salesPerson']?.['id'] ,
        label: supplierEdit['salesPerson']?.['username'] 
      })
  
      
      
      const getcontactlist =  supplierEdit['contact']
      if (Array.isArray(getcontactlist)) {
        let processed_contacts = getcontactlist.map(item =>  {
          let updated_contact = {}
          updated_contact['id'] = item['id']
          updated_contact['Contact_Person_Name'] = item['contactPersonName']
          updated_contact['Email'] = item['email']
          updated_contact['Default'] = item['default'] ? item['default'] : false
          updated_contact['Phone_number'] = item['phoneNumber']
          updated_contact['Salutation'] = item['salutation']
          updated_contact['OtherNo'] = item['otherNo']
          updated_contact['WhatsappNo'] = item['whatsappNo']
          return updated_contact
        })
        setContanctPost(processed_contacts);
      }
      const getAddressList = supplierEdit['address'];
      if (Array.isArray(getAddressList)) {
      let processed_addresss = getAddressList.map(item => {
          let updated_address = {}
          updated_address['id'] = item['id']
          updated_address['Address_Line_1'] = item['addressLine1']
          updated_address['Address_Line_2'] = item['addressLine2']
          updated_address['Default'] = item['default'] ? item['default'] : false
          updated_address['State'] = item['state']
          updated_address['Country'] = item['country']
          updated_address['address_type'] = item['addressType']
          updated_address['City'] = item['city']
          updated_address['pincode'] = item['pincode']
          return updated_address
      })
        setAddressPost(processed_addresss);
      }

      } else { 
      setEditForm(true)
      console.log("0--0dlnsdvlm");
      
      setInitialValue({
        Company_Name :"",
        Legal_Name: "",
        Customer: false,
        Supplier: false,
        Transporter: false,
        TransporterId: "",
        GSTIN_Type: "",
        GSTIN: "",
        TCS: "",
        Pan_no: "",
        active :true,
        contact: [],
        address: [],
      })
      }
      
    }, [supplierEdit])
    useEffect(()=>{

      if (getData.name === 'SupplierFrom_Conatct_Data') { 
        console.log(getData?.value?.items);
        
        setContactList(getData?.value?.items?.map((item)=>({value:item?.id, label:item?.phoneNumber,
          Contact_Person_Name:item?.contactPersonName, Email:item?.email,
          Default:false,  Phone_number:item?.phoneNumber, Salutation:convertToTitleCase(item?.['salutation'])
          , OtherNo:item?.otherNo,WhatsappNo:item?.whatsappNo, id: item?.id
        })))
      }
    },[getData])
    useEffect(()=>{console.log(initialValue);
    },[initialValue])
    const [exitWaring, setexitWaring] = useState(false)

    const handlesetexitWaringClose = ()=>{
      setexitWaring(false)
    }
    const Waringmodel = ()=>{
         return(
          <Modal
            show={exitWaring}
            onHide={handlesetexitWaringClose}
            backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className='text-warning'>warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='fs-4 fw-bold text-center'>Do You Want to Save the Changes.</p>
             <div className="row">
              <div className="col-12 text-center">
              <button type="button" className="btn btn-outline-success px-4" onClick={handlesetexitWaringClose}>Yes</button>
          <button type="button" className="btn btn-outline-danger ms-3  px-4" onClick={()=>{  handleSupplierClose();
            RESETDATA() 
            // setcontactlength([])
            // setAddresslength([])
            handlesetexitWaringClose()}}>No</button>
              </div>
             </div>
          </Modal.Body>
       
        </Modal>
         )
    }

    const [deleteData, setDeleteData ] = useState('')
    
    const tcs_options = [
        { value: "SALES", label: "SALES" },
        { value: "PURCHASE", label: "PURCHASE" },
        { value: "BOTH", label: "BOTH" },
    ]

    function RESETDATA() {
        setAddressPost([])
        setContanctPost([])
       
        setSelectTcs('')
        setgsttype('')
        setCustomerGroupSelec('')
        setSelectSalesperson('')
        setSupplierGroupSelect('')
        if(supplierEdit){
          setSupplierEdit(null)
        }
        
    }
    
    // retun Bool;  Save Supplier Gst Effective
    async function saveSupplierGstEffective(params) {
       
      try{
         const response = await axiosInstance.post("/itemmaster/graphql", {query: supplierFormGstEffectiveDateCreateMutation(params)}            )
         if (response.data.data.supplierFormGstEffectiveDateCreateMutation.success) { 
             toast.success( 'Effective Date Saved Successfully' , {
                 position: "bottom-right",
                 autoClose: 5000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
                 progress: undefined,
                 theme: "light",
                 transition: Bounce,
             });  
             return true 
         } else{
             let error = response.data.data.supplierFormGstEffectiveDateCreateMutation.errors 
             let errorData = ShowErrorNoties(error)
             toast.error( errorData , {
                 position: "bottom-right",
                 autoClose: 5000,
                 hideProgressBar: false,
                 closeOnClick: true,
                 pauseOnHover: true,
                 draggable: true,
                 progress: undefined,
                 theme: "light",
                 transition: Bounce,
             });
             return false
         }
     }catch(e){ 
         let errorData = ShowErrorNoties(e)
         toast.error( errorData , {
             position: "bottom-right",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             theme: "light",
             transition: Bounce,
         });
         return false
     }
     
         
    }

    const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
        if (supplierEdit){
          if (initialEffectivedata['gstType'] ===  values['GSTIN_Type']  && initialEffectivedata['gstin'] === values['GSTIN']){
            setEffectiveDate("") 
          } else{
            if (!effectiveDate){
              toast.error( "Effective date is required " , {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
              return"" 
            }
            let parameter ;
            let resultSupplierGstEffective = false ;
            if ( DateFormate(effectiveDate) ===  DateFormate(new Date)){ 
                parameter = `supplierFormDataId: ${supplierEdit['id']} , modifiedBy: null ,id: "" , gstinType: ${values['GSTIN_Type']}, 
                gstin: "${values['GSTIN']}", effectiveDate: "${effectiveDate}", createdBy: ${userId}`
                resultSupplierGstEffective = await saveSupplierGstEffective(parameter)
                if (!resultSupplierGstEffective){
                  values['GSTIN'] = initialEffectivedata['gstin'] 
                  values['GSTIN_Type'] = initialEffectivedata['gstType'] 
                }   
            } else{
              parameter = `supplierFormDataId: ${supplierEdit['id']} , modifiedBy: null ,id: "" , gstinType: ${values['GSTIN_Type']}, 
                gstin: "${values['GSTIN']}", effectiveDate: "${effectiveDate}", createdBy: ${userId}`
                resultSupplierGstEffective = await saveSupplierGstEffective(parameter)
                values['GSTIN'] = initialEffectivedata['gstin'] 
                values['GSTIN_Type'] = initialEffectivedata['gstType']  
            }
            if (!resultSupplierGstEffective){
              toast.error( "Supplier GST Effective Not Updated Try again later" , {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  transition: Bounce,
              });
          }
          }
        }
        const ContactRows = gridApiRef.current.props.rowData
        const Contactids = ContactRows.map(item => item.id)
        const addressRows = gridApiRef2.current.props.rowData          
        const addressids =  addressRows.map(item => item.id)
        let has_default_contact = ContactRows.find(item => item.Default === true)
        let has_default_address = addressRows.find(item => item.Default === true)
        if(!has_default_address){
          toast.error('Any of the address should be as default', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }
        if(!has_default_contact){
          toast.error('Any of the contact should be as default', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          return;
        }
        let updated_form_submit_data = {
          "customer": values['Customer'],
          "supplier": values['Supplier'],
          "transporter": values['Transporter'],
          "contact": [],
          "address": [],
          "transporterId": "",
          "isLead" : lead? true : false
        }
        if(values['Company_Name'] !== '' && values['Company_Name']){
          updated_form_submit_data["companyName"]  = values['Company_Name']
        }
        if(values['Legal_Name'] !== '' && values['Legal_Name']){
          updated_form_submit_data["legalName"]  = values['Legal_Name']
        }
        if(values['Customer'] !== '' && values['Customer']){
          updated_form_submit_data["customer"]  = values['Customer']
        }
        if(values['Supplier'] !== '' && values['Supplier']){
          updated_form_submit_data["supplier"]  = values['Supplier']
        }
        if(values['Transporter'] !== '' && values['Transporter']){
          updated_form_submit_data["transporter"]  = values['Transporter']
        }
        if(values['TransporterId'] !== '' && values['TransporterId']){
          updated_form_submit_data["transporterId"]  = values['TransporterId']
        }
        if(values['GSTIN_Type'] !== '' && values['GSTIN_Type']){
          updated_form_submit_data["gstinType"]  = values['GSTIN_Type']
        }
        if(values['GSTIN'] !== '' && values['GSTIN']){
          updated_form_submit_data["gstin"]  = values['GSTIN']
        }
        if(values['TCS'] !== '' && values['TCS']){
          updated_form_submit_data["tcs"]  = values['TCS']
        }
        if(values['Pan_no'] !== '' && values['Pan_no']){
          updated_form_submit_data["panNo"]  = values['Pan_no']
        }
        if(values['active'] !== ''  ){
          updated_form_submit_data["active"]  = values['active']
        }
        if(customerGroupSelect !== '' && customerGroupSelect && customerGroupSelect.value !== ''){
          updated_form_submit_data["customerGroup"]  = customerGroupSelect.value
        }
        if(values['sales_person'] !== '' && values['sales_person']){
          updated_form_submit_data["salesPerson"]  = values['sales_person']
        }
        if(values['customer_credited_period'] !== '' && values['customer_credited_period']){
          updated_form_submit_data["customerCreditedPeriod"]  = values['customer_credited_period']
        }
        if(values['credited_limit'] !== '' && values['credited_limit']){
          updated_form_submit_data["creditedLimit"]  = values['credited_limit']
        }
        if(supplierGroupSelect !== '' && supplierGroupSelect && supplierGroupSelect.value !== ''){
          updated_form_submit_data["supplierGroup"]  = supplierGroupSelect.value
        }
        if(values['supplier_credited_period'] !== '' && values['supplier_credited_period']){
          updated_form_submit_data["supplierCreditedPeriod"]  = values['supplier_credited_period']
        }
        if(Contactids.length > 0 && Contactids){
          updated_form_submit_data["contact"]  = Contactids
        }
        if(addressids.length > 0 && addressids){
          updated_form_submit_data["address"]  = addressids
        }
        updated_form_submit_data = DeleteEmptyKeys(updated_form_submit_data) 
        if(supplierEdit !== "" && supplierEdit !== undefined  && supplierEdit){ 
          updated_form_submit_data['id'] = supplierEdit['id']
          let update_mutate_query = `mutation createContactData{
            supplierFormCreateMutation(`
            update_mutate_query += QueryFilterWithArray(updated_form_submit_data, 
              ['default', 'active', 'customer', 'transporter', 'supplier', "gstinType","isLead"], 
              ['id', 'supplierCreditedPeriod', 'creditedLimit', 'supplierGroup', 'creditedLimit', 'customerCreditedPeriod',
            'salesPerson', 'customerGroup'], 
              ['contact', 'address'])
            update_mutate_query +=`){
              success
              errors
              supplierFormItem{
                id
                companyName
                legalName
                customer
                supplier
                supplierCreditedPeriod
                transporter
                transporterId
                gstinType{
                  id
                  gstType
                }
                gstin
                tcs
                panNo
                contact{
                  id
                }
                address{
                  id
                }
              
                active
              }
            }
          }` 
          try{
            let response = await axiosInstance.post("/itemmaster/graphql", {query: update_mutate_query})
            response = response?.data?.data?.supplierFormCreateMutation
            if(response?.success){
              const responseData = response?.supplierFormItem
              let updated_res_data = {...responseData} 
              updated_res_data["Company_Name"]  = responseData['companyName']
              updated_res_data["Legal_Name"]  = responseData['legalName']
              updated_res_data["Customer"]  = responseData['customer']
              updated_res_data["Supplier"]  = responseData['supplier']
              updated_res_data["Transporter"]  = responseData['transporter']
              updated_res_data["TransporterId"]  = responseData['transporterId']
              updated_res_data["GSTIN_Type"]  = responseData['gstinType']
              updated_res_data["GSTIN"]  = responseData['gstin']
              updated_res_data["TCS"]  = responseData['tcs']
              updated_res_data["Pan_no"]  = responseData['panNo']
              updated_res_data["active"]  = responseData['active']
              updated_res_data["customer_group"]  = responseData['customerGroup']
              updated_res_data["sales_person"]  = responseData['salesPerson']
              updated_res_data["customer_credited_period"]  = responseData['customerCreditedPeriod']
              updated_res_data["credited_limit"]  = responseData['creditedLimit']
              updated_res_data["supplier_group"]  = responseData['supplierGroup']
              updated_res_data["supplier_credited_period"]  = responseData['supplierCreditedPeriod']
              setsupplierdetails((prevData) => {
                return prevData.map((item) => {
                  return item.id === updated_res_data.id ? updated_res_data : item;
                });
              })
              resetForm()
              RESETDATA()
              setSubmitting(false)
              toast.success('Updated Successfully', {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
              });
              // reset_Form()
              handleSupplierClose()
              // close()
              if (setLoad){
                setLoad(!isLoad)}
              }
              
            else{
              if(response.errors){
                try{
                  response.errors.forEach((errorsdata) => {
                    toast.error(errorsdata, {
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  });
                } catch{
                  toast.error(response.errors?.[0], {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
              
            }
            }
          }catch(errors){ 
            if(errors.response.data){
                const finderrors = Object.values(errors.response.data);
                finderrors.forEach((errorsdata) => {
                  console.log(errorsdata)
                  const showerror = String(errorsdata) 
                  toast.error(showerror, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                });
            } else{
                toast.error("Undefined", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });

            }
          }
        } else{
          let update_mutate_query = `mutation createContactData{
            supplierFormCreateMutation(`
            update_mutate_query += QueryFilterWithArray(updated_form_submit_data, 
              ['default', 'active', 'customer', 'transporter', 'supplier', "gstinType","isLead"], 
              ['id', 'supplierCreditedPeriod', 'creditedLimit', 'supplierGroup', 'creditedLimit', 'customerCreditedPeriod',
            'salesPerson', 'customerGroup'], 
              ['contact', 'address'])
            update_mutate_query +=`){
              success
              errors
              supplierFormItem{
                id
                supplierNo
                companyName
                legalName
                customer
                supplier
                transporter
                transporterId
                gstinType{
                  id
                  gstType
                }
                gstin
                tcs
                panNo
                supplierCreditedPeriod
                contact{
                  id
                  contactPersonName
                  default
                }
                address{
                  id
                  addressType
                  addressLine1
                  addressLine2
                  city
                  state
                  country
                  pincode
                }
                active
              }
            }
          }` 
          try{
            let response = await axiosInstance.post("/itemmaster/graphql", {query: update_mutate_query}) 
            response = response?.data?.data?.supplierFormCreateMutation
            if(response?.success){
              const responseData = response?.supplierFormItem
              console.log("responseData",responseData); 
              let updated_res_data = {...responseData}
              updated_res_data["supplierNo"] = responseData['supplierNo']
              updated_res_data["Company_Name"]  = responseData['companyName']
              updated_res_data["Legal_Name"]  = responseData['legalName']
              updated_res_data["Customer"]  = responseData['customer']
              updated_res_data["Supplier"]  = responseData['supplier']
              updated_res_data["Transporter"]  = responseData['transporter']
              updated_res_data["TransporterId"]  = responseData['transporterId']
              updated_res_data["GSTIN_Type"]  = responseData['gstinType']?.['gstType']
              updated_res_data["GSTIN"]  = responseData['gstin']
              updated_res_data["TCS"]  = responseData['tcs']
              updated_res_data["Pan_no"]  = responseData['panNo']
              updated_res_data["active"]  = responseData['active']
              updated_res_data["customer_group"]  = responseData['customerGroup']
              updated_res_data["sales_person"]  = responseData['salesPerson']
              updated_res_data["customer_credited_period"]  = responseData['customerCreditedPeriod']
              updated_res_data["credited_limit"]  = responseData['creditedLimit']
              updated_res_data["supplier_group"]  = responseData['supplierGroup']
              updated_res_data["supplier_credited_period"]  = responseData['supplierCreditedPeriod']
              updated_res_data['contact'] = responseData['contact']
              updated_res_data['address'] = responseData['address']
              // ?.[0]?.['id'] 
              setSupplierSelect({id:updated_res_data["id"],
              lable: updated_res_data["Company_Name"], supplier_no : updated_res_data['supplierNo'],
              gstin: updated_res_data?.['GSTIN'], gstinType: updated_res_data?.['GSTIN_Type'], 
              CreditedPeriod: updated_res_data?.['supplier_credited_period'], address: updated_res_data?.['address'],
              contact : updated_res_data['contact']}) 
              setsupplierdetails ( prevData => [ updated_res_data, ...prevData])
              resetForm()
              RESETDATA()
              setSubmitting(false)
              toast.success('Saved ', {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
              handleSupplierClose()
              
              // close()
                if (setLoad){
                  setLoad(!isLoad)} 
            }else{
              if(response.errors){
                  try{
                    response.errors.forEach((errorsdata) => {
                      toast.error(errorsdata, {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                    });
                  } catch{
                    toast.error(response.errors?.[0], {
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  }
                
              }
            }
            
                // reset_Form()
          }catch(errors){ 
            if(errors.response.data){
              //  console.log(typeof(errors.response.data));
              //  console.log(typeof(errors.response.data) === obj);
               if (typeof errors.response.data === 'object') {
                const finderrors = Object.values(errors.response.data);
                try{
                  finderrors.forEach((errorsdata) => {
                    console.log(errorsdata)
                    const showerror = String(errorsdata).split('with').pop().trim();
                    console.log(showerror);
                
                    toast.error(showerror, {
                      position: "bottom-right",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  });
                } catch{
                  toast.error(errors.response.data, {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                }
              
              }
             
            } else{
                toast.error("Undefined", {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });

            }
          }
        }
    };
    // Contants
    const [EditContact, setEditContact] = useState()
    const [EditAddress, setEditAddress] = useState()
    const [ContactFromAdd, setcontactFromAdd] = useState('')
    const handleContactFromAddClose = ()=> setcontactFromAdd(false)
    const handleContactFromAddShow = ()=> setcontactFromAdd(true)
    const [waringToRemove, setWaringToRemove] = useState(false)
    function RemoveContact(params) {
      if(params){
        const remove_delete_data = contanctPost.filter(data=> data.id !== deleteData.id) 
        setContanctPost(remove_delete_data) 
      }
     
    }

    const ContantsEdit = ({ value, data }) => { 
      const handleClickedit = () => {  
        handleContactFromAddShow()
        setEditContact(data);
        setDeleteData({
         Name: "",
         id: ""
        }); 
        
      };
      
      const handledeleteConfomation = () => {
        setWaringToRemove(true)
        setDeleteData({
          Name:data.Contact_Person_Name,
          id: data.id
         }); 
        // handltoastDeleteConfomationShow();
        // seturl_fix('/itemmaster/Contact') 
        // 
      };
      
     return ( 
       <>
      {/* <Can I={"Delete"} a={"Contact"}> */}
      <button type="button" className={`btn btn-outline-danger btn-sm px-3 ${isEditForm ? '' : ''}`} 
           onClick={handledeleteConfomation}  ><i className="fa-solid fa-trash" ></i> </button>
      {/* </Can>  */}
      {/* <Can I={"Edit"} a={"Contact"}> */}
      <button type="button"   className={`btn btn-outline-success btn-sm  px-3 ms-1 ${isEditForm ? '' : ''}`} 
          onClick={handleClickedit}> <i className="fa-solid fa-pen" ></i> </button> 
      {/* </Can>      */}
       
       </>
     );
    }

    // EditAddress, 
    const AddresEdit = ({ value, data }) => { 
      const handleClickAddressEdit = () => {  
         
        setEditAddress(data);
        handleAddressFormAddShow()
        // setDeleteData({
        //  Name: "",
        //  id: ""
        // }); 
        
      };
      const handleAddressdeleteConfomation = () => {
      
        handltoastDeleteConfomationShow();
        setDeleteData({
         Name:data.address_type,
         id: data.id
        });  
        seturl_fix( '/itemmaster/caddress')
        
    
      };
    return ( 
      
      <>
      {/* <Can I={"Delete"} a={"Address"}> */}
      <button type="button" className={`btn btn-outline-danger btn-sm  px-3 ${isEditForm ? '' : ''}`}
          onClick={()=>{handleAddressdeleteConfomation()}}   ><i className="fa-solid fa-trash " ></i> </button>
      {/* </Can> */}
      {/* <Can I={"Edit"} a={"Address"}> */}
      <button type="button"   className={`btn btn-outline-success btn-sm  px-3 ms-1 ${isEditForm ? '' : ''}`}
          onClick={()=>{ handleClickAddressEdit()}} > <i className="fa-solid fa-pen   "   ></i> </button>    
      {/* </Can> */}
        
      
      </>
    );
    } 


    const colums = [
        {
            headerName : "id",
            hide: true ,
            field:"id",
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName : "Person Name",
            flex:1,
            field:"Contact_Person_Name",
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName : "Salutation",
            flex:1,
            field:"Salutation",
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName : "Email",
            flex:1,
            field:"Email",
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName : "Phone_number",
            flex:1,
            field:"Phone_number",
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
          headerName : "Type",
          flex:1,
          field:"contactType?.name",
          onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName : "Whatsapp No",
            flex:1,
            field:"WhatsappNo",
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName : "Other No",
            flex:1,
            field:"OtherNo",
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName : "Default",
            flex:.5,
           
            field: 'Default', 
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            onCellDoubleClicked: onCellDoubleClicked,
            // editable: true,
        },
        {
            headerName : "Actions",
            flex:1,
            cellRenderer: "ContantsEdit", 
        },
    ]

    const components1 = {
        ContantsEdit: ContantsEdit,
        AddresEdit: AddresEdit
    };

    const defaultColDef ={
        sortable:true, editable:false
        , filter:false, floatingFilter:false,  
        resizable: true,
    }

    // address
    const components = {
        AddresEdit: AddresEdit
    };
 
    const [ addressadd, setaddressadd] = useState(false)
    const handleAddressFormAddClose = () => { setaddressadd(false)}
    const handleAddressFormAddShow = () => { setaddressadd(true);}

    const colums_2 = [
        {
            headerName : "id",
            hide: true ,
            field:"id", 
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName : "Address Type",
            flex:1,
            field:"address_type",
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName : "Address Line 1",
            flex:1,
            field:"Address_Line_1", 
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName : "Address Line 2",
            flex:1,
            field:"Address_Line_2",
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName : "City",
            flex:1,
            field:"City",
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName : "Pincode",
            flex:1,
            field:"pincode",
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName : "State",
            flex:1,
            field:"State",
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName : "Country",
            flex:1,
            field:"Country",
            onCellDoubleClicked: onCellDoubleClicked,
        },
        {
            headerName : "Default",
            flex:1,
           
            field: 'Default', 
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
            
            // editable: true,
           
        },
        {
            headerName : "Actions",
            flex:1,
            cellRenderer: "AddresEdit"
        },
    ]
   
    const onCellValueChangedaddress =  async (event) => {
        const data = event.data
        try{
            let row_data = gridApiRef2?.current?.props?.rowData?.map(item => parseInt(item.id))
            row_data = row_data.filter(id => id !== (parseInt(data['id'])))

            let address_bulk_update_query = `mutation AddressFormBulkUpdate{
              supplierSubFormDefaultUpdateMutation(name: "CompanyAddress", idList: [${row_data}]){
                success
                errors
              }
            }
            `
            await axiosInstance.post("/itemmaster/graphql", {query: address_bulk_update_query})
        }catch(e){
            console.log(e)
        }

        let saveValue_ = {
          "id": data["id"],
          "addressType": data['address_type'],
          "addressLine1":data["Address_Line_1"],
          "addressLine2": data["Address_Line_2"],
          "city": data['City'],
          "pincode": data['pincode'],
          "state": data['State'],
          "country":data['Country'],
          "default": data['Default']
        }

        try{

          let update_mutation_query = `mutation companyAddressCreateMutation {
            companyAddressCreateMutation(`
            update_mutation_query += QueryFilter(saveValue_, ['default'], ['id'])
            update_mutation_query+=`) {
              companyAddressItem {
                id
                addressType
                addressLine1
                addressLine2
                city
                country
                city
                state
                pincode
                default
              }
              errors
              success
            }
          }`

          const response = await axiosInstance.post("/itemmaster/graphql", {query: update_mutation_query})
          const responseData = response?.data?.data?.companyAddressCreateMutation?.companyAddressItem 
          let updated_res_data  = {...responseData}
          updated_res_data['Address_Line_1'] = updated_res_data['addressLine1']
          updated_res_data['Address_Line_2'] = updated_res_data['addressLine2']
          updated_res_data['address_type'] = updated_res_data['addressType']
          updated_res_data['City'] = updated_res_data['city']
          updated_res_data['State'] = updated_res_data['state']
          updated_res_data['Default'] = updated_res_data['default']
          updated_res_data['Country'] = updated_res_data['country']
          setAddressPost((prevData) => {
            return prevData.map((item) => {
              return item.id === updated_res_data.id ? updated_res_data : item;
            });
          })
        } catch(error){
          console.log(error);
        }

    }

    const onCellValueChangedContact =  async (event) => {
      const data = event.data
      // let rowData = gridApiRef?.current?.props?.rowData
      
      try{
        let row_data = gridApiRef?.current?.props?.rowData?.map(item => parseInt(item.id))
        row_data = row_data.filter(id => id !== (parseInt(data['id'])))

        let contact_bulk_update_query = `mutation contactFormBulkUpdate{
          supplierSubFormDefaultUpdateMutation(name: "ContactDetalis", idList: [${row_data}]){
            success
            errors
          }
        }
        `
        await axiosInstance.post("/itemmaster/graphql", {query: contact_bulk_update_query})
    }catch(e){
        console.log(e)
    }

      let saveValue_ = {
        "id": data['id'],
        "contactPersonName": data['Contact_Person_Name'],
        "salutation":  data['Salutation'],
        "email":  data['Email'],
        "phoneNumber":  data['Phone_number'],
        "default": data['Default']
      }

      try{
        let update_mutate_query = `mutation createContactData{
          contactDetailsCreateMutation(`
        update_mutate_query += QueryFilter(saveValue_, ['default'], ['id'])
        update_mutate_query +=`){
            success
            errors
            contactDetailsItem{
              id
              contactPersonName
              email
              default
              phoneNumber
              salutation
              otherNo
              whatsappNo
            }
          }
        }`
        const response = await axiosInstance.post("/itemmaster/graphql", {query: update_mutate_query})
        const responseData = response?.data?.data?.contactDetailsCreateMutation?.contactDetailsItem
        let updated_res_data = {...responseData}
        updated_res_data['Contact_Person_Name'] = updated_res_data['contactPersonName']
        updated_res_data['Email'] = updated_res_data['email']
        updated_res_data['Default'] = updated_res_data['default']
        updated_res_data['Phone_number'] = updated_res_data['phoneNumber']
        updated_res_data['Salutation'] = updated_res_data['salutation']
        updated_res_data['OtherNo'] = updated_res_data['otherNo']
        updated_res_data['WhatsappNo'] = updated_res_data['whatsappNo']
        setContanctPost((prevData) => {
            return prevData.map((item) => {
              return item.id === updated_res_data.id ? updated_res_data : {...item, 'default':  false, 'Default': false};
            });
        })
      } catch(error){
        console.log(error);
      }  
    }
      
    const [gstType, setgsttype] = useState([])
    const handlechnagegstType = (option)=>{
      if(option){
        setgsttype( { value: option.value , label: option.label })
      } else{
        setgsttype('')
      }
    
    }

    const [selectTcs , setSelectTcs] = useState({ value: '', label: '' })
    const handleChangeTcs = (option)=>{
      if (option){
        setSelectTcs( { value: option.value, label: option.label })
      } else{
        setSelectTcs( { value: '', label: '' })
      }
    
    }

    const handleChangeCustomerGroup = (option)=>{
      if(option){
        setCustomerGroupSelec( { value: option.value, label: option.label })
      } else{
        setCustomerGroupSelec({ value: '', label: '' })
      }
      
    }
   
    const [selectSalesperson , setSelectSalesperson] = useState({ value: '', label: '' })
    const handleChangeSalesPerson = (option)=>{
      if(option){
        setSelectSalesperson( { value: option.value, label: option.label })
      } else{
        setSelectSalesperson({ value: '', label: '' })
      }
    
    }

    const handleChangeSupplierGroup = (option)=>{
      if(option){
        setSupplierGroupSelect({ value: option.value, label: option.label })
      } else{
        setSupplierGroupSelect({ value: '', label: '' })
      }
  
    }
    
    const [waringModal, setWaringModal] = useState(false);
    const handleShowDeleteWarningModal = ()=>{
      setWaringModal(true)
    }

    const handleCloseDeleteWarningModal = ()=>{
      setWaringModal(false)
    }

    async function DeletaTheData(supplier_id, contact_list, address_list) {
      contact_list = contact_list.map(item => item.id)
      address_list = address_list.map(item => item.id)
      let contact_delete_query = `mutation deleteContactData{
        contactDetailsDeleteMutation(idList: [${contact_list}]){
          success
          errors
        }
      }`
      let address_delete_query = `mutation deleteAddressData{
        companyAddressDeleteMutation(idList: [${address_list}]){
          success
          errors
        }
      }`
      let supplier_delete_query = `mutation deleteSupplierData{
        supplierFormDeleteMutation(id: ${supplier_id}){
          success
          errors
        }
      }`
      try{
          await axiosInstance.post("/itemmaster/graphql", {query: contact_delete_query})
          // contact_response = contact_response?.data?.data?.contactDetailsDeleteMutation
          await axiosInstance.post("/itemmaster/graphql", {query: address_delete_query})
          // address_response = address_response?.data?.data?.CompanyAddressDeleteMutation
          await axiosInstance.post("/itemmaster/graphql", {query: supplier_delete_query})
          // response = response?.data?.data?.SupplierFormDeleteMutation 
          handleSupplierClose()
          if (setLoad){
            setLoad(!isLoad)
          }
          
      }catch(e) {
        console.log(e)
        toast.error('Failed to delete!', {
          position: "bottom-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        })
      }
    }

    const DeleteConfirmation = ()=>{
        const handleDeleteData = async ()=>{
          let supplierid = supplierEdit?.id
          let supplierContectId = supplierEdit?.contact
          let SupplierAddressId = supplierEdit?.address
          DeletaTheData(supplierid, supplierContectId, SupplierAddressId)
        }
        const canceltheDeleteOptions =()=>{
          setDeleteData()
          handleCloseDeleteWarningModal()
        }
        return(
              <Modal
                  show={waringModal}
                  onHide={handleCloseDeleteWarningModal}
                  backdrop="static"
                  keyboard={false}
              >
              <Modal.Header closeButton>
                <Modal.Title>Confirm</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure you want to delete "{supplierEdit?.['companyName']}" ?</Modal.Body>
              <Modal.Footer>
                  <Button size='sm' variant="danger px-4 " onClick={()=>{canceltheDeleteOptions()}}>No</Button>
                  <Button  size='sm'  variant="success px-4 " onClick={()=>{handleDeleteData()}}>Yes</Button>
              </Modal.Footer>
            </Modal>
        )
    }
    
    const handleDeleteSupplierData = () =>{
      handleShowDeleteWarningModal()
    }

    const gridOptions = {
      // other grid options...
      overlayNoRowsTemplate: "<span>No rows to show</span>"
    };

    
  return (
    <>
    <Waringmodel />
    <DeleteConfirmation />
     <Modal show={SupplierAdd} 
      fullscreen={true}
       onHide={()=>{handleDisableEditView(); RESETDATA(); handleSupplierClose()}} 
       size='xl'
     >
        <ToastContainer/>
        <Modal.Header  >
          <Modal.Title style={{width: '98%'}}>
            <div className='d-flex justify-content-between' >
              <div>
                  <h4>  <i className='fa-solid fa-arrow-left fa-sm me-2' 
                onClick={() => {handleDisableEditView(); RESETDATA(); handleSupplierClose()}}
            ></i>Supplier / Customer</h4>
              </div>
              {supplierEdit && <div style={{fontSize: '1rem'}} className='d-flex align-items-center'>
                <span>
                  {/* <Can  allow_list={["Edit_Supplier","Edit_Customer"]}> */}
                  <i role='button' className={`fa-solid fa-pen me-4`} 
                    onClick={()=>{handleEnableEditView()}}
                   ></i>
                  {/* </Can> */}
                   
                </span>
                <span>
                  {/* <Can allow_list={["Delete_Supplier","Delete_Customer"]}> */}
                  <i role='button' className="fa-solid fa-trash text-danger"
                      onClick={()=>{handleDeleteSupplierData()}}
                    ></i>
                  {/* </Can> */}
                  
                </span>
              </div> }
            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Formik initialValues={initialValue} validationSchema={SupplierFromSchema}
            onSubmit={handleSubmit} key={formKey} enableReinitialize>
                {({errors, touched , isSubmitting, values,setFieldValue,   })=>(
                    <>
                    <Form>
                      {/* UI Change starts */}
                        <div>
                          <div className='top-div d-flex justify-content-between my-3' style={{width: '100%'}}>
                              <div className='left-pane card' style={{width: '30%'}}>
                                  <div className='my-2 mx-2 pt-1 border-bottom' onClick={()=>{console.log(formKey);
                                  }}>
                                      <h6  >Company Details- {initialValue?.supplierNo}</h6>
                                  </div>
                                  <div>
                                    <div className='row mx-2'>
                                      <div className='col-6 staan-input-group'>
                                          <Field type='text'  name="Company_Name" 
                                          disabled = {isEditForm ? false : true}
                                          value={values.Company_Name} onChange={(e)=>{setFieldValue("Company_Name",e.target.value);}}  className='w-100  staan-form-input'/>
                                          <label htmlFor="Company_Name" className='staan-form-input-label pt-2 px-1 mx-2' onClick={()=>{console.log(initialValue);
                                          }}>Company Name  </label>
                                          {  touched.Company_Name &&  errors.Company_Name && <small>{errors.Company_Name}</small>}
                                      </div>
                                      <div className='col-6 staan-input-group'>
                                          <Field type='text' id='Legal_Name' name='Legal_Name' 
                                          disabled = {isEditForm ? false : true}
                                          value={values.Legal_Name} onChange={(e)=>{setFieldValue("Legal_Name",e.target.value)}}  placeholder='' className='w-100   staan-form-input'/>
                                          <label htmlFor="Legal_Name" className='staan-form-input-label pt-2 px-1 mx-2'>Legal Name</label>
                                          {  touched.Legal_Name &&  errors.Legal_Name && <small>{errors.Legal_Name}</small>}
                                      </div>
                                    </div>
                                    <div className='row mx-2 my-4'>
                                        <div className='col-6 staan-input-group'>
                                            <Select
                                              name="GSTIN_Type"
                                              options={gstTypeList?.items?.map(item => ({label: item.gstType, value: item.id  }))}
                                              isSearchable={true}
                                              isDisabled = {isEditForm ? false : true}
                                              value={gstType}  
                                              onChange={(selectedOption) =>{
                                                if(selectedOption){
                                                  setIsChangeGst(true)
                                                  if(selectedOption?.label?.includes('UNREGISTERED')){
                                                    setFieldValue("GSTIN", 'URP')
                                                  }else{
                                                    setFieldValue('GSTIN', "")
                                                  }
                                                }
                                                  handlechnagegstType(selectedOption)
                                                  setFieldValue("GSTIN_Type", selectedOption?.value ?? null)}}
                                              isClearable
                                              className='mt-3'
                                              styles={customSelectStyle}
                                              />
                                              <label htmlFor="GSTIN_Type" className='staan-form-input-label pt-1 px-1 mx-2'>GSTIN Type</label>
                                            {  touched.GSTIN_Type &&  errors.GSTIN_Type && <small>{errors.GSTIN_Type}</small>}
                                        </div>
                                        <div className='col-6 staan-input-group'>
                                          <Field type='text' id='GSTIN' name='GSTIN'  
                                          disabled = {isEditForm ? false : true}
                                          placeholder='' 
                                          onChange={e => {
                                            if (e.target.value){
                                                setIsChangeGst(true)
                                                setFieldValue('GSTIN', e.target.value); 
                                            }}}
                                          className='w-100 staan-form-input'/>
                                          <label htmlFor="GSTIN" className='staan-form-input-label pt-2 px-1 mx-2'>GSTIN</label>
                                          {  touched.GSTIN &&  errors.GSTIN && <small>{errors.GSTIN}</small>}
                                        </div>
                                    </div>
                                    <div className='row mx-2 my-4'>
                                      {isChangeGst &&
                                       <div className="col-4 staan-input-group" > 
                                       <input 
                                           type='date' 
                                           className=' staan-form-input form-control w-100 fs-6' 
                                           style={{ border:"1px solid rgb(206, 201, 201)", fontSize: '.8rem' }}
                                           value={effectiveDate}
                                           min={getCurrentDate()}
                                           onChange={e => {
                                               if (e.target.value){
                                                   
                                                   setEffectiveDate(e.target.value)
                                               }
                                           }}
                                           />
                                       <label className='staan-form-input-label pt-1 px-1 mx-2'  >Effective Date</label>      
                                   </div>
                                      }
                                     
                                      <div className={`${isChangeGst? "col-4" : "col-6" } staan-input-group`}>
                                        <Select
                                          name="tcs"
                                          options={tcs_options}
                                          isSearchable={true} 
                                          isDisabled = {isEditForm ? false : true}
                                          value={selectTcs}  // Use value prop to bind the value
                                          onChange={(selectedOption) => {
                                              handleChangeTcs(selectedOption) 
                                              setFieldValue("TCS", selectedOption?.value ?? null)
                                          }}
                                          isClearable
                                          className='mt-3'
                                          styles={customSelectStyle}
                                        />
                                        <label htmlFor="TCS" className='staan-form-input-label pt-1 px-1 mx-2'>TCS</label>
                                        {  touched.TCS &&  errors.TCS && <small>{errors.TCS}</small>} 
                                      </div>
                                      <div className={`${isChangeGst? "col-4" : "col-6" } staan-input-group`}>
                                          <Field type='text' id='Pan_no' name='Pan_no' 
                                          disabled = {isEditForm ? false : true}
                                           placeholder='' className='w-100 staan-form-input'/>
                                          <label htmlFor="Pan_no" className='staan-form-input-label pt-2 px-1 mx-2'>PAN No</label>
                                          {  touched.Pan_no &&  errors.Pan_no && <small>{errors.Pan_no}</small>}
                                      </div>
                                    </div>
                                    <div className='row mx-2 my-4'>
                                        <div className='col-6 staan-input-group d-flex align-items-center'>
                                          <Field type="checkbox" name="Transporter"  disabled ={!values.Supplier ? true : isEditForm ? false : true}  className='me-2'   />
                                          <label style={{fontSize: '.8rem', top: '5px'}} className='pb-1'>Transporter</label>
                                          {  touched.Transporter &&  errors.Transporter && <small>{errors.Transporter}</small>}
                                        </div>
                                        <div className="col-6 staan-input-group">
                                            <Field type='text' id='TransporterId' name='TransporterId'  disabled ={!values.Transporter} className='w-100 staan-form-input'/>
                                            <label htmlFor="TransporterId" className='staan-form-input-label pt-2 px-1 mx-2'>Transporter Id</label>
                                            {  touched.TransporterId &&  errors.TransporterId && <small>{errors.TransporterId}</small>}
                                        </div>
                                    </div>
                                    <div className='row mx-2 my-4'>
                                      <div className="col-4">
                                          <Field type="checkbox" name="Customer"   className='me-2' disabled = {isEditForm ? values?.Supplier? true : false :  true}  />
                                          <label style={{fontSize: '.8rem'}} className='pb-1' onClick={()=>{console.log(isEditForm);
                                          }}>Customer</label>
                                      </div>
                                      <div className="col-4">
                                          <Field type="checkbox" name="Supplier"   className='me-2'  disabled = {isEditForm ? values?.Customer? true : false : true} />
                                          <label style={{fontSize: '.8rem'}} className='pb-1'>Supplier</label>
                                      </div>
                                      <div className="col-4">
                                          <Field type="checkbox" name="active"   className='me-2' disabled = {isEditForm ? false : true}  />
                                          <label style={{fontSize: '.8rem'}} className='pb-1'>Active</label>
                                      </div>
                                    </div>
                                  </div>
                              </div>
                              <div className='right-pane card'  style={{width: '68%'}}>
                                  <div className='my-2 mx-2 py-1 border-bottom d-flex justify-content-between align-items-center'>
                                      <h6>Contact Details</h6>
                                      {/* <Can allow_list={["Create_Contact","Edit_Contact"]}> */}
                                      
                                      <div className="col-4 staan-input-group">
                                        {console.log(contactList)
                                        }
                                      <CreatableSelect
                                        onInputChange={(e)=>{dispatch(fetchOptionsData(ContactFetch(`phoneNumber:"${e}"`) , "SupplierFrom_Conatct_Data"))  
                                        }}
                                        name="Mobile"
                                        isClearable
                                        options={contactList} 
                                        isSearchable={true} 
                                        onCreateOption={(e)=>{setNewContactNumber(e); handleContactFromAddShow() }}
                                        onChange={(option)=>{
                                          if(option){
                                            let updated_res_data = {...option}
                                            
                                            console.log(updated_res_data);
                                            
                                            setContanctPost(prevData => [ updated_res_data, ...prevData]);
                                          }
                                        }}
                                       
                                        placeholder="Search Mobile Number. . ."
                                        className='my-3  '   
                                        />
                                      </div>
                                      {/* </Can> */}
                                  </div>
                                  <div className='my-2'>
                                    <div className='ag-theme-alpine mx-auto' style={{ height: '400px', width: '98%' , overflow:"auto"}} > 
                                      <AgGridReact
                                        gridOptions={gridOptions}
                                        ref={gridApiRef}
                                        rowData={contanctPost}
                                        columnDefs={colums}
                                        components={components1}
                                        defaultColDef={defaultColDef} 
                                        onCellValueChanged ={onCellValueChangedContact}
                                      />
                                    </div>
                                  </div>
                              </div>
                          </div>
                          <div className='my-3 card' hidden={values.Customer? false : true }>
                              <div className='my-2 mx-2 pt-1 border-bottom'>
                                <h6>Customer Details</h6>
                              </div>
                              <div className='row mb-3 mx-2'>
                                <div className="col-3 staan-input-group">
                                    <Select
                                      name="customer_group"
                                      options={costomerGroupdata.map((item)=>({ value: item.id , label: item.name }))}
                                      isSearchable={true} 
                                      isDisabled = {isEditForm ? false : true} 
                                      value = {customerGroupSelect}
                                      onChange={(selectedOption) => {
                                          handleChangeCustomerGroup(selectedOption)
                                          setFieldValue("customer_group", selectedOption?.value ?? null)
                                      }}
                                      isClearable
                                      className='mt-3'
                                      styles={customSelectStyle}
                                    />
                                    <label htmlFor="customer_group" className='staan-form-input-label pt-1 px-1 mx-2'>Customer Group 
                                    <i className='bx bx-plus-circle' onClick={handlecustomerGroupShow} ></i></label>
                                    {  touched.customer_group &&  errors.customer_group  && <small>{errors.customer_group}</small>}
                                </div>
                                <div className="col-3 staan-input-group">
                                  <Select
                                    name="sales_person"
                                    options={userOption}
                                    isSearchable={true} 
                                    isDisabled = {isEditForm ? false : true}
                                    value={selectSalesperson} 
                                    onChange={(selectedOption) =>{
                                        handleChangeSalesPerson(selectedOption)
                                        setFieldValue("sales_person", selectedOption?.value ?? null)}}
                                    isClearable
                                    className='mt-3'
                                    styles={customSelectStyle}
                                  />
                                  <label htmlFor="sales_person" className='staan-form-input-label pt-1 px-1 mx-2'>Sales Person</label>
                                  {  touched.sales_person &&  errors.sales_person && <small>{errors.sales_person}</small>}
                                </div>
                                <div className="col-3  staan-input-group">
                                    <Field type='text'  name="customer_credited_period" value={values.customer_credited_period} onchange={(e)=>{setFieldValue("customer_credited_period",e.target.value)}}    placeholder='' className='w-100  staan-form-input'/>
                                    <label htmlFor="customer_credited_period" className='staan-form-input-label pt-2 px-1 mx-2'>Credit period</label>
                                    {  touched.customer_credited_period &&  errors.customer_credited_period && <small>{errors.customer_credited_period}</small>}
                                </div>
                                <div className="col-3  staan-input-group">
                                  <Field type='text'  name="credited_limit" value={values.credited_limit} onchange={(e)=>{setFieldValue("credited_limit",e.target.value)}}    placeholder='' className='w-100  staan-form-input'/>
                                  <label htmlFor="credited_limit" className='staan-form-input-label pt-2 px-1 mx-2'>Credit Limit</label>
                                  {  touched.credited_limit &&  errors.credited_limit && <small>{errors.credited_limit}</small>}
                                </div>
                              </div>
                          </div>
                          <div className='my-3 card'  hidden={values.Supplier ? false : true }>
                            <div className='my-2 mx-2 pt-1 border-bottom'>
                              <h6>Supplier Details</h6>
                            </div>
                            <div className='row mb-3 mx-2'>
                              <div className="col-3 staan-input-group"> 
                                    <Select
                                        name="supplier_group"
                                        options={supplierGroupdata.map((item)=>({ value: item.id , label: item.name }))}
                                        isSearchable={true}
                                        isDisabled = {isEditForm ? false : true}
                                        value={supplierGroupSelect}
                                        onChange={(selectedOption) =>{
                                          handleChangeSupplierGroup(selectedOption)
                                            setFieldValue("supplier_group", selectedOption?.value ?? null)}}
                                        isClearable
                                        className='mt-3'
                                        styles={customSelectStyle}
                                    />
                                    <label htmlFor="supplier_group" className='staan-form-input-label pt-1 px-1 mx-2'>Supplier Group <i className='bx bx-plus-circle' onClick={handlesupplierGroupShow} ></i></label>
                                    {  touched.supplier_group &&  errors.supplier_group  && <small>{errors.supplier_group}</small>}
                                </div>
                                <div className="col-3  staan-input-group">
                                    <Field type='text'  name="supplier_credited_period" 
                                    disabled = {isEditForm ? false : true}
                                     value={values.supplier_credited_period} onchange={(e)=>{setFieldValue("supplier_credited_period",e.target.value)}}   placeholder='' className='w-100 staan-form-input'/>
                                    <label htmlFor="supplier_credited_period" className='staan-form-input-label pt-2 px-1 mx-2'>Credit Period</label>
                                    {  touched.supplier_credited_period &&  errors.supplier_credited_period && <small>{errors.supplier_credited_period}</small>}
                                </div>
                            </div>
                          </div>
                          <div className='my-3 card'>
                              <div className=' my-2 mx-2 py-1 pb-2 border-bottom d-flex justify-content-between align-items-center'>
                                  <h6>Address Details</h6>
                                  {/* <Can I={"Save"} a={"Address"}> */}
                                  <button type="button" 
                                  className={`btn btn-sm btn-outline-success ${isEditForm ? '' : 'disabled'}`} 
                                  onClick={handleAddressFormAddShow}><i className='bx bxs-plus-circle pt-1 me-2' ></i>New</button>
                                  {/* </Can> */}
                              </div>
                              <div className="row mx-auto py-3" style={{width: '99%'}}>
                                <div className='ag-theme-alpine' style={{ height: '250px', width: '100%' }} > 
                                    <AgGridReact 
                                      ref ={gridApiRef2}
                                      rowData ={AddressPost}
                                      columnDefs={colums_2}
                                      defaultColDef={defaultColDef} 
                                      components = {components}
                                      onCellValueChanged = {onCellValueChangedaddress}
                                    />
                                </div> 
                              </div>
                          </div>
                          <div className="row">
                            <div className="text-end me-5 mt-2">
                              <button type="submit" disabled={isSubmitting} className="fw-bold px-3 btn btn-sm shadow-sm btn-success">Save</button>
                            </div>
                          </div>
                        </div>
                      {/* UI Change ends */}
                      <CustomerGroup 
                      setFieldValue = {setFieldValue}/>
                     <SupplierGroup 
                     setFieldValue = {setFieldValue}/>
                     </Form>
                  </>
                )} 
            </Formik> 
        </Modal.Body>
      </Modal>
      <>
        {addressadd && <AddressFrom
          addressadd = {addressadd}
          handleaddressFromAddClose = {handleAddressFormAddClose}
          EditAddress = {EditAddress}
          setEditAddress = {setEditAddress}
        />}
     
        {ContactFromAdd && <ContactFrom 
            newContactNumber= {newContactNumber}
            setNewContactNumber = {setNewContactNumber}
            ContactFromAdd = {ContactFromAdd}
            setcontactFromAdd = {setcontactFromAdd}
            setContanctPost = {setContanctPost}
            EditContact = {EditContact}
            setEditContact ={setEditContact}
            contanctPost = {contanctPost}
           
        />}
        <DeleteConformation 
            deleteData={deleteData}
            url = { url_fix}
        />
        {waringToRemove && (<WaringModel
        state={waringToRemove}
        setstate={setWaringToRemove}
        title={'Confirm'}
        text={'Remove The Contact This Form '}
        Confirmtion={value => {
          RemoveContact(value)
        }}
        />)}
      </>
    </>
  )
}

export default SupplierFrom
