export const expenseRequestCreateMutation = (data) => {
    // createdBy: 10 employeeName: 10 expenseFor: "" expenseRequestDate: "" id: ""
    // modifiedBy: 10 requestAmount: ""
    return `
        mutation MyMutation {
            expenseRequestCreateMutation(${data}) {
                errors
                success
                	ExpenseRequestInstance{
                id
                expenseRequestNo
                employeeName{
                  id
                  employeeId
                  employeeName
                }
                requestAmount
                isCancel
                approvedBy{
                  id
                username
                }
                payBy{
                  id
                username
                }
              }
            }
            }`
}

export const allExpenseRequestDatas = (data)=>{
 return `
    query MyQuery {
    allExpenseRequest(${data}) {
        items {
        id
        expenseRequestDate
        employeeName{
            id
            employeeName
            employeeId
        }
        isCancel
        expenseRequestNo
        expenseFor
        requestAmount
        createdBy{
            id
            username
        }
        approvedBy{
            id
            username
        }
            payBy{
          id
        }
      }
    }
  }`
}


export const expenseRequestDeleteMutation = (data)=>{
    return `
    mutation MyMutation {
    expenseRequestDeleteMutation(id:${data}) {
      errors
      success
    }
  }`
   }

export const expenseRequestCancleMutation =(data)=>{
  return `
    mutation MyMutation {
    expenseRequestCancleMutation(id:${data}) {
      errors
      success
    }
  }
  `
}


