
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import Select from 'react-select';
import { SideNavbar } from '../../../components/sideNavbar/SideNavbar';
import DataContext from '../../../context/ItemMasterContext';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery";
import { CurrentConferenct } from '../../../GraphQLQueries/ItemmasterQuery';
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import { customSelectStyle, DateFormate } from '../../../utils/Utils';
import "../PosStyles.css";
import CustomDatePicker from './CustomDatePicker';
import { handleExcelReportDownload } from './ExcelReportDownload';
import { handlePdfDetailedReportDownload } from './PdfReportDownload';

const PosDetailedReport = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    const { userId, currentConference, setPosEdit, Navigate, currentUserEvent,
        setCurrentUserEvent, selectedReportDates, setSelectedReportDates, screenSize } = useContext(DataContext);
    const [account, setAccount] = useState([])
    const [isShowDatePicker, setShowDatePicker] = useState(false)
    const [marketingEventOptions, setMarketingEventOptions] = useState([])
    const [isShowExportOptionModal, setShowExportOptionModal] = useState(false)
    const handleShowExportOptionModal = () => { setShowExportOptionModal(true) }
    const handleCloseExportOptionModal = () => {
        setShowExportOptionModal(false);
        let parentElement = document.getElementById('pos-table-detailed-report-data');
        while (parentElement?.firstChild) {
            parentElement.removeChild(parentElement.firstChild);
        }
    }
    const [posReportDataist, setPosReportDataList] = useState([])
    const dispatch = useDispatch();
    const conferenctData = useSelector(state => state.itemGroup.data);
    const [accountList, setAccountList] = useState([])
    const [columnFilters, setColumnFilters] = useState({});

    // Function to handle changes in filter input for each column
    const handleFilterChange = (field, e) => {
        setColumnFilters({
            ...columnFilters,
            [field]: e.target.value,
        });
    };
    const detailed_report_headers = [
        { header: 'POS Type', field: 'IsPOS' },
        { header: 'Order Date', field: 'OrderDate' },
        { header: 'Order No', field: 'POSId' },
        { header: 'Customer', field: 'CosName' },
        { header: 'POS Mobile', field: 'Mobile' },
        { header: 'Final Total', field: 'FinalTotalValue' },
        { header: 'Balance', field: 'balanceAmount' },
        { header: 'Remarks', field: 'Remarks' },
    ]
    const dynamicStyle = screenSize?.height < 1000 ? {
        width: `${screenSize.width / 15}rem`,   // Dynamically calculate width
        height: `${screenSize.height / 21}rem`,    // Dynamically calculate height
        overflow: 'auto'                           // Enable scrolling when content overflows
    } : {
        width: `${screenSize.width / 15}rem`,
        height: '40rem',                           // Default height
        overflow: 'scroll'                         // Default scrolling behavior
    };


    useEffect(() => {
        if (currentConference) {
            if (currentUserEvent?.value) {
                handluserEvent({ value: currentUserEvent?.value, label: currentUserEvent?.label })
            } else {
                handluserEvent({ value: currentConference?.value, label: currentConference?.lable })
            }
        }
    }, [currentConference])
    const handluserEvent = (option) => {
        if (option) {
            let iscollectionswise = currentPath === "/pos-collection-wise" ? true : false
            setCurrentUserEvent({ value: option?.value, label: option?.label })
            dispatch(fetchOptionsData(QueryData.PosDetailedReport(option?.value,
                selectedReportDates.start_date, selectedReportDates.end_date, iscollectionswise, userId), "PosDetailsReport"))
        }
        else {
            setCurrentUserEvent({ value: "", label: "" })
            setPosReportDataList([])
        }
    }

    const getData = () => {
        if (userId) {
            dispatch(fetchOptionsData(CurrentConferenct(userId, false), "CurrentConferenct"))
            dispatch(fetchOptionsData(QueryData.accountsMasterTypeWithIndex(), "accountsMasterTypeWithIndex"))
        }

    }

    useEffect(() => {
        getData()
    }, [userId])

    let accoutnydata = []
    useEffect(() => {
        setAccountList([])

        account.map((item, index) => {
            if (currentPath === "/pos-collection-wise") {
                detailed_report_headers.splice(index + 3, 0, { header: item.accountsName, field: item.accountsName });
            } else {
                detailed_report_headers.push({ header: item.accountsName, field: item.accountsName })
            }
            accoutnydata.push(item.accountsName)
            setTableHeaders(detailed_report_headers)
            // console.log(accoutnydata);

            setAccountList(accoutnydata)
        })
    }, [posReportDataist])
    useEffect(() => {
        if (conferenctData.name === "CurrentConferenct") {
            let event = conferenctData?.value?.items?.map(item => ({ value: item.id, label: item.name }))
            event.push({ value: 0, label: "All" })
            setMarketingEventOptions(event)
        } else if (conferenctData.name === "PosDetailsReport") {
            setPosReportDataList(conferenctData.value)
        } else if (conferenctData.name === "accountsMasterTypeWithIndex") {

            setAccount(conferenctData?.value)

        }

    }, [conferenctData])



    const [tableHeaders, setTableHeaders] = useState(detailed_report_headers)

    let export_options = [
        { value: 1, label: 'Excel Export' },
        { value: 2, label: 'PDF Export' }
    ]


    const formateGivenStringdate = (date_str) => {
        const date_obj = new Date(date_str);
        const formattedDate = `${date_obj.getDate().toString().padStart(2, '0')}-${(date_obj.getMonth() + 1).toString().padStart(2, '0')}-${date_obj.getFullYear()}`;
        return formattedDate
    }

    const handleDatePickerShow = () => {
        setShowDatePicker(true)
    }

    const handleDatePickerClose = () => {
        setShowDatePicker(false)
    }

    const handleGetSelectedDate = (data) => {
        let selected_start_date = formateGivenStringdate(data.startDate)
        let selected_end_date = formateGivenStringdate(data.endDate)
        let iscollectionswise = currentPath === "/pos-collection-wise" ? true : false
        // console.log(currentUserEvent.value);
        if (currentUserEvent?.value || currentUserEvent?.value === 0) {
            console.log(currentUserEvent?.value, "-->>>");
            dispatch(fetchOptionsData(QueryData.PosDetailedReport(currentUserEvent.value,
                selected_start_date, selected_end_date, iscollectionswise, userId), "PosDetailsReport"))
        }

        setSelectedReportDates({
            start_date: selected_start_date,
            end_date: selected_end_date
        })
    }

    const DayReportExport = ({ show, close }) => {
        return (
            <Modal
                show={show}
                onHide={close}
                size='sm'
            >
                <Modal.Header closeButton>Export Options</Modal.Header>
                <Modal.Body>
                    <ul style={{ listStyleType: 'none' }}>
                        <li className='my-2' role='button' onClick={() => { handleClickedExportOption(export_options[0].value) }}>
                            <i className="fa-regular fa-file-excel fs-5 me-3"></i> {export_options[0].label}
                        </li>
                        <li className='my-2' role='button' onClick={() => { handleClickedExportOption(export_options[1].value) }}>
                            <i className="fa-regular fa-file-lines fs-5 me-3"></i> {export_options[1].label}
                        </li>
                    </ul>
                </Modal.Body>
            </Modal>
        )
    }

    const createDayReportPdf = (data_list) => {

        let table_container = document.getElementById('pos-table-detailed-report-data')
        while (table_container?.firstChild) {
            table_container.removeChild(table_container.firstChild);
        }
        table_container.setAttribute('style', 'position: absolute; left: -999px')
        let table_headers = document.getElementById('table-to-export').firstChild
        let copiedHeaders = table_headers.cloneNode(true);
        table_container.appendChild(copiedHeaders)
        data_list.map(item => {
            let table_row = document.createElement('tr')
            for (const [key, value] of Object.entries(item)) {
                let td_item_key = document.createElement('td')
                td_item_key.innerText = value ? value : ''
                if (accountList.includes(item.key)) {
                    td_item_key.setAttribute('style', 'text-align: end; padding: 10px 5px; border: 1px solid gray')
                } else {
                    td_item_key.setAttribute('style', 'border: 1px solid gray')
                }
                table_row.appendChild(td_item_key)
            }
            table_container.appendChild(table_row)
        })

    }

    // retun obj ; filter conditions
    const matchesFilters = (item) => {
        return Object.entries(columnFilters).every(([field, filterValue]) => {
            if (!filterValue) return true; // Skip filtering if filter value is empty 
            if (accountList.includes(field)) {
                // Number filter for money-related fields
                const paymentValue = item["Payments"][field];
                return paymentValue === parseFloat(filterValue); // Use parseFloat to convert filter value to number
            } else {
                // String filter for other fields
                return item[field]?.toString()?.toLowerCase()?.includes(filterValue.toLowerCase());
            }
        });
    };

    // Filtered items based on column filters

    const filteredItems = posReportDataist?.items?.filter(matchesFilters);


    // Initialize totals object
    let paymentTotals = accountList.reduce((acc, field) => {
        acc[field] = 0;
        return acc;
    }, { FinalTotalValue: 0, balanceAmount: 0 });

    // Sum up the values for each payment field
    filteredItems?.forEach(item => {
        accountList?.forEach(field => {
            paymentTotals[field] += parseFloat(item["Payments"][field] || 0);
        });

        if ('FinalTotalValue' in item) {
            paymentTotals['FinalTotalValue'] += Number(item['FinalTotalValue']);
        }
        if ('balanceAmount' in item) {
            paymentTotals['balanceAmount'] += Number(item['balanceAmount']);
        }
    });

    const handleClickedExportOption = (option) => {
        let excel_input_data = filteredItems?.map(report_row_item => {

            let inner_object = {}
            tableHeaders.map(header => {
                if (accountList.includes(header.header)) {
                    inner_object[header.header] = report_row_item['Payments'][header.field]
                } else {
                    inner_object[header.header] = report_row_item[header.field]
                }

            })
            return inner_object
        })
        if (option === 1) {
            handleExcelReportDownload(excel_input_data, 'pos_detailed_report')
        } else if (option === 2) {
            createDayReportPdf(excel_input_data)
            handlePdfDetailedReportDownload('pos_detailed_report')
        }
    }

    function MoveToPos(id) {
        setPosEdit(Number(id))
        Navigate("/pos")

    }

    return (
        <>
            <SideNavbar />
            <div className=' ' style={{ position: "fixed", top: "0", left: "1rem", width: "100%" }}>
                <div className=" my-4 " id="pos-report-data" >
                    <div className='bg-white' style={{ height: '100%' }}>
                        <div className='pos-report-header border-bottom border-solid shadow'>
                            
                        <div className='  row'>
                            <div className="col-3 " style={{alignItems:"center"}}>
                                <h3 className='py-3 px-4'>{currentPath === "/pos-collection-wise" ? "POS Collection-wise Report" : "POS Detailed Report"}</h3>
                            </div>
                            <div className="col-3 staan-input-group" style={{zIndex:100000}}>
                                    <Select
                                        name="marketingEvent"
                                        isClearable
                                        options={marketingEventOptions}
                                        className='mt-3'
                                        styles={customSelectStyle}
                                        value={currentUserEvent}
                                        onChange={(option) => { handluserEvent(option) }}
                                    />
                                    <label htmlFor="marketingEvent" className="staan-form-input-label  px-1 mx-2"  >Conference Name</label>
                                </div>
                            <div className='col-4 row align-self-center '  >
                                <div className='col-9 text-end'>
                                    <p className='m-0'>
                                        <span onClick={handleDatePickerShow} role="button" className='text-primary'>Date</span>:
                                        <b> {selectedReportDates.start_date}</b> to <b>{selectedReportDates.end_date}</b>
                                    </p>
                                </div>
                                <div className='col-3   m-0'>
                                    <span>
                                        <i
                                            className="fa-solid fa-download fs-4 text-primary"
                                            role='button'
                                            onClick={() => { handleShowExportOptionModal() }}
                                        ></i>
                                    </span>
                                </div>
                            </div>
                                </div>
                        </div>
                       
                        <div className='detailed-report-container mx-5 mt-5' style={dynamicStyle}>

                            <table style={{ overflow: 'scroll-x' }} className='mx-auto table-bordered' id='table-to-export'>
                                <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1, borderBottom: '2px solid #ccc' }}>
                                    {tableHeaders && tableHeaders.map(header_name => {
                                        return <th className='text-center px-3' style={{ border: '1px solid gray', whiteSpace: 'nowrap', }}>{header_name.header}</th>
                                    })}
                                </thead>
                                <tbody className='mt-1'>
                                    <tr style={{ position: 'sticky', top: 20, backgroundColor: 'white', zIndex: 1 }}>
                                        {tableHeaders.map(header => (
                                            <td>
                                                <input
                                                    key={header.field}
                                                    type="text"
                                                    value={columnFilters[header.field] || ''}
                                                    onChange={(e) => handleFilterChange(header.field, e)}
                                                />
                                            </td>
                                        ))}
                                    </tr>
                                    {filteredItems && filteredItems?.map(report_row_item => {
                                        return <tr> {
                                            tableHeaders.map(header => {
                                                if (accountList.includes(header.field)) {
                                                    return <td style={{ whiteSpace: 'nowrap' }} className={accountList.includes(header.header) ? 'text-end px-2 py-1' : 'px-2 py-1'}>
                                                        {report_row_item["Payments"][header.field]}</td>
                                                } else if (header.field === "POSId") {
                                                    return <td style={{ whiteSpace: 'nowrap', cursor: "pointer" }} onClick={() => { MoveToPos(report_row_item['id']) }} className={accountList.includes(header.header) ? 'text-end px-2 py-1 text-primary' : 'px-2 py-1 text-primary'}>
                                                        {report_row_item[header.field]}</td>

                                                } else if(header.field === "OrderDate"){
                                                    return <td style={{whiteSpace: 'nowrap', cursor:"pointer"}}  className={accountList.includes(header.header)? 'text-end px-2 py-1': 'px-2 py-1'}>
                                                    {DateFormate(report_row_item[header.field])}</td>

                                                } else if(header.field === "FinalTotalValue" || header.field === "balanceAmount"){
                                                    return <td style={{whiteSpace: 'nowrap', cursor:"pointer"}}  className="text-end px-2 py-1">
                                                    {(report_row_item[header.field])}</td>

                                                } 
                                                 else{
                                                    return <td style={{whiteSpace: 'nowrap'}} className={accountList.includes(header.header)? 'text-end px-2 py-1': 'px-2 py-1'}>
                                                {report_row_item[header.field]}</td>
                                                } 
                                        })} 
                                    </tr>
                                })} 
                                <tr> 
                                    {tableHeaders.map(header => {
                                        if (accountList.includes(header.field) || ['FinalTotalValue', 'balanceAmount'].includes(header.field)) {
                                            return <td style={{whiteSpace: 'nowrap'}} className='text-end px-2 py-1'>
                                                {paymentTotals[header.field] }
                                            </td>
                                        } else  {
                                            return <td style={{whiteSpace: 'nowrap'}} className='px-2 py-1'></td>; // Empty cells for non-payment fields
                                        }
                                    })}
                                </tr>

                                </tbody>
                            </table>
                        </div>

                        <table className='mt-5' id='pos-table-detailed-report-data' style={{ display: 'none' }}></table>
                    </div>
                    <CustomDatePicker
                        show={isShowDatePicker}
                        close={handleDatePickerClose}
                        handleGetSelectedDate={(d) => { handleGetSelectedDate(d) }}
                    />
                    {isShowExportOptionModal &&
                        <DayReportExport
                            show={isShowExportOptionModal}
                            close={handleCloseExportOptionModal}
                        />
                    }
                </div>
            </div>

        </>
    )
}

export default PosDetailedReport