import React, { useState } from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import TableView from '../../Packages/Custom/DataTable'
import TermsConditionsModel from './Models/TermsConditionsModel'

const TermsConditions = () => {
    const [isLoad, setLoad] = useState(false)
    const [termsConditionsIsShowing, setTermsConditionsIsShowing] = useState()
    const [termsConditionsEditData, setTermsConditionsEditData] = useState(null)
    const CustomViewModalComponent = (data)=>{
      setTermsConditionsIsShowing(true) 
      setTermsConditionsEditData(data); 
    }
    const headers = [
        {
          header: 'Name',
          field: 'name', 
          flex: 10,
        //   query: 'accountsMasterOptionQueryForTable',
          inputType: 'text',
          renderComponent: CustomViewModalComponent,
        },
        {
          header: 'Module',
          field: 'module',  
          flex: 10,
        //   query: 'accountGroupOptionforTable',
        //   inputType: 'text', 
        },
        // {
        //   header: 'Tc',
        //   field: 'tc', 
        //   label: "username",
        //   flex: 10, 
        //   align: 'center', 
        // //   option: true,
        // //   bool: true,
        // //   inputType: 'text',
        // },
        
      ]
      const CustomAddModalComponent = () => {
        setTermsConditionsIsShowing(true)
     }
  return (
    <>
    <div className=' index_page_aliment_warp'>
        <div className='index_page_aliment_sidebar'>
            <SideNavbar />
        </div>
        <div className='index_page_aliment_table'>
          <TableView
            headers={headers}
            query={"TermsConditionsTableQuery"}
            addComponent={CustomAddModalComponent}
            loadComponent={isLoad}
            permission={"Save_Numbering_Series"}
            titleName={"Terms Conditions"}
            heightAdjust={"40rem"}
            customFilter={true}
            />
        </div>
    </div>
    
    <TermsConditionsModel
    termsConditionsEditData = {termsConditionsEditData}
    setTermsConditionsEditData = {setTermsConditionsEditData}
     termsConditionsIsShowing = {termsConditionsIsShowing}
     setLoad = {()=>{setLoad(!isLoad)}}
     setTermsConditionsIsShowing = {setTermsConditionsIsShowing}
    /> 
    </>
  )
}

export default TermsConditions