import React, { useEffect, useState } from "react";
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import TableView from '../../Packages/Custom/DataTable';
import OtherIncomeChargesModel from "./OtherIncomeChargesmodel";

const OtherIncomeCharges=()=>{
    const [isLoad, setLoad] = useState(false)
    const [otherIncomeChargesIsShowing, setOtherIncomeChargesIsShowing] = useState(false)
    const [otherIncomeChargesEdit, setOtherIncomeChargesEdit] = useState(null)
    const CustomViewModalComponent = (data)=>{
        setOtherIncomeChargesIsShowing(true) 
        setOtherIncomeChargesEdit(data); 
    }
        const headers = [
            {
              header: 'Name',
              field: 'name', 
              flex: 10,
            //   query: 'accountsMasterOptionQueryForTable',
              inputType: 'text',
              renderComponent: CustomViewModalComponent,
            },
            {
              header: 'Account',
              field: 'account',  
              label: "accountsName",
              flex: 10,
            //   query: 'accountGroupOptionforTable',
            //   inputType: 'text', 
            },
            {
                header: 'HSN',
                field: 'hsn',  
                label: "hsnCode",
                flex: 10,
              //   query: 'accountGroupOptionforTable',
              //   inputType: 'text', 
            }, 
          ]
          const CustomAddModalComponent = () => {
           setOtherIncomeChargesIsShowing(true)
        }
        useEffect(()=>{
            console.log(otherIncomeChargesIsShowing);
         },[otherIncomeChargesIsShowing])
    return(
        <>
          <div className=' index_page_aliment_warp'>
            <div className='index_page_aliment_sidebar'>
                <SideNavbar />
            </div>
            <div className='index_page_aliment_table'>
                <TableView
                  headers={headers}
                  query={"otherIncomingChargesQuery"}
                  addComponent={CustomAddModalComponent}
                  loadComponent={isLoad}
                  titleName={"Other Income Charges"}
                  heightAdjust={"40rem"}
                  permission={'Save_Other_Income'}
                  customFilter={true}
                />
            </div>
           
            {otherIncomeChargesIsShowing &&
                <OtherIncomeChargesModel
                otherIncomeChargesIsShowing = {otherIncomeChargesIsShowing}
                setOtherIncomeChargesIsShowing={setOtherIncomeChargesIsShowing}
                setLoad = {()=>{setLoad(!isLoad)}} 
                otherIncomeChargesEdit={otherIncomeChargesEdit}
                setOtherIncomeChargesEdit={setOtherIncomeChargesEdit}
                />
            }
          </div>
      </>
    )
}
export default OtherIncomeCharges;