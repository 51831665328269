import React, { useState } from 'react';
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import TableView from '../../Packages/Custom/DataTable';
import NumberingModal from '../../Page/ItemMaster/model/NumberingModal';
 

const NumberingSeries = () => { 
 
  const [isLoad, setLoad] = useState(false)
  const [NumberingSeries, setNumberingSeries] = useState(false)
  const handleNumberingSeriesClose = ()=>setNumberingSeries(false)
  const handleNumberingSeriesShow = ()=>setNumberingSeries(true)
  const [numberingSeriesEdit, setNumberngSeriesEdit] = useState()
 
//   const deleteButton = ({ value, data }) => {
//     // Assign the value to the higher-scoped itemId
//    const handleClick = () => {
//       handleNumberingSeriesShow();  
//      setNumberngSeriesEdit(data)
//    };
   
//    const handledeleteConfomation = () => {
      
//       handltoastDeleteConfomationShow();
//      setdeleteData({
//       Name:data.NumberingSeries_Name,
//       id: data.id
//      }); 
 
//    };
  

//   return ( 
//     <>
//     <button className='btn btn-outline-danger btn-sm px-3 mx-2' onClick={handledeleteConfomation}><i className="fa-solid fa-trash " ></i> </button>
//     <button className='btn btn-outline-success btn-sm px-3 mx-2' onClick={handleClick}> <i className="fa-solid fa-pen   " ></i> </button>      
    
//     </>
//   );
// }
  
const CustomViewModalComponent=(data)=>{
  setNumberngSeriesEdit(data)
  handleNumberingSeriesShow()
}

  const headers = [
    {
      header: 'Name',
      field: 'numberingSeriesName', 
      flex: 10,
      query: 'NumberingOptions',
      inputType: 'text',
      renderComponent: CustomViewModalComponent,
    },
    {
      header: 'Resource',
      field: 'resource', 
      flex: 10,
      // query: 'ItemGroupOptionQuery',
      inputType: 'text', 
    },
    {
      header: 'Formate',
      field: 'formate', 
      flex: 10,
      query: 'NumberingFormateOptions',
      inputType: 'text', 
    },
    {
      header: 'Current Value',
      field: 'currentValue', 
      flex: 10, 
      inputType: 'number', 
    },
    {
      header: 'Default',
      field: 'default', 
      flex: 10,
      option: true,
      bool: true,
      align: 'center',
      inputType: 'text',
    },
    {
      header: 'Active',
      field: 'active', 
      flex: 10,
      option: true,
      bool: true,
      align: 'center',
      inputType: 'text',
    },
  ]
  const CustomAddModalComponent = () => {
    handleNumberingSeriesShow()
  }
  return (
    <>
        <div className='container-fluid index_page_aliment_warp'>
            <div className="index_page_aliment_sidebar">
                <SideNavbar/>
            </div>
            <div className="index_page_aliment_table">
                <TableView
                  headers={headers}
                  query = {'NumberingSeriesTableQuery'}
                  addComponent = {CustomAddModalComponent}
                  loadComponent = {isLoad}
                  titleName = {'Numbering Series'}
                  heightAdjust = {'40rem'}
                  permission = {"Save_Numbering_Series"}
                  customFilter={true}
                />
            </div>
        </div>
    <NumberingModal
    NumberingSeries = {NumberingSeries}
    handleNumberingSeriesClose = {handleNumberingSeriesClose}
    numberingSeriesEdit = {numberingSeriesEdit}
    setLoad = {()=>{setLoad(!isLoad)}}
    setNumberngSeriesEdit = {setNumberngSeriesEdit}
    />

    </>
  )
}

export default NumberingSeries