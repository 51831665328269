import React, { useState } from 'react'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import TableView from '../../Packages/Custom/DataTable'
import EmployeeForm from './models/EmployeeFrom'


const Employee = () => {
    const [isLoad, setLoad] = useState(false)
    const [employeeValue, setEmployeeValue] = useState({
        employeeIsShow:false,
        department:"",
        userid:"",
        present_Country:"",
        present_State:"",
        permanent_Country:"",
        permanent_State:"",
        ImageId:"",
        Image:"",
        editData:"",
        isLoading:"",
        
    })
    const CustomAddModalComponent = () => {
        setEmployeeValue(prev=>({...prev, employeeIsShow:true}))
    }
    const CustomViewModalComponent = (data)=>{
        setEmployeeValue(prev=>({...prev, employeeIsShow:true, editData:data}))
        
    }
    const headers = [
        {
            header: 'Employee Id',
            field: 'employeeId',
            flex: 10,
            inputType: 'text',
            renderComponent: CustomViewModalComponent,
        },
        {
            header: 'Employee Name',
            field: 'employeeName',
            
            flex: 10,
            inputType: 'text',
        },
        {
            header: 'Department',
            field: 'department',
            label: 'name',
            flex: 10,
            inputType: 'text',
        },
        {
            header: 'Designation',
            field: 'designation',
            flex: 10,
            inputType: 'text',
        },
        
    ]
    return (
        <>
        <div className=' index_page_aliment_warp'>
            <div className='index_page_aliment_sidebar'>
            <SideNavbar />
            </div>
            <div className='index_page_aliment_table'>
                    <TableView
                        headers={headers}
                        query={"allEmployee"}
                        addComponent={CustomAddModalComponent}
                        loadComponent={isLoad}
                        titleName={"Employee"}
                        heightAdjust={"40rem"}
                        permission={"Save_Department"}
                        customFilter={true}
                    />
            </div>
        </div>
        <EmployeeForm
        employeeValue={employeeValue}
        setEmployeeValue={setEmployeeValue}
        setLoad ={()=>{setLoad(!isLoad)}}
        />

        </>
    )
}

export default Employee