import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import DataContext from '../../context/ItemMasterContext';
// import EnquiryFromEdit from './EnquiryFromEdit';
import { Field, Form, Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { AiOutlineDelete, AiOutlineShoppingCart } from 'react-icons/ai';
import { BiCheckCircle } from 'react-icons/bi';
import { BsPersonCircle, BsPhone } from 'react-icons/bs';
import { FaFilePdf, FaSearch } from 'react-icons/fa';
import { GoLocation } from 'react-icons/go';
import { RiFileExcel2Line } from "react-icons/ri";
import { TbMessage } from 'react-icons/tb';
import { VscDeviceMobile } from 'react-icons/vsc';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../../api/axoiss';
import { userdataOptionsQuery } from '../../GraphQLQueries/ItemmasterQuery';
import TableView from '../../Packages/Custom/DataTable';
import { createPDFReport, customSelectStyle, handleExportExcel, toTitleCase } from '../../utils/Utils';
import EnquiryEditForm from './EnquiryEdit';
import './Enquirystyle.css';


const EnquiryTableAssignSalesPerson = ({show, close, data, isLoad, setLoad}) => {
  
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedSalesPerson, setSelectedSalesPerson] = useState(null);
  const [salesPersonOptions, setSalesPersonOptions] = useState([]);

  let initialData = {
    "salesPerson": "",
  }

  const handleOnInputTypeSalesPersonFetch = (search_term) => {
    if(search_term !== ''){
      axiosInstance.post("/itemmaster/graphql", {query: userdataOptionsQuery(search_term)})
        .then(res => {
            let user_data = res?.data?.data?.User?.items
            user_data = user_data?.map(item => ({label:  item.username, value: item.id}));
            setSalesPersonOptions(user_data)
        }).catch(err => {
            // console.log(err);
            setSalesPersonOptions([])
        })
    }
  }

  const handleSubmit = async (values, formikProps) => {
    let sales_person_id = null;
    let id_list = null;
    if(!selectedSalesPerson){
      toast.error('Please Select Sales Person', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }
    sales_person_id = selectedSalesPerson?.value
    id_list = selectedRows?.map(item => item?.id)
    if(sales_person_id && id_list){
      let update_enquiry_sales_person = `mutation EnquiryFormSalesPersonUpdate{
        enquiryDataSalesPersonMutation(salesPerson: ${sales_person_id}, idList: [${id_list}]){
          success
          errors
        }
      }`;
      try{
        axiosInstance.post("/itemmaster/graphql", {query: update_enquiry_sales_person})
        .then(res => {
            let enquiry_data = res?.data?.data?.enquiryDataSalesPersonMutation
            if(enquiry_data?.success){
                close();
                setLoad(!isLoad)
                toast.success('Successfully Saved!', {
                    position: 'bottom-right',
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light'
                })
            }else{
                console.log(enquiry_data)
            }
        }).catch(err => {
            console.log(err)
        })
      }catch(e){

      }
    } 
  }

  useEffect(()=>{
    if(data?.hasOwnProperty('data')){
      let selected_rows = data?.['data']
      let selected_row_items = Object.values(selected_rows);
      setSelectedRows(selected_row_items);
      let selected_org_names = selected_row_items?.map(item => item.organizationName);
      setSelectedOrganization(selected_org_names)
    } 
    if(data?.hasOwnProperty('filters')){
      setSelectedLocation(data?.['filters']?.location)
    }
  }, [data]);

  return (
    <>
      <Modal show={show} onHide={close} size='xl'>
          <Modal.Header closeButton>
              <Modal.Title>
                  <h5>Assign Sales Person</h5>
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik 
                initialValues = {initialData}
                onSubmit={(values, formikProps) => handleSubmit(values, formikProps)}
            >
              {({errors, submitForm, touched, setFieldValue, setTouched, setFieldError }) => (
                  <>
                      <Form style={{width: "100%", fontSize: '.8rem', height: '100%' }}>
                          <div className='row mx-2'>
                              <div className='col-6 staan-input-group'>
                                  <Field 
                                      type="text" 
                                      name="city"
                                      className = "w-100 staan-form-input" 
                                      disabled
                                      value = {selectedLocation?.label}
                                  />
                                  <label htmlFor="city" className='staan-form-input-label pt-2 px-1 mx-2'>City</label>
                              </div>
                              <div className='col-6 staan-input-group'>
                                  <Select
                                      onInputChange={(e)=>{handleOnInputTypeSalesPersonFetch(e)}}
                                      options={salesPersonOptions}
                                      name='salesPerson' 
                                      style={{fontSize: '.7rem'}} 
                                      className='mt-3'
                                      styles={customSelectStyle}    
                                      value={selectedSalesPerson}    
                                      onChange={(option)=>{
                                        setSelectedSalesPerson(option);
                                      }}   
                                  />
                                  <label htmlFor="salesPerson" className='staan-form-input-label pt-2 px-1 mx-2'>Sales Person</label>
                              </div>
                          </div>
                          <div className='row mx-2 mt-2'>
                              <div className='col-12 staan-input-group'>
                                  <div className='w-100 staan-form-input'
                                    style={{
                                      minHeight: '6rem',
                                      maxHeight: '10rem'
                                    }}
                                  >
                                      <div className='d-flex jusity-content-start align-items-center'>
                                        {
                                          selectedOrganization?.map((item, index) => {
                                            return <span 
                                                      key={index}
                                                      className="badge rounded-pill bg-secondary me-2"
                                                      style={{fontSize: '.7rem', letterSpacing: '2px'}}
                                                    >{item}</span>
                                          })
                                        }
                                      </div>
                                  </div>
                                  <label htmlFor="city" className='staan-form-input-label pt-2 px-1 mx-2'>Selected Organization</label>
                              </div>
                          </div>
                          <div className='row mx-2 mt-4'>
                              <div className='col-10'></div>
                              <div className='col-2'>
                                <button 
                                type='button'
                                  className='btn btn-sm btn-outline-danger me-4 ms-4' 
                                  onClick={()=>{close()}}
                                >Cancel</button>
                                <button 
                                  type='submit'
                                  className='btn btn-sm btn-outline-success'
                                  >Save</button>
                              </div>
                          </div>
                      </Form>
                  </>
              )}
            </Formik>
          </Modal.Body>
      </Modal>
    </>
  )
}

const ExportModel=({ExportData, setExportData, screenSize})=>{
 
    
    return(
      <Modal show={ExportData?.show} onHide={()=>{setExportData(prve=>({...prve,show:false }))}} size='xl'>
      <Modal.Header closeButton>
          <Modal.Title>
              <h5>Export Data</h5>
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row ">
          <div className="col-6">

          </div>
          <div className="col-6 text-end">
            <button className="btn btn-sm btn-outline-primary p-3 m-2  ">
            <RiFileExcel2Line style={{scale:"2"}}
            
            onClick={()=>{
              let EnquiryReportpdfRef = document.getElementById('EnquiryExportView');
              handleExportExcel(EnquiryReportpdfRef, "Enquiry")}}/></button>
            <button className="btn btn-sm btn-outline-danger p-3 m-2  ">
            <FaFilePdf style={{scale:"2"}} onClick={()=>{
              let EnquiryReportpdfRef = document.getElementById('EnquiryExportView');
              createPDFReport(EnquiryReportpdfRef, "Enquiry")}}/></button>

            
          </div>
        </div>
        <div  className='table-responsive ' id='EnquiryExportView' >
          <table class="table">
            <thead>
              <tr>
                <th scope="col" >S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Status</th>
                <th scope="col">Email</th>
                <th scope="col">Mobile</th>
                <th scope="col">Whatsapp Number</th>
                <th scope="col">Other</th>
                <th scope="col">District</th>
                <th scope="col">Remark</th>
                <th scope="col">Conference</th>
                <th scope="col">Interested</th>
                <th scope="col">Sales Person</th>
                <th scope="col">Created By</th>
              </tr>
            </thead>
            <tbody>
              {ExportData?.data?.map((enquiry, index)=>(
                <>
                <tr>
                <th scope="row">{index+1}</th>
                <td>{enquiry?.name}</td>
                <td>{enquiry?.status}</td>
                <td>{enquiry?.email}</td>
                <td>{enquiry?.mobileNumber}</td>
                <td>{enquiry?.alternateMobileNumber}</td>
                <td>{enquiry?.otherNumber}</td>
                <td>{enquiry?.district?.district}</td>
                <td>{enquiry?.remarks}</td>
                <td>{enquiry?.conferenceData?.name}</td>
                <td>{enquiry?.interestsJoined}</td>
                <td>{enquiry?.salesPerson?.username}</td>
                <td>{enquiry?.createdBy?.username}</td>
              </tr>
                </>
              )
                
              )}
            
            </tbody>
          </table>
        </div>
        
      </Modal.Body>
    </Modal>
    )
}

const EnquiryTable = () => {
  const {
    enquiryTable,
    editListView,
    conferenct,
    userdata,
    userName,
    permisiontochangesalesperson,
    setEnquiry,
    salesUserOptions,
    screenWidth,
    userId,
    screenSize
  } = useContext(DataContext)

  const gridRef = useRef()
  const navigate = useNavigate()
  const [post, setPost] = useState([])
  const [enquiryEdit, setEnquiryEdit] = useState('')
  const [pageSize, setPageSize] = useState()
  const [gridApi, setGridApi] = useState(null);
  const [currentUserInfo, setCurrentUserInfo] = useState(null)
  const [searchInput, setSearchInput] = useState('')
  const [salesperson, setSalesperson] = useState('')
  const [citylisttofiliter, setCityListToFilter] = useState([])
  const [ColoumnToDisplay, setColoumnToDisplay] = useState({
    Name: false,
    status: false,
    OrganizationName: false,
    Email: false,
    MobileNumber: false,
    Location: false,
    message: false,
    con_name: false,
    intrested_products: false,
    salesmana_name: false,
    followup: false,
    Remarks: false,
    id: true
  })
  const [ExportData, setExportData] = useState({data:"", show:false})
  
  const [filiterConditions, setfiliterConditions] = useState()
  const [showColoumn, setShowColoumn] = useState({
    Name: false,
    status: false,
    OrganizationName: false,
    Email: false,
    MobileNumber: false,
    Location: false,
    message: false,
    con_name: false,
    intrested_products: false,
    salesmana_name: false,
    followup: false,
    Remarks: false
  }) 
  const [enquiryEditFrom, setEnquiryEditFrom] = useState(false)
  const [EditListViewData, setEditListViewData] = useState()
  const [selectionDone, setSelectionDone] = useState(false);
  const savedFilterState = useRef()


  const handleenquiryEditFromClose = () => setEnquiryEditFrom(false)
  const handleenquiryEditFromShow = () => setEnquiryEditFrom(true)


  const salespersonlist = [
    // "jegathish.e@staan.in",
    'sales@staan.in',
    'babu.m@staan.in',
    'martin.a@staan.in',
    'venkatesh.p@staan.in',
    'siddharth.p@staan.in',
    'premkumar.m@staan.in',
    'sakthivel.k@staan.in',
    'mandal.g@staan.in',
    'krishnamoorthy.l@staan.in',
    'puviarasan.g@staan.in',
    'suresh.d@staan.in',
    'Karthiraman.k@staan.in',
    'selvaraju.k@staan.in'
  ]

  useEffect(() => {
    setPost(enquiryTable);
    let current_user_id = localStorage.getItem('userid')
    let user_permission_query = `query userPermissionFetch{
      userPermission(userId: ${current_user_id}){
        items{
          id
          userId {
            id
            username
          }
          isSalesPerson
          isAdminPerson
          isEnquiryAdmin
        }
      }
    }`
    try{
      axiosInstance.post("/itemmaster/graphql", {query: user_permission_query})
      .then(res => {
        let user_data = res?.data?.data?.userPermission?.items
        if(user_data?.length){
          setCurrentUserInfo(user_data)
        }
      }).catch(err => {
        console.log(err)
      })
    }catch(e){
      console.log(e)
    }
    // Extract unique city values
    const uniqueCitySet = new Set(enquiryTable.map(item => item.Location))
    const uniqueCityList = [...uniqueCitySet]

    // Sort the city list alphabetically
    const sortedCityList = uniqueCityList.sort()

    // Create an array of objects with 'value' and 'label' properties
    const updatedCityList = sortedCityList.map(city => ({
      value: city,
      label: city
    }))

    setCityListToFilter(updatedCityList)
  }, [enquiryTable])

  useEffect(() => {
    const uniqueEmails = new Set(salesperson)
    salespersonlist.forEach(personemail => {
      const loginperson = userdata.find(
        logindata => logindata.email === personemail
      )
      uniqueEmails.add('')
      if (loginperson !== undefined) {
        uniqueEmails.add(loginperson.email)
      }
    })
    setSalesperson([...uniqueEmails])
  }, [userdata])

  useEffect(() => {
    if (localStorage.getItem('EnqryTableCount') !== '') {
      setPageSize(localStorage.getItem('EnqryTableCount'))
    } else {
      setPageSize(10)
    }
  }, [pageSize])


  const handleButtonClick = () => {
    navigate('/Enquiry')
  }
  
  const editButton = ({ value, data }) => {
    const handleClick = () => {
      handleenquiryEditFromShow()

      setEnquiryEdit(data)
    }

    // const handledeleteConfomation = () => {

    //   handltoastDeleteConfomationShow();
    //   setdeleteData({
    //     Name:data.StoreName,
    //     id: data.id
    //   });

    // };

    return (
      <>
        <button
          className='btn btn-outline-success btn-sm px-3 mx-2'
          onClick={handleClick}
          onMouseDown={handleClick}
        >
          {' '}
          <i className='fa-solid fa-pen   '></i>{' '}
        </button>
      </>
    )
  }

 
  const handlePageSizeChange = event => {
    const newSize = parseInt(event.target.value, 10)
    setPageSize(newSize)
    localStorage.setItem('EnqryTableCount', newSize)
  }

   

  function resetFiliter () {
    setShowColoumn({
      Name: false,
      status: false,
      OrganizationName: false,
      Email: false,
      MobileNumber: false,
      Location: false,
      message: false,
      con_name: false,
      intrested_products: false,
      salesmana_name: false,
      followup: false,
      Remarks: false
    })
    setColoumnToDisplay({
      Name: false,
      status: false,
      OrganizationName: false,
      Email: false,
      MobileNumber: false,
      Location: false,
      message: false,
      con_name: false,
      intrested_products: false,
      salesmana_name: false,
      followup: false,
      Remarks: false
    })
  }

  const handleEditListViewDataChange = option => {
    if (option) {
      setEditListViewData({
        value: option.value,
        label: option.label,
        filiter: option.filiter
      })
      savedFilterState.current = option.filiter
      OnBtApply()
      setShowColoumn(option.dissplay_col)
    } else {
      setEditListViewData({ value: '', label: '', filiter: '' })
      savedFilterState.current = ''
      OnBtApply()
      setShowColoumn('')
    }
  }

  const onBtSave = () => {
    const filtermodel = gridRef.current.api.getFilterModel()
    setfiliterConditions(filtermodel)
  }

  const OnBtApply = () => {
    const filterModel = savedFilterState.current
    gridRef.current.api.setFilterModel(filterModel)
  }
  // const [loading, setLoading] = useState(false)
  const saveDataforSalesPerson = async (data, SalesPerson) => {
    const id = data['id']
    const save_enquiry_from = {
      Name: data['Name'],
      OrganizationName: data['OrganizationName'],
      Email: data['Email'],
      status: data['status'],
      MobileNumber: data['MobileNumber'],
      Location: data['Location'],
      message: data['message'],
      conferencedata: data['conferencedata'],
      Interesteds: data['Interesteds'],
      Remarks: data['Remarks'],
      followup: data['followup'],
      salesPerson: SalesPerson
    }

    try {
      const respones = await axiosInstance.put(`/api/${id}`, save_enquiry_from)
      const responseData = Array.isArray(respones.data)
        ? respones.data
        : [respones.data]
      setEnquiry(prevData => {
        return prevData.map(item => {
          // Log the IDs for debugging
          return item.id === responseData[0].id ? responseData[0] : item
        })
      })

      // toast.success('Updated Successfully', {
      //   position: "bottom-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    } catch (error) {
      console.log(error)
    }
  }

  const onCellValueChanged = event => {
    const findeSalseperson = userdata.find(
      person => person.email === event.data['salesmana_name']
    )
    const salepersonid = findeSalseperson ? findeSalseperson.id : ''
    //  const id =  event.data["id"]
    const selectedNodes = event.api.getSelectedNodes()

    if (selectedNodes.length > 0) {
      const api = event.api

      api.forEachNodeAfterFilter(node => {
        if (node.isSelected()) {
          const selectedRows = []
          selectedRows.push(node.data)

          const itratedData = node.data
          saveDataforSalesPerson(itratedData, salepersonid)
        }
      })
    } else {
      // No rows are selected
      const itratedData = event.data
      saveDataforSalesPerson(itratedData, salepersonid)
      // console.log("No rows are selected", event.data);
    }
  }



  const handleSelectAllFiltered = () => {
    if (gridApi && !selectionDone) {
      gridApi.selectAllFiltered()
      setSelectionDone(true)
    }
  }

  const EditView = data => {
    handleenquiryEditFromShow()

    setEnquiryEdit(data)
  }
  // search function
  const SearchFunction = value => {
    const filteredPosts = post.filter(
      post =>
        post.Name.toUpperCase().includes(value.toUpperCase()) ||
        post.MobileNumber.includes(value) ||
        post.Location.toUpperCase().includes(value.toUpperCase()) ||
        post.status.toUpperCase().includes(value.toUpperCase()) ||
        post.message.toUpperCase().includes(value.toUpperCase()) ||
        post.intrested_products.includes(toTitleCase(value))
    )
    if (value) {
      setPost(filteredPosts)
    } else {
      setPost(enquiryTable)
    }

    setSearchInput(value)
  }


  /** new function starts */
  const [isLoad, setLoad] = useState(false);
  const [isShowEnquiryForm, setShowEnquiryForm] = useState(false);
  const [enquiryData, setEnquiryData] = useState(null);
  const [showAssigningModal, setShowAssigningModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([])

  const handleShowAssigningModal = () => setShowAssigningModal(true);
  const handleCloseAssigningModal = () => setShowAssigningModal(false);

  const handleShowEnquiryForm = () => {
    setShowEnquiryForm(true)
  }

  const handleCloseEnquiryForm = () => {
    setShowEnquiryForm(false)
  }

  const CustomViewModalComponent = (data) => { 
    setEnquiryData(data)
    handleShowEnquiryForm();
    // setLoad(!isLoad);
  }

  const CustomAddModalComponent = () => {
      window.open(`${window.location.origin}/Enquiry`, '_blank')
      // handleShowStockAdditionModal()
      // setLoad(!isLoad);
  }

  const CustomComponent = (data, filters) => {
    let construct_data = {
      'data': data,
      'filters': filters
    }
    setSelectedRowData(construct_data)
    if(Object.keys(data)?.length < 1){
      toast.error('Please select enquiry data', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }else if(filters && !filters?.location){
      toast.error('Please select city!', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }else{
      handleShowAssigningModal();
    }
  }

  // return null ; get the export data from list view
  let exportData = (data)=>{
    
    
    setExportData({data:data, show:true})
  }

  let column_details = [
      { 
        header: "ID", 
        field: 'id',
        flex: 5, 
        inputType: 'number',
        align: 'end'
     
        // query: 'EnquiryMasterNameOptionFetchQuery',
      },
      { 
        header: "Name", 
        flex: 15, 
        field: 'name',
        // label: 'contactPersonName',
        inputType: 'text',
        renderComponent: CustomViewModalComponent,
        query: 'EnquiryMasterNameOptionFetchQuery',
      },
      { 
        header: "Status", 
        field: "status",
        flex: 10,
        inputType: 'text',
        option: true,
        query: 'EnquiryStatuSFetchQuery',
        renderComponent: CustomViewModalComponent,

      }, 
      { 
        header: "Organization", 
        field: "organizationName",
        flex: 15,
        inputType: 'text',
        query: 'EnquiryMasterNameOptionFetchQuery',
        renderComponent: CustomViewModalComponent,
      },
      { 
        header: "Email", 
        field: "email",
        flex: 15,
        inputType: 'text',
        query: 'EnquiryMasterNameOptionFetchQuery',
        renderComponent: CustomViewModalComponent,
      },
      { 
        header: "Mobile", 
        field: "mobileNumber",
        flex: 10,
        inputType: 'text',
        query: 'EnquiryMasterNameOptionFetchQuery',
        renderComponent: CustomViewModalComponent,
      },
      { 
        header: "Alternate", 
        field: "alternateMobileNumber",
        flex: 10,
        inputType: 'text',
        query: 'EnquiryMasterNameOptionFetchQuery',
        renderComponent: CustomViewModalComponent,
      },
      { 
        header: "Other", 
        field: "otherNumber",
        flex: 10,
        inputType: 'text',
        query: 'EnquiryMasterNameOptionFetchQuery',
        renderComponent: CustomViewModalComponent,
      },
      { 
        header: "City", 
        field: "location",
        flex: 15,
        inputType: 'text',
        query: 'EnquiryMasterNameOptionFetchQuery',
        renderComponent: CustomViewModalComponent,
      },
      { 
        header: "Message", 
        field: "message",
        flex: 15,
        inputType: 'text',
        query: 'EnquiryMasterNameOptionFetchQuery',
        renderComponent: CustomViewModalComponent,
      },
      { 
        header: "Conference", 
        field: "conferenceData",
        flex: 15,
        label: 'name',
        inputType: 'text',
        query: 'EnquiryMasterNameOptionFetchQuery',
        renderComponent: CustomViewModalComponent,
      },
      { 
        header: "Interested", 
        field: "interestsJoined",
        flex: 15,
        inputType: 'text',
        option: true,
        query: 'ProductFetchQuery',
        label: 'Name',
        renderComponent: CustomViewModalComponent,
      },
      { 
        header: "Sales Person", 
        field: "salesPerson",
        flex: 15,
        inputType: 'text',
        label: 'username',
        query: 'EnquiryMasterNameOptionFetchQuery',
        renderComponent: CustomViewModalComponent,
      },
      { 
        header: "Created By", 
        field: "createdBy",
        label: "username",
        flex: 10,
        option: true,
        query: 'userOptionQuery',
        inputType: 'text',
        renderComponent: CustomViewModalComponent,
      },
      { 
        header: "Created At", 
        field: "createdAt",
        flex: 10,
        inputType: 'text', 
        isDateField: true,
        renderComponent: CustomViewModalComponent, 
      },
    
  ]
  
  return (
    <div> 
        {screenWidth <= 567 && <>
            <div className='container m-0 p-0 bg-white'>
              <div className='mt-3 mb-3' style={{ overflow: 'clip' }}>
                <div className='row'>
                  <div className='col-6'>
                    <p
                      className='fs-3 fw-bold ms-2'
                      style={{ fontFamily: 'monospace' }}
                    >
                      Enquiry
                    </p>
                  </div>
                  <div className='col-6 text-end mt-2'>
                    <i
                      className='fa-sharp fa-solid fa-circle-plus text-primary fa-2xl pe-2 '
                      onClick={handleButtonClick}
                    ></i>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-12 d-flex justify-content-center'>
                    <div className='input-group rounded-pill w-100 mx-2 bg-light border border-light'>
                      <input
                        type='search'
                        name='EnquirSearch'
                        className='form-control rounded-pill border-0 bg-light my-2'
                        value={searchInput}
                        onChange={e => {
                          SearchFunction(e.target.value)
                        }}
                        placeholder=''
                        style={{ position: 'relative' }}
                      />
                      <span
                        style={{ position: 'absolute' }}
                        className='ms-3 mt-3'
                        hidden={searchInput}
                      >
                        <FaSearch />{' '}
                        <span className='ms-1 text-muted'>
                          Search For Enquiry
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {post.map(item => (
                <ol
                  className='list-group mx-3 '
                  onClick={() => {
                    EditView(item)
                  }}
                >
                  <li className='list-group-item d-flex justify-content-between align-items-start mt-3 '>
                    <div className='ms-2 me-auto'>
                      <div
                        className='fw-bold text-capitalize py-1'
                        style={{ font: 'status-bar' }}
                      >
                        <BsPersonCircle className='me-3' />
                        {item.Name}
                      </div>
                      <p
                        className='mb-0 fw-bold py-1'
                        style={{ font: 'status-bar', fontFamily: 'monospace' }}
                      >
                        <BsPhone className='me-3' />
                        {item.MobileNumber}
                      </p>
                      <p
                        className='mb-0 fw-bold py-1'
                        style={{ font: 'status-bar', fontFamily: 'monospace' }}
                      >
                        <GoLocation className='me-3' />
                        {item.Location}
                      </p>

                      <p
                        className='mb-0 fw-bold py-1'
                        style={{ font: 'status-bar', fontFamily: 'monospace' }}
                      >
                        {item.status === 'Not Contacted' ? (
                          <VscDeviceMobile className='me-3' />
                        ) : item.status === 'Converted' ? (
                          <BiCheckCircle className='me-3' />
                        ) : item.status === 'Junk' ? (
                          <AiOutlineDelete className='me-3' />
                        ) : (
                          ''
                        )}
                        {item.status}
                      </p>

                      {item.intrested_products.length > 0 ? (
                        <p
                          className='mb-0 fw-bold py-1'
                          style={{ font: 'status-bar', fontFamily: 'monospace' }}
                        >
                          <AiOutlineShoppingCart className='me-3' />
                          {String(item.intrested_products)}
                        </p>
                      ) : (
                        ''
                      )}
                      {item.message ? (
                        <p
                          className='mb-0 fw-bold py-1'
                          style={{ font: 'status-bar', fontFamily: 'monospace' }}
                        >
                          <TbMessage className='me-3' />
                          {String(item.message)}
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  </li>
                </ol>
              ))}
            </div>
        </>}
        {screenWidth >= 567 &&
         <div className='container-fluid' style={{width:"100%", position:"relative"}}>
            <div className='' style={{position:"fixed", top:"0", left:"0", width:"10%", zIndex:"20"}}>
                <SideNavbar />
            </div>
            <div className=' ' style={{position:"fixed", top:"0", left:"5rem",width:"95%" }}>
                    <TableView
                        headers = {column_details}
                        query = {'EnquiryDatamasterFetchQuery'}
                        loadComponent = {isLoad}
                        titleName = {'Enquiry Details'}
                        heightAdjust = {`${(screenSize?.height/20)}rem`}
                        addComponent = {CustomAddModalComponent}
                        rowSelect = {true}
                        exportData = {exportData}
                        customComponent = {CustomComponent}
                        customFilter={true}
                    />
            </div>
       
          {/* <EditListView
            table_name={'EnquiryTable'}
            ColoumnToDisplay={ColoumnToDisplay}
            filiterConditions={filiterConditions}
          /> */}

          {
            isShowEnquiryForm && 
            <EnquiryEditForm 
                show = {isShowEnquiryForm}
                close = {handleCloseEnquiryForm}
                enquiryEdit = {enquiryData}
                isLoad = {isLoad}
                setLoad = {setLoad}
            />
          }
          {
            showAssigningModal && 
            <EnquiryTableAssignSalesPerson 
              show = {showAssigningModal}
              close = {handleCloseAssigningModal}
              data = {selectedRowData}
              isLoad={isLoad}
              setLoad = {setLoad}
            />
          } 
          {ExportData?.show &&
          <ExportModel
          ExportData={ExportData}
          setExportData={setExportData}
          screenSize={screenSize}
          />}
        </div>}
        <ToastContainer />
    </div>
  )
}

export default EnquiryTable
