import React, { useState } from 'react';
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import TableView from '../../Packages/Custom/DataTable';
import PaymentVoucherModal from './Models/paymentVoucherModal';

const PaymentVoucher = () => {
    const [isVouchershow, setVouchershow] = useState(false)
    const [isLoad, setLoad] = useState(false)
    const [editData, setEditData] = useState(null)

    const CustomViewModalComponent = data => {
        setEditData(data)
        setVouchershow(true)
    }
    const headers = [
        {
            header: 'Payment Voucher No',
            field: 'paymentVoucherNo',
            label: "linkedModelId",
            flex: 10,
            inputType: 'text',
            renderComponent: CustomViewModalComponent,
        },
        {
            header: 'Payment Voucher Date',
            field: 'date',
            flex: 10,
            inputType: 'text',
            renderComponent: CustomViewModalComponent,
        }
    ];
    const CustomAddModalComponent = () => {
        setVouchershow(true)

    }
    return (
        <>
            <SideNavbar />
            <div id='custom-table-itemmaster'>
                <TableView
                    headers={headers}
                    query={"paymentVoucherTable"}
                    titleName={"Payment Voucher"}
                    addComponent={CustomAddModalComponent}
                    loadComponent={isLoad}
                    heightAdjust={"40rem"}
                    permission={"View_Enquiry"}
                    customFilter={true}
                />
            </div>
            {isVouchershow &&
                <PaymentVoucherModal
                    isVouchershow={isVouchershow}
                    setVouchershow={setVouchershow}
                    editData={editData}
                    setEditData={setEditData}
                    setLoad={() => { setLoad(!isLoad) }}
                />
            }
        </>
    );
}

export default PaymentVoucher;
