import React, { useContext, useState } from 'react'
import TableView from '../../Packages/Custom/DataTable'
import { SideNavbar } from '../../components/sideNavbar/SideNavbar'
import DataContext from '../../context/ItemMasterContext'
import LeadFormEdit from '../Lead/modals/LeadFormEdit'
import SalesOrderForm from '../SalesOrder2/model/SalesOrderForm'
import QuotationFrom from './Forms/Quatationform'

const QuotationTable = () => {
  const {quotationModels, openQuotationModal, salesOrderModels, leadModels } = useContext(DataContext)
  const [isLoad, setLoad] = useState(false)


  const CustomViewModalComponent = data => {
    // Function to open the modal for editing a quotation.
    // It sets the quotation ID for the data being edited and shows the form.
    openQuotationModal(data?.id)
  }


  const headers = [
    // Table headers configuration for displaying data.
    {
      header: 'quotationNo',
      field: 'quotationNo',
      flex: 10,
      label: 'linkedModelId',
      inputType: 'text',
      renderComponent: CustomViewModalComponent,
    },
    {
      header: 'Costomer Name',
      field: 'customerId',
      label: "companyName",
      flex: 10,
      inputType: 'text',
      },
      {
        header: 'City',
        field: 'customerAddress',
        label: "city",
        queryField: "city",
        flex: 10,
        inputType: 'text',
    },
    {
        header: 'State',
        field: 'customerAddress',
        label: "state",
        queryField: "state",
        flex: 10,
        inputType: 'text',
    },
    {
      header: 'Net Amount',
      field: 'netAmount',
      flex: 10,
      inputType: 'number',
  },
    {
      header: 'Date',
      field: 'CreatedAt',
      flex: 10,
      inputType: 'text',
      isDateField: true,
  },
  ]
  const CustomAddModalComponent = data => {
 
  }

  return (
      <>
        <div className='container-fluid index_page_aliment_warp'>
          <div className='index_page_aliment_sidebar'>
            <SideNavbar />
          </div>
          <div className='index_page_aliment_table'>
            <TableView
              headers={headers}
              query={'QuotationTable'}
              addComponent={CustomAddModalComponent}
              loadComponent={isLoad}
              titleName={'Quotation'}
              heightAdjust={'50rem'}
              customFilter={true}
            />
          </div>
        </div>
        {quotationModels?.map((item)=>(
          <QuotationFrom
          index={item?.index}
          setLoad={() => {
            setLoad(!isLoad)
          }}
          quotationId={item?.id}
          />
        ))}
        {salesOrderModels?.map((item)=>(
                <SalesOrderForm
                index={item?.index}
                setLoad = {()=>{setLoad(!isLoad)}}
                editData = {item?.id}
                />
        ))}
      
         {leadModels?.map((item)=>(
          <LeadFormEdit
          index={item?.index}
          editData={item?.id}
          setload={() => { setLoad(!isLoad) }}
          />
      ))}
        
      </>
  )
}

export default QuotationTable
