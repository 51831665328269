import { Field, Form, Formik } from 'formik'
import React, { useContext, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import axiosInstance, { commanFetch } from '../../../api/axoiss'
import BallTriangleLoading from "../../../components/loading/BallTriangle"
import {
  DiscountAsValue,
  DiscountPercentage,
  removeEmptyValueInObject,
  ShowErrorNoties
} from '../../../utils/Utils'
import { parseAndGroupItemComboData } from './itemCombo'
// import { stockIdFetchQuery } from "../../../GraphQLQueries/ItemmasterQuery";
import { AiOutlinePercentage } from 'react-icons/ai'
import { GoXCircle } from 'react-icons/go'
import Select from 'react-select'
import { ItemMasterPurchaseOrderFetchQuery } from '../../../GraphQLQueries/ItemmasterQuery'
import { quatationItemModalMutation } from '../../../GraphQLQueries/QuatationqueryAndMutatiom/QuatationItemModalMutation'
import { discountClearAllQuery } from '../../../GraphQLQueries/QuatationqueryAndMutatiom/quatationQuery'
import showErrorToast from '../../../components/notifications/react-toastify/toast'
import DataContext from '../../../context/ItemMasterContext'
import { customSelectStyle } from '../../../utils/Utils'
import { quotationFormitemValidation } from '../../../validations/QuotationValidation'
import ItemCombo from './itemCombo'

const QuatationItemModel = ({
  setItemComboData,
  itemComboData,
  stateValue,
  EditData,
  EditShow,
  onNewItem,
  QuotationItemDetailsShow,
  setQuotationItemDetailsShow,
  itemDetailsData,
  setItemDetailsData,
  otherIncomeChargesData,
  setOtherIncomeChargesData,
  isAfterOverallDiscount,
  setAfterOverallDiscount,
  setOverallDiscountPercentage,
  setOverallDiscountValue,
  setFinalTotal,
  currencyData
}) => {
  const { userId } = useContext(DataContext)
  const [initialItemQuatation, setInitialItemQuatation] = useState({
    // Initialize state for item quotation with default values or existing data if editing
    partCode: EditShow ? EditData?.itemmaster?.id : '',
    partName: EditShow ? EditData?.itemmaster?.id : '',
    category: EditShow ? EditData?.itemmaster?.category?.name : '',
    po_qty: EditShow ? EditData?.qty : 1,
    amount: EditShow ? EditData?.amount : 0,
    uom: EditShow ? EditData?.uom?.id : '',
    rate: EditShow ? EditData?.rate : '',
    gstRates: EditShow ? EditData?.tax : '',
    discount: EditShow ? (EditData?.discountPercentage ? `${EditData.discountPercentage}% ` : '') +
      (EditData?.discountValue ? `${currencyData?.currencySymbol}${EditData.discountValue}` : '')
      : '',
    rateAfterDiscount: EditShow?.afterDiscountValueForPerItem ? EditData?.afterDiscountValueForPerItem : '',
    description: EditShow ? EditData?.description : '',
    partCodeLabel: '',
    partNameLabel: ''

  })
  const [loading, setLoading] = useState(false)

  const [partCodeSelect, setPartCodeSelect] = useState(
    EditShow
      ? {
        value: EditData?.itemmaster?.itemPartCode,
        label: EditData?.itemmaster?.itemPartCode // Adjust label if necessary
      }
      : []
  )
  const [UomSelect, setUomSelect] = useState(
    EditShow
      ? {
        value: EditData?.uom?.id,
        label: EditData?.uom?.name // Adjust label if necessary
      }
      : []
  )
  const [partNameSelect, setPartNameSelect] = useState(
    EditShow
      ? {
        value: EditData?.itemmaster?.itemName,
        label: EditData?.itemmaster?.itemName // Adjust label if necessary
      }
      : []
  )
  const [partCodeList, setPartCodeList] = useState([])
  const [partNameList, setPartNameList] = useState([])
  const [isPercentage, setIsPercentage] = useState(
    EditShow ? EditData?.discountPercentage ? true : EditData.discountValue ? false : true : true

  )

  const [discount, setDiscount] = useState(
    EditShow
      ? (EditData?.discountPercentage ? `${EditData.discountPercentage}%` : EditData.discountValue ? `${currencyData?.currencySymbol}${EditData.discountValue}` : '0%')
      : '0%'
  );
  const [RateAfterDiscount, setRateAfterDiscount] = useState(
    EditShow ? `${EditData?.afterDiscountValueForPerItem ? EditData?.afterDiscountValueForPerItem : ''}` : ''
  );
  const [amount, setAmount] = useState(null)
  const [showItemCombo, setShowItemCombo] = useState(false);
  const [lastUpdatedItemDetails, setlastUpdatedItemDetails] = useState('')
  const [itemComboModalData, setItemComboModalData] = useState([])

  function FinalValue(discount, amount) {
    // Function to calculate final value after discount based on the type of discount
    if (discount.includes("%")) {
      return DiscountPercentage(Number(discount?.replace("%", "")), Number(amount))
    } else if (discount.includes(currencyData?.currencySymbol)) {
      // console.log("currencySymbol", currencyData?.currencySymbol, "discount", discount?.replace(currencyData?.currencySymbol, ""));

      return DiscountAsValue(discount?.replace(currencyData?.currencySymbol, ""), amount)
    }

  }

  // Function to fetch options based on user input
  async function getOption(e, query, name) {
    if (query === 'ItemMasterPurchaseOrderFetchQuery') {
      if (name === 'PartNumber') {
        let perameter = `itemPartCode : "${e}",page:1, pageSize:100,  itemIndicatorsList:["Sell","Both"]`

        const response = await commanFetch(
          ItemMasterPurchaseOrderFetchQuery(perameter)
        )
        const responseData = response?.data?.items
        if (responseData.length > 0) {
          // Store detailed item data for later use
          setPartCodeList(
            responseData.map(item => ({
              value: item?.id,
              label: item?.itemPartCode,
              itemName: item?.itemName,
              uom: item?.itemUom,
              category: item?.category?.name,
              rate: item?.itemMrp,
              gstRate: item?.itemHsn?.gstRates?.rate,
              description: item?.description
            }))
          )
        }
      } else if (name === 'itemName') {
        let perameter = `itemName: "${e}" , page:1, pageSize:100,  itemIndicatorsList:["Sell","Both"]`
        const response = await commanFetch(
          ItemMasterPurchaseOrderFetchQuery(perameter)
        )
        const responseData = response?.data?.items
        if (responseData?.length > 0) {
          setPartNameList(
            responseData?.map(item => ({
              value: item?.id,
              label: item?.itemName,
              itemPartCode: item?.itemPartCode,
              uom: item?.itemUom,
              category: item?.category?.name,
              rate: item?.itemMrp,
              gstRate: item?.itemHsn?.gstRates?.rate,
              description: item?.description
            }))
          )
        }
      }
    }
  }

  // Function to handle quantity change and update amount accordingly
  const handleQtyChange = (e, setFieldValue, currentRate) => {
    const qty = e.target.value
    const rate = currentRate || 0 // Get the rate from the current form values
    const amount = qty * rate // Calculate amount
    setFieldValue('po_qty', qty) // Update Qty
    setFieldValue('amount', amount) // Update Amount
  }


  const clearDiscountData = async (itemId, otherIncomeId) => {
    if (itemId?.length > 0) {
      const requestBody = {
        ids: itemId,
        otherIncomeId: otherIncomeId.length > 0 ? [...otherIncomeId] : [],
      };
      const response = await commanFetch(discountClearAllQuery(requestBody));
      const responseData = response?.data;
      setItemDetailsData(responseData?.items);
      const transformedOtherIncomeChargesData = responseData?.quotationsOtherIncomeCharges?.map(item => ({
        ...item,
        account: {
          label: item?.otherIncomeChargesId?.name,
          tax: item?.otherIncomeChargesId?.hsn?.gstRates?.rate,
          value: item?.otherIncomeChargesId?.id,
        },
        tax: item?.otherIncomeChargesId?.hsn?.gstRates?.rate
      }));
      setOtherIncomeChargesData(transformedOtherIncomeChargesData);
      setOverallDiscountPercentage('')
      setOverallDiscountValue('')
      setFinalTotal('')
    }
  };




  const handleSubmit = async (

    values,
    { setSubmitting, resetForm, setErrors }
  ) => {

    setLoading(true)
    let reqBody = {
      id: "",
      states: stateValue ?? '',
      amount: amount ? `${amount}` : String(values?.amount),
      createdBy: EditData?.id ? Number(EditData?.createdBy?.id) : Number(userId),
      itemmaster: Number(values?.partCode),
      qty: values?.po_qty,
      rate: String(values?.rate),
      uom: Number(values?.uom),
      discountPercentage: isPercentage ? (discount?.replace(/%/g, '') || '') : '',
      discountValue: !isPercentage ? (discount?.replace(currencyData?.currencySymbol, '') || '') : '',
      afterDiscountValueForPerItem: RateAfterDiscount ? String(RateAfterDiscount) : '',
      tax: Number(values?.gstRates),
      description: values?.description.trim() === "" || values?.description === null || values?.description === undefined ? `${values?.partCodeLabel}-${values?.partNameLabel}` : values?.description
    }
    if (EditShow) {
      reqBody.id = Number(EditData?.id)
      reqBody.modifiedBy = Number(userId);
    }
    console.log("request--------------", reqBody);

    let ClearValue = removeEmptyValueInObject(reqBody, ['modifiedBy', 'id', 'discountPercentage', 'discountValue', "afterDiscountValueForPerItem"])
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, {
        query: quatationItemModalMutation(ClearValue)
      })
      const responseData = response?.data?.data?.quotationsItemDetailsMutations
      console.log("Item Details responseData------------------", responseData);
      setLoading(false)
      if (responseData.success) {
        if (responseData?.itemCombo === false) {
          console.log("item combo false ");

          onNewItem(responseData?.quotationsItemDetails)
          if (isAfterOverallDiscount) {
            const idList = itemDetailsData?.map(item => Number(item?.id));
            const newId = Number(responseData?.quotationsItemDetails?.id);
            idList.push(newId);
            const otherIncomeIds = otherIncomeChargesData?.map(item => parseInt(item?.id, 10));
            await clearDiscountData(idList, otherIncomeIds);
          }
          showErrorToast(true, 'success', '', 'Saved Successfully')
          resetForm()
          setSubmitting(false)
          setQuotationItemDetailsShow(false)
          console.log(
            'Item Data Saved ----------------',
            responseData?.quotationsItemDetails
          )
        }
        else if (responseData?.itemCombo === true) {
          console.log("item combo true ");

          onNewItem(responseData?.quotationsItemDetails)
          if (isAfterOverallDiscount) {
            const idList = itemDetailsData?.map(item => Number(item?.id));
            const newId = Number(responseData?.quotationsItemDetails?.id);
            idList.push(newId);
            const otherIncomeIds = otherIncomeChargesData?.map(item => parseInt(item?.id, 10));
            await clearDiscountData(idList, otherIncomeIds);
          }
          console.log("responseData?.itemComboData", responseData?.itemComboData);

          const groupedData = parseAndGroupItemComboData(responseData?.itemComboData);
          setItemComboData(groupedData);
          setItemComboModalData(groupedData)
          setlastUpdatedItemDetails(responseData?.quotationsItemDetails)
          setShowItemCombo(true);
        }
        setAfterOverallDiscount(false)
      }

      else {
        let errorData = ShowErrorNoties(responseData.errors)

        console.log(errorData);
        showErrorToast(true, 'error', errorData)
      }
    } catch (error) {
      console.log(error);
      let errorData = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorData)
    }
    setLoading(false)
  }



  return (
    <>
      {<BallTriangleLoading isshow={loading} />}
      <Modal
        show={QuotationItemDetailsShow}
        backdrop='static'
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Header  >
          <Modal.Title style={{ color: '#5CADA9' }}>
            <i
              className='fa-solid fa-arrow-left fa-sm me-2 cursor-pointer'
              onClick={() => {
                if (setQuotationItemDetailsShow) {
                  setQuotationItemDetailsShow(false)
                }
              }}
            ></i>
            Item
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialItemQuatation}
            onSubmit={handleSubmit}
            validationSchema={quotationFormitemValidation}
          >
            {({
              errors,
              touched,
              setFieldValue,
              values,
              isSubmitting,
              submitForm
            }) => {
              return (
                <Form>
                  <div className='row '>
                    <div className='col-6 staan-input-group'>
                      <Select
                        name='partCode'
                        className='my-3'
                        styles={customSelectStyle}
                        onInputChange={e => {
                          getOption(
                            e,
                            'ItemMasterPurchaseOrderFetchQuery',
                            'PartNumber'
                          )
                        }}
                        options={partCodeList}
                        value={partCodeSelect}
                        onChange={option => {
                          setFieldValue(
                            'partCode',
                            option ? option?.value : null
                          )
                          setFieldValue(
                            'partCodeLabel',
                            option ? option?.label : null
                          )
                          setFieldValue(
                            'partNameLabel', option ? option?.itemName : null
                          )
                          setPartCodeSelect(option ? option : null)

                          setPartNameSelect({
                            value: option?.value,
                            label: option?.itemName
                          })

                          setUomSelect({
                            value: option?.uom?.id,
                            label: option?.uom?.name
                          })
                          setFieldValue('uom', option?.uom?.id ?? null)
                          setFieldValue('category', option?.category ?? null)
                          setFieldValue('rate', option?.rate ?? null)
                          setFieldValue('gstRates', option?.gstRate ?? null)
                          setFieldValue('amount', values?.po_qty * option?.rate)
                          setFieldValue('partName', option?.value ?? null)
                        
                          setFieldValue('description', option?.description)

                        }}
                      />

                      <label
                        htmlFor='partCode'
                        className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                          console.log(EditData);
                        }}
                      >
                        Part Code<span className='text-danger'>*</span>
                      </label>
                      {touched.partCode && errors.partCode && (
                        <small className='staan-error-msg'>
                          {errors.partCode}
                        </small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Select
                        name='partName'
                        className='my-3'
                        styles={customSelectStyle}
                        onInputChange={e => {
                          getOption(
                            e,
                            'ItemMasterPurchaseOrderFetchQuery',
                            'itemName'
                          )
                        }}
                        options={partNameList}
                        value={partNameSelect}
                        onChange={option => {
                          console.log('Option----------------', option)

                          setFieldValue(
                            'partName',
                            option ? option?.value : null
                          )
                          setFieldValue(
                            'partNameLabel',
                            option ? option?.label : null
                          )
                          setFieldValue(
                            'partCodeLabel',
                            option ? option?.itemPartCode : null
                          )
                          setPartNameSelect(option ? option : null)
                          setPartCodeSelect({
                            value: option?.value,
                            label: option?.itemPartCode
                          })
                          setUomSelect({
                            value: option?.uom?.id,
                            label: option?.uom?.name
                          })
                          setFieldValue('uom', option?.uom?.id ?? null)
                          setFieldValue('category', option?.category ?? null)
                          setFieldValue('rate', option?.rate ?? null)
                          setFieldValue('gstRates', option?.gstRate ?? null)
                          setFieldValue('amount', values?.po_qty * option?.rate)
                          setFieldValue('partCode', option?.value ?? null)
                          setFieldValue('description', option?.description)
                        }}
                      />

                      <label
                        htmlFor='partName' onClick={() => {
                          console.log(values);
                        }}
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Part Name<span className='text-danger'>*</span>
                      </label>
                      {touched.partName && errors.partName && (
                        <small className='staan-error-msg'>
                          {errors.partName}
                        </small>
                      )}
                    </div>
                    <div className='col-12 staan-input-group'>
                      <Field
                        type='textarea'
                        name='description'
                        className='w-100 staan-form-input'
                        onChange={e =>
                          setFieldValue('description', e.target.value)
                        }
                      />
                      <label
                        htmlFor='description' onClick={() => {
                          console.log(initialItemQuatation);
                        }}
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Description<span className='text-danger'>*</span>
                      </label>
                      {touched.description && errors.description && (
                        <small className='staan-error-msg'>{errors.description}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='category'
                        disabled
                        className='w-100 staan-form-input'
                      />
                      <label
                        htmlFor='category'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Category<span className='text-danger'>*</span>
                      </label>
                      {touched.category && errors.category && (
                        <small className='staan-error-msg'>
                          {errors.category}
                        </small>
                      )}

                    </div>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='po_qty'
                        className='w-100 staan-form-input'
                        onChange={e =>
                          handleQtyChange(e, setFieldValue, values.rate)
                        }
                      />
                      <label
                        htmlFor='po_qty'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        {' '}
                        Qty<span className='text-danger'>*</span>
                      </label>
                      {touched.po_qty && errors.po_qty && (
                        <small className='staan-error-msg'>
                          {errors.po_qty}
                        </small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Select
                        className='my-3'
                        styles={customSelectStyle}
                        value={UomSelect}
                        name='uom'
                      />
                      <label
                        htmlFor='uom'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        UOM<span className='text-danger'>*</span>
                      </label>
                      {touched.uom && errors.uom && (
                        <small className='staan-error-msg'>{errors.uom}</small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='rate'
                        placeholder='Enter Rate'

                        className='w-100 staan-form-input'
                        onChange={(e) => {
                          setFieldValue('rate', e?.target?.value)
                          setFieldValue('amount', Number(e?.target?.value) * Number(values?.po_qty))
                          setRateAfterDiscount(0)
                          setFieldValue('discount', isPercentage ? `0%` : `${currencyData?.currencySymbol}0`)
                          setDiscount(isPercentage ? `0%` : `${currencyData?.currencySymbol}0`)
                        }}
                      />
                      <label
                        htmlFor='rate'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Rate<span className='text-danger'>*</span>
                      </label>
                      {touched.rate && errors.rate && (
                        <small className='staan-error-msg'>{errors.rate}</small>
                      )}
                    </div>
                    <div className="col-2 staan-input-group">
                      <Field type='text' name='discount' placeholder='Enter discount'
                        value={discount} className='w-100 staan-form-input'
                        onChange={(e) => {
                          setDiscount(e.target.value)
                          // setFieldValue('discount', e.target.value)
                          let discountAmount = FinalValue(e.target.value, Number(values.rate))
                          if (discountAmount === undefined || discountAmount === "") {
                            // setFieldValue("amount", Number(values.rate * values.po_qty))
                            setAmount(Number(values.rate * values.po_qty).toFixed(3))
                            setRateAfterDiscount(0)
                            setFieldValue('rate', Number(values.rate).toFixed(2))
                          } else {
                            // setFieldValue("amount", Number(discountAmount) * Number(values.po_qty))
                            setFieldValue('rate', Number(values.rate).toFixed(2));
                            setAmount(Number(discountAmount) * Number(values.po_qty).toFixed(3))
                            setRateAfterDiscount(discountAmount)
                          }
                        }}
                        disabled={isAfterOverallDiscount ? true : values?.rate === '' ? true : values.rate === 0 ? true : false}
                      // disabled={values?.rate === '' ? true : values.rate == 0 ? true : false}
                      />
                      <label htmlFor="discount" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                        console.log("isAfterOverallDiscount", isAfterOverallDiscount, "partCodeSelect", partCodeSelect, "partNameSelect", partNameSelect);

                      }}>Discount Per Qty
                      </label>
                      <GoXCircle className='cursor-pointer ms-2' style={{ top: "-2rem", left: "5rem", position: "relative" }}
                        onClick={() => {
                          setFieldValue("amount", Number(values.rate * values.po_qty))
                          setAmount(Number(values.rate * values.po_qty).toFixed(3))
                          setDiscount("")
                          setFieldValue('discount', "")

                        }}
                      />
                      {touched.discount && errors.discount && <small>{errors.discount}</small>}

                    </div>
                    <div className='col-1 mt-3 text-center text-primary'>
                      {
                        isPercentage ?
                          <p onClick={() => {
                            setIsPercentage(false)
                            const newValue = `${currencyData?.currencySymbol}${discount.replace("%", "")}`
                            console.log(newValue);
                            setDiscount(newValue);
                            let discountAmount = FinalValue(newValue, Number(values.rate));
                            setFieldValue("amount", discountAmount);
                            setAmount(discountAmount.toFixed(3));
                            setRateAfterDiscount(Number(discountAmount / values.po_qty).toFixed(3));
                            setFieldValue('rate', String(values.rate));
                          }
                          }>
                            {currencyData?.currencySymbol}
                          </p> :
                          <AiOutlinePercentage
                            onClick={() => {
                              setIsPercentage(true);
                              const newValue = `${discount.replace(currencyData?.currencySymbol, "")}%`
                              setDiscount(newValue);
                              let discountAmount = FinalValue(newValue, Number(values.rate * values.po_qty));
                              setFieldValue("amount", discountAmount);
                              setAmount(discountAmount.toFixed(3));
                              setRateAfterDiscount(Number(discountAmount / values.po_qty).toFixed(3));
                            }}
                          />
                      }
                    </div>

                    <div className='col-3 staan-input-group'>
                      <Field type='text' name='rateAfterDiscount' disabled value={RateAfterDiscount} placeholder='Enter discount'
                        className='w-100 staan-form-input' />
                      <label htmlFor="discount" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => {
                        console.log(initialItemQuatation);
                      }}>Rate After Discount</label>
                    </div>

                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='gstRates'
                        disabled
                        className='w-100 staan-form-input'
                      />
                      <label
                        htmlFor='gstRates'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Tax<span className='text-danger'>*</span>
                      </label>
                      {touched.gstRates && errors.gstRates && (
                        <small className='staan-error-msg'>
                          {errors.gstRates}
                        </small>
                      )}
                    </div>
                    <div className='col-6 staan-input-group'>
                      <Field
                        type='text'
                        name='amount'
                        disabled
                        className='w-100 staan-form-input'
                      />
                      <label
                        htmlFor='amount'
                        className='staan-form-input-label pt-1 px-1 mx-2'
                      >
                        Amount<span className='text-danger'>*</span>
                      </label>
                      {touched.amount && errors.amount && (
                        <small className='staan-error-msg'>
                          {errors.amount}
                        </small>
                      )}
                    </div>
                    <div className='row mt-2'>
                      <div className='text-end'>
                        <button
                          type='button'
                          className='btn btn-outline-danger btn-sm me-2 '
                          onClick={() => {
                            if (setQuotationItemDetailsShow) {
                              setQuotationItemDetailsShow(false)
                            }
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-sm btn-outline-success shadow-sm px-3'
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <ItemCombo
                    IsshowItemCombo={showItemCombo}
                    setshowItemCombo={setShowItemCombo}
                    lastUpdatedItemDetails={lastUpdatedItemDetails}
                    stateValue={stateValue}
                    itemComboData={itemComboModalData}
                    setItemComboData={setItemComboModalData}
                    setItemDetailsData={setItemDetailsData}
                    setQuotationItemDetailsShow={setQuotationItemDetailsShow}
                  />
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default QuatationItemModel
