import React, { useContext, useState } from 'react';
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import DataContext from '../../context/ItemMasterContext';
import TableView from '../../Packages/Custom/DataTable';
import "../../Style/main.css";
import PosPage from './PosPage model';
const PosTable = () => {
  const {  Navigate, setPosEdit, screenSize, setPosItemData } = useContext(DataContext) 
  const [isShowPos, setIsShowPos] = useState(false)
  const [isLoad, setLoad] = useState(false)
  const CustomViewModalComponent=(data)=>{
    setPosItemData([])
    setPosEdit(data.id)
    setIsShowPos(true)
  }
  const headers = [
    // {
    //   header: 'ID',
    //   field: 'id',
    //   flex: 7,
    //   inputType: 'text',

    // },
    {
      header: 'Date',
      field: 'OrderDate',
      flex: 6.25,
      inputType: 'text',
      isDateField: true,
      renderComponent: CustomViewModalComponent,
    },
    {
      header: 'Doc No',
      field: 'POSId',
      label: "linkedModelId",
      flex: 9,
      query: 'PosIdTableFiliter',
      inputType: 'text',
      renderComponent: CustomViewModalComponent,
    },
    {
      header: 'Type',
      field: 'posType',
      label: "posType",
      flex: 5,
      query: 'Postype',
      inputType: 'text',
      renderComponent: CustomViewModalComponent,
    },
    {
      header: 'Conference',
      field: 'marketingEvent',
      label: "name",
      flex: 12,
      query: 'ConferenceNameOptionFetchQuery',
      inputType: 'text',
      renderComponent: CustomViewModalComponent,
    },
    {
      header: 'Customer',
      field: 'CosName',
      lable:"CosName",
      flex: 12,
      query: 'posCostomeNameForTableFilter',
      inputType: 'text',
      renderComponent: CustomViewModalComponent,
    },
    {
      header: 'Mobile',
      field: 'Mobile',
      lable:"Mobile",
      flex: 6.5,
      query: 'posNumberForTableFilter',
      inputType: 'text',
      renderComponent: CustomViewModalComponent,
    },
    {
      header: 'Amount',
      field: 'FinalTotalValue',
      flex: 6,
      inputType: 'number',
      renderComponent: CustomViewModalComponent,
    },
    {
      header: 'Balance',
      field: 'balanceAmount',
      flex: 6,
      inputType: 'number',
      renderComponent: CustomViewModalComponent,
    },
    {
      header: 'Status',
      field: 'status',
      label: "Status",
      flex: 6,
      option: true,
      query: 'posStatus',
      inputType: 'text',
      renderComponent: CustomViewModalComponent,
    },
    {
      header: 'Remarks',
      field: 'Remarks',
      lable:"Remarks",
      flex: 10,
      query: 'posRemorksTableFilter',
      inputType: 'text',
      renderComponent: CustomViewModalComponent,
    },
    
    {
      header: 'Pending',
      field: 'Pending',
      flex: 5,
      bool: true,
      option: true,
      inputType: 'text',
      renderComponent: CustomViewModalComponent,
    },
    {
      header: 'Deliver',
      field: 'isDelivered',
      flex: 5,
      bool: true,
      option: true,
      inputType: 'text',
      renderComponent: CustomViewModalComponent,
    },
    {
      header: 'Created By',
      field: 'createdby',
      label :"username",
      flex: 7,
      option: true,
      query: 'userOptionQuery',
      inputType: 'text',
      renderComponent: CustomViewModalComponent,
    },
    
  ]
  const CustomAddModalComponent =()=>{
    setPosEdit()
    Navigate('/pos')
  }
  
  return (
    <>
    <div className='container-fluid index_page_aliment_warp'>
      <div className='index_page_aliment_sidebar'>
        <SideNavbar/>
      </div>
      <div className='index_page_aliment_table'>
        <TableView
          headers={headers}
          query = {'PosSalesOredrTableQuery'}
          addComponent={CustomAddModalComponent}
          titleName = {'POS'}
          loadComponent={isLoad}
          heightAdjust = {`${(screenSize?.height/20)}rem`}
          permission={"Save_POS"}
          customFilter={true}
        />
      </div>
      <PosPage isShowPos={isShowPos}
      setIsShowPos = {setIsShowPos}
      setLoad = {()=>{setLoad(!isLoad)}}/>
      
    </div>
    
        
    </>
  )
}

export default PosTable