import React, { useState } from 'react';
import { SideNavbar } from "../../../components/sideNavbar/SideNavbar";
import TableView from '../../../Packages/Custom/DataTable';
import StockDeletion from "../Deletion";
import InventoryApprovals from "../InventoryApproval";


const InventoryDeletionHandler = () => {
    const [isLoad, setLoad] = useState(false);
    const [isShowInventoryApprovalModal, setShowInventoryApprovalModal] = useState(false)
    const handleShowInventoryApprovalModal = () => setShowInventoryApprovalModal(true)
    const handleCloseInventoryApprovalModal = () => {
        localStorage.setItem('inventory_approval_ids', null)
        setShowInventoryApprovalModal(false)
    }
    const [isShowStockDeletionModal, setShowStockDeletionModal] = useState(false)
    const handleShowStockDeletionModal = () => setShowStockDeletionModal(true)
    const handleCloseStockDeletionModal = () => setShowStockDeletionModal(false)
    const CustomViewModalComponent = (data) => {
        let inventory_approval_ids = data['inventoryId'].map(item => parseInt(item.id))
        localStorage.setItem('inventory_approval_ids', inventory_approval_ids)
        handleShowInventoryApprovalModal()
        setLoad(!isLoad);
    }

    const CustomAddModalComponent = () => {
        handleShowStockDeletionModal()
        // setLoad(!isLoad);
    }

    let column_details = [
        { 
            header: "Id", 
            flex: 5, 
            field: 'inventoryHandlerId',
            inputType: 'text',
            query: 'InventoryHandlerDeleteIdOptionFetchQuery',
            label: 'inventoryHandlerId',
            renderComponent: CustomViewModalComponent,
          },
          { 
            header: "Store", 
            field: "store",
            flex: 5,
            inputType: 'text',
            label: 'storeName',
            query: 'storeFetchQuery',
            option: true,
          }, 
          { 
            header: "Created By", 
            field: "savedBy",
            flex: 5,
            inputType: 'text',
            label: 'username',
            option: true,
            query: 'userOptionQuery',
          },
          { 
            header: "Created Date", 
            field: "createdAt",
            flex: 5,
            inputType: 'text',
            isDateField: true,
          },
    ]

    return(
        <>  
            <div className=' index_page_aliment_warp'>
                <div className='index_page_aliment_sidebar'>
                    <SideNavbar />
                </div>
                <div className='index_page_aliment_table'>
                    <TableView 
                            headers = {column_details}
                            query = {'InventoryDeletionHandlerMasterQuery'}
                            loadComponent = {isLoad}
                            titleName = {'Stock Deletion'}
                            heightAdjust = {'40rem'} 
                            addComponent = {CustomAddModalComponent}
                            permission={"Save_Stock_Deletion"}
                            customFilter={true}
                        />
                </div>
            </div> 
            <div className="container-lg">    
               
                {
                    isShowInventoryApprovalModal && 
                    <InventoryApprovals 
                        show = {isShowInventoryApprovalModal}
                        close = {handleCloseInventoryApprovalModal}
                    />
                }
                {
                    isShowStockDeletionModal && 
                    <StockDeletion 
                        show = {isShowStockDeletionModal}
                        close = {handleCloseStockDeletionModal}
                        setLoad = {setLoad}
                        isLoad = {isLoad}
                    />
                }
            </div>
        </>
    )
}

export default InventoryDeletionHandler;