import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer } from 'react-toastify';
import axiosInstance from '../../api/axoiss';
import { SideNavbar } from '../../components/sideNavbar/SideNavbar';
import DataContext from '../../context/ItemMasterContext';
import TableView from '../../Packages/Custom/DataTable';
import "../../Style/main.css";
import SupplierForm from '../ItemMaster/model/SupplierFrom';

const SupplierList = () => {
    const { setcontactdata, setContanctPost, setAddressPost, SupplierAdd, handleSupplierShow, screenSize , SetSupplierFiliter} =  useContext( DataContext)
    const [supplierEdit, setSupplierEdit] = useState(null)
    const [deleteData, setDeleteData] = useState([])
    const [waringModal, setWaringModal] = useState(false);
    const [componentName, setComponentName] = useState("Supplier")
    const [tableDataFetchQuery, setTableDataFetchQuery] = useState([])
    const [supplierTypeFilter, setSupplierFilterType] = useState(null)
    const currentURL = window.location.pathname;

    const handleChangeSCBOption = (value)=>{
        if(value === 'Supplier'){
            setSupplierFilterType({
                supplier: true
            })
        }else if(value === 'Both' && currentURL === '/supplier'){
            setSupplierFilterType({
                supplier: true,
                transporter:  true
            })
        }else if(value === 'Both' && currentURL === '/customer'){
            setSupplierFilterType({
                supplier: true,
                transporter:  true
            })
        }else if(value === 'Transporter'){
            setSupplierFilterType({
                transporter: true
            })
        }else{

        }
        SetSupplierFiliter(value)
    }

    const handleShowModal = ()=>{
      setWaringModal(true)
    }

    const handleCloseModal = ()=>{
      setWaringModal(false)
    }

    async function DeletaTheData(id, url, setdata) {
      const response = await axiosInstance.delete(`${url}/${id}`)
      if(response){ 
        if(setdata){
          const remove_delete_data = setdata.filter(data=> data.id !== deleteData.id)
          console.log(remove_delete_data);
        }
      }
    
    
    }
    
    const waringToDelete = ()=>{
        const ConformTodete = async ()=>{
          let supplierid = deleteData.id
          let supplierContectId = deleteData.contact
          let SupplierAddressId = deleteData.contact
          DeletaTheData(supplierid,"/itemmaster/Contact", setcontactdata)
          for (let key in supplierContectId) {
            DeletaTheData(key,"/itemmaster/Contact", setContanctPost)
          }  
          for (let key in SupplierAddressId) {
            DeletaTheData(key,"/itemmaster/caddress", setAddressPost)
          }     
          
        }
        const canceltheDeleteOptions =()=>{
          setDeleteData()
          handleCloseModal()
        }
      return(
        <Modal
            show={waringModal}
            onHide={handleCloseModal}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
            <Modal.Title>Waring To Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                I will not close if you click outside me. Do not even try to press
                escape key.
            </Modal.Body>
            <Modal.Footer>
                <button type="button" onClick={ConformTodete} className="btn btn-outline-success">Yes</button>
                <button type="button" onClick={canceltheDeleteOptions} className="btn btn-outline-danger">No</button>
            </Modal.Footer>
        </Modal>
      )
    }

    const [isLoad, setLoad] = useState(false);
    const CustomViewModalComponent = (data) => {
        handleSupplierShow()
        setSupplierEdit(data)
    }

    const CustomAddModalComponent = () => {
        handleSupplierShow()
    }

    let column_details = [
        { 
            header: "Company Name", 
            flex: 10, 
            field: 'companyName',
            inputType: 'text',
            query: 'SupplierFormCompanyOptionFetchQuery',
            renderComponent: CustomViewModalComponent,
        },
        { 
            header: "Legal Name", 
            field: "legalName",
            flex: 10,
            inputType: 'text',
            query: 'SupplierFormLegalOptionFetchQuery',
        },
        { 
            header: "GSTIN", 
            field: "gstin",
            flex: 8,
            inputType: 'text',
            query: 'SupplierFormGSTINOptionFetchQuery',
        },
        { 
            header: "Pan No", 
            field: "panNo",
            flex: 8,
            inputType: 'text',
            query: 'SupplierFormPanNumberOptionFetchQuery',
        },
        { 
            header: "Person Name", 
            field: "contact",
            flex: 10,
            inputType: 'text',
            label: 'contactPersonName',
            customFilterField: 'contactPersonName',
        },
        { 
            header: "Mobile Number", 
            field: "contact",
            flex: 8,
            inputType: 'text',
            label: 'phoneNumber',
            customFilterField: 'phoneNumber',
        },
        { 
            header: "Address Type", 
            field: "address",
            flex: 10,
            inputType: 'text',
            label: 'addressType',
            customFilterField: 'addressType',
        },
        { 
            header: "City", 
            field: "address",
            flex: 8,
            inputType: 'text',
            label: 'city',
            customFilterField: 'city',
        },
        { 
            header: "State", 
            field: "address",
            flex: 8,
            inputType: 'text',
            label: 'state',
            customFilterField: 'state',
        },
        { 
            header: "Country", 
            field: "address",
            flex: 8,
            inputType: 'text',
            label: 'country',
            customFilterField: 'country',
        },
    ]


    useEffect(()=>{
        let component_name = currentURL === "/customer"? "Customer" : currentURL === "/supplier" ? "Supplier" : "Supplier & Customer"
        if(component_name === 'Customer'){
            setTableDataFetchQuery(['CustomerDataFetch', 'Save_Customer'])
        }else{
            setTableDataFetchQuery(['SupplierDataFetch', 'Save_Supplier'])
        }
        setComponentName(component_name)
    }, [])

    return(
        <>
            <ToastContainer />
            <waringToDelete />
            <SideNavbar  />
            <div id="custom-table-supplier">
                <div style={{width: '90%'}} className='mx-auto'>
                    {/* <div style={{width: '97%'}} className="row ps-3 bg-white mx-auto mt-2 d-flex align-items-center">
                        <div className="col-3">
                            <span>Supplier Type</span>
                        </div>
                        <div className="col-6">
                            <div className="row mt-2">
                                <div className="col-4">
                                    <label htmlFor="all" className='pe-2'>Both</label>
                                    <input type="radio" name="Filiter"  checked={ supplierFiliter === "Both"} onClick={()=>{handleChangeSCBOption('Both')}}  id="" />
                                </div>
                                {currentURL === "/supplier"? 
                                <div className="col-4">
                                    <label htmlFor="all" className='pe-2'>Supplier</label>
                                    <input type="radio" name="Filiter"   checked={ supplierFiliter === "Supplier"} onClick={()=>{handleChangeSCBOption('Supplier')}} />
                                </div> : currentURL === "/customer" ?  <div className="col-4">
                                    <label htmlFor="all" className='pe-2'>Customer</label>
                                    <input type="radio" name="Filiter"   checked={ supplierFiliter === "Customer"} onClick={()=>{handleChangeSCBOption('Customer')}} />
                                </div> : "" } 
                                <div className="col-4">
                                    <label htmlFor="Transporter" className='pe-2'>Transporter</label>
                                    <input type="radio" name="Filiter"    checked={ supplierFiliter === "Transporter"} onClick={()=>{handleChangeSCBOption('Transporter')}}   />
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
                <div>
                    <div id="custom-table-itemmaster">
                        {tableDataFetchQuery.length > 0 && <TableView
                            headers = {column_details}
                            query = {tableDataFetchQuery[0]}
                            loadComponent = {isLoad}
                            titleName = {componentName}
                            permission={tableDataFetchQuery[1]}
                            heightAdjust = {`${(screenSize?.height/20)}rem`}
                            addComponent = {CustomAddModalComponent}
                            customFilter={true}
                        />}
                    </div>
                </div>
                {SupplierAdd && 
                    <SupplierForm
                        supplierEdit ={supplierEdit}
                        setSupplierEdit = {setSupplierEdit}
                        isLoad = {isLoad}
                        setLoad = {setLoad}
                    />
                }
            </div>
        </>
    )
}

export default SupplierList;