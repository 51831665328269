export const categoryEditQuery = (data) => {
  // id: 6
  return `
    query MyQuery { 

  allExpenseCategories(${data}) { 

    items { 

      active 

      expenseCategoryName 
      accountName{
        accountsName
        id
      }

      id 
      createdBy{
        id
      }
        modifiedBy{
        id
      }

    } 

    pageInfo { 

      hasNextPage 

      hasPreviousPage 

      totalPages 

    } 

  } 

} 
    `
}

export const categoryOptionQuery = (data) => {
  // id: 6
  return `
  query MyQuery { 
  allExpenseCategories(${data}) { 
    items { 
      id
      expenseCategoryName
      active
      accountName{
        gstApplicable
      }
    } 
  } 
} 
   
`
}

export const expenseClaimEditQuery = (data) => {
  return `
query MyQuery {
  allExpenseClaim(${data}) {
    items {
      id
      status{
        id
        name
      }
      createdBy {
        id
        username
      }
      modifiedBy{
        id
        username
      }
      employeeId {
        id
        employeeId
        employeeName
        userProfile{
          id
          image
        }
      }
      expenseClaimDate
      expenseClaimNo
      id
      totalApprovedAmount
      reimburseAmount
      balanceAmount
      
      expenseClaimDetails {
        claimAmount
        approvedAmount
        dateOfExp
        descriptions
        gstIn
        id
        pdfUrl{
          id
          documentFile
          isDelete
        }
        expenseCategories {
          id
          expenseCategoryName
        }
      }
      status {
        id
        name
      }
    }
  }
}
`

}

export const voucherEditQuery = (data) => {
  return `
  query MyQuery { 

  allPaymentVoucher(${data}) { 

    items { 

            advance
      againstInvoice
      advanceDetails{
        id
        advRemark
        amount
        createdAt
        createdBy{
          id 
          username
        }
        modifiedBy{
          id
          username
        }
        
      }
      bank {
        accountType
        accountsName
        id
      }
      chqDate
      chqRefNo
      cusSupAmount
      createdAt
      createdBy {
        id
        username
      }
      cusSupId{
        id
        supplierNo
        companyName
      }
      date
      empAmount
      employeeId{
        id
        employeeName
        employeeId
      }
      expenseRequestId{
        id
        expenseRequestNo
      }
      id
      modifiedBy {
        id
        username
      }
      payFor {
        accountType
        accountsName
        id
      }
      payMode {
        id
        name
      }
      payTo {
        id
        name
      }
      paymentVoucherNo {
        id
        linkedModelId
      }
      transferVia {
        id
        name
      }
      status{
      id
      name
    }

    } 

  } 

} 
  `
}

export const expenseReconciliationEditQuery = (data) => {
  return `
  mutation MyMutation {
  expenseReconciliationCallFormExpenseClaim(${data}) {
    errors
    success
    PaymentVoucherInstence {
      id
      date
      empAmount
      expenseRequestId{
        expenseRequestNo
      }
      paymentVoucherNo{
        id
      }
      employeeId {
        employeeId
        employeeName
        id
      }
      createdBy{
        id
      }
      modifiedBy{
        id
      }
    }
  }
}
  
  `
}