import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { commanFetch } from "../../../api/axoiss";
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import { discountClearAllQuery, discountFinalQuery, discountOverallPercentage, discountValueItemQuery } from "../../../GraphQLQueries/QuatationqueryAndMutatiom/quatationQuery";
import { ShowErrorNoties } from '../../../utils/Utils';
import BallTriangleLoading from "../../../components/loading/BallTriangle";

const Discount = ({ setShowDiscount, showDiscount, editView, setIsOverAllDiscountApplied, setItemDetailsData, itemDetailsData,
  otherIncomeChargesData, setOtherIncomeChargesData, quotationStatus,
  setAfterOverallDiscount, isAfterOverallDiscount, overallDiscountPercentage,
  setOverallDiscountPercentage, setOverallDiscountValue, overallDiscountValue, setFinalTotal, finalTotal,toSaveData,setCheckedDiscount}) => {
  const [isDisabled, setIsDisabled] = useState(false); // New state variable
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (overallDiscountPercentage || overallDiscountValue || finalTotal) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [quotationStatus, editView]);

  // const [overallDiscountPercentage, setOverallDiscountPercentage] = useState('');
  // const [overallDiscountValue, setOverallDiscountValue] = useState('');
  // const [finalTotal, setFinalTotal] = useState('');
  const [discountError, setdiscountError] = useState('');



  // Function to handle changes to the discount percentage input
  const handlediscountpercentage = (value) => {
    setOverallDiscountPercentage(value);
    setOverallDiscountValue('');
    setFinalTotal('');
  };

  const handlediscountValue = (value) => {
    setOverallDiscountValue(value);
    setOverallDiscountPercentage('');
    setFinalTotal('');
  };

  const handleDiscountTotalValue = (value) => {
    setFinalTotal(value);
    setOverallDiscountPercentage('');
    setOverallDiscountValue('');
  };

  // Function to update discount data in the backend and refresh local data
  const updateDiscountData = async (requestBody, queryFunc) => {
    try {
      setLoading(true)
      const response = await commanFetch(queryFunc(requestBody));
      const responseData = response?.data || [];
      if (responseData?.items.length > 0) {
        const updatedOtherIncomeChargesData = otherIncomeChargesData?.map(charge => {
          const updatedCharge = responseData?.quotationsOtherIncomeCharges.find(updated => updated.id === charge.id);
          return updatedCharge ? {
            ...charge,
            ...updatedCharge
          } : charge;
        });
        setItemDetailsData(responseData?.items);
        setOtherIncomeChargesData(updatedOtherIncomeChargesData);
        toast.success(`Discount Applied Successfully.`, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        });
        setdiscountError('');
        handleCloseDisCountModel();
        setIsOverAllDiscountApplied(true)
        setCheckedDiscount(true)
        setAfterOverallDiscount(true);

      } else {
        setdiscountError("Unable To Set The Discount!!!");
      }
      setLoading(false)
    } catch (error) {
      console.log(error);
      
      let errorData = ShowErrorNoties(error);
      showErrorToast(true, 'error', errorData);
    }
  };

  // Function to handle overall discount calculation based on the selected type (value, total, percentage)
  const discountOverall = async () => {

    const ids = itemDetailsData?.map(item => parseInt(item.id, 10));
    const otherIncomeIds = otherIncomeChargesData?.map(item => parseInt(item.id, 10));
    // Check which discount value is being used and prepare the request body
    if (overallDiscountValue) {
      const requestBody = {
        ids: [...ids],
        otherIncomeId: [...otherIncomeIds],
        value: overallDiscountValue,
        totalValue: String(toSaveData?.netAmount)
      };

      await updateDiscountData(requestBody, discountValueItemQuery);
    }
    else if (finalTotal) {
      const requestBody = {
        ids: [...ids],
        otherIncomeId: [...otherIncomeIds],
        value: String(finalTotal),
        TotalWithTaxValue: String(toSaveData?.netAmount)
      };
      await updateDiscountData(requestBody, discountFinalQuery);
    }
    else if (overallDiscountPercentage) {
      const requestBody = {
        ids: [...ids],
        otherIncomeId: [...otherIncomeIds],
        value: String(overallDiscountPercentage)
      };
      await updateDiscountData(requestBody, discountOverallPercentage);
    }

  };

  // Function to clear all discount values and reset the data
  const handleClearAll = async () => {
    if (setLoading){
      setLoading(true)
    }
    
    setOverallDiscountPercentage(0);
    setOverallDiscountValue(0);
    setFinalTotal(0);
    const ids = itemDetailsData?.map(item => parseInt(item.id, 10));
    const otherIncomeIds = otherIncomeChargesData?.map(item => parseInt(item.id, 10));

    const requestBody = {
      ids: [...ids],
      otherIncomeId: [...otherIncomeIds],
    };
    const response = await commanFetch(discountClearAllQuery(requestBody));
    const responseData = response?.data || [];
    if (responseData?.items.length > 0) {
      const updatedItemDetailsData = itemDetailsData?.map(item => {
        const updatedItem = responseData?.items?.find(updated => updated.id === item.id);
        return updatedItem ? {
          ...item,
          ...updatedItem
        } : item;
      });
      setItemDetailsData(updatedItemDetailsData);
    };
    const updatedOtherIncomeChargesData = otherIncomeChargesData?.map(charge => {
      const updatedCharge = responseData.quotationsOtherIncomeCharges.find(updated => updated.id === charge.id);
      return updatedCharge ? {
        ...charge,
        ...updatedCharge
      } : charge;
    });
    setOtherIncomeChargesData(updatedOtherIncomeChargesData);
    setdiscountError('');
    setIsOverAllDiscountApplied(false)
    setAfterOverallDiscount(false);
    setCheckedDiscount(true)
    setIsDisabled(false)
   
    if (setLoading){
      setLoading(false)
    }
  }


  // Function to close the discount model
  const handleCloseDisCountModel = () => {
    setShowDiscount(false)

  }
  return (
    <>
    {<BallTriangleLoading isshow={loading} />}
      <Modal show={showDiscount} onHide={handleCloseDisCountModel} backdrop="static" size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5  >Discount</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row mx-1 '>
            <div className='col-12'>
              <div className='row'>
                <div className='col-12 text-center'>
                  <p className='text-danger'>{discountError}</p>
                </div>
              </div>

              <div className='row'>
                <div className='col-4 staan-input-group'>
                  <input
                    type='number'
                    value={overallDiscountPercentage}
                    onChange={e => {
                      const value = e.target.value;
                      if (value >= 0 || value === "") {
                        handlediscountpercentage(value);
                      }
                    }}
                    disabled={overallDiscountValue ? true : finalTotal ? true : false || isDisabled}
                    className='staan-form-input text-end pe-3'
                  />
                  <label
                    htmlFor='OverallDiscountPercentage'
                    className='staan-form-input-label pt-1 px-1 mx-2 fw-bold'
                    onClick={() => {
                      console.log(quotationStatus);
                    }}
                  >
                    Overall Discount Percentage %
                  </label>
                </div>
                <div className='col-4 staan-input-group'>
                  <input
                    type='number'
                    value={overallDiscountValue}
                    disabled={overallDiscountPercentage ? true : finalTotal ? true : false || isDisabled}
                    onChange={e => {
                      const value = e.target.value;
                      if (value >= 0 || value === "") {
                        handlediscountValue(value);
                      }
                    }}
                    className='staan-form-input text-end pe-3'
                  />
                  <label
                    htmlFor='OverallDiscountValue'
                    className='staan-form-input-label pt-1 px-1 mx-2 fw-bold'
                  >
                    Overall Discount Value
                  </label>
                </div>
                <div className='col-4 staan-input-group'>
                  <input
                    type='number'
                    value={finalTotal}
                    disabled={overallDiscountPercentage ? true : overallDiscountValue ? true : false || isDisabled}
                    onChange={e => {
                      const value = e.target.value;
                      if (value >= 0 || value === "") {
                        handleDiscountTotalValue(value);
                      }
                    }}
                    className='staan-form-input text-end pe-3'
                  />
                  <label
                    htmlFor='FinalTotal'
                    className='staan-form-input-label pt-1 px-1 mx-2 fw-bold'
                  >
                    Final Total
                  </label>
                </div>
              </div>
              <div className='d-flex justify-content-center align-items-center mb-2'>
                <div>
                  <button
                    type='button'
                    className='btn btn-sm btn-outline-danger mt-3 me-2 px-5'
                    onClick={handleClearAll}
                    disabled={!isDisabled}
                  >
                    Clear
                  </button>
                  <button
                    type='button'
                    className='btn btn-sm btn-outline-success mt-3 ms-3'
                    disabled={isDisabled}
                    onClick={discountOverall}
                  >
                    Apply Discount
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Discount