import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { TbHistory } from "react-icons/tb";
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Bounce, toast } from 'react-toastify';
import { otherExpensesCreateMutations, otherExpensesDeleteMutations } from '../../../GraphQLQueries/HrMutation';
import { OtherExpensesGetWithId } from '../../../GraphQLQueries/HrQuery';
import * as QueryData from "../../../GraphQLQueries/ItemmasterQuery";
import { fetchOptionsData } from '../../../ReduxState/Action/itemMasterAction';
import axiosInstance from '../../../api/axoiss';
import Can from '../../../components/authorizationComponents/Can';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import DataContext from '../../../context/ItemMasterContext';
import { ShowErrorNoties, customSelectStyle, removeEmptyValueInObject } from '../../../utils/Utils';
import { otherExpensesSchema } from '../../../validations/Hr';
import HistoryData from '../../ItemMaster/model/HistoryData';

const OtherExpensesModel = ({ otherExpensesIsShowing, setOtherExpensesIsShowing,
    setLoad, otherExpensesEdit, setOtherExpensesEdit }) => {
    const { userId, historyIds, setHistoryIds, handltoastDeleteConfomationShow } = useContext(DataContext)
    const [initialOtherExpenses, setInitialOtherExpenses] = useState({
        id: "",
        name: "",
        account: "",
        HSN: "",
        HistoryDetails: "",
        modified_by: "",
        createdBy: "",
        effectiveDate: '',
        isActive:true

    })
    const [formKey, setFormKey] = useState(0);
    const [otherExpensesEditData, setOtherExpensesEditData] = useState(null)
    const [deleteData, setDeleteData] = useState({ Name: "", id: "" })
    const [edit, setEdit] = useState(true)
    const dispatch = useDispatch();
    const OptionsDataList = useSelector(state => state.itemGroup.data);
    const [accountList, setAccountList] = useState([])
    const [hsnList, setHsnList] = useState([])
    const [selectAccount, setSelectAccount] = useState({ value: "", label: "" })
    const [selectHsn, setSelectHsn] = useState({ value: "", label: "" })
    const [isShoweffectiveDate, setShoweffectiveDate] = useState(true)

    // retun null  ; save department
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        let save_data = {
            id: otherExpensesEdit !== null ? Number(initialOtherExpenses.id) : "",
            name: values?.['name'],
            account: values?.['account'] ? Number(values?.['account']) : "",
            HSN: values?.['HSN'] ? Number(values?.['HSN']) : "",
            createdBy: otherExpensesEdit !== null ? Number(initialOtherExpenses?.createdBy) : Number(userId),
            modifiedBy: otherExpensesEdit !== null ? Number(userId) : "",
            effectiveDate:values?.effectiveDate ? values?.effectiveDate : '',
            active:values?.isActive
        }
        let allow_null = ['id', "modifiedBy", "HSN","effectiveDate"]
        let clear_data = removeEmptyValueInObject(save_data, allow_null)
        try {
            const response = await axiosInstance.post("/itemmaster/graphql", { query: otherExpensesCreateMutations(clear_data) })
            if (response.data.data.otherExpensesCreateMutation.success) {
                resetForm()
                setLoad()
                setEdit(false)
                close()
                toast.success('Successfully Saved', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            } else {
                let errorData = ShowErrorNoties(response.data.data.otherExpensesCreateMutation.errors)
                toast.error(errorData, {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }

        } catch (error) {
            let errorData = ShowErrorNoties(error)
            toast.error(errorData, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    }
    // retun null; to fetch options
    const getOption = (e, query, name) => {
        let search_term = e.trim().replace(/"/g, '\\"');
        if (query === "accountsMasterForPosOptionQuery" && search_term) {
            let filter_data = `accountsType : "Expenses" , accountsName :"${search_term}"`
            dispatch(fetchOptionsData(QueryData.accountsMasterForPosOptionQuery(filter_data), "accountsMaster"))
        }
        if (query === "HsnOptionQueryWithFiliter" && search_term) {
            let filter_data = `hsnCode : ${search_term}`
            dispatch(fetchOptionsData(QueryData.HsnOptionQueryWithFiliter(filter_data), "HsnOptionQuery"))
        }
    }
    useEffect(() => {
        if (OptionsDataList.name === "accountsMaster") {
            setAccountList(OptionsDataList?.value?.items?.map((item) => ({ value: item?.id, label: item?.accountsName })))
        } else {
            setAccountList([])
        }
        if (OptionsDataList.name === "HsnOptionQuery") {

            setHsnList(OptionsDataList?.value?.items?.map((item) => ({ value: item?.id, label: item?.hsnCode })))
        } else {
            setHsnList([])
        }
        if (OptionsDataList.name === "OtherExpensesEditId") {
            setOtherExpensesEditData(OptionsDataList?.value?.items?.[0])
        }
    }, [OptionsDataList])
    // retun object; before close check changes
    function close() {
        setInitialOtherExpenses({
            id: "",
            name: "",
            HSN: "",
            account: "",
            HistoryDetails: "",
            createdBy: "",
            modified_by: "",
            effectiveDate: ''
        })
        setSelectHsn({ value: "", label: "" })
        setSelectAccount({ value: "", label: "" })
        setEdit(false)
        setOtherExpensesIsShowing(false)
        setOtherExpensesEditData(null)
        setOtherExpensesEdit(null)
    }
    function fetch(id) {
        dispatch(fetchOptionsData(OtherExpensesGetWithId(id), "OtherExpensesEditId"))
    }

    useEffect(() => {
        if (otherExpensesEdit !== null) {
            setEdit(true)
            fetch(otherExpensesEdit?.id)
        } else {
            setOtherExpensesEditData(null)
            setOtherExpensesEdit(null)
            setInitialOtherExpenses({
                id: "",
                name: "",
                HSN: "",
                account: "",
                HistoryDetails: "",
                createdBy: "",
                modified_by: "",
                effectiveDate: '',
                isActive:true
            })
            setSelectHsn({ value: "", label: "" })
            setSelectAccount({ value: "", label: "" })

        }
    }, [otherExpensesEdit])

    useEffect(() => {
        if (otherExpensesEditData !== null) {
            initialOtherExpenses.id = otherExpensesEditData?.id
            initialOtherExpenses.name = otherExpensesEditData?.name
            if (otherExpensesEdit?.HSN?.id) {
                initialOtherExpenses.HSN = otherExpensesEdit?.HSN?.id
                setSelectHsn({ value: otherExpensesEdit?.HSN?.id, label: otherExpensesEdit?.HSN?.hsnCode })
            }
            initialOtherExpenses.account = otherExpensesEditData?.account?.id
            if (initialOtherExpenses.account) {
                setSelectAccount({ value: otherExpensesEditData?.account?.id, label: otherExpensesEditData?.account?.accountsName })
            }
            initialOtherExpenses.isActive = otherExpensesEditData?.active
            initialOtherExpenses.createdBy = otherExpensesEditData?.createdBy?.id
            initialOtherExpenses.HistoryDetails = otherExpensesEditData?.historyDetails
            setFormKey(prev=>prev+1)
        }
    }, [otherExpensesEditData])
    const handledeleteConfomation = (data) => {
        handltoastDeleteConfomationShow();
        setDeleteData({ Name: data?.name, id: data?.id });
    }
    const handleDeletedId = (deleted_id) => {
        if (deleted_id) {
            setOtherExpensesIsShowing(false)
            setEdit(false)
            setLoad()
            close()
        }
    }
    return (
        <>
            <HistoryData
                historyIds={historyIds}
                setHistoryIds={setHistoryIds} />
            <Modal show={otherExpensesIsShowing} size='lg'>
                <div className='row mx-1'>
                    <div className="col-5 m-3" style={{ color: "#5CADA9" }}>
                        <Modal.Title>
                            <i className='fa-solid fa-arrow-left fa-sm me-2'
                                onClick={close}
                            >
                            </i> Other Expenses
                        </Modal.Title>
                    </div>
                    <div className="col-6 mt-4 text-end" >
                        <Can I={"Edit"} a={"Other_Expenses"}>
                            <i className="fa-solid fa-pen fs-5 text-primary pe-3"
                                hidden={!(otherExpensesEdit && edit)}
                                onClick={() => { setEdit(!edit) }}  ></i>
                        </Can>
                        <Can I={"Delete"} a={"Other_Expenses"}>
                            <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  "
                                hidden={!(otherExpensesEdit && edit)}
                                onClick={() => { handledeleteConfomation(otherExpensesEdit) }}
                            ></i>
                        </Can>
                        <Can I={"Historys"} a={"Other_Expenses"}>
                            <TbHistory type="button" className='fs-4 ms-3' data-bs-toggle="offcanvas"
                                hidden={!(otherExpensesEdit && edit)}
                                data-bs-target="#History"
                                onClick={() => { setHistoryIds(initialOtherExpenses.HistoryDetails) }}
                            />
                        </Can>



                    </div>

                    <hr />
                    <Modal.Body>
                        <Formik initialValues={initialOtherExpenses}
                            validationSchema={otherExpensesSchema}
                            onSubmit={handleSubmit} key={formKey}>
                            {({ errors, touched, isSubmitting, setFieldValue, values }) => {
                                return (
                                    <Form>
                                        <div className="row">
                                            <div className="col-6 staan-input-group">
                                                <Field type="text" name="name" disabled={(otherExpensesEdit && edit)} placeholder="Name" className='w-100 staan-form-input' />
                                                <label htmlFor="Name" className='staan-form-input-label pt-1 px-1 mx-2' onClick={() => { console.log(initialOtherExpenses); }} >Name</label>
                                                {touched.name && errors.name && <small className='text-danger' >{errors.name}</small>}
                                            </div>
                                            <div className="col-6 staan-input-group">
                                                <Select
                                                    onInputChange={(e) => { getOption(e, "accountsMasterForPosOptionQuery") }}
                                                    name="account"
                                                    isClearable
                                                    isDisabled={(otherExpensesEdit && edit)}
                                                    options={accountList}
                                                    isSearchable={true}
                                                    value={selectAccount}
                                                    onChange={(option) => {
                                                        setFieldValue('account', option ? option?.value : null);
                                                        setSelectAccount(option)
                                                    }}
                                                    styles={customSelectStyle}
                                                    className='my-3'
                                                />
                                                <label htmlFor="account" className='staan-form-input-label pt-1 px-1 mx-2' onClick={()=>{console.log(values);
                                                }}>
                                                    Account </label>
                                                {touched.account && errors.account && <small>{errors.account}</small>}
                                            </div>
                                            <div className="col-6 staan-input-group">
                                                <Select
                                                    onInputChange={(e) => { getOption(e, "HsnOptionQueryWithFiliter") }}
                                                    name="HSN"
                                                    isClearable
                                                    isDisabled={(otherExpensesEdit && edit)}
                                                    options={hsnList}
                                                    isSearchable={true}
                                                    value={selectHsn}
                                                    onChange={(option) => {
                                                        setFieldValue('HSN', option ? option?.value : null);
                                                        setSelectHsn(option)
                                                        if (otherExpensesEdit?.id) {
                                                            setShoweffectiveDate(false)
                                                        }
                                                    }}
                                                    styles={customSelectStyle}
                                                    className='my-3'
                                                />
                                                <label htmlFor="HSN" className='staan-form-input-label pt-1 px-1 mx-2'>
                                                    HSN </label>
                                                {touched.HSN && errors.HSN && <small>{errors.HSN}</small>}
                                            </div>
                                            <div className='col-6 staan-input-group' hidden={isShoweffectiveDate}>
                                                <Field
                                                    type='date'
                                                    name='effectiveDate'
                                                    value={values.effectiveDate}
                                                    disabled={otherExpensesEdit && edit}
                                                    onChange={e => {
                                                        setFieldValue('effectiveDate', e.target.value)
                                                        // setInitialOtherIncomeCharges(prev => ({
                                                        //   ...prev,
                                                        //   effectiveDate: e.target.value
                                                        // }))
                                                    }}
                                                    className='w-100 staan-form-input'
                                                />
                                                <label
                                                    htmlFor='effectiveDate' onClick={() => {
                                                        console.log(values);
                                                    }}
                                                    className='staan-form-input-label pt-1 px-1 mx-2'
                                                >
                                                    Effective Date{' '}<span className='text-danger'>*</span>
                                                </label>
                                                {touched.effectiveDate && errors.effectiveDate && (
                                                    <small>{errors.effectiveDate}</small>
                                                )}
                                            </div>
                                            <div className='col-6 staan-input-group mt-4'>
                                                <Field
                                                    type="checkbox"
                                                    name="isActive"
                                                    disabled={otherExpensesEdit && edit}
                                                    className="me-2"
                                                />
                                                <span>Active</span>
                                                </div>
                                            <div className="row">
                                                <div className="text-end">
                                                    <button type="button" className="btn btn-outline-danger btn-sm me-2"
                                                        onClick={close}
                                                    >Cancel</button>
                                                    <Can allow_list={["Save_Other_Expenses", "Edit_Other_Expenses"]}>
                                                        <button type="submit" className="btn btn-sm btn-outline-success shadow-sm px-3" disabled={isSubmitting} >Save</button>
                                                    </Can>

                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </Modal.Body>

                </div>
            </Modal>
            {deleteData.Name && <DeleteConformation
                handleDeletedId={(id) => { handleDeletedId(id) }}
                deleteData={deleteData}
                fullquery={otherExpensesDeleteMutations(deleteData?.id)}
            />
            }

        </>
    )
}

export default OtherExpensesModel