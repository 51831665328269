import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ExpenceReconciliationValidation, reconvalidation } from '../../../validations/ExpenceValidation';
import BallTriangleLoading from "../../../components/loading/BallTriangle";
import { expenseReconciliationEditQuery } from '../../../GraphQLQueries/ExpenseQueryandMutation/expenseQuery';
import axiosInstance from '../../../api/axoiss';
import { removeEmptyValueInObject, ShowErrorNoties } from '../../../utils/Utils';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import DataContext from '../../../context/ItemMasterContext';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import { reconsiliationCreateMutation, reconsiliationTableCreateMutation } from '../../../GraphQLQueries/ExpenseQueryandMutation/expenseMutation';

const ExpenseReconciliationModal = ({ isReconciliationShow, setReconciliationShow, claimFormData, setclaimFormData }) => {
    const [initialExpenseReconciliation, setinitialExpenseReconciliation] = useState({
        employeeID: '',
        employeeName: '',
        advanceAmount: '',
        claimAmount: ''
    })
    const { userId, prefetchValue, handltoastDeleteConfomationShow } = useContext(DataContext)

    const [IsReconciliationModalShow, setReconciliationModalShow] = useState(false)
    const [reconValue, setreconValue] = useState({ editIndex: '', id: "", createdBy: '', expenseRequestno: '', advanceAmount: '', adjustedAmount: '' })
    const [reconinitialValue, setreconinitialValue] = useState({
        id: '',
        advRcvdDate: '',
        expenseRequestno: '',
        advAmount: '',
        adjustedAmount: ''
    })
    const [reconformKey, setreconformKey] = useState(0)
    const [expenseReconciliationFormKey, setexpenseReconciliationFormKey] = useState(0)
    const [autoAdjust, setAutoAdjust] = useState(false);
    const [autoAdjustChecked, setautoAdjustChecked] = useState(false)
    const [reconciliationTableData, setreconciliationTableData] = useState([]);
    const [previousAdjustedAmounts, setPreviousAdjustedAmounts] = useState([]);
    const [editView, seteditView] = useState(false)
    const [loading, setLoading] = useState(false)
    const [deleteData, setdeleteData] = useState({ Name: '', id: '' })



    useEffect(() => {
        if (isReconciliationShow && claimFormData !== null && claimFormData?.id) {
            fetchEditData(claimFormData?.id)
            seteditView(true)
        }
    }, [claimFormData])

    async function fetchEditData(id) {
        setLoading(true)
        try {
            const response = await axiosInstance.post(`/itemmaster/graphql`, { query: expenseReconciliationEditQuery(`employeeId:${id}`) });
            const reponseData = response?.data?.data?.expenseReconciliationCallFormExpenseClaim
            if (reponseData?.success) {
                console.log("reponseData", reponseData);
                const resData = reponseData?.PaymentVoucherInstence
                const totaladvanceAmount = resData?.reduce((acc, item) => acc + parseFloat(Number(item.empAmount) || 0), 0);
                setinitialExpenseReconciliation({
                    employeeID: resData?.[0]?.employeeId?.employeeId,
                    employeeName: resData?.[0]?.employeeId?.employeeName,
                    advanceAmount: totaladvanceAmount,
                    claimAmount: ''
                })
                setreconciliationTableData(resData.map((item, index) => ({
                    index: index + 1,
                    date: item?.date,
                    expenseRequestNo: item?.expenseRequestId?.expenseRequestNo,
                    advAmount: item?.empAmount,
                    createdBy: item?.createdBy?.id,
                    modifiedBy: item?.modifiedBy?.id,
                    paymentVoucherId: item?.id
                })))


            }
        } catch (error) {

            let errorData = ShowErrorNoties(error)
            showErrorToast(true, 'error', errorData)
        }
        setLoading(false)
    }

    const handleAutoAdjust = (values) => {
        if (values) {
            let remainingClaimAmount = Number(values?.claimAmount);
            const previousAmounts = reconciliationTableData?.map(item => item.adjustedAmount);
            setPreviousAdjustedAmounts(previousAmounts); // Store previous adjusted amounts
            // Iterate over the reconciliationTableData to adjust the amounts
            const updatedData = reconciliationTableData?.map((item) => {
                let newAdjustedAmount = 0;
                // If remainingClaimAmount is greater than 0, proceed with adjustment
                if (remainingClaimAmount >= 0) {
                    if (Number(item?.advAmount) <= remainingClaimAmount) {
                        // If the advanceAmount is less than or equal to the remaining claim amount, set adjustedAmount to advanceAmount
                        newAdjustedAmount = Number(item?.advAmount);
                        remainingClaimAmount -= item.advAmount;  // Decrease the remaining claimAmount
                    } else {
                        // If the advanceAmount is greater than the remaining claim amount, set adjustedAmount to the remaining claimAmount
                        newAdjustedAmount = remainingClaimAmount;
                        remainingClaimAmount = 0;  // After this, the remaining claimAmount is 0
                    }
                    console.log("newAdjustedAmount", newAdjustedAmount);

                }
                // If the claim amount has been exhausted, the adjustedAmount will be set to 0 for the remaining items
                if (remainingClaimAmount < 0) {
                    newAdjustedAmount = 0;
                }
                // Return the updated item with the new adjustedAmount
                return {
                    ...item,
                    adjustedAmount: newAdjustedAmount
                };
            });
            console.log("remainingClaimAmount", remainingClaimAmount);
            // Update the state with the new adjusted data
            setreconciliationTableData(updatedData);
            console.log('Updated mock data after adjustment:', updatedData);
            setautoAdjustChecked(true)
        }
    };
    const handlePreviousAutoAdjust = () => {
        const updatedData = reconciliationTableData?.map((item, index) => {
            return {
                ...item,
                adjustedAmount: ''
            };
        });

        setreconciliationTableData(updatedData);
        setautoAdjustChecked(false);
    };
    const ReconciliationTable = ({ values }) => {
        // Define mock data for the table
        const handleReconSave = async (rowIndex, item, values) => {
            setLoading(true)
            console.log("values", values);
            console.log("item", item);

            let reqBody = {
                adjustedAmount: values?.adjustedAmount ? values?.adjustedAmount : '',
                createdBy: item?.createdBy ? Number(item?.createdBy) : Number(userId),
                id: item?.id ? item?.id : '',
                modifiedBy: item?.modifiedBy ? Number(item?.modifiedBy) : '',
                paymentVoucherId: item?.paymentVoucherId ? Number(item?.paymentVoucherId) : '',
            }
            let ClearValue = removeEmptyValueInObject(reqBody, ['id', 'modifiedBy'])
            try {
                const response = await axiosInstance.post(`/itemmaster/graphql`, {
                    query: reconsiliationTableCreateMutation(ClearValue)
                })
                const responseData = response?.data?.data?.expenseReconciliationDetailsCreateMutation
                if (responseData?.success) {
                    const resData = responseData?.expenseReconciliationDetailsInstance
                    console.log("resData----------", resData);
                    setreconciliationTableData((prevData) => {
                        const updatedData = [...prevData];
                        updatedData[rowIndex] = {
                            ...updatedData[rowIndex],
                            date: resData?.paymentVoucherId?.date,
                            expenseRequestNo: resData?.paymentVoucherId?.expenseRequestId?.expenseRequestNo,
                            advAmount: resData?.paymentVoucherId?.empAmount,
                            createdBy: resData?.createdBy?.id,
                            modifiedBy: resData?.modifiedBy?.id,
                            paymentVoucherId: resData?.paymentVoucherId?.id,
                            adjustedAmount: resData?.adjustedAmount,
                            id: resData?.id
                        };

                        return updatedData;
                    });
                    setreconinitialValue(
                        {
                            id: '',
                            advRcvdDate: '',
                            expenseRequestno: '',
                            advAmount: '',
                            adjustedAmount: ''
                        }
                    )
                    setreconformKey(prve => prve + 1)
                    setreconValue(prve => ({ ...prve, editIndex: '', id: "", createdBy: '', expenseRequestno: '', advanceAmount: '', adjustedAmount: '' }))

                }
            }
            catch (error) {

                let errorData = ShowErrorNoties(error)
                showErrorToast(true, 'error', errorData)
            }

            setLoading(false)
        }
        const handledeleteConfomation = (item) => {
            if (item) {
                handltoastDeleteConfomationShow()
                setdeleteData({
                    Name: item?.expenseRequestId?.expenseRequestNo,
                    id: item?.id
                })
            }
        }
        return (
            <>
                <div className="row">
                    <div className="col-8">
                        <h6 className="commanModelTitleColor" onClick={() => {
                            console.log("reconciliationTableData", reconciliationTableData);
                        }}>Reconciliation Details</h6>
                    </div>
                    <div className="col-4 text-end">
                        <div className="form-check form-switch">
                            <input
                                className='form-check-input'
                                type="checkbox"
                                id="flexSwitchCheckDefault"
                                checked={autoAdjustChecked}
                                onChange={() => {
                                    if (autoAdjustChecked) {
                                        setautoAdjustChecked(!autoAdjustChecked);
                                        handlePreviousAutoAdjust();
                                    } else {
                                        handleAutoAdjust(values)
                                    }
                                }
                                }

                            />
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault" onClick={() => {
                                console.log(reconciliationTableData);
                            }}>
                                Auto Adjust
                            </label>
                        </div>
                    </div>
                </div>

                <div id="staan-input-table" style={{ height: '25rem', overflowY: 'auto', width: '100%' }}>
                    <table className="StaanTable" style={{ width: '100%', tableLayout: 'fixed' }}>
                        <thead>
                            <tr style={{ fontSize: '.9rem', height: '2rem' }}>
                                <th style={{ width: '15%' }} onClick={() => {
                                    console.log(values);
                                }}>Adv Rcvd Date</th>
                                <th style={{ width: '15%' }}>Expense Request no</th>
                                <th style={{ width: '15%' }}>Advance Amount</th>
                                <th style={{ width: '15%' }}>Adjusted Amount</th>
                                <th style={{ width: '15%' }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reconciliationTableData.map((item, rowIndex) => (
                                <Formik
                                    initialValues={reconinitialValue}
                                    validationSchema={reconvalidation}
                                    enableReinitialize key={reconformKey}

                                >
                                    {({ touched, errors, setFieldValue, values }) => (
                                        <tr>
                                            <td>
                                                {item?.date}
                                            </td>
                                            <td>
                                                {item?.expenseRequestNo}
                                            </td>
                                            <td>
                                                {item?.advAmount}
                                            </td>
                                            <td>
                                                {reconValue?.editIndex === rowIndex + 1 ? (
                                                    <div className="row m-1">
                                                        <div className="staan-input-group">
                                                            <Field
                                                                type="text"
                                                                name="adjustedAmount"
                                                                id="adjustedAmount"
                                                                className="w-100 staan-form-input"
                                                            />
                                                            <label
                                                                htmlFor="adjustedAmount"
                                                                className="staan-form-input-label pt-1 px-1 mx-2"
                                                            >
                                                                Adjusted Amount<span className="text-danger">*</span>
                                                            </label>
                                                            {touched.adjustedAmount && errors.adjustedAmount && <small>{errors.adjustedAmount}</small>}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    item?.adjustedAmount
                                                )}
                                            </td>
                                            <td>
                                                <div style={{ display: 'flex' }}>
                                                    {reconValue?.editIndex === rowIndex + 1 ? (
                                                        <>
                                                            <button
                                                                type="button"
                                                                className="save-button"
                                                                onClick={() => handleReconSave(rowIndex, item, values)}
                                                            >
                                                                Save
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="cancel-button"
                                                                onClick={() => {
                                                                    setreconValue({ editIndex: '', advRemarks: "", Amount: "", id: "", createdBy: item?.createdBy });
                                                                    // && item?.index !== rowIndex
                                                                    // setAdvanceData(prev => {
                                                                    //     return prev?.filter(item => item?.id !== "");
                                                                    // });
                                                                }}

                                                            >
                                                                <i className="fa-solid fa-xmark"></i>
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <button
                                                                type="button"
                                                                className="edit-button"
                                                                // disabled={editView}
                                                                onClick={() => {
                                                                    setreconinitialValue({
                                                                        id: item?.id ? Number(item?.id) : '',
                                                                        advRcvdDate: item?.date,
                                                                        expenseRequestno: item?.expenseRequestId?.expenseRequestNo,
                                                                        advAmount: item?.empAmount,
                                                                        adjustedAmount: item?.adjustedAmount,
                                                                    })
                                                                    setreconValue(prve => ({ ...prve, editIndex: item?.index, advRemarks: item?.advRemarks, Amount: item?.Amount, createdBy: item?.createdBy }))

                                                                }}                                         >
                                                                <i className="fa-solid fa-pen-to-square"></i>
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="delete-button"
                                                                onClick={() => handledeleteConfomation(item)}
                                                            // disabled={editView}

                                                            >
                                                                <i className="fa-solid fa-trash"></i>
                                                            </button>
                                                        </>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </Formik>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        );
    };
    const handlereconsiliationDeletedId = deleted_id => {
        if (deleted_id) {

        }
    }
    const resetForm = () => {
        setinitialExpenseReconciliation({
            employeeID: '',
            employeeName: '',
            advanceAmount: '',
            claimAmount: ''
        })
        setReconciliationShow(false)
    }

    const handleReconciliationSubmit = async (values, { setErrors }) => {
        setLoading(true)
        let isValid = true
        if (true) {

        }

        if (isValid) {
            try {
                const reqBody = {
                    advanceDue: values?.advanceAmount ? values?.advanceAmount : '',
                    claimDue:values?.claimAmount ? values?.claimAmount : '',
                    createdBy: 10,
                    employeeId: 10,
                    expenseReconciliationDetails: 10,
                    id: "",
                    modifiedBy: 10,
                    status: ""
                }
                let allow_null = []
                let clear_data = removeEmptyValueInObject(reqBody, allow_null)
                const respones = await axiosInstance.post(`/itemmaster/graphql`, {
                    query: reconsiliationCreateMutation(clear_data, allow_null)
                })
                const responseData = respones?.data?.data?.paymentVoucherCreateMutation
                if (responseData?.success) {
                    const resData = responseData?.PaymentVoucherInstance

                }
                else {
                    let errors = ShowErrorNoties(responseData.errors)
                    showErrorToast(true, "error", errors)
                }
            } catch (error) {
                console.log(error);
                showErrorToast(true, "error", ShowErrorNoties(error))
            }
        }
        else {
            console.log("Error");

        }
        setLoading(false)
    }

    return (
        <>
            {<BallTriangleLoading isshow={loading} />}
            <Modal show={isReconciliationShow} size="xl">
                <div className="row mx-1">
                    <div className="col-5 m-3" style={{ color: "#5CADA9" }}>
                        <Modal.Title>
                            <i className="fa-solid fa-arrow-left fa-sm me-2" onClick={resetForm}></i>
                            Expense Reconciliation
                        </Modal.Title>
                    </div>
                    <div className="col-6 mt-4 text-end">
                        <i className="fa-solid fa-pen fs-5 text-primary pe-3"></i>
                        <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3"></i>
                    </div>
                </div>
                <hr />
                <Modal.Body>
                    <Formik
                        initialValues={initialExpenseReconciliation}
                        validationSchema={ExpenceReconciliationValidation}
                        enableReinitialize key={expenseReconciliationFormKey}
                        onSubmit={handleReconciliationSubmit}
                    >
                        {({ errors, submitForm, touched, setFieldValue, values, resetForm }) => (
                            <>
                                <Form>
                                    <div className="row">
                                        <div className="col-3 staan-input-group">
                                            <Field
                                                type='text'
                                                name='employeeID'
                                                id="employeeID"
                                                values={initialExpenseReconciliation?.employeeID}
                                                className='w-100 staan-form-input'
                                                disabled={claimFormData?.id ? true : false}
                                            />
                                            <label htmlFor="employeeID" className='staan-form-input-label pt-1 px-1 mx-2'>Employee ID</label>
                                            {touched.employeeID && errors.employeeID && <small>{errors.employeeID}</small>}

                                        </div>
                                        <div className="col-3 staan-input-group">
                                            <Field
                                                type='text'
                                                name='employeeName'
                                                id="employeeName"
                                                values={initialExpenseReconciliation?.employeeName}
                                                className='w-100 staan-form-input'
                                                disabled={claimFormData?.id ? true : false}
                                            />
                                            <label htmlFor="employeeName" className='staan-form-input-label pt-1 px-1 mx-2'
                                                onClick={() => {
                                                    console.log("values", values);
                                                }}>Employee Name</label>
                                            {touched.employeeName && errors.employeeName && <small>{errors.employeeName}</small>}

                                        </div>
                                        <div className="col-3 staan-input-group">
                                            <Field type='text' name='advanceAmount' id='advanceAmount' className='w-100 staan-form-input' disabled={claimFormData?.id ? true : false} />
                                            <label htmlFor="advanceAmount" className='staan-form-input-label pt-1 px-1 mx-2'>Advance Due<span className='text-danger'>*</span></label>
                                            {touched.advanceAmount && errors.advanceAmount && <small>{errors.advanceAmount}</small>}
                                        </div>
                                        <div className="col-3 staan-input-group">
                                            <Field type='text' name='claimAmount' id='claimAmount' className='w-100 staan-form-input' />
                                            <label htmlFor="claimAmount" className='staan-form-input-label pt-1 px-1 mx-2'>Claim Due<span className='text-danger'>*</span></label>
                                            {touched.claimAmount && errors.claimAmount && <small>{errors.claimAmount}</small>}
                                        </div>

                                    </div>
                                    <div className='row  ms-3 mt-4 card shadow mx-2 p-3'>
                                        <div
                                            className='ag-theme-alpine mt-2'
                                            style={{ height: '30rem', width: '100%' }}
                                        >
                                            <ReconciliationTable
                                                values={values} />

                                        </div>

                                    </div>
                                    <div className="row mt-3 me-2">
                                        <div className="text-end">
                                            <button type="button" className="btn btn-outline-danger btn-sm me-3 px-4">
                                                Draft
                                            </button>
                                            <button type="button" className="btn btn-sm btn-outline-success shadow-sm px-3">
                                                Submit
                                            </button>
                                            <button type="button" className="btn btn-sm btn-outline-info shadow-sm px-3 ml-2 pl-4 pr-4">
                                                Pay
                                            </button>
                                        </div>
                                    </div>

                                </Form>
                            </>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
            {deleteData && (
                <DeleteConformation
                    handleDeletedId={id => handlereconsiliationDeletedId(id)}
                    deleteData={deleteData}
                // fullquery={advanceTableDeleteMutation(deleteData?.id)}
                />
            )}
        </>
    );
}

export default ExpenseReconciliationModal;
