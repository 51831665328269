import React, { useContext, useState } from 'react'
import { FaUsers } from "react-icons/fa"
import { FaUserGear } from "react-icons/fa6"
import { LuLayoutDashboard } from 'react-icons/lu'
import Can from "../../components/authorizationComponents/Can"
import DataContext from '../../context/ItemMasterContext'
import staanlogo from '../../img/Max Nik 5.png'
import './sideNavbar.css'

export const SideNavbar = () => {
  const { logout, userName, userEmail, SetSupplierFiliter, Navigate } =
    useContext(DataContext)

  const [InventorySubBar, setInventorySubBar] = useState(false)
  const [accountSubBar, setaccountSubBar] = useState(false)
  const [conferenceSubBar, setconferenceSubBar] = useState(false)
  const [salesOrdersubBar, setSalesOrderSubBar] = useState(false)
  const [posSubBar, setPosSubBar] = useState(false)
  const [enquirySubBar, setenquirySubBar] = useState(false)
  const [PurchasesubBar, setPurchaseSubBar] = useState(false)
  const [general, setGeneral] = useState(false)
  const [userManagement, setUserManagement] = useState(false) 
  const [showProductionCategory, setShowProductionCategory] = useState(false)
  const [hr, setHr] = useState(false)

  return (
    <>
      <div className='nav_sidebar Larger shadow'>
        <div className='nav_logodetails'>
          <img src={staanlogo} alt='' className='img-fluid' srcSet='' />
        </div>
        <ul className='nav-list'>
          <li>
            <div  onClick={() => {
                    Navigate('/dashboard')
                  }}>
              <i>
                <LuLayoutDashboard />
              </i>
              <span className='nav_link_name'>Dashboard</span>
            </div>
          </li>
          <Can allow_list={['View_Numbering_Series','View_Terms_Conditions','View_User_Management', "View_Roles", "View_Profile"]}>
            <li
              onClick={() => setGeneral(true)}
              onMouseEnter={() => setGeneral(true)}
              onMouseLeave={() => setGeneral(false)}
            >
              <div>
                <i className='bx bx-home-alt'></i>
                <span className='nav_link_name'>General</span>
              </div>
              <ul
                className='sub_nav-list shadow'
                style={{ display: general ? 'block' : 'none' }}
              >
                <Can I={"View"} a={"Numbering_Series"}>
                  <li>
                    <div
                      onClick={() => {
                        Navigate('/numbering-series')
                      }}
                    >
                      <i className='fa-solid fa-list-ol'></i>
                      <span className='nav_link_name'>Numbering Series</span>
                    </div>
                  </li>
                </Can>
                <Can I={"View"} a={"Terms_Conditions"}>
                  <li>
                    <div
                      onClick={() => {
                        Navigate('/terms-conditions')
                      }}
                    >
                      <i className='fa-regular fa-note-sticky'></i>
                      <span className='nav_link_name'>Terms Conditions</span>
                    </div>
                  </li>
                </Can>
                <Can allow_list={['View_User_Management', "View_Roles", "View_Profile"]}>
                <li 
                    onClick={() => setUserManagement(true)}
                    onMouseEnter={() => setUserManagement(true)}
                    onMouseLeave={() => setUserManagement(false)}>
                  
                  <div>
                    <i className='fa-regular fa-note-sticky'></i>
                    <span className='nav_link_name'>User Management</span>
                  </div>
                    <ul
                      className='sub_nav-list shadow'
                      style={{ display: userManagement ? 'block' : 'none' }}
                    >
                      <Can I={"View"} a={"User_Management"}>
                        <li>
                          <div
                            onClick={() => {
                              Navigate('/all-user-management')
                            }}
                          >
                            <i  ><FaUserGear/></i>
                            <span className='nav_link_name'>Users</span>
                          </div>
                        </li>
                      </Can>
                      <Can  I={"View"} a={"Roles"}>
                        <li>
                          <div
                            onClick={() => {
                              Navigate('/role')
                            }}
                          >
                            <i  ><FaUsers/></i>
                            <span className='nav_link_name'>Role</span>
                          </div>
                        </li>
                      </Can>
                      <Can  I={"View"} a={"Profile"}>
                        <li>
                            <div
                              onClick={() => {
                                Navigate('/profile')
                              }}
                            >
                              <i  ><FaUsers/></i>
                              <span className='nav_link_name'>Profile</span>
                            </div>
                        </li>
                      </Can>
                        
                    </ul>
                </li>
                </Can>
                
                
              </ul>
            </li>
          </Can>
          <Can allow_list={['View_Item_Master','View_Store','View_UOM', "View_Item_Group", "View_Stock_Statement","View_Stock_Addition",
            "View_Stock_Deletion"
          ]}>
          <li
            onClick={() => setInventorySubBar(true)}
            onMouseEnter={() => setInventorySubBar(true)}
            onMouseLeave={() => setInventorySubBar(false)}
          >
            <div>
              <i className='bx bx-notepad'></i>
              <span className='nav_link_name'>Stock</span>
            </div>
            <ul
              className='sub_nav-list shadow'
              style={{ display: InventorySubBar ? 'block' : 'none' }}
            >
              <Can I={"View"} a={"Item_Master"}>
                <li>
                  <div
                    onClick={() => {
                      Navigate('/ItemMaster')
                    }}
                  >
                    <i className='bx bx-home-alt'></i>
                    <span className='nav_link_name'>Item Master</span>
                  </div>
                </li>
              </Can>
              <Can I={"View"} a={"Store"}>
                <li>
                  <div
                    onClick={() => {
                      Navigate('/store')
                    }}
                  >
                    <i className='bx bx-store-alt'></i>
                    <span className='nav_link_name'>Store</span>
                  </div>
                </li>
              </Can>
              <Can I={"View"} a={"UOM"}>
                <li>
                  <div
                    onClick={() => {
                      Navigate('/unit')
                    }}
                  >
                    <i className='fa-solid fa-weight-hanging'></i>
                    <span className='nav_link_name'>Unit</span>
                  </div>
                </li>
              </Can>
              <Can I={"View"} a={"Item_Group"}>
                <li>
                  <div
                    onClick={() => {
                      Navigate('/item_Group')
                    }}
                  >
                    <i className='fa-solid fa-sitemap'></i>
                    <span className='nav_link_name'>Item Group</span>
                  </div>
                </li>
              </Can>
              
              <Can I={"View"} a={"Stock_Statement"}>
                <li>
                  <div
                    onClick={() => {
                      Navigate('/stock-statement')
                    }}
                  >
                    <i className='fa-solid fa-note-sticky'></i>
                    <span className='nav_link_name'>Stock Statement</span>
                  </div>
                </li>
              </Can>
              <Can I={"View"} a={"Stock_Addition"}>
              <li>
                <div
                  onClick={() => {
                    Navigate('/InventryHandler')
                  }}
                >
                  <i className='fa-solid fa-boxes-stacked'></i>
                  <span className='nav_link_name'>Stock Addtions</span>
                </div>
              </li>
              </Can>
              <Can I={"View"} a={"Stock_Deletion"}>
              <li>
                <div
                  onClick={() => {
                    Navigate('/InventryHandlerDeletion')
                  }}
                >
                  <i className='fa-solid fa-warehouse'></i>
                  <span className='nav_link_name'>Stock Deletion</span>
                </div>
              </li>
              </Can>
             
              <li>
                <div
                  onClick={() => {
                    Navigate('/printLabel')
                  }}
                >
                  <i className='fa-solid fa-warehouse'></i>
                  <span className='nav_link_name'>Print Label</span>
                </div>
              </li>
            </ul>
          </li>
          </Can>
          <Can allow_list={["View_Purchase_Order","View_GIN","View_QIR","View_Delivery_Challan","View_GRN","View_PRC"]}>
          <li
            onMouseEnter={() => setPurchaseSubBar(true)}
            onMouseLeave={() => setPurchaseSubBar(false)}
          >
            <div>
              <i className='bx bx-credit-card'></i>
              <span className='nav_link_name'>Purchase</span>
            </div>
            <ul
              className='sub_nav-list shadow'
              style={{ display: PurchasesubBar ? 'block' : 'none' }}
            >
              <li>
                <div
                  onClick={() => {
                    Navigate('/supplier')
                  }}
                >
                  <i className='fa-solid fa-users-line'></i>
                  <span
                    className='nav_link_name'
                    onClick={() => {
                      SetSupplierFiliter('Supplier')
                    }}
                  >
                    Supplier
                  </span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    Navigate('/purchaseOrder')
                  }}
                >
                  <i className='fa-solid fa-cart-shopping'></i>
                  <span className='nav_link_name'>Purchase Order</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    Navigate('/gin')
                  }}
                >
                  <i className='fa-solid fa-cart-shopping'></i>
                  <span className='nav_link_name'>Goods Inward Note</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    Navigate('/Qir')
                  }}
                >
                  <i className='fa-solid fa-cart-shopping'></i>
                  <span className='nav_link_name'>
                    Quality Inspection Report
                  </span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    Navigate('/dc')
                  }}
                >
                  <i className='fa-solid fa-cart-shopping'></i>
                  <span className='nav_link_name'>Rework Delivery Challan</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    Navigate('/GRN')
                  }}
                >
                  <i className ='fa-solid fa-cart-shopping'></i>
                  <span className='nav_link_name'>Goods Receipt Note</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    Navigate('/purchase-return-challan')
                  }}
                >
                  <i className='fa-solid fa-cart-shopping'></i>
                  <span className='nav_link_name'>Purchase Return Challan</span>
                </div>
              </li>
            </ul>
          </li>
          </Can>
          <Can allow_list={['View_POS','View_POS_Report','View_POS_Detailed_Report', "View_POS_Collection_Repor",
           "View_POS_Stock_Report"]}>
             <li
            onMouseEnter={() => setSalesOrderSubBar(true)}
            onMouseLeave={() => setSalesOrderSubBar(false)}
          >
            <div>
              <i className='bx bx-bar-chart-alt'></i>
              <span className='nav_link_name'>Sales</span>
            </div>
            <ul
              className='sub_nav-list shadow'
              style={{ display: salesOrdersubBar ? 'block' : 'none' }}
            >
              <Can I={"View"} a={"POS"}>
                <li
                  onMouseEnter={() => setPosSubBar(true)}
                  onMouseLeave={() => setPosSubBar(false)}
                >
                  <div>
                    <div
                      onClick={() => {
                        Navigate('/posdata')
                      }}
                    >
                      <i className='fa-brands fa-wpforms'></i>
                      <span className='nav_link_name'>POS</span>
                    </div>
                  </div>
                  <ul
                    className='sub_nav-list shadow'
                    style={{ display: posSubBar ? 'block' : 'none' }}
                  >
                    <Can I={"View"} a={"POS_Report"}>
                    <li>
                      <div
                        onClick={() => {
                          Navigate('/pos-report')
                        }}
                      >
                        <i className='fa-regular fa-file'></i>
                        <span className='nav_link_name'>POS Report</span>
                      </div>
                    </li>
                    </Can>
                    <Can I={"View"} a={"POS_Detailed_Report"}>
                      <li>
                        <div
                          onClick={() => {
                            Navigate('/pos-detailed-report')
                          }}
                        >
                          <i className='fa-solid fa-book'></i>
                          <span className='nav_link_name'>POS Detailed Report</span>
                        </div>
                      </li>
                    </Can>
                    <Can I={"View"} a={"POS_Collection_Repor"}>
                      <li>
                        <div
                          onClick={() => {
                            Navigate('/pos-collection-wise')
                          }}
                        >
                          <i className='fa-solid fa-book'></i>
                          <span className='nav_link_name'>
                            POS collection Report
                          </span>
                        </div>
                      </li>
                    </Can>
                    <Can I={"View"} a={"POS_Stock_Report"}>
                      <li>
                        <div
                          onClick={() => {
                            Navigate('/pos-stock-report')
                          }}
                        >
                          <i className='fa-solid fa-book'></i>
                          <span className='nav_link_name'>POS Stock Report</span>
                        </div>
                      </li>
                    </Can>
                    
                  </ul>
                </li>
              </Can>
              <Can I={"View"} a={"Customer"}>
                <li>
                  <div
                    onClick={() => {
                      Navigate('/customer')
                    }}
                  >
                    <i className='fa-solid fa-users-line'></i>
                    <span
                      className='nav_link_name'
                      onClick={() => {
                        SetSupplierFiliter('Customer')
                      }}
                    >
                      Customer
                    </span>
                  </div>
                </li>
              </Can>
              <Can I={"View"} a={"Contact"}>
                <li>
                  <div
                    onClick={() => {
                      Navigate('/contact')
                    }}
                  >
                    <i className ='fa-solid fa-address-card'></i>
                    <span className='nav_link_name'>Contact</span>
                  </div>
                </li>
              </Can>
            
              <li   onMouseEnter={() => setenquirySubBar(true)}
                onMouseLeave={() => setenquirySubBar(false)}>
                <div
                  onClick={() => {
                    Navigate('/Enquiry')
                  }}
                >
                  <i className='fa-brands fa-wpforms'></i>
                  <span className='nav_link_name'>Enquiry</span>
                </div>
                <ul 
                  className='sub_nav-list shadow'
                  style={{ display: enquirySubBar ? 'block' : 'none' }}
                >
                  <Can I={"View"} a={"Enquiry"}>
                    <li>
                      <div
                        onClick={() => {
                          Navigate('/EnquiryDetail')
                        }}
                      >
                        <i className='fa-regular fa-rectangle-list'></i>
                        <span className='nav_link_name'>Enquiry Contacts</span>
                      </div>
                    </li>
                  </Can>
                  
                </ul>
              </li>
              <li>
                  <div
                    onClick={() => {
                      Navigate('/Lead')
                    }}
                  >
                     <i className='fa-solid fa-book'></i>
                    <span className='nav_link_name'>Lead</span>
                  </div>
              </li>
              <li>
                  <div
                    onClick={() => {
                      Navigate('/sales-order')
                    }}
                  >
                    <i className='fa-solid fa-users-line'></i>
                    <span
                      className='nav_link_name'
                    >
                      Sales Order
                    </span>
                  </div>
              </li>
              <li>
                  <div
                    onClick={() => {
                      Navigate('/quotation')
                    }}
                  >
                    <i className='fa-solid fa-users-line'></i>
                    <span
                      className='nav_link_name'
                    >
                      Quotation
                    </span>
                  </div>
              </li>

              
             
              
            </ul>
          </li>
          </Can>
         
          <Can I={"View"} a={"Conference"}>
            <li
              onMouseEnter={() => setconferenceSubBar(true)}
              onMouseLeave={() => setconferenceSubBar(false)}
            >
              <div>
                <i className='fa-solid fa-people-roof'></i>
                <span className='nav_link_name'>Conference </span>
              </div>
              <ul
                className='sub_nav-list shadow'
                style={{ display: conferenceSubBar ? 'block' : 'none' }}
              >
                <li>
                  <div
                    onClick={() => {
                      Navigate('/conference')
                    }}
                  >
                    <i className='fa-solid fa-list-ul'></i>
                    <span className='nav_link_name'>Conference</span>
                  </div>
                </li>
              </ul>
            </li>
          </Can>
          <Can allow_list={['View_Accounts_Master','View_Account_Group','View_HSN', "View_Currency_Exchange",
           "View_Currency_Master", "View_Other_Expenses",  ]}>
            <li
            onMouseEnter={() => setaccountSubBar(true)}
            onMouseLeave={() => setaccountSubBar(false)}
          >
            <div>
              <i className='bx bx-rupee'></i>
              <span className='nav_link_name'>Account</span>
            </div>
            <ul
              className='sub_nav-list shadow'
              style={{ display: accountSubBar ? 'block' : 'none' }}
            >
              <Can I={"View"} a={"Accounts_Master"}>
                <li>
                  <div
                    onClick={() => {
                      Navigate('/account')
                    }}
                  >
                    <i className='fa-regular fa-note-sticky'></i>
                    <span className='nav_link_name'>Account Master</span>
                  </div>
                </li>
              </Can>
              <Can I={"View"} a={"Account_Group"}>
                <li>
                  <div
                    onClick={() => {
                      Navigate('/account_Group')
                    }}
                  >
                    <i className='fa-solid fa-cubes-stacked'></i>
                    <span className='nav_link_name'>Account Group</span>
                  </div>
                </li>
              </Can>
              <Can I={"View"} a={"HSN"}>
                <li>
                  <div
                    onClick={() => {
                      Navigate('/hsn')
                    }}
                  >
                    <i className='fa-regular fa-credit-card'></i>
                    <span className='nav_link_name'>HSN</span>
                  </div>
                </li>
              </Can>
              <Can I={"View"} a={"Currency_Exchange"}>
                <li>
                  <div
                    onClick={() => {
                      Navigate('/currencyexchangelists')
                    }}
                  >
                    <i className='fa-solid fa-hand-holding-dollar'></i>
                    <span className='nav_link_name'>Currency Exchange</span>
                  </div>
                </li>

              </Can>
              <Can I={"View"} a={"Currency_Master"}>
                <li>
                  <div
                    onClick={() => {
                      Navigate('/currencymaster')
                    }}
                  >
                    <i className='fa-solid fa-building-columns'></i>
                    <span className='nav_link_name'>Currency Master</span>
                  </div>
                </li>
              </Can> 
              <Can I={"View"} a={"Other_Expenses"}>
                <li>
                  <div
                    onClick={() => {
                      Navigate('/other-expenses')
                    }}
                  >
                    <i className='fa-regular fa-note-sticky'></i>
                    <span className='nav_link_name'>Other Expenses</span>
                  </div>
                </li>
              </Can>
              <li>
                  <div
                    onClick={() => {
                      Navigate('/otherincomecharges')
                    }}
                  >
                    <i className='fa-regular fa-note-sticky'></i>
                    <span className='nav_link_name'>Other Income Charges</span>
                  </div>
                </li>
              {/*  */}
            </ul>
            </li>
          </Can>
          
          <li>
            <div>
              <i className='bx bx-grid-alt'></i>
              <span className='nav_link_name'>Service</span>
            </div>
          </li>
            <Can allow_list={['View_Bom',"View_MRP","View_Manufacturing_Order","View_Material_Request"]}>        
          <li
            onMouseEnter={() => setShowProductionCategory(true)}
            onMouseLeave={() => setShowProductionCategory(false)}
          >
            <div>
              <i className='bx bx-cog'></i>
              <span className='nav_link_name'>Manufacturing</span>
            </div>
            <ul
              className='sub_nav-list shadow'
              style={{ display: showProductionCategory ? 'block' : 'none' }}
            >
              <li>
                <div
                  onClick={() => {
                    Navigate('/bom')
                  }}
                >
                  <i className='fa-regular fa-note-sticky'></i>
                  <span className='nav_link_name'>BOM</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    Navigate('/mrp')
                  }}
                >
                  <i className='fa-solid fa-house-laptop'></i>
                  <span className='nav_link_name'>MRP</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    Navigate('/manufacturing-order')
                  }}
                >
                  <i className='fa-solid fa-house-chimney-user'></i>
                  <span className='nav_link_name'>Manufacturing Order</span>
                </div>
              </li>
              <li>
                <div
                  onClick={() => {
                    Navigate('/material-request')
                  }}
                >
                  <i className='fa-solid fa-comment-dots'></i>
                  <span className='nav_link_name'>Material Request</span>
                </div>
              </li>
            </ul>
          </li>
          </Can>
          <Can allow_list={['View_Department']}>
            <li
              onMouseEnter={() => setHr(true)}
              onMouseLeave={() => setHr(false)}
            >
              {/* hr, setHr */}
              <div>
                <i className='bx bxs-user-circle'></i>
                <span className='nav_link_name'>HR</span>
              </div>
              <ul
                className='sub_nav-list shadow'
                style={{ display: hr ? 'block' : 'none', maxHeight: '300px',
                  overflowY: 'auto', }}
                
              >
                <Can I={"View"} a={"Department"}>
                  <li>
                    <div
                      onClick={() => {
                        Navigate('/department')
                      }}
                    >
                      <i className='fa-regular fa-note-sticky'></i>
                      <span className='nav_link_name'>Department</span>
                    </div>
                  </li>
                </Can>
                  <li>
                    <div
                      onClick={() => {
                        Navigate('/employee')
                      }}
                    >
                      <i className='fa-regular fa-note-sticky'></i>
                      <span className='nav_link_name'>Employee</span>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => {
                        Navigate('/expense_request')
                      }}
                    >
                      <i className='fa-regular fa-note-sticky'></i>
                      <span className='nav_link_name'>Expense Request</span>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => {
                        Navigate('/expense_categories')
                      }}
                    >
                      <i className='fa-regular fa-note-sticky'></i>
                      <span className='nav_link_name'>Expense Categories</span>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => {
                        Navigate('/expense_claim')
                      }}
                    >
                      <i className='fa-regular fa-note-sticky'></i>
                      <span className='nav_link_name'>Expense Claim</span>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => {
                        Navigate('/expense_reconciliation')
                      }}
                    >
                      <i className='fa-regular fa-note-sticky'></i>
                      <span className='nav_link_name'>Expense Reconciliation</span>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => {
                        Navigate('/payment_voucher')
                      }}
                    >
                      <i className='fa-regular fa-note-sticky'></i>
                      <span className='nav_link_name'>Payment Voucher</span>
                    </div>
                  </li>
              
              </ul>
            </li>

          </Can>


          <div className='profile'>
            <div className='profile_details'>
              {/* <img className='userimg' src="https://w7.pngwing.com/pngs/178/595/png-transparent-user-profile-computer-icons-login-user-avatars-thumbnail.png" alt="user" /> */}
              <i className='bx bxs-user-circle fs-1 mx-3'></i>
              <div>
                <div className='profile_name'>{userName}</div>
                <div className='desingnations'>{userEmail}</div>
              </div>
            </div>
            <i
              className='bx bx-log-out-circle'
              onClick={logout}
              id='log_out'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='Log Out'
            ></i>
          </div>
        </ul>
      </div>
    </>
  )
}
