import { AgGridReact } from 'ag-grid-react';
import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AiOutlineCaretLeft, AiOutlineCaretRight, AiOutlineShoppingCart } from 'react-icons/ai';
import { LuGift } from "react-icons/lu";
import { PiPrinterLight } from "react-icons/pi";
import { TiCancel } from 'react-icons/ti';
import ReactQuill from 'react-quill';
import Select from 'react-select';
import { toast } from 'react-toastify';
import axiosInstance, { commanFetch } from '../../../api/axoiss';
import HierarchicalTreeView from '../../../components/customTreeViewComponent/HierarchicalTreeView';
import DeleteConformation from '../../../components/deleteConformations.js/DeleteConformation';
import BallTriangleLoading from "../../../components/loading/BallTriangle";
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import WaringModel from '../../../components/Warings/WaringModel';
import WaringModelWithCopy from '../../../components/Warings/WaringModelWithCopy';
import DataContext from '../../../context/ItemMasterContext';
import { DepartmentQuery, stockIdFetchQuery, SupplierOptionPerameter, TermsConditionsQuotation, userPermissionOptions } from '../../../GraphQLQueries/ItemmasterQuery';
import { leadTimeLine } from '../../../GraphQLQueries/LeadQueryAndMutations/LeadQuery';
import { otherIncomeChargesDeleteMutation, quotationsOtherIncomeChargesCreateMutation } from '../../../GraphQLQueries/OtherIncomeChargesQuery/OtherIncomingChargesMutation';
import { getItemComboUnsavedDetails, quotationCreateMutation, quotationCurrencyConvertions, QuotationsCancel, quotationsDeleteMutation, quotationsgeneratePDF, quotationsItemDetailsDeleteMutation } from '../../../GraphQLQueries/QuatationqueryAndMutatiom/QuatationItemModalMutation';
import { discountClearAllQuery, otherIncomeChargesList, quotationAmandCancel, quotationEditQuery, quotationsAmand, quotationsBulkUpdateForTax } from '../../../GraphQLQueries/QuatationqueryAndMutatiom/quatationQuery';
import { calculateTax, customSelectStyle, formateGivenStringdate, getStates, onCellDoubleClicked, removeEmptyValueInObject, roundToNearest10, ShowErrorNoties, textEditormodules, TimeFormate } from '../../../utils/Utils';
import { quotationFormSchema } from '../../../validations/QuotationValidation';
import SalesOrderForm from '../../SalesOrder2/model/SalesOrderForm';
import StockStatementDetails from '../../StockStatement/Details/StockStatementDetails';
import ItemCombo, { parseAndGroupItemComboData } from '../Popup/itemCombo';
import QuatationItemModel from '../Popup/itemDetailsPopup';
import Discount from '../Popup/overallDiscount';
import TaxModel from '../Popup/taxModal';

const QuotationFrom = ({
  index,
  quotationId,
  setQuotationsList,
  leadData,
  setLoad
}) => {
  const { userId, prefetchValue, openQuotationModal, closeQuotationModal, openSalesOrderModal, openLeadModal, handltoastDeleteConfomationShow } = useContext(DataContext)
  // State to hold the initial form values for a quotation
  const [initialQuotation, setInitialQuotation] = useState({
    id: "",
    supplierNumber: '',
    supplierName: '',
    department_id: '',
    remark: '',
    QuatationNumber: '',
    QuotationDate: '',
    buyerAddress: "",
    buyerContactPerson: "",
    termsConditions: '',
    TermsOptions: "",
    childCount: "",
    createdBy: {
      id: '',
      name: ''
    },
    createdAt: "",
    createdTime: "",
    modifiedBy: "",
    status: "",
    isAmend: false,
    isDuplicate: "",
    parentOrder: "",
    LeadNumber: '',
    salesPerson: '',
    Currency: '',
    currencyExchangeRate: '',
    active: ""

  })
  const [customerDetailsLists, setCustomerDetailsLists] = useState({
    "buyerAddressList": [], "buyerContactList": [], "buyerStatesList": [],
  })
  const [currencyData, setcurrencyData] = useState("")
  const [timeLineValue, setTimeLineValue] = useState({ treeData: [], isShow: false })
  const [editView, seteditView] = useState(false)
  const [isSaveOtherIncomeCharges, setisSaveOtherIncomeCharges] = useState(false)
  const [IswarningShow, setwarningShow] = useState(false)
  const [IswarningAmandCancelShow, setwarningAmandCancelShow] = useState(false)
  const [isCancelWarningShow, setIsCancelWarningShow] = useState(false)
  const [isDeleteWarningShow, setIsDeleteWarningShow] = useState(false)
  const [IsshowWarningModalWithCopy, setShowWarningModalWithCopy] = useState(false)
  const [errorMessagesWarningModalWithCopy, setErrorMessagesWarningModalWithCopy] = useState("")
  const [IsOverAllDiscountApplied, setIsOverAllDiscountApplied] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [versionList, setVersionList] = useState([])
  const [isFirst, setIsFirst] = useState(true);
  const [isLast, setIsLast] = useState(versionList ? versionList.length <= 1 : 0);
  const [currentVersionId, setCurrentVersionId] = useState(null)
  const [salesPersonList, setSalesPersonList] = useState([])
  const [salesPersonSelect, setsalesPersonSelect] = useState([])
  const [currencySelect, setcurrencySelect] = useState("")
  const [supplierNoList, setSupplierNoList] = useState([])
  const [supplierNoSelected, setSupplierNoSelected] = useState(null)
  const [supplierNameSelected, setSupplierNameSelected] = useState(null)
  const [supplierNameList, setSupplierNameList] = useState([])
  const [departmentSelect, setDepartmentSelect] = useState([])
  const [departmentList, setdepartmentList] = useState([])
  const [leadSelect, setleadSelect] = useState('')
  const [quotationInputValue, setQuotationInputValue] = useState({
    address: '',
    remark: ''
  })
  const [isShowSalesOrder, setIsShowSalesOrder] = useState(false)
  const [salesOrderData, setSalesOrderData] = useState({ Quotation: "", Department: "", SalesPerson: "", CustomerCode: "", lead: "" })
  const [quotationAddressContactData, setquotationAddressContactData] = useState({
    buyerAddress: "", buyerContactPerson: "",
  })
  const [tCList, setTCList] = useState([])
  const [showTaxModel, setShowTaxModel] = useState(false)
  const [termsOptions, setTermsOptions] = useState()
  const [termsConditions, setTermsConditions] = useState(null)
  const [isShowStockStatementModal, setShowStockStatementModal] = useState(false)

  // const [IsEnableShowDiscount, setIsEnableShowDiscount] = useState(false)
  // Effect to fetch data when quotationId changes
  const [otherIncomeChargesvalues, setotherIncomeChargesvalues] = useState({
    accountList: [], errorMessages: [], onNewItemSelect: false, deleteData: { Name: '', id: '' },
    showDiscount: false, otherIncomeValue: { editIndex: '', account: "", tax: "", amount: "", id: "", createdBy: '' }
  })
  const [itemDetailsValues, setitemDetailsValues] = useState({
    ItemEditData: [], deleteData: { Name: '', id: '' }, lastUpdatedItemDetails: [],
    selectedStockStatementDetails: [],selectedPartNo: '', itemComboData: []
  })
  const [isItemDeleteWarningShow, setisItemDeleteWarningShow] = useState(false)
  const [itemComboData, setItemComboData] = useState([])
  const [showItemCombo, setShowItemCombo] = useState(false)
  const [QuotationItemDetailsShow, setQuotationItemDetailsShow] = useState(false)
  const [ItemEditShow, setItemEditShow] = useState(false)
  const [IsSaveItemCombo, setIsSaveItemCombo] = useState(false)
  const [otherIncomeChargesData, setOtherIncomeChargesData] = useState([]);
  const [itemDetailsData, setItemDetailsData] = useState([])
  const [quotationStatus, setquotationStatus] = useState('')
  const [isAfterOverallDiscount, setAfterOverallDiscount] = useState(false)
  const [overallDiscountPercentage, setOverallDiscountPercentage] = useState('');
  const [overallDiscountValue, setOverallDiscountValue] = useState('');
  const [finalTotal, setFinalTotal] = useState('');
  const [toSaveData, setToSaveData] = useState({
    beforeTax: '',
    otherIncomeChagersBeforeTax: "",
    roundOff: "",
    netAmount: '',
    itemDatalist: [],
    totaltax: '',
    cgst: {},
    sgst: {},
    igst: {},
    actualigstResult: "",
    actualcgstResult: "",
    actualsgstResult: "",
    actualNetAmount: "",
    otherIncomeChargesId: []
  });
  const [checkDiscount, setCheckedDiscount] = useState(false)
  const [showDiscount, setShowDiscount] = useState(false);
  const [loading, setLoading] = useState(false)


  const fetchData = async (data) => {
    try {
      const response = await commanFetch(quotationEditQuery(data));
      if (!response.success) return;
      const responseData = response?.data?.items;
      const versionData = response?.data?.version;
      if (responseData.length === 0) return;
      setVersionList(versionData?.versionList)
      const {
        quotationNo,
        leadNo,
        CreatedAt,
        remarks,
        customerAddress,
        salesPerson,
        customerContactPerson,
        customerId,
        department,
        itemDetails,
        otherIncomeCharge,
        termsConditions,
        termsConditionsText,
        createdBy,
        status,
        discountFinalTotal,
        overallDiscountValue,
        overallDiscountPercentage,
        childCount,
        currency,
        active,
        id
      } = responseData[0];
      setleadSelect(leadNo?.leadNo)
      seteditView(true)
      setquotationStatus(status?.name)
      setCurrentVersionId(id)
      const linkedModelId = quotationNo?.linkedModelId || '';
      const createdAt = formateGivenStringdate(CreatedAt);
      const address = {
        id: customerAddress?.id,
        addressLine1: customerAddress?.addressLine1 || '',
        addressLine2: customerAddress?.addressLine2 || '',
        city: customerAddress?.city || '',
        state: customerAddress?.state || '',
        pincode: customerAddress?.pincode || '',
      };

      // Update the state with fetched data
      setInitialQuotation(prevState => ({
        ...prevState,
        id: id,
        QuatationNumber: linkedModelId,
        QuotationDate: createdAt,
        remark: remarks || '',
        supplierNumber: customerId?.supplierNo,
        supplierName: customerId?.companyName,
        department_id: department?.id,
        TermsOptions: termsConditions?.id,
        termsConditions: termsConditionsText,
        childCount: childCount,
        status: status?.name,
        createdBy: {
          id: createdBy?.id,
          name: createdBy?.username
        },
        LeadNumber: leadNo?.id ? Number(leadNo?.id) : "",
        createdAt: createdAt,
        createdTime: TimeFormate(CreatedAt),
        salesPerson: salesPerson ? Number(salesPerson?.id) : "",
        buyerAddress: Number(customerAddress?.id),
        buyerContactPerson: Number(customerContactPerson?.id),
        Currency: currency?.id ? currency?.id : '',
        currencyExchangeRate: currency?.rate ? currency?.rate : '',
        active: active
      }));

      setTermsOptions({
        value: termsConditions?.id,
        label: termsConditions?.name,
      })
      setTermsConditions(termsConditionsText)

      setQuotationInputValue(prevState => ({
        ...prevState,
        address: address,
      }));
      setSupplierNameSelected({
        value: customerId?.id,
        label: customerId?.companyName,
      });
      setSupplierNoSelected({
        value: customerId?.id,
        label: customerId?.supplierNo,
      });
      setDepartmentSelect({
        value: department?.id,
        label: department?.name,
      });
      setsalesPersonSelect({ value: salesPerson?.id, label: salesPerson?.username })
      setquotationAddressContactData({
        buyerAddress: { value: customerAddress?.id, label: customerAddress?.addressType, fullAddredd: customerAddress },
        buyerContactPerson: { value: customerContactPerson?.id, label: customerContactPerson?.contactPersonName, mobile: customerContactPerson?.phoneNumber, Email: customerContactPerson?.email },
      })
      setCustomerDetailsLists({
        buyerContactList: customerId?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
        buyerAddressList: customerId?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ }))
      })
      setOverallDiscountPercentage(overallDiscountPercentage)
      setOverallDiscountValue(overallDiscountValue)
      setFinalTotal(discountFinalTotal)
      setSalesPersonList({ value: salesPerson?.id, label: salesPerson?.username })
      if (overallDiscountPercentage || overallDiscountValue || discountFinalTotal) {
        setCheckedDiscount(true)
        setAfterOverallDiscount(true)
      }
      // setIsEnableShowDiscount(true)
      // Set the item details
      if (itemDetails && Array.isArray(itemDetails)) {
        setItemDetailsData(itemDetails?.map(item => ({
          ...item,
          amount: item?.amount ? Number(item?.amount).toFixed(3) : item?.amount,
          rate: item?.rate ? Number(item?.rate).toFixed(3) : item?.rate
        })));
      }
      if (otherIncomeCharge && Array.isArray(otherIncomeCharge)) {
        //formatting according to Other Income Charges
        const transformedOtherIncomeChargesData = otherIncomeCharge?.map(item => ({
          ...item,
          amount: Number(item?.amount).toFixed(2),
          account: {
            label: item?.otherIncomeChargesId?.name,
            tax: item?.otherIncomeChargesId?.hsn?.gstRates?.rate,
            value: item?.otherIncomeChargesId?.id,
          },
          tax: item?.tax,
          currencyId: Number(currencyData?.value),
        }));
        setOtherIncomeChargesData(transformedOtherIncomeChargesData);
        setLoading(false)
      }

      // for sales Order
      setSalesOrderData({
        Quotation: { "id": id, "QuatationNumber": linkedModelId },
        Department: { value: department?.id, label: department?.name },
        SalesPerson: { value: salesPerson?.id, label: salesPerson?.username },
        CustomerCode: { value: customerId?.id, label: customerId?.supplierNo },
        lead: leadNo,
        overallDiscountPercentage: overallDiscountPercentage,
        overallDiscountValue: overallDiscountValue,
        discountFinalTotal: discountFinalTotal,
        currency: { value: currency?.id, label: currency?.Currency?.name, Symbol: currency?.Currency?.currencySymbol },
      })

      setcurrencyData({
        currencySymbol: currency?.Currency?.currencySymbol,
        label: currency?.Currency?.name,
        rate: currency?.rate,
        value: currency?.Currency?.id
      })
      setcurrencySelect({
        value: currency?.id,
        label: currency?.Currency?.name,
        rate: currency?.rate,
      })



    } catch (error) {
      let errorData = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorData)
    }
  };
  useEffect(() => {
    if (quotationId) {
      fetchData(quotationId);
    } else {
      seteditView(false)
    }
    if (prefetchValue?.currencyExchangeList?.length > 0 && quotationId) {
      let currencyDefaultValue = prefetchValue?.currencyExchangeList?.filter((currency) => currency?.label === "Rupee")
      if (currencyDefaultValue) {
        setInitialQuotation(prev => ({
          ...prev,
          Currency: Number(currencyDefaultValue?.[0]?.value),
          currencyExchangeRate: currencyDefaultValue?.[0]?.rate
        }));
        setcurrencyData(currencyDefaultValue?.[0])
        setcurrencySelect({
          value: currencyDefaultValue?.[0]?.value,
          label: currencyDefaultValue?.[0]?.label,
          rate: currencyDefaultValue?.[0]?.rate
        })
      }

    }

  }, [quotationId]);

  useEffect(() => {
    const leadfetchData = async () => {
      console.log(leadData && quotationId);

      if (leadData && quotationId === null) {
        console.log(leadData, quotationId);
        let peremeter = `id: "${Number(leadData?.customer?.id)}", customer:true , isLead:null `
        const response = await commanFetch(SupplierOptionPerameter(peremeter))
        const reponseData = response?.data?.items
        if (reponseData.length > 0) {
          setSupplierNoSelected({
            value: reponseData[0]?.id,
            label: reponseData[0]?.supplierNo,
          });
          setSupplierNameSelected({
            value: reponseData[0]?.id,
            label: reponseData[0]?.companyName,
          });
          setquotationAddressContactData({
            buyerAddress: {
              value: reponseData[0]?.address?.find(address_ => address_.default)?.id || '',
              label: reponseData[0]?.address?.find(address_ => address_.default)?.addressType || '',
              fullAddredd: reponseData[0]?.address?.find(address_ => address_.default) || '',
            },
            buyerContactPerson: {
              value: reponseData[0]?.contact?.find(contact_ => contact_.default)?.id || '',
              label: reponseData[0]?.contact?.find(contact_ => contact_.default)?.contactPersonName || '',
              mobile: reponseData[0]?.contact?.find(contact_ => contact_.default)?.phoneNumber || '',
              Email: reponseData[0]?.contact?.find(contact_ => contact_.default)?.email || '',
            },
          });
          setCustomerDetailsLists({
            buyerContactList: reponseData[0]?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
            buyerAddressList: reponseData[0]?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ }))
          })
          setsalesPersonSelect({
            value: leadData ? leadData?.salesPerson?.id : '',
            label: leadData ? leadData?.salesPerson?.username : '',
          });

          setleadSelect(leadData?.leadNo)
          setInitialQuotation(prevState => ({
            ...prevState,
            supplierNumber: reponseData[0]?.supplierNo,
            supplierName: reponseData[0]?.companyName,
            salesPerson: leadData ? Number(leadData?.salesPerson?.id) : "",
            buyerAddress: reponseData[0]?.address?.find(addr => addr.default)?.id ? Number(reponseData[0].address.find(addr => addr.default).id) : '',
            buyerContactPerson: leadData ? Number(reponseData[0]?.contact?.find(contact_ => contact_.default)?.id) : '',
            LeadNumber: leadData?.id ? leadData?.id : '',
            Currency: Number(leadData?.leadCurrency?.id),
            currencyExchangeRate: leadData?.leadCurrency?.[0]?.rate
          }));
          setcurrencyData(leadData?.leadCurrency)
          setcurrencySelect({
            value: leadData?.leadCurrency?.id,
            label: leadData?.leadCurrency?.Currency.name,
            rate: leadData?.leadCurrency?.rate
          })
        }
      }
    }
    if (leadData?.customer?.id) {
      leadfetchData();
    }
  }, [leadData]);
  // Function to handle confirmation for discount warnings
  const handleWarningConfirmationOverallDiscount = (data) => {
    if (data === "yes") {
      if (checkDiscount === false) {
        setAfterOverallDiscount(true)
        setCheckedDiscount(true);
      }
      else {
        setCheckedDiscount(false)
        setAfterOverallDiscount(false)
      }
      clearDiscountData()
    }
  }
  const handleWarningConfirmationAmandCancel = async (data) => {
    if (data === "yes") {
      const reqBody = {
        itemDetails: itemDetailsData?.map(item => item?.id),
        otherIncomeCharge: otherIncomeChargesData?.map(item => item?.id)
      }
      try {
        const respones = await axiosInstance.post(`/itemmaster/graphql`, {
          query: quotationAmandCancel(reqBody)
        })
        const responseData = respones?.data?.data?.quotationsAmandCancel
        if (responseData?.success) {
          resetForm()
        }
        else {
          let errorFilter = ShowErrorNoties(responseData?.errors)
          showErrorToast(true, 'error', errorFilter)
        }
        closeQuotationModal(index)
      } catch (error) {
        let errorData = ShowErrorNoties(error)
        showErrorToast(true, 'error', errorData)
      }
    }
    else {
      setwarningAmandCancelShow(false)
    }
  }

  useEffect(() => {

    // Effect to calculate final amounts and taxes based on item details and other income charges
    if (itemDetailsData?.length >= 0) {

      let item_value = 0;
      let other_income_value = 0
      let actual_item_value = 0
      let actual_other_income_value = 0

      const igstList = {};
      const cgstList = {};
      const sgstList = {};

      // This actual tax with discount
      const actualIgstList = {}
      const actualCgstList = {}
      const actualSgstList = {}
      // Calculate total amount and populate tax lists
      itemDetailsData?.forEach(itemValue => {
        item_value += Number(itemValue?.amount);
        actual_item_value += Number((itemValue.rate) * (itemValue?.qty));
        if (itemValue.igst) {
          igstList[itemValue?.igst] = (igstList[itemValue?.igst] || []).concat(itemValue?.amount);
          actualIgstList[itemValue.igst] = (actualIgstList[itemValue.igst] || []).concat((itemValue.rate) * (itemValue?.qty));

        }
        if (itemValue.cgst) {
          cgstList[itemValue?.cgst] = (cgstList[itemValue?.cgst] || []).concat(itemValue?.amount);
          actualCgstList[itemValue.cgst] = (actualCgstList[itemValue.cgst] || []).concat((itemValue.rate) * (itemValue?.qty));

        }
        if (itemValue.sgst) {
          sgstList[itemValue?.sgst] = (sgstList[itemValue?.sgst] || []).concat(itemValue?.amount);
          actualSgstList[itemValue.sgst] = (actualSgstList[itemValue.sgst] || []).concat((itemValue.rate) * (itemValue?.qty));

        }
      });
      otherIncomeChargesData?.forEach(itemValue => {
        let other_income_amount = itemValue?.afterDiscountValue ? Number(itemValue?.afterDiscountValue) : Number(itemValue.amount);
        other_income_value += other_income_amount
        actual_other_income_value += Number(itemValue.amount)
        if (itemValue.igst) {
          igstList[itemValue.igst] = (igstList[itemValue.igst] || []).concat(other_income_amount);
          actualIgstList[itemValue.igst] = (actualIgstList[itemValue.igst] || []).concat(other_income_amount);
        }
        if (itemValue.cgst) {
          cgstList[itemValue.cgst] = (cgstList[itemValue.cgst] || []).concat(other_income_amount);
          actualCgstList[itemValue.cgst] = (actualCgstList[itemValue.cgst] || []).concat(other_income_amount);

        }
        if (itemValue.sgst) {
          sgstList[itemValue.sgst] = (sgstList[itemValue.sgst] || []).concat(other_income_amount);
          actualSgstList[itemValue.sgst] = (actualSgstList[itemValue.sgst] || []).concat(other_income_amount);
        }
      });
      // Calculate taxes
      const { taxResult: igstResult, taxValues: igstaxvalues } = calculateTax(igstList);
      const { taxResult: cgstResult, taxValues: cgstaxvalues } = calculateTax(cgstList);
      const { taxResult: sgstResult, taxValues: sgstaxvalues } = calculateTax(sgstList);

      // // actual taxes
      const { taxResult: actualigstResult, taxValues: actualigstaxvalues } = calculateTax(actualIgstList);
      const { taxResult: actualcgstResult, taxValues: actualcgstaxvalues } = calculateTax(actualCgstList);
      const { taxResult: actualsgstResult, taxValues: actualgstaxvalues } = calculateTax(actualSgstList);

      let actualNetAmount = Number(actualigstaxvalues) + Number(actualcgstaxvalues) + Number(actualgstaxvalues) + Number(actual_item_value) + actual_other_income_value
      const newFinalAmount = roundToNearest10(igstaxvalues + cgstaxvalues + sgstaxvalues + item_value + other_income_value).toFixed(2)
      // Update state only if values have changed
      let totalTax = (igstaxvalues + cgstaxvalues + sgstaxvalues).toFixed(2)
      let roundOff = (newFinalAmount - (igstaxvalues + cgstaxvalues + sgstaxvalues + item_value + other_income_value)).toFixed(2)
      const itemDetailsds = itemDetailsData?.map(item => parseInt(item.id, 10));
      const otherIncomeIds = otherIncomeChargesData?.map(item => parseInt(item.id, 10));
      setToSaveData(prve => ({
        ...prve, totaltax: totalTax, beforeTax: item_value.toFixed(2),
        otherIncomeChagersBeforeTax: other_income_value.toFixed(2), netAmount: newFinalAmount,
        roundOff: roundOff, sgst: sgstResult, cgst: cgstResult, igst: igstResult, itemDatalist: itemDetailsds,
        otherIncomeChargesId: otherIncomeIds, actualNetAmount: roundToNearest10(actualNetAmount), actualigstResult: actualigstResult,
        actualcgstResult: actualcgstResult, actualsgstResult: actualsgstResult

      }))
      setisSaveOtherIncomeCharges(false)
    }
  }, [itemDetailsData, isSaveOtherIncomeCharges]);

  const forWord = () => {
    for (let x in versionList) {
      if (Number(currentVersionId) === Number(versionList[x])) {
        let nextIndex = Number(x) + 1;
        if (nextIndex < versionList.length) {
          // close() 

          setIsFirst(false);
          setLoading(true)
          fetchData(versionList[nextIndex])
          setCurrentVersionId(versionList[nextIndex]);
          if (nextIndex === versionList.length - 1) {
            setIsLast(true);
          } else {
            setIsLast(false);
          }
        }
        break;
      }
    }
  };

  const backWord = () => {
    for (let x in versionList) {
      if (Number(currentVersionId) === Number(versionList[x])) {
        let prevIndex = Number(x) - 1;

        if (prevIndex >= 0) {
          setIsLast(false);
          setLoading(true)
          fetchData(versionList[prevIndex])

          setCurrentVersionId(versionList[prevIndex]);
          if (prevIndex === 0) {
            setIsFirst(true);
          } else {
            setIsFirst(false);
          }

        }
        break;
      }
    }
  };

  const handleNewItem = async newItem => {
    // Function to handle adding or updating an item in the item details list
    await setItemDetailsData(prevItems => {
      const itemIndex = prevItems.findIndex(item => item.id === newItem.id);
      if (itemIndex !== -1) {
        const updatedItems = [...prevItems];// Create a copy of the previous items
        updatedItems[itemIndex] = newItem; // Update the item at the found index
        return updatedItems; // Return the updated list
      } else {
        // If the item does not exist, add the new item to the list
        return [...prevItems, newItem];
      }
    });
  };


  async function getOptions(e, query, name) {
    // Function to fetch options based on user input
    if (query === 'SupplierOptionsOptionsQuery') {
      if (name === 'supplierNumber') {
        let peremeter = `supplierNo: "${e}", customer:true `
        const response = await commanFetch(SupplierOptionPerameter(peremeter))
        const reponseData = response?.data?.items
        if (reponseData.length > 0) {
          setSupplierNoList(
            reponseData?.map(item => ({
              value: item?.id,
              label: item?.supplierNo,
              address: item?.address || [],
              companyName: item?.companyName,
              contact: item?.contact
            }))
          )
        }
      }
      else if (name === 'supplierName') {
        let peremeter = `companyName: "${e}", customer:true `
        const response = await commanFetch(SupplierOptionPerameter(peremeter))
        const reponseData = response?.data?.items
        if (reponseData?.length > 0) {
          setSupplierNameList(
            reponseData?.map(item => ({
              value: item?.id,
              label: item?.companyName,
              address: item?.address || [],
              supplierNo: item?.supplierNo,
              contact: item?.contact
            }))
          )
        }
      }

    }
    if (query === 'DepartmentQuery') {
      let peremeter = `name: "${e}" `
      const response = await commanFetch(DepartmentQuery(peremeter))
      const responseData = response?.data?.items
      if (responseData?.length > 0) {
        setdepartmentList(
          responseData?.map(item => ({ value: item?.id, label: item?.name }))
        )
      }
    }
    if (query === "TermsConditionsFetchQuery") {
      const response = await commanFetch(TermsConditionsQuotation(e))
      const responseData = response?.data?.items
      setTCList(responseData.map((item) => (
        { value: item?.id, label: item?.name, tc: item?.tc }
      )))

    }
    if (query === 'salesPerson') {
      let peremeter = `userName:"${e}", isSales: true`
      const response = await commanFetch(userPermissionOptions(peremeter))
      const responseData = response?.data?.items
      setSalesPersonList(responseData?.map((user) => ({ value: user?.userId?.id, label: user?.userId?.username })))


    }
  }

  const resetForm = async () => {
    // Function to reset the form to its initial state

    closeQuotationModal(index)
    setInitialQuotation({
      id: "",
      supplierNumber: '',
      supplierName: '',
      department_id: '',
      remark: '',
      QuatationNumber: '',
      QuotationDate: '',
      termsConditions: '',
      TermsOptions: "",
      childCount: "",
      modifiedBy: "",
      status: "",
      createdBy: {
        id: '',
        name: ''
      },
      createdAt: "",
      createdTime: "",
      isAmend: false,
      isDuplicate: "",
      currencyExchangeRate: '',
      active: ""
    });
    setleadSelect("")
    setOtherIncomeChargesData([]);
    seteditView(false)
    setSupplierNoList([]);
    setSupplierNoSelected(null);
    setSupplierNameSelected(null);
    setSupplierNameList([]);
    setDepartmentSelect([]);
    setItemDetailsData([]);
    setQuotationInputValue({ address: '', remark: '' });
    setTCList([]);
    setTermsOptions(null);
    setTermsConditions(null);
    setquotationStatus(" ")
    setOverallDiscountPercentage("")
    setOverallDiscountValue("")
    setFinalTotal("")
    setCheckedDiscount(false)
    setVersionList([])
    setErrorMessage("")
    setquotationAddressContactData({
      buyerAddress: "", buyerContactPerson: "",
    })
    setsalesPersonSelect("")
    setSalesPersonList([])
    setCustomerDetailsLists({
      "buyerAddressList": [], "buyerContactList": [], "buyerStatesList": [],
    })
    setcurrencyData("")
    if (setLoad) {
      setLoad()
    }

    // setIsEnableShowDiscount(false)
  };

  const clearDiscountData = async () => {
    const ids = itemDetailsData?.map(item => parseInt(item.id, 10));
    const otherIncomeIds = otherIncomeChargesData?.map(item => parseInt(item.id, 10));
    const requestBody = {
      ids: [...ids],
      otherIncomeId: [...otherIncomeIds],
    };
    const response = await commanFetch(discountClearAllQuery(requestBody));
    const responseData = response?.data;
    // Update itemDetailsData
    setItemDetailsData(responseData?.items);
    ////formatting according to Other Income Charges
    const transformedOtherIncomeChargesData = responseData?.quotationsOtherIncomeCharges?.map(item => ({
      ...item,
      account: {
        label: item.otherIncomeChargesId.name,
        tax: item.otherIncomeChargesId.hsn.gstRates.rate,
        value: item.otherIncomeChargesId.id,
      },
      tax: item.otherIncomeChargesId.hsn.gstRates.rate,
      currencyId: Number(currencyData?.value),
    }));
    setOtherIncomeChargesData(transformedOtherIncomeChargesData);
    setOverallDiscountPercentage('')
    setOverallDiscountValue('')
    setFinalTotal('')
    showErrorToast(true, 'success', "", "Discount Cleared Successfully")

  };

  async function CreateDuplicateData() {
    const reqBody = {
      id: Number(initialQuotation?.id),
      userId: Number(userId)
    }
    try {
      const respones = await axiosInstance.post(`/itemmaster/graphql`, {
        query: quotationsAmand(reqBody)
      })
      const responseData = respones?.data?.data?.quotationsAmand
      if (responseData?.success) {
        setItemDetailsData(responseData?.itemDetails?.map(item => ({
          ...item,
          amount: item?.amount ? Number(item?.amount).toFixed(3) : item?.amount,
          rate: item?.rate ? Number(item?.rate).toFixed(3) : item?.rate
        })));
        const transformedOtherIncomeChargesData = responseData?.otherIncomeCharge?.map(item => ({
          ...item,
          account: {
            label: item.otherIncomeChargesId.name,
            tax: item.otherIncomeChargesId.hsn.gstRates.rate,
            value: item.otherIncomeChargesId.id,
          },
          tax: item.otherIncomeChargesId.hsn.gstRates.rate,
          currencyId: Number(currencyData?.value),
        }));
        setOtherIncomeChargesData(transformedOtherIncomeChargesData);
        setFinalTotal('')
        setOverallDiscountPercentage('')
        setOverallDiscountValue('')
        seteditView(false)
        showErrorToast(true, 'success', "", "Duplicate ItemDetails And Other income Charges Created Successfully")
        setLoading(false)
      }
      else {
        let errorData = ShowErrorNoties(responseData?.errors)
        showErrorToast(true, 'error', errorData)
      }
    } catch (error) {
      setLoading(false)
      let errorData = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorData)
    }

  }
  function duplicaTheData(isDup) {
    setLoading(true)
    setOtherIncomeChargesData([])
    setItemDetailsData([])
    setInitialQuotation(prev => ({
      ...prev, status: "", createdBy: null, createdAt: "", isDuplicate: isDup, parentOrder: initialQuotation?.id, QuatationNumber: isDup ? "" : prev?.QuatationNumber,
      QuotationDate: isDup ? "" : prev?.QuotationDate, id: ""
    }))
    CreateDuplicateData()
  }

  const handleQuotationFromSubmit = async (values, { resetForm, setErrors }) => {
    // Function to handle the form submission for the quotation
    if (values?.status === "Draft" || values?.status === "Submit") {
      if (itemDetailsData.length > 0) {
        setLoading(true)
        let requestBody = {
          id: values?.id ? Number(values?.id) : "",
          itemTotalBeforTax: toSaveData?.beforeTax,
          cgst: toSaveData.cgst,
          createdBy: values?.createdBy?.id ? Number(values?.createdBy?.id) : Number(userId),
          customerAddress: Number(values?.buyerAddress),
          customerContactPerson: Number(values?.buyerContactPerson),
          salesPerson: values?.salesPerson ? values?.salesPerson : "",
          customerId: Number(supplierNoSelected?.value),
          leadNo: initialQuotation?.LeadNumber ? Number(initialQuotation?.LeadNumber) : "",
          department: Number(values?.department_id),
          roundOff: toSaveData?.roundOff,
          discountFinalTotal: finalTotal ? finalTotal : "",
          igst: toSaveData.igst,
          parentOrder: "",
          childCount: "",
          itemDetails: toSaveData?.itemDatalist,
          netAmount: toSaveData?.netAmount,
          otherIncomeCharge: toSaveData?.otherIncomeChargesId,
          overallDiscountPercentage: overallDiscountPercentage ? overallDiscountPercentage : "",
          overallDiscountValue: overallDiscountValue ? overallDiscountValue : "",
          remarks: values?.remark,
          sgst: toSaveData?.sgst,
          status: values?.status ? values?.status : "Draft",
          taxTotal: toSaveData?.totaltax,
          otherChargesBeforTax: toSaveData?.otherIncomeChagersBeforeTax,
          termsConditions: Number(values?.TermsOptions),
          termsConditionsText: values?.termsConditions,
          currency: Number(values?.Currency)
        }
        if (values?.isAmend) {

          requestBody.id = "";
          requestBody.createdBy = Number(userId);
          requestBody.parentOrder = initialQuotation?.parentOrder ? Number(initialQuotation?.parentOrder) : "";
          requestBody.childCount = initialQuotation.childCount > 0 ? Number(initialQuotation?.childCount) + 1 : 1;
          requestBody.modifiedBy = "";
          requestBody.status = "Submit";
        }

        let allow_null = ["id", "modifiedBy", "overallDiscountPercentage", "overallDiscountValue", "discountFinalTotal", "remarks", "parentOrder", "childCount", "leadNo"]
        let clear_data = removeEmptyValueInObject(requestBody, allow_null)
        try {
          const respones = await axiosInstance.post(`/itemmaster/graphql`, {
            query: quotationCreateMutation(clear_data, allow_null)
          })
          const responseData = respones?.data?.data?.quotationsCreateMutation
          const versionData = responseData?.version ? responseData?.version?.versionList : []
          setVersionList(versionData)

          if (responseData?.success === true) {
            if (setQuotationsList) {
              if (setQuotationsList) {
                setQuotationsList(prev => {
                  if (!Array.isArray(prev)) {
                    return [responseData?.quotations]; // Initialize with the new quotation if `prev` is not an array
                  }

                  const updatedList = prev.map(quotation =>
                    quotation.id === responseData?.quotations.id ? responseData?.quotations : quotation
                  );

                  const isUpdated = updatedList.some(quotation => quotation.id === responseData?.quotations.id);

                  return isUpdated ? updatedList : [...updatedList, responseData?.quotations];
                });
              }


            }
            const {
              id,
              quotationNo,
              CreatedAt,
              createdBy,
              remarks,
              customerAddress,
              customerId,
              department,
              itemDetails,
              otherIncomeCharge,
              status,
              salesPerson,
              customerContactPerson,
              termsConditions,
              termsConditionsText,
              discountFinalTotal,
              overallDiscountValue,
              overallDiscountPercentage,
              active,
              currency,
              leadNo,
              childCount
            } = responseData?.quotations


            const linkedModelId = quotationNo?.linkedModelId || '';
            const createdAt = formateGivenStringdate(CreatedAt);
            const address = {
              addressLine1: customerAddress?.addressLine1 || '',
              addressLine2: customerAddress?.addressLine2 || '',
              city: customerAddress?.city || '',
              state: customerAddress?.state || '',
              pincode: customerAddress?.pincode || '',
              id: customerAddress?.id || ''
            };
            setCurrentVersionId(id)
            setquotationStatus(status?.name)
            // Update states in one go
            setInitialQuotation(prevState => ({
              ...prevState,
              id: id,
              QuatationNumber: linkedModelId,
              QuotationDate: createdAt,
              remark: remarks || '',
              department_id: department?.id,
              TermsOptions: termsConditions?.id,
              termsConditions: termsConditionsText,
              status: status?.name,
              supplierNumber: customerId?.supplierNo,
              supplierName: customerId?.companyName,
              createdBy: {
                id: createdBy?.id,
                name: createdBy?.username
              },
              createdAt: createdAt,
              createdTime: TimeFormate(CreatedAt),
              childCount: childCount,
              isAmend: false,
              isDuplicate: false,
              salesPerson: salesPerson ? Number(salesPerson?.id) : "",
              buyerAddress: customerAddress ? Number(customerAddress?.id) : '',
              buyerContactPerson: customerContactPerson ? Number(customerContactPerson?.id) : '',
              active: active,
              LeadNumber: leadNo ? leadNo?.id : '',
              Currency: currency?.id ? currency?.id : '',
              currencyExchangeRate: currency?.rate
            }));

            setSalesOrderData({
              Quotation: { "id": id, "QuatationNumber": linkedModelId },
              Department: { value: department?.id, label: department?.name },
              SalesPerson: { value: salesPerson?.id, label: salesPerson?.username },
              CustomerCode: { value: customerId?.id, label: customerId?.supplierNo },
              lead: leadNo,
              overallDiscountPercentage: overallDiscountPercentage,
              overallDiscountValue: overallDiscountValue,
              discountFinalTotal: discountFinalTotal,
              currency: { value: currency?.id, label: currency?.Currency?.name, Symbol: currency?.Currency?.currencySymbol },
            })

            setQuotationInputValue(prevState => ({
              ...prevState,
              address: address,
            }));

            setSupplierNameSelected({
              value: customerId?.id,
              label: customerId?.companyName,
            });

            setSupplierNoSelected({
              value: customerId?.id,
              label: customerId?.supplierNo,
            });

            setDepartmentSelect({
              value: department?.id,
              label: department?.name,
            });
            setTermsOptions({
              value: termsConditions?.id,
              label: termsConditions?.name,
            })
            setleadSelect(leadNo?.leadNo)
            setsalesPersonSelect({ value: salesPerson?.id, label: salesPerson?.username })
            setquotationAddressContactData({
              buyerAddress: { value: customerAddress?.id, label: customerAddress?.addressType, fullAddredd: customerAddress },
              buyerContactPerson: { value: customerContactPerson?.id, label: customerContactPerson?.contactPersonName, mobile: customerContactPerson?.phoneNumber, Email: customerContactPerson?.email },
            })
            setOverallDiscountPercentage(overallDiscountPercentage)
            setOverallDiscountValue(overallDiscountValue)
            setFinalTotal(discountFinalTotal)
            setTermsConditions(termsConditionsText)
            // setcurrencyData(currency)
            setcurrencyData({
              currencySymbol: currency?.Currency?.currencySymbol,
              label: currency?.Currency?.name,
              rate: currency?.rate,
              value: currency?.Currency?.id
            })
            setcurrencySelect({
              value: currency?.id,
              label: currency?.Currency?.name,
              rate: currency?.Currency?.rate,
            })
            // Set the item details
            if (itemDetails && Array.isArray(itemDetails)) {
              setItemDetailsData(itemDetails);
            }
            if (otherIncomeCharge && Array.isArray(otherIncomeCharge)) {
              // Update other income charges accordingly 
              const transformedOtherIncomeChargesData = otherIncomeCharge?.map(item => ({
                ...item,
                account: {
                  label: item?.otherIncomeChargesId?.name,
                  tax: item?.otherIncomeChargesId?.hsn?.gstRates?.rate,
                  value: item?.otherIncomeChargesId?.id,
                },
                tax: item?.otherIncomeChargesId?.hsn?.gstRates?.rate,
                currencyId: Number(currencyData?.value),
              }));

              setOtherIncomeChargesData(transformedOtherIncomeChargesData);
            }
            if (status?.name === "Submit") {
              seteditView(true)
            }

            // setStatus('')
            toast.success(`${status?.name} Successfully.`, {
              position: 'bottom-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light'
            })
          }
          else {
            if (responseData?.outOfRangeErrors) {
              setShowWarningModalWithCopy(true)
              setErrorMessagesWarningModalWithCopy(responseData?.outOfRangeErrors);
            } else {
              let errorData = ShowErrorNoties(responseData?.errors)
              showErrorToast(true, 'error', errorData)
            }

          }

          setLoading(false)
        } catch (error) {
          setLoading(false)
          let errorData = ShowErrorNoties(error)
          showErrorToast(true, 'error', errorData)
        }
      }
      else {
        showErrorToast(true, 'error', "Select Atleast One Item Details ...")
      }
    }
  }

  function BeforeCloseCheckData(params) {
    if (initialQuotation?.id === "" && (itemDetailsData?.length > 0 || otherIncomeChargesData?.length > 0)) {
      setwarningAmandCancelShow(true)
      setErrorMessage("Are you sure you want to leave?")
    } else {
      resetForm()
    }
  }

  async function QuotationBulkUpdateForTaxmutations(state) {
    let itemIdList = itemDetailsData?.map(item => {
      const id = Number(item?.id);
      return isNaN(id) ? null : id; // Return null for invalid IDs
    }).filter(id => id !== null) || []; // Filter out nulls and default to an empty array

    let otherIncomeChargeIdList = otherIncomeChargesData?.map(item => {
      const id = Number(item?.id);
      return isNaN(id) ? null : id; // Return null for invalid IDs
    }).filter(id => id !== null) || []; // Filter out nulls and default to an empty array
    let parame = `itemDetialsIds:[${itemIdList}], otherIncomeChargesIds:[${otherIncomeChargeIdList}],states:"${state ? state : null}"`

    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, {
        query:
          quotationsBulkUpdateForTax(parame)
      });
      const reponseData = response?.data?.data?.quotationsBulkUpdateForTax
      setItemDetailsData(reponseData?.QuotationsItemDetails)
      const transformedOtherIncomeChargesData = reponseData?.QuotationsOtherincomeChanges?.map(item => ({
        ...item,
        account: {
          label: item?.otherIncomeChargesId?.name,
          tax: item?.otherIncomeChargesId?.hsn?.gstRates?.rate,
          value: item?.otherIncomeChargesId?.id,
        },
        tax: item?.otherIncomeChargesId?.hsn?.gstRates?.rate,
        currencyId: Number(currencyData?.value),
      }));
      setOtherIncomeChargesData(transformedOtherIncomeChargesData)
      showErrorToast(true, 'success', "", "Tax Values Updated Successfully")
    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorFilter, "")
    }
  }

  // return null; convertToSalesOrder
  function CreateSalesOrder(params) {
    setIsShowSalesOrder(true)
  }

  // return null; Cancel the Quotations
  async function CancelTheQuotations() {
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: QuotationsCancel(`id:${initialQuotation?.id}`) })
      let reponseData = response?.['data']?.['data']?.['quotationsCanceledMutation']
      if (reponseData?.success) {
        setInitialQuotation(prev => ({ ...prev, status: "Canceled" }))
      } else {
        showErrorToast(true, "error", reponseData?.errors)
      }
    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, "error", errorFilter)
    }

  }

  // return null; Delete the Quotations
  async function DeleteTheQuotations() {
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: quotationsDeleteMutation(`id:${initialQuotation?.id}`) })
      let reponseData = response?.['data']?.['data']?.['quotationsDeleteMutation']
      if (reponseData?.success) {
        resetForm()
      } else {
        showErrorToast(true, "error", reponseData?.errors)
      }
    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, "error", errorFilter)
    }

  }


  async function quotationCurrencyConvertion(CurrencyId, previousCurrencyId) {
    setLoading(true)

    let itemIdList = itemDetailsData?.map(item => {
      const id = Number(item?.id);
      return isNaN(id) ? null : id; // Return null for invalid IDs
    }).filter(id => id !== null) || []; // Filter out nulls and default to an empty array

    let otherIncomeChargeIdList = otherIncomeChargesData?.map(item => {
      const id = Number(item?.id);
      return isNaN(id) ? null : id; // Return null for invalid IDs
    }).filter(id => id !== null) || []; // Filter out nulls and default to an empty array
    let parame = `itemIdList:[${itemIdList}], otherIncomeChargeIdList:[${otherIncomeChargeIdList}], currentCurrency:${CurrencyId ? Number(CurrencyId?.value) : null}, preCurrency:${previousCurrencyId ? previousCurrencyId : null}`

    try {

      const response = await axiosInstance.post(`/itemmaster/graphql`, {
        query:
          quotationCurrencyConvertions(parame)
      });
      const responseData = response?.data?.data?.quotationsCurrencyConvertions
      if (responseData?.success) {
        setItemDetailsData(responseData?.QuotationsItemDetails);
        const transformedOtherIncomeChargesData = responseData?.QuotationsOtherincomeChanges?.map(item => ({
          ...item,
          account: {
            label: item?.otherIncomeChargesId?.name,
            tax: item?.otherIncomeChargesId?.hsn?.gstRates?.rate,
            value: item?.otherIncomeChargesId?.id,
          },
          tax: item?.otherIncomeChargesId?.hsn?.gstRates?.rate,
          currencyId: Number(currencyData?.value),
        }));

        setOtherIncomeChargesData(transformedOtherIncomeChargesData);
        setOverallDiscountPercentage('')
        setOverallDiscountValue('')
        setFinalTotal('')
        showErrorToast(true, 'success', "", "Currency Updated Successfully")
        setLoading(false)
        return true
      } else {
        let errorFilter = ShowErrorNoties(responseData?.errors)
        showErrorToast(true, 'error', errorFilter, "")
        setLoading(false)
        return false
      }

    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorFilter, "")
      return false
    }

  }
  async function genPDF() {
    setLoading(true)
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, {
        query:
          quotationsgeneratePDF(`id:${initialQuotation?.id}`)
      });
      const reponseData = response?.data?.data
      if (reponseData?.generatePDF?.success) {
        const base64Data = reponseData.generatePDF.pdfData;

        // Convert base64 string to a Blob
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([byteNumbers], { type: 'application/pdf' });
        // vnd.openxmlformats-officedocument.wordprocessingml.document


        // Create a link element for downloading
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.target = '_blank';
        link.click(); // Trigger the download

        // Clean up
        window.URL.revokeObjectURL(link.href);
      }

    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorFilter, "")
      return false
    }
    setLoading(false)
  }
  // fetch time line Datas
  async function fetchTimelineData(id) {
    // setLoading(true)
    try {
      const respones = await axiosInstance.post(`/itemmaster/graphql`, {
        query: leadTimeLine(id)
      })
      let timeLime = respones?.data?.data?.leadTimeLine
      setTimeLineValue(prev => ({ ...prev, treeData: [timeLime], isShow: true }))
    } catch (error) {
      console.log(error);
    }
    // setLoading(false)
  }
  function getOpenmodelId(id, model) {
    if (id && model === "Quotation") {
      openQuotationModal(id)
    } else if (id && model === "Sales Order") {
      openSalesOrderModal(id)
    } else if (id && model === "Lead") {
      openLeadModal(id)
    }

  }

  async function getOptionOtherIncome(e, queryName) {
    let search_term = e.trim().replace(/"/g, '\\"')
    if (queryName === 'accountList' && search_term) {
      let param = `name:"${search_term}"`
      const response = await commanFetch(otherIncomeChargesList(param))
      if (response.success) {
        const responseData = response?.data?.items
        const activeItems = responseData?.filter(item => item?.account?.accountsActive);
        // setAccountList(
        //   activeItems?.map(data => ({
        //     value: data?.id,
        //     label: data?.name,
        //     tax: data?.hsn?.gstRates?.rate,
        //   }))
        // );
        setotherIncomeChargesvalues(prev => ({
          ...prev,
          accountList: activeItems?.map(data => ({
            value: data?.id,
            label: data?.name,
            tax: data?.hsn?.gstRates?.rate,
          }))
        }))
      }
    } else {
    }
  }
  const renderAmountOtherIncome = (item, rowData) => {
    let amount = item?.amount !== undefined ? item?.amount : 0;
    let discountValue = item?.discountValue !== undefined ? item?.discountValue : 0;
    let afterDiscountValue = item?.afterDiscountValue !== undefined ? item?.afterDiscountValue : 0;
    if (discountValue) {
      return `${amount} - ${discountValue} = ${afterDiscountValue}`;
    }
    else {
      return `${amount}`
    }

  };
  const handleSaveRowOtherincome = async index => {
    let requestBody = {
      id: otherIncomeChargesvalues?.otherIncomeValue.id ? Number(otherIncomeChargesvalues?.otherIncomeValue.id) : '',
      otherIncomeChargesId: Number(otherIncomeChargesvalues?.otherIncomeValue?.account?.value),
      amount: otherIncomeChargesvalues?.otherIncomeValue.amount,
      createdBy: otherIncomeChargesvalues?.otherIncomeValue?.id ? otherIncomeChargesvalues?.otherIncomeValue?.createdBy : Number(userId),
      tax: otherIncomeChargesvalues?.otherIncomeValue?.tax ? Number(otherIncomeChargesvalues?.otherIncomeValue?.tax) : "",
      states: quotationAddressContactData ? quotationAddressContactData?.buyerAddress?.fullAddredd?.state : ''
    };

    let ClearValue
    if (otherIncomeChargesvalues?.otherIncomeValue?.id) {
      requestBody.modifiedBy = Number(userId);
      ClearValue = removeEmptyValueInObject(requestBody, [])
    }
    else {
      ClearValue = removeEmptyValueInObject(requestBody, ['id', 'modifiedBy'])
    }
    try {
      setLoading(true)
      const response = await axiosInstance.post(`/itemmaster/graphql`, {
        query: quotationsOtherIncomeChargesCreateMutation(ClearValue)
      })
      const responseData = response?.data?.data?.quotationsOtherIncomeChargesCreateMutation
      if (responseData.success) {
        const updatedRows = otherIncomeChargesData?.map((row, i) => {
          if (i === index) {
            return {
              ...row,
              id: responseData?.quotationsOtherIncomeCharges?.id ?? row.id,
              amount: responseData?.quotationsOtherIncomeCharges?.amount ?? row.amount,
              account: {
                value: responseData?.quotationsOtherIncomeCharges?.otherIncomeChargesId?.id ?? row.account.value,
                label: responseData?.quotationsOtherIncomeCharges?.otherIncomeChargesId?.name ?? row.account.label,
                tax: responseData?.quotationsOtherIncomeCharges?.otherIncomeChargesId?.hsn?.gstRates?.rate ?? row.account.tax,
              },
              tax: responseData?.quotationsOtherIncomeCharges?.tax,
              createdBy: Number(responseData?.quotationsOtherIncomeCharges?.createdBy?.id),
              currencyId: Number(currencyData?.value),
              isEditing: false
            };
          }
          return row; // Return the original row if no changes are made
        });

        setisSaveOtherIncomeCharges(true)
        setOtherIncomeChargesData(updatedRows)
        if (isAfterOverallDiscount) {
          const idList = itemDetailsData?.map(item => parseInt(item?.id, 10));
          console.log("otherIncomeChargesData-------------", otherIncomeChargesData);

          const otherIncomeIds = otherIncomeChargesData.filter(item => item?.id && item.id.trim() !== '').map(item => Number(item.id));
          const newId = Number(responseData?.quotationsOtherIncomeCharges?.id)
          otherIncomeIds.push(newId)
          await clearDiscountData(idList, otherIncomeIds);

        }
        // setOtherIncomeValue(prve => ({ ...prve, editIndex: "", account: "", tax: "", amount: "" }))
        setotherIncomeChargesvalues(prev => ({
          ...prev, // Spread the previous state to preserve other parts of the state
          otherIncomeValue: {
            ...prev.otherIncomeValue,  // Spread the previous otherIncomeValue to keep other fields
            editIndex: "", account: "", tax: "", amount: ""
          },
          onNewItemSelect: false
        }));
        // setonNewItemSelect(false)
        setLoading(false)
      } else {
        let errorData = ShowErrorNoties(responseData.errors)
        showErrorToast(true, 'error', errorData)
        setLoading(false)
      }
    } catch (error) {
      let errorData = ShowErrorNoties(error)
      showErrorToast(true, 'error', errorData)
      setLoading(false)
    }

  };
  const handledeleteConfomation = (index) => {
    const updatedRow = otherIncomeChargesData[index];
    if (updatedRow) {
      handltoastDeleteConfomationShow()
      setotherIncomeChargesvalues(prev => ({
        ...prev,
        deleteData: {
          Name: updatedRow?.account?.label,
          id: updatedRow?.id
        },
        onNewItemSelect: false
      }))
    }
  }
  const handleNewRowotherincome = () => {
    const newRow = {
      id: '',
      index: otherIncomeChargesData ? otherIncomeChargesData.length === 0 ? 1 : otherIncomeChargesData.length + 1 : 1,
      account: '',
      tax: '',
      amount: '',
      createdBy: '',
      isEditing: true,
      afterDiscountValue: '',
      discountValue: ''
    }
    // setOtherIncomeValue(prve => ({ ...prve, editIndex: otherIncomeChargesData ? otherIncomeChargesData.length : 0 }))
    setotherIncomeChargesvalues(prev => ({
      ...prev, // Spread the previous state to preserve other parts of the state
      otherIncomeValue: {
        ...prev.otherIncomeValue,  // Spread the previous otherIncomeValue to keep other fields
        editIndex: otherIncomeChargesData ? otherIncomeChargesData.length : 0
      },
      onNewItemSelect: true
    }));
    setOtherIncomeChargesData([...otherIncomeChargesData, newRow])
  }
  const handleDeletedIdotherincome = deleted_id => {
    if (deleted_id) {
      console.log("deleted Id-------------", deleted_id);
      setOtherIncomeChargesData(prevRowData =>
        prevRowData.filter(row => row.id !== deleted_id)
      );
      setotherIncomeChargesvalues(prev => ({
        ...prev,
        onNewItemSelect: false,
        deleteData: { Name: '', id: '' }
      }));
      setisSaveOtherIncomeCharges(true)
    }
  }
  const ratecellrender = params => {
    let discount_percentage = params.data?.discountPercentage !== undefined ? params.data.discountPercentage : 0;
    let discount_value = params.data?.discountValue !== undefined ? params.data.discountValue : 0;
    let afterDiscountValue = params.data.afterDiscountValueForPerItem !== undefined ? params.data.afterDiscountValueForPerItem : 0;


    return (
      <>
        {params.data.rate}
        {Number(discount_percentage) !== 0
          ? -params.data.discountPercentage +
          ' % = ' +
          Number(afterDiscountValue).toFixed(2)
          : Number(discount_value) !== 0
            ? '-' +
            Number(discount_value).toFixed(2) +
            ' = ' +
            Number(afterDiscountValue).toFixed(2)
            : Number(afterDiscountValue) !== 0
              ? ' - ' +
              Number(afterDiscountValue).toFixed(2) +
              ' = ' +
              (params.data.finalValue !== undefined ? Number(params.data.finalValue).toFixed(2) : '0.00')
              : ''}
      </>
    );
  };
  const itemDetailsAction = ({ data }) => {
    // const handleItemdeleteConfomation = () => {
    //     if (data) {
    //         console.log("data", data);
    //         setdeleteData({
    //             Name: data?.itemmaster?.itemPartCode, id: data.id
    //         })
    //         handltoastDeleteConfomationShow()
    //     }
    // }
    const handleItemCombo = async () => {
      try {
        if (Array.isArray(data?.itemComboItemDetails) && data?.itemComboItemDetails.length === 0) {
          const reqBody = {
            itemmaster: data?.itemmaster?.id,
            amount: String(data?.amount),
            qty: String(data?.qty)
          }
          const response = await axiosInstance.post(`/itemmaster/graphql`, {
            query: getItemComboUnsavedDetails(reqBody)
          });
          console.log("getItemComboUnsavedDetails", response);

          const responseData = response?.data?.data?.getItemComboItemDetails?.itemComboData;
          console.log("responseData", responseData);
          const groupedData = parseAndGroupItemComboData(responseData);
          setitemDetailsValues(prev => ({
            ...prev,
            itemComboData: groupedData,
            lastUpdatedItemDetails: data
          }))
          setShowItemCombo(true)
        }
        else {
          setItemComboData(data?.itemComboItemDetails?.map((item) => ({
            edit: true,
            isSelected: true,
            afterDiscountValueForPerItem: item?.afterDiscountValueForPerItem,
            amount: item?.amount,
            id: item?.id,
            display: item?.display,
            isMandatory: item?.isMandatory,
            qty: item?.qty,
            rate: item?.rate,
            itemmasterId: item?.itemmaster?.id,
            partCode: item?.itemmaster?.itemPartCode,
            partName: item?.itemmaster?.itemName,
            uom: {
              label: item?.uom?.name,
              value: item?.uom?.id
            }
          })))
          setIsSaveItemCombo(true)
          setShowItemCombo(true)
        }

      }

      catch (error) {
        console.log(error);
        const errorData = ShowErrorNoties(error);
        showErrorToast(true, 'error', errorData);
      }


    };
    const showStock = () => {
      handleShowStockStatementModal(data)
    }
    return (
      <>
        <div>
          {data?.itemCombo === false &&
            <button
              type='button'
              className='btn btn-outline-success btn-sm px-3 mx-2'
              onClick={() => {
                setitemDetailsValues(prev => ({
                  ...prev,
                  ItemEditData: data,

                }))
                setItemEditShow(true)
                setQuotationItemDetailsShow(true)
              }}
              disabled={editView}
            >
              <i className='fa-solid fa-pen'></i>
            </button>
          }
          {data?.itemCombo === true &&
            <span className='ms-4 px-3 mx-2' onClick={handleItemCombo}>
              <LuGift
                style={{
                  cursor: 'pointer',
                  color: Array.isArray(data?.itemComboItemDetails) && data?.itemComboItemDetails.length === 0 ? 'brown' : 'green'
                }}
              />
            </span>
          }
          <button
            type='button'
            className='btn btn-outline-danger btn-sm px-3 mx-2'
            onClick={() => {
              setitemDetailsValues(prev => ({
                ...prev,
                deleteData: {
                  Name: data?.itemmaster?.itemPartCode, id: data.id
                },
              }))
              setisItemDeleteWarningShow(true);
            }}
            disabled={editView}
          >
            <i className='fa-solid fa-trash '></i>
          </button>
          {data?.itemCombo === false &&
            <AiOutlineShoppingCart
              style={{ cursor: 'pointer' }}
              className='fs-3'
              onClick={() => {
                showStock()
              }}
            />
          }
        </div>
      </>
    )
  }
  const columns = [
    {
      headerName: 'S.No',
      flex: 0.7,
      valueGetter: params => params.node.rowIndex + 1,// Use params.node.rowIndex
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 2,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'HSN',
      field: 'hsn.hsnCode',
      flex: 2,
      onCellDoubleClicked: onCellDoubleClicked,

    },
    {
      headerName: ' Qty',
      field: 'qty',
      flex: .8,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'UOM',
      field: 'uom.name',
      flex: .8,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'Rate',
      cellRenderer: ratecellrender,
      field: 'rate',
      flex: 2,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'Discount',
      valueGetter: params => {
        const discountPercentage = params.data.discountPercentage;
        const discountValue = params.data.discountValue;
        if (discountPercentage && discountValue === null) {
          return `${discountPercentage} %`;
        } else if (discountValue !== null) {
          return `${discountValue}`;
        }
        return ' ';
      },
      flex: 1,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'Tax',
      field: 'itemmaster.itemHsn.gstRates.rate',
      flex: .7,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'Amount',
      field: 'amount',
      flex: 1,
      onCellDoubleClicked: onCellDoubleClicked,
    },
    {
      headerName: 'Action',
      flex: 2,
      cellRenderer: itemDetailsAction
    }
  ]
  const defaultColDef = {
    // Default column definition for the table
    filter: true,
    floatingFilter: true,
    resizable: true
  }
  const handleShowStockStatementModal = data => {
    axiosInstance
      .post('/itemmaster/graphql', {
        query: stockIdFetchQuery(data?.['itemmaster']?.['id'])
      })
      .then(res => {
        let stock_ids = []
        stock_ids = res?.data?.data?.stockIds?.items
        let construct_stock_dict = {
          stockId: [],
          itemPartCode: data?.partCode?.itemPartCode,
          itemName: data?.partCode?.itemName,
          description: data?.description,
          batchNumber: '',
          serial: ''
        }
        if (stock_ids.length > 0) {
          construct_stock_dict['partCode'] =
            stock_ids[0]?.partNumber?.['itemPartCode']
          construct_stock_dict['partName'] =
            stock_ids[0]?.partNumber?.['itemName']
          construct_stock_dict['description'] =
            stock_ids[0]?.partNumber?.['description']
          construct_stock_dict['isBatch'] =
            stock_ids[0]?.partNumber?.['batchNumber']
          construct_stock_dict['isSerial'] =
            stock_ids[0]?.partNumber?.['serial']
        }
        construct_stock_dict['stockId'] = stock_ids?.map(item => item.id)
        setitemDetailsValues(prev => ({
          ...prev,
          selectedStockStatementDetails: construct_stock_dict
        }))
        setShowStockStatementModal(true)
      })
      .catch(error => {
        // Handle errors here
        console.error('Error fetching data:', error)
      })
    setitemDetailsValues(prev => ({
      ...prev,
      selectedPartNo: data?.['itemmaster']?.['id']
    }))

  }
  const handleCloseStockStatementModal = () => {
    setShowStockStatementModal(false)
  }
  async function DeleteTheItem() {
    try {
      const response = await axiosInstance.post(`/itemmaster/graphql`, { query: quotationsItemDetailsDeleteMutation(itemDetailsValues?.deleteData?.id) })
      let reponseData = response?.['data']?.['data']?.quotationsItemDetailsDeleteMutation
      if (reponseData?.success) {
        setItemDetailsData(prevData => {
          const filteredData = prevData.filter(item => item.id !== itemDetailsValues?.deleteData?.id);
          return [...filteredData];  // Ensure that a new array reference is created
        });
        setitemDetailsValues(prev => ({
          ...prev,
          deleteData: { Name: '', id: '' }
        }))
      } else {
        showErrorToast(true, "error", reponseData?.errors)
      }

    } catch (error) {
      let errorFilter = ShowErrorNoties(error)
      showErrorToast(true, "error", errorFilter)
    }

  }



  return (
    <>
      {<BallTriangleLoading isshow={loading} />}
      <HierarchicalTreeView data={timeLineValue?.treeData} show={timeLineValue?.isShow} getOpenmodelId={getOpenmodelId} currentID={quotationId}
        close={() => { setTimeLineValue((prev => ({ ...prev, isShow: false }))) }} />
      <Modal key={index} show={true} fullscreen>
        <Modal.Body>
          <div className='itemMaster_Top mx-3 mt-1 d-flex justify-content-center card' style={{ width: '98%' }}>
            <div className='row align-items-center justify-content-between'>
              <div className='col-4 commanModelTitleColor'>
                <h3 className='mx-2 commanModelTitleColor'>
                  <i
                    className='fa-solid fa-arrow-left fa-sm me-2 commanModelTitleColor'
                    onClick={BeforeCloseCheckData}
                  ></i>
                  Quotation {initialQuotation?.status ? <span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen'>{initialQuotation?.status}</span> : ""}
                  {initialQuotation?.isAmend ? (<span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen'>Amend</span>) :
                    initialQuotation?.isDuplicate ? (<span className='btn badge rounded-pill text-dark me-3 fs-6 logoGreen'>Duplicate</span>) :
                      ("")}
                  <span className='commanModelTitleColor cursor-pointer' onClick={() => { fetchTimelineData(initialQuotation.LeadNumber) }}> Time Line</span>
                </h3>

              </div>
              <div className="col-3" hidden={initialQuotation?.isAmend ? true : initialQuotation?.isDuplicate ? true :
                versionList?.length <= 1 ? true : false}>
                <p className='text-start  p-0 m-0 d-flex justify-content-center'><span className=''>Version</span></p>
                <div className='d-flex justify-content-center' hidden>
                  <AiOutlineCaretLeft size={30} onClick={backWord} disabled={isFirst} />
                  <span className='fs-5 px-1 ms-1  '>{initialQuotation?.QuatationNumber}</span>
                  <AiOutlineCaretRight size={30} onClick={forWord} disabled={isLast} />
                </div>
              </div>
              <div className="col-5 ">
                <div className="row ">
                  <div className="col-9 ">
                    <h6 className='text-end'>
                      {initialQuotation?.createdBy?.id ? `Created By : ${initialQuotation?.createdBy?.name}` : ""}
                    </h6>
                    <h6 className='text-end '>
                      {initialQuotation?.createdAt ? `Last Modified Date : ${initialQuotation?.createdAt} (${initialQuotation?.createdTime})` : ""}
                    </h6>
                  </div>
                  <div className="col-3  ">
                    <i
                      className="fa-solid fa-pen fs-5 text-primary pe-3"
                      style={{ cursor: 'pointer' }}
                      onClick={() => { seteditView(false); }}
                      hidden={initialQuotation.status === "Submit" ? true : initialQuotation.status === "Canceled" ? true : !editView}
                    ></i>
                    <i
                      className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3"
                      style={{ cursor: 'pointer' }}
                      hidden={!(initialQuotation?.status === "Canceled")}
                      onClick={() => { setIsDeleteWarningShow(true) }}
                    ></i>
                    <PiPrinterLight
                      className='fs-3'
                      onClick={genPDF}
                      hidden={initialQuotation.status === "Canceled" ? true : !editView} />
                    <i
                      className="fa-solid fa-copy fs-5 pt-1 mx-3 text-success"
                      hidden={!initialQuotation?.active || initialQuotation.status === "Canceled" || !editView}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Duplicate"
                      role="button"
                      style={{ cursor: "pointer" }}
                      onClick={() => { duplicaTheData(true) }}
                    >
                    </i>

                    <TiCancel
                      hidden={!initialQuotation?.active === true || initialQuotation.status === "Canceled" || !editView}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Cancel"
                      className='fs-2 pb-1'
                      style={{ color: '#7f7f7f', cursor: "pointer" }} onClick={() => { setIsCancelWarningShow(true) }}>
                    </TiCancel>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Formik
            initialValues={initialQuotation}
            validationSchema={quotationFormSchema}
            enableReinitialize={true}
            onSubmit={handleQuotationFromSubmit}
          >
            {({
              errors,
              touched,
              setFieldValue,
              submitForm,
            }) => {
              return (
                <Form>
                  <div className='row ms-1 mt-3' style={{ width: '100%' }}>
                    {/* Left Side */}
                    <div className='col card shadow mx-2'>
                      <div className='row'>
                        {/* Quotation No */}
                        <div className='col-6 staan-input-group'>
                          <Field
                            type='text'
                            name='QuatationNumber'
                            className='w-100 staan-form-input'
                            disabled={true}

                          />
                          <label
                            htmlFor='QuatationNumber'
                            className='staan-form-input-label pt-1 px-1 mx-2'
                            onClick={() => {
                              console.log(itemDetailsData);
                            }}>
                            Quotation No
                          </label>

                        </div>
                        {/* Quotation Date */}
                        <div className='col-6 staan-input-group'>
                          <Field
                            type='text'
                            name='QuotationDate'
                            className='w-100 staan-form-input'
                            disabled={true}
                          />
                          <label
                            htmlFor='QuotationDate'
                            className='staan-form-input-label pt-1 px-1 mx-2'
                          >
                            Quotation Date
                          </label>
                        </div>
                        {/* Lead Number */}
                        <div className='col-6 staan-input-group'>
                          <Field
                            type='text'
                            name='LeadNumber'
                            value={leadSelect}
                            className='w-100 staan-form-input'
                            disabled={true}
                          />
                          <label
                            htmlFor='LeadNumber'
                            className='staan-form-input-label pt-1 px-1 mx-2'
                          >
                            Lead No
                          </label>
                        </div>
                        {/* Department */}
                        <div className='col-6 staan-input-group'>
                          <Select

                            onInputChange={(e) => {
                              if (e?.trim() !== "" && e?.trim() !== undefined) {
                                getOptions(e, 'DepartmentQuery');
                              }

                            }}
                            options={departmentList}
                            name='department_id'
                            className='my-3'
                            styles={customSelectStyle}
                            value={departmentSelect}
                            onChange={(option) => {
                              setFieldValue('department_id', option ? option.value : null);
                              setDepartmentSelect(option ? option : null);
                            }}
                            isDisabled={initialQuotation?.status === "Submit" || initialQuotation?.status === "Draft" ? true :
                              initialQuotation?.isAmend ? true : initialQuotation?.isDuplicate ? true : false}
                          />
                          <label className='staan-form-input-label pt-0 px-1 mx-2'  >
                            Department<span className='text-danger'>*</span>
                          </label>
                          {touched.department_id && errors.department_id && (
                            <small className='staan-error-msg'>
                              {errors.department_id}
                            </small>
                          )}
                        </div>
                        {/* Sales Person */}
                        <div className='col-6 staan-input-group'>
                          <Select
                            onInputChange={(e) => {

                              if (e?.trim() !== "" && e?.trim() !== undefined) {
                                getOptions(e, 'salesPerson');
                              }
                            }}
                            options={salesPersonList}
                            name='salesPerson'
                            value={salesPersonSelect}
                            className='mt-3'
                            isDisabled={editView}
                            onChange={(option) => {
                              setFieldValue('salesPerson', option ? Number(option?.value) : null)
                              setsalesPersonSelect(option ? option : null)


                            }}
                            styles={customSelectStyle}
                          />
                          <label className='staan-form-input-label  px-1 mx-2'  >Sales Person</label>
                          {touched.salesPerson && errors.salesPerson && <small>{errors.salesPerson}</small>}
                        </div>
                        {/* Remark */}
                        <div className='col-6 staan-input-group'>
                          <Field
                            type='text'
                            name='remark'
                            className='w-100 staan-form-input'

                            onChange={e =>
                              setFieldValue(
                                'remark',
                                e.target.value
                              )
                            }
                            disabled={editView}
                          />
                          <label
                            htmlFor='remark'
                            className='staan-form-input-label pt-1 px-1 mx-2'
                          >
                            Remarks
                          </label>
                          {touched.remark && errors.remark && (
                            <small className='staan-error-msg'>
                              {errors.remark}
                            </small>
                          )}
                        </div>
                        {/* currency */}
                        <div className="col-6 staan-input-group mb-3"  >
                          <Select
                            options={prefetchValue?.currencyExchangeList}
                            // isClearable
                            name='Currency'
                            value={currencySelect}
                            className='mt-3'
                            isDisabled={editView}
                            onChange={async (option) => {
                              if ((itemDetailsData?.length > 0 || otherIncomeChargesData?.length > 0) && option) {
                                const currencyResponse = await quotationCurrencyConvertion(option, currencySelect ? Number(currencySelect?.value) : null);
                                if (currencyResponse) {

                                  setcurrencyData(option)
                                  setcurrencySelect(option);
                                  setFieldValue('Currency', Number(option?.value));
                                  setFieldValue('currencyExchangeRate', option?.rate)
                                }
                                // else {
                                //   const rupeeOption = prefetchValue.currencyExchangeList.find(item => item.label === 'Rupee');
                                //   setcurrencyData(rupeeOption)
                                //   setcurrencySelect(rupeeOption);
                                //   setFieldValue('Currency', Number(rupeeOption?.value));
                                // }
                              }
                            }}
                            styles={customSelectStyle}
                          />
                          {/* rate = rate * convertion rate  */}
                          <label className='staan-form-input-label pt-1  px-1 mx-2' >Currency<span className='text-danger'>*</span></label>
                          {/* {touched.Currency &&  errors.Currency && <small>{errors.Currency}</small>} */}
                        </div>
                        <div className="col-6 staan-input-group mb-3"  >
                          <Field
                            type='text'
                            name='currencyExchangeRate'
                            className='w-100 staan-form-input'
                            disabled={true}
                          />
                          <label
                            htmlFor='currencyExchangeRate'
                            className='staan-form-input-label pt-1 px-1 mx-2'
                          >
                            Currency Exchange Rate
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col card shadow mx-2 py-2">
                      {/* Buyer GST Details */}
                      <p className='fw-bolder fs-6 commanModelTitleColor'>Customer Details</p>
                      <div className="row">
                        <div className="col-6 staan-input-group">
                          <Select
                            onInputChange={(e) => {
                              if (e?.trim() !== "" && e?.trim() !== undefined) {
                                getOptions(e, 'SupplierOptionsOptionsQuery', 'supplierNumber');
                              }

                            }}
                            className='my-3'
                            options={supplierNoList}
                            styles={customSelectStyle}
                            name='supplierNumber'
                            value={supplierNoSelected}

                            onChange={(option) => {
                              setFieldValue('supplierNumber', option?.value ? option?.value : null);
                              setFieldValue(
                                'supplierName',
                                option?.value ? option?.value : null
                              );
                              setFieldValue('buyerAddress', '')
                              setFieldValue('buyerContactPerson', '')
                              setquotationAddressContactData(prevState => ({ ...prevState, buyerAddress: "", buyerContactPerson: "" }));
                              setSupplierNoSelected(option ? option : null);
                              setSupplierNameSelected(
                                option
                                  ? { value: option?.id, label: option?.companyName }
                                  : null
                              );
                              setQuotationInputValue(
                                option
                                  ? { address: option?.address?.[0], remark: quotationInputValue?.remark }
                                  : null
                              );
                              setCustomerDetailsLists(prve => ({
                                ...prve, buyerStatesList: getStates(option?.address?.country),
                                buyerContactList: option?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
                                buyerAddressList: option?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
                              }))
                              if (itemDetailsData?.length > 0 && option) {
                                QuotationBulkUpdateForTaxmutations(null)
                              }
                            }}
                            isDisabled
                          />
                          <label className='staan-form-input-label pt-1 px-1 mx-2'  >
                            Customer Code<span className='text-danger'>*</span>
                          </label>
                          {touched.supplierNumber && errors.supplierNumber && (
                            <small className='staan-error-msg'>
                              {errors.supplierNumber}
                            </small>
                          )}
                        </div>
                        <div className="col-6 staan-input-group">
                          <Select
                            className='my-3'
                            onInputChange={(e) => {
                              if (e?.trim() !== "" && e?.trim() !== undefined) {
                                getOptions(e, 'SupplierOptionsOptionsQuery', 'supplierName');
                              }

                            }}
                            options={supplierNameList}
                            value={supplierNameSelected}
                            name='supplierName'
                            onChange={(option) => {

                              setFieldValue('supplierName', option?.value ? option?.value : null);
                              setFieldValue('supplierNumber', option?.value ? option?.value : null);
                              setquotationAddressContactData(prevState => ({ ...prevState, buyerAddress: "", buyerContactPerson: "" }));
                              setSupplierNameSelected(option ? option : null);
                              setSupplierNoSelected(
                                option
                                  ? { value: option?.value, label: option?.supplierNo }
                                  : null
                              );
                              setQuotationInputValue(
                                option
                                  ? { address: option?.address?.[0], remark: quotationInputValue?.remark }
                                  : null
                              );
                              setCustomerDetailsLists(prve => ({
                                ...prve, buyerStatesList: getStates(option?.address?.country),
                                buyerAddressList: option?.address?.map((address_) => ({ value: address_?.id, label: address_?.addressType, fullAddredd: address_ })),
                                buyerContactList: option?.contact?.map((contact_) => ({ value: contact_?.id, label: contact_?.contactPersonName, mobile: contact_?.phoneNumber, Email: contact_?.email })),
                              }))
                              if (itemDetailsData?.length > 0 && option) {
                                QuotationBulkUpdateForTaxmutations(null)
                              }
                            }}
                            styles={customSelectStyle}
                            isDisabled
                          />
                          <label className='staan-form-input-label pt-1 px-1 mx-2' >
                            Customer Name<span className='text-danger'>*</span>
                          </label>
                          {touched.supplierName && errors.supplierName && (
                            <small className='staan-error-msg'>
                              {errors.supplierName}
                            </small>
                          )}
                        </div>
                        <div className="col-6 staan-input-group">
                          <Select
                            options={customerDetailsLists?.buyerAddressList}
                            value={quotationAddressContactData?.buyerAddress}
                            isDisabled={editView}
                            onChange={(option => {
                              setFieldValue('buyerState', option?.fullAddredd?.state)
                              setFieldValue("buyerAddress", Number(option?.value))
                              setCustomerDetailsLists(prve => ({
                                ...prve, buyerStatesList: getStates(option?.fullAddredd?.country),
                              })
                              )
                              // select the buyerPlaceOfSupply
                              setquotationAddressContactData(prve => ({ ...prve, buyerAddress: option ? option : null }))
                              if ((itemDetailsData?.length > 0) && option) {
                                QuotationBulkUpdateForTaxmutations(option?.fullAddredd?.state)
                              }

                              setAfterOverallDiscount(false)
                            })}
                            name='buyerAddress'
                            className='mt-3'
                            styles={customSelectStyle}
                          />
                          <label className='staan-form-input-label  px-1 mx-2' >Address</label>
                          {touched.buyerAddress && errors.buyerAddress && <small>{errors.buyerAddress}</small>}
                        </div>
                        <div className="col-6 staan-input-group">
                          <Select
                            options={customerDetailsLists?.buyerContactList}
                            value={quotationAddressContactData?.buyerContactPerson}
                            onChange={(option => {
                              setquotationAddressContactData(prve => ({ ...prve, buyerContactPerson: option ? option : null }))
                              setFieldValue("buyerContactPerson", Number(option?.value))
                            })}
                            isDisabled={editView}
                            name='buyerContactPerson'
                            className='mt-3'
                            styles={customSelectStyle}
                          />
                          <label className='staan-form-input-label  px-1 mx-2'  >Contact Person</label>
                          {touched.buyerContactPerson && errors.buyerContactPerson && <small>{errors.buyerContactPerson}</small>}
                        </div>

                      </div>
                    </div>
                    <div className="col card shadow mx-2 py-2">
                      <div className="row">
                        <div className="col-6 staan-input-group">
                          <p className='p-2   fs-6 fw-bolder'  >Address</p>
                          {quotationAddressContactData?.buyerAddress?.fullAddredd ?
                            <>
                              <p className='ps-2 lh-1 '>{quotationAddressContactData?.buyerAddress?.fullAddredd?.addressLine1} {quotationAddressContactData?.buyerAddress?.fullAddredd?.addressLine2},</p>
                              <p className='ps-2 lh-1 '>{quotationAddressContactData?.buyerAddress?.fullAddredd?.city} {quotationAddressContactData?.buyerAddress?.fullAddredd?.state},</p>
                              <p className='ps-2 lh-1 '>{quotationAddressContactData?.buyerAddress?.fullAddredd?.country}</p>
                              <p className='ps-2 lh-1 '>Pincode   :{quotationAddressContactData?.buyerAddress?.fullAddredd?.pincode}</p>
                            </> :
                            <></>
                          }
                        </div>
                        <div className="col-6">
                          <p className='p-2 fs-6 fw-bolder'>Contact </p>
                          {quotationAddressContactData?.buyerContactPerson ?
                            <>

                              <p className='ps-2 lh-1'>Mobile : {quotationAddressContactData?.buyerContactPerson?.mobile} </p>
                              <p className='ps-2 lh-1'> Email  : {quotationAddressContactData?.buyerContactPerson?.Email} </p>
                            </> :
                            ""
                          }
                        </div>
                      </div>
                    </div>
                    {/* Item Details */}
                    <div className='row  ms-3 mt-3 card shadow mx-2 pb-2'>
                      {/* <ItemDetails
                        // itemData={itemDetailsData}
                        stateValue={quotationAddressContactData ? quotationAddressContactData?.buyerAddress?.fullAddredd?.state : ''}
                        editView={editView}
                        handleNewItem={handleNewItem}
                        showDiscount={showDiscount}
                      >
                      </ItemDetails> */}
                      <div className='row justify-content-between align-items-center'>
                        <div className='col-2'>
                          <h6 className='commanModelTitleColor' onClick={() => {
                            console.log("itemDetailsData", currencyData);
                          }}>Item Details</h6>
                        </div>
                        <div className='col-4 text-end'>
                          <span className='me-3'>
                            <LuGift className='me-2' style={{ cursor: 'pointer', color: 'brown' }} />
                            Item Combo Not Saved
                          </span>
                          <span className='me-3'>
                            <LuGift className='me-2' style={{ cursor: 'pointer', color: 'green' }} />
                            Item Combo Saved
                          </span>
                        </div>
                        <div className='col-3 text-end'>
                          <button
                            type='button'
                            className='btn btn-outline-primary mt-4 px-4'
                            onClick={() => {
                              console.log(!quotationAddressContactData ? quotationAddressContactData?.buyerAddress?.fullAddredd?.state : '' || editView || !currencyData);

                              setItemEditShow(false)
                              setQuotationItemDetailsShow(true)
                            }}
                            disabled={!quotationAddressContactData ? quotationAddressContactData?.buyerAddress?.fullAddredd?.state : '' || editView || !currencyData}
                          >
                            <i className='bx bxs-plus-circle me-2'></i>New
                          </button>
                        </div>
                      </div>
                      <div
                        className='ag-theme-alpine mt-2'
                        style={{ height: '40rem', width: '100%' }}
                      >
                        <AgGridReact
                          rowData={itemDetailsData}
                          columnDefs={columns}
                          defaultColDef={defaultColDef}

                        />
                        <ItemCombo
                          IsshowItemCombo={showItemCombo}
                          setshowItemCombo={setShowItemCombo}
                          lastUpdatedItemDetails={itemDetailsValues?.lastUpdatedItemDetails}
                          IsSaveItemCombo={IsSaveItemCombo}
                          setIsSaveItemCombo={setIsSaveItemCombo}
                          itemComboData={itemComboData}
                          setItemComboData={setItemComboData}
                          setQuotationItemDetailsShow={setQuotationItemDetailsShow}
                          setItemDetailsData={setItemDetailsData}
                          loading={loading}
                          setLoading={setLoading}
                        />
                      </div>
                      <br />
                    </div>

                    <div className='row justify-content-between ms-3 mt-4'>
                      {/* Terms & Conditions */}
                      <div className='col card shadow-lg'>
                        <div className='row'>
                          <div className='col-6 staan-input-group'>
                            <Select
                              options={tCList}
                              onInputChange={(e) => {
                                if (e?.trim() !== "" && e?.trim() !== undefined) {
                                  getOptions(e, "TermsConditionsFetchQuery")
                                }

                              }}
                              value={termsOptions}
                              name='termsConditions'
                              styles={customSelectStyle}
                              className='my-3'
                              onChange={(option) => {
                                setFieldValue("TermsOptions", option ? option?.value : "")
                                setFieldValue("termsConditions", option ? option?.tc : "")
                                setTermsOptions(option)
                                setTermsConditions(option?.tc)

                              }}
                              isDisabled={editView}
                            />
                            <label
                              className='staan-form-input-label pt-1 px-1 mx-2'
                            >
                              Terms & Conditions<span className='text-danger'>*</span>
                            </label>
                            {touched.termsConditions && errors.termsConditions && (
                              <small className='staan-error-msg'>
                                {errors.termsConditions}
                              </small>
                            )}
                          </div>
                          <div className='col-12 staan-input-group'>
                            <ReactQuill
                              value={termsConditions}
                              onChange={(e) => {
                                setFieldValue("termsConditions", e ? e : "")
                                setTermsConditions(e)
                              }}
                              modules={textEditormodules}
                              readOnly={editView}
                            />

                          </div>
                        </div>
                      </div>
                      <div className='col'>

                        {/* <OtherIncomeCharges
                          stateValue={quotationAddressContactData ? quotationAddressContactData?.buyerAddress?.fullAddredd?.state : ''}
                          editView={editView}
                          isSaveOtherIncomeCharges={isSaveOtherIncomeCharges}
                          setisSaveOtherIncomeCharges={setisSaveOtherIncomeCharges}
                        /> */}
                        <div className='card shadow-lg p-3'>
                          <h6 className='commanModelTitleColor p-2'>Other Charges</h6>
                          <div id='staan-input-table' style={{ height: '12rem', overflowY: 'auto' }}>
                            <table
                              className='StaanTable'
                            >
                              <thead>
                                <tr style={{ fontSize: '.9rem', height: '2rem' }}>
                                  <th style={{ width: '15rem' }} onClick={() => {
                                    console.log(otherIncomeChargesData);
                                  }}  >Account</th>
                                  <th style={{ width: '8rem' }} onClick={() => {
                                    console.log("otherIncomeChargesvalues", otherIncomeChargesvalues);
                                  }}>Tax</th>
                                  <th style={{ width: '15rem' }}>Amount</th>
                                  <th style={{ width: '10rem' }}>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {otherIncomeChargesData?.map((item, rowIndex) => (
                                  <tr key={rowIndex} style={{ height: '2rem' }}>
                                    <td style={{ padding: '0.5rem' }}>
                                      {otherIncomeChargesvalues?.otherIncomeValue?.editIndex === rowIndex ? (
                                        <Select
                                          onInputChange={e => { getOptionOtherIncome(e, 'accountList'); }}
                                          options={otherIncomeChargesvalues?.accountList}
                                          value={otherIncomeChargesvalues?.otherIncomeValue?.account}
                                          onChange={e => {
                                            // setOtherIncomeValue(prve => ({ ...prve, account: e, tax: e.tax, id: item?.id, createdBy: item?.createdBy }))
                                            setotherIncomeChargesvalues(prev => ({
                                              ...prev, // Spread the previous state to preserve other parts of the state
                                              otherIncomeValue: {
                                                ...prev.otherIncomeValue,  // Spread the previous otherIncomeValue to keep other fields
                                                account: e,                // Set new account value
                                                tax: e.tax,                // Set new tax value
                                                id: item?.id,              // Set the new id
                                                createdBy: item?.createdBy // Set the new createdBy value
                                              }
                                            }));
                                            // handleInputChange(rowIndex, 'account', e);
                                            // handleInputChange(rowIndex, 'tax', );
                                          }}
                                          className='staan-input-group my-3'
                                        />
                                      ) : (
                                        item?.account?.label
                                      )}
                                      {otherIncomeChargesvalues?.errorMessages[rowIndex]?.amount && (
                                        <span style={{ color: 'red', marginLeft: '0.5rem' }}>
                                          {otherIncomeChargesvalues?.errorMessages[rowIndex].amount}
                                        </span>
                                      )}
                                    </td>

                                    <td style={{ padding: '0.5rem' }}>
                                      {otherIncomeChargesvalues?.otherIncomeValue?.editIndex === rowIndex ? (
                                        <input
                                          type='text'
                                          value={otherIncomeChargesvalues?.otherIncomeValue?.tax}
                                          disabled
                                          style={{ width: '100%', height: '38px' }} // Fixed height
                                        />
                                      ) : (
                                        item.tax
                                      )}
                                    </td>
                                    <td style={{ padding: '0.5rem' }}>
                                      {otherIncomeChargesvalues?.otherIncomeValue?.editIndex === rowIndex ? (
                                        <input
                                          type='text'
                                          value={otherIncomeChargesvalues?.otherIncomeValue?.amount}
                                          onChange={e =>
                                            // handleInputChange(rowIndex, 'amount', e.target.value)
                                            // setOtherIncomeValue(prve => ({ ...prve, amount: e.target.value, id: item?.id, createdBy: item?.createdBy }))
                                            setotherIncomeChargesvalues(prev => ({
                                              ...prev, // Spread the previous state to preserve other parts of the state
                                              otherIncomeValue: {
                                                ...prev.otherIncomeValue,  // Spread the previous otherIncomeValue to keep other fields
                                                amount: e.target.value,
                                                id: item?.id,
                                                createdBy: item?.createdBy // Set the new createdBy value
                                              }
                                            }))
                                          }
                                          style={{ width: '100%', height: '38px' }} // Fixed height
                                        />
                                      ) : (
                                        renderAmountOtherIncome(item, otherIncomeChargesData)
                                      )}
                                      {otherIncomeChargesvalues?.errorMessages[rowIndex]?.account && (
                                        <span style={{ color: 'red', marginLeft: '0.5rem' }}>
                                          {otherIncomeChargesvalues?.errorMessages[rowIndex].account}
                                        </span>
                                      )}
                                    </td>

                                    <td>
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {otherIncomeChargesvalues?.otherIncomeValue?.editIndex === rowIndex ? (
                                          <>
                                            <button type='button' className='save-button' onClick={() => handleSaveRowOtherincome(rowIndex)} disabled={editView}>
                                              Save
                                            </button>
                                            <button type='button' className='cancel-button' onClick={() => {
                                              // setOtherIncomeValue({ editIndex: "", account: "", tax: "", amount: "", id: "", createdBy: item?.createdBy });
                                              setotherIncomeChargesvalues(prev => ({
                                                ...prev, // Spread the previous state to preserve other parts of the state
                                                otherIncomeValue: {
                                                  ...prev.otherIncomeValue,  // Spread the previous otherIncomeValue to keep other fields
                                                  editIndex: "", account: "", tax: "", amount: "", id: "", createdBy: item?.createdBy
                                                },
                                                onNewItemSelect: false
                                              }));
                                              setOtherIncomeChargesData(prev => {

                                                return prev?.filter(item => item?.id !== "" && item?.index !== rowIndex);
                                              });

                                            }}
                                              disabled={editView}>
                                              <i className='fa-solid fa-xmark'></i>
                                            </button>


                                          </>
                                        ) : (
                                          <>
                                            <button type='button' className='edit-button' onClick={() => {
                                              // handleInputChange(rowIndex, 'isEditing', true);
                                              // setOtherIncomeValue(prve => ({ ...prve, editIndex: rowIndex, account: item?.account, tax: item?.tax, amount: item.amount, createdBy: item?.createdBy }))
                                              setotherIncomeChargesvalues(prev => ({
                                                ...prev, // Spread the previous state to preserve other parts of the state
                                                otherIncomeValue: {
                                                  ...prev.otherIncomeValue,  // Spread the previous otherIncomeValue to keep other fields
                                                  editIndex: rowIndex, account: item?.account, tax: item?.tax, amount: item.amount, createdBy: item?.createdBy
                                                },
                                                onNewItemSelect: true
                                              }));
                                            }} disabled={editView}>
                                              <i className='fa-solid fa-pen-to-square'></i>
                                            </button>
                                            <button type='button' className='delete-button' onClick={() => handledeleteConfomation(rowIndex)} disabled={editView}>
                                              <i className='fa-solid fa-trash'></i>
                                            </button>
                                          </>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>

                              <tfoot>
                                <tr style={{ background: '#ffff' }}>
                                  <td colSpan={4}>
                                    <button
                                      className='btn btn-sm btn-outline-primary'
                                      type='button'
                                      onClick={handleNewRowotherincome}
                                      style={{
                                        borderRadius: 'none !important',
                                        border: '1px solid'
                                      }}
                                      disabled={otherIncomeChargesvalues?.onNewItemSelect || !quotationAddressContactData ? quotationAddressContactData?.buyerAddress?.fullAddredd?.state : '' || editView}
                                    >
                                      <i className='fa-solid fa-plus'></i> New
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>

                        </div>


                        <div className='card shadow-lg mt-3'>
                          <table className='table'>
                            <tbody>
                              <tr>
                                <td className='text-end p-2'  >Item Before Tax</td>
                                <td className='text-end p-2'>{currencyData?.currencySymbol}{toSaveData?.beforeTax}</td>
                              </tr>
                              <tr>
                                <td className='text-end p-2'>Other Charges Before Tax</td>
                                <td className='text-end p-2'>{currencyData?.currencySymbol}{toSaveData?.otherIncomeChagersBeforeTax}</td>
                              </tr>
                              <tr  >
                                <td className='text-end p-2'>Taxable Value</td>
                                <td className='text-end p-2'>{currencyData?.currencySymbol}{Number(toSaveData?.beforeTax) + Number(toSaveData?.otherIncomeChagersBeforeTax) ?
                                  (Number(toSaveData?.beforeTax) + Number(toSaveData?.otherIncomeChagersBeforeTax)).toFixed(2) : "0.00"}</td>
                              </tr>
                              <tr>
                                <td className='text-end p-2'>
                                  <input
                                    type="checkbox"
                                    checked={checkDiscount}
                                    onChange={e => {
                                      const isChecked = e.target.checked;
                                      if (isChecked) {
                                        setwarningShow(true)
                                        // setIsEnableShowDiscount(true);
                                        setErrorMessage("Do you want clear Discount?")
                                      }
                                      else {
                                        setwarningShow(true)
                                        // setIsEnableShowDiscount(false);
                                        setErrorMessage("Do you want clear Discount?")
                                      }
                                    }}
                                    disabled={editView}
                                    className="me-2"
                                  />
                                  <span

                                    // onClick={() => IsEnableShowDiscount && setShowDiscount(prev => !prev)}
                                    onClick={() => {
                                      if (initialQuotation?.status !== "Draft" && initialQuotation?.status !== "" && !initialQuotation?.isAmend) {
                                        // If not allowed to click, exit early (prevent action)
                                        return;
                                      }
                                      if (!checkDiscount) {
                                        showErrorToast(true, 'warn', "Please Select The Check Box!!!")

                                      } else {
                                        // Toggle discount visibility if checkbox is checked
                                        setShowDiscount(prev => !prev);

                                      }
                                    }}
                                    style={{ cursor: 'pointer', color: 'red' }}
                                  // disabled={initialQuotation?.status === "Draft" ? false : initialQuotation?.isAmend ? true : initialQuotation?.status === "" ? false : true}

                                  >
                                    Overall Discount
                                  </span>
                                </td>
                                <td className='text-end p-2'>
                                  {/* {currencyData?.currencySymbol}{(toSaveData?.actualNetAmount - toSaveData?.netAmount).toFixed(2)} */}
                                  {currencyData?.currencySymbol}{(Number(toSaveData?.actualNetAmount) - Number(toSaveData?.netAmount)).toFixed(2) ?
                                    (Number(toSaveData?.actualNetAmount) - Number(toSaveData?.netAmount)).toFixed(2) : "0.00"}
                                </td>
                              </tr>
                              <tr>
                                <td className='text-end  p-2 cursor-pointer text-primary' style={{ cursor: 'pointer' }}
                                  onClick={() => setShowTaxModel(true)}
                                >Tax Total</td>
                                <td className='text-end p-2'>{currencyData?.currencySymbol}{toSaveData?.totaltax}</td>
                              </tr>
                              <tr>
                                <td className='text-end p-2' onClick={() => {
                                  console.log("toSaveData", toSaveData);
                                }}>Round of</td>
                                <td className='text-end p-2'>{currencyData?.currencySymbol}{toSaveData?.roundOff}</td>
                              </tr>
                              <tr>
                                <td className='text-end p-2 fw-bold' onClick={() => {
                                  console.log(versionList);
                                }}>Nett Amount</td>
                                <td className='text-end p-2' >{currencyData?.currencySymbol}{toSaveData?.netAmount}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>


                    <div className='row mt-3 ms-3 justify-content-evenly'>
                      <div className='col-1'>
                        <button
                          type='submit'
                          style={{ width: "10rem" }}
                          className='btn btn-outline-secondary px-4 '
                          disabled={initialQuotation?.status === "Draft" ? false : initialQuotation?.isAmend ? true : initialQuotation?.status === "" ? false : true}
                          onClick={() => { setFieldValue('status', "Draft"); }}
                        >
                          Draft
                        </button>
                      </div>
                      <div className='col-1'>
                        <button
                          type='button'
                          style={{ width: "10rem" }}
                          className='btn btn-outline-success px-3'
                          disabled={initialQuotation?.status === "Draft" ? false : initialQuotation?.isAmend ? false : true}
                          onClick={() => { setFieldValue('status', "Submit"); submitForm() }}
                        >
                          Submit
                        </button>
                      </div>
                      <div className='col-1'>
                        <button
                          type="submit"
                          style={{ width: "10rem" }}
                          disabled={initialQuotation?.status === "Submit" && initialQuotation?.active === true ? false : true}
                          className='btn btn-outline-primary px-3'
                          onClick={() => {
                            // handleQuotationFromSubmit(values)
                            // setStatus("Amend")
                            setInitialQuotation(prev => ({ ...prev, isAmend: true }))
                            setVersionList([])
                            duplicaTheData(false)
                          }}
                        >
                          Amend
                        </button>
                      </div>
                      <div className='col-1'>
                        <button
                          type='button'
                          style={{ width: "10rem" }}
                          disabled={initialQuotation?.status === "Submit" && initialQuotation?.active === true ? false : true}
                          className='btn btn-outline-info px-4'
                          onClick={CreateSalesOrder}>
                          Sales Order
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
      {showDiscount &&
        <Discount
          setShowDiscount={setShowDiscount}
          showDiscount={showDiscount}
          editView={editView}
          setIsOverAllDiscountApplied={setIsOverAllDiscountApplied}
          IsOverAllDiscountApplied={IsOverAllDiscountApplied}
          itemDetailsData={itemDetailsData}
          setItemDetailsData={setItemDetailsData}
          otherIncomeChargesData={otherIncomeChargesData}
          setOtherIncomeChargesData={setOtherIncomeChargesData}
          quotationStatus={quotationStatus}
          setAfterOverallDiscount={setAfterOverallDiscount}
          isAfterOverallDiscount={isAfterOverallDiscount}
          setOverallDiscountPercentage={setOverallDiscountPercentage}
          overallDiscountPercentage={overallDiscountPercentage}
          setOverallDiscountValue={setOverallDiscountValue}
          overallDiscountValue={overallDiscountValue}
          setFinalTotal={setFinalTotal}
          finalTotal={finalTotal}
          toSaveData={toSaveData}
          setCheckedDiscount={setCheckedDiscount}

        />
      }

      {isCancelWarningShow &&
        <WaringModel
          state={isCancelWarningShow}
          setstate={setIsCancelWarningShow}
          title={'Confirm'}
          text={"Confirm to cancel"}
          Confirmtion={value => {
            if (value) {
              CancelTheQuotations(value)
            }
          }}
        />}
      {isDeleteWarningShow &&
        <WaringModel
          state={isDeleteWarningShow}
          setstate={setIsDeleteWarningShow}
          title={'Confirm'}
          text={"Confirm to Delete"}
          Confirmtion={value => {
            if (value) {
              DeleteTheQuotations(value)
            }
          }}
        />}
      {isItemDeleteWarningShow &&
        <WaringModel
          state={isItemDeleteWarningShow}
          setstate={setisItemDeleteWarningShow}
          title={'Confirm'}
          text={"Confirm to Delete"}
          Confirmtion={value => {
            if (value) {
              DeleteTheItem(value)
            }
          }}
        />}

      {
        showTaxModel &&
        <TaxModel
          showTaxModel={showTaxModel}
          setShowTaxModel={() => { setShowTaxModel(false) }}
          igstTax={toSaveData.igst}
          sgstTax={toSaveData.sgst}
          cgstTax={toSaveData.cgst}
        />
      }
      {IswarningShow &&
        <WaringModel
          state={IswarningShow}
          setstate={setwarningShow}
          title={'Confirm'}
          text={errorMessage}
          Confirmtion={value => {
            if (value) {
              handleWarningConfirmationOverallDiscount(value)
            }
          }}
        />}
      {IswarningAmandCancelShow &&
        <WaringModel
          state={IswarningAmandCancelShow}
          setstate={setwarningAmandCancelShow}
          title={'Confirm'}
          text={errorMessage}
          Confirmtion={value => {
            if (value) {
              handleWarningConfirmationAmandCancel(value)
            }
          }}
        />}
      {IsshowWarningModalWithCopy &&
        <WaringModelWithCopy
          state={IsshowWarningModalWithCopy}
          setState={setShowWarningModalWithCopy}
          title="Value Out Of Range"
          text={errorMessagesWarningModalWithCopy}
          Copy={true}
        />
      }
      {isShowStockStatementModal &&
        <StockStatementDetails
          isShowSelectedStockStatement={isShowStockStatementModal}
          handleCloseSelectedStockStatement={handleCloseStockStatementModal}
          stockStatement={itemDetailsValues?.selectedStockStatementDetails}
          part_id={itemDetailsValues?.selectedPartNo}
          from={'pos'}
        />}
      {isShowSalesOrder && salesOrderData &&
        <SalesOrderForm
          isShow={isShowSalesOrder}
          setIsShow={setIsShowSalesOrder}
          editData={null}
          setEditData={null}
          quotationData={salesOrderData}
        />}
      {otherIncomeChargesvalues?.deleteData && (
        <DeleteConformation
          handleDeletedId={id => handleDeletedIdotherincome(id)}
          deleteData={otherIncomeChargesvalues?.deleteData}
          fullquery={otherIncomeChargesDeleteMutation(otherIncomeChargesvalues?.deleteData?.id)}
        />
      )}
      {QuotationItemDetailsShow && (
        <QuatationItemModel
          QuotationItemDetailsShow={QuotationItemDetailsShow}
          setQuotationItemDetailsShow={setQuotationItemDetailsShow}
          stateValue={quotationAddressContactData ? quotationAddressContactData?.buyerAddress?.fullAddredd?.state : ''}
          onNewItem={handleNewItem}
          EditData={itemDetailsValues?.ItemEditData}
          EditShow={ItemEditShow}
          editView={editView}
          setItemComboData={setItemComboData}
          itemDetailsData={itemDetailsData}
          setItemDetailsData={setItemDetailsData}
          setOtherIncomeChargesData={setOtherIncomeChargesData}
          otherIncomeChargesData={otherIncomeChargesData}
          isAfterOverallDiscount={isAfterOverallDiscount}
          setAfterOverallDiscount={setAfterOverallDiscount}
          setOverallDiscountPercentage={setOverallDiscountPercentage}
          setOverallDiscountValue={setOverallDiscountValue}
          setFinalTotal={setFinalTotal}
          currencyData={currencyData}
        />
      )}
    </>
  )

}

export default QuotationFrom;