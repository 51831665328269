import { Field, Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { ToastContainer } from 'react-toastify';
import axiosInstance from '../../../api/axoiss';
import showErrorToast from '../../../components/notifications/react-toastify/toast';
import WaringModel from '../../../components/Warings/WaringModel';
import DataContext from '../../../context/ItemMasterContext';
import { QueryFilter } from '../../../GraphQLQueries/ItemmasterQuery';
import { convertToTitleCase, customSelectStyle, ShowErrorNoties } from '../../../utils/Utils';
import { ContectSchema } from '../../../validations/itemmaster';

const ContactFrom = ({newContactNumber, setNewContactNumber, setEditContact, EditContact, ContactFromAdd, setcontactFromAdd, setLoad, condition}) => {
    const {setcontactdata,   setContanctPost, contanctPost} = useContext(DataContext)
    const [initialValue, setInitialValue] = useState({
      Contact_Person_Name: '',
      Salutation: '',
      Email: '',
      Phone_number: '',
      WhatsappNo : "",
      OtherNo: ""
    })
    const [formKey, setFormKey] = useState(0)
    const [waringModel, setWaringModel] = useState(false)
    const [edit, setEdit]= useState(true)
    const [isDefault, setDefault] = useState(false)
    const handleDefaultChange = (e) => {
      setDefault(e.target.checked)
    }

    useEffect(() => {
      if(EditContact){
        setEdit(true)
        setInitialValue(
          {
            Contact_Person_Name: EditContact['Contact_Person_Name'],
            Salutation:EditContact['Salutation'],
            Email:EditContact['Email'],
            Phone_number:EditContact['Phone_number'],
          }
        ) 
      setPersonName(EditContact['Contact_Person_Name'])
      if (EditContact['Salutation']){
        setSalutation({ value: EditContact['Salutation'] , label: convertToTitleCase(EditContact['Salutation']) })
      }
      
      setEmail(EditContact['Email'])
      setmobileNo(EditContact['Phone_number'])
      setotherNo(EditContact['OtherNo'])
      setWhatsappNo(EditContact['WhatsappNo'])
      setDefault(EditContact['Default'])
      initialValue.Contact_Person_Name = EditContact['Contact_Person_Name']
      initialValue.Salutation = EditContact['Salutation']
      initialValue.Email = EditContact['Email']
      initialValue.Phone_number = EditContact['Phone_number']
      initialValue.WhatsappNo = EditContact['WhatsappNo']
      initialValue.OtherNo = EditContact['OtherNo']
      initialValue.Default = EditContact['Default']
      setFormKey(prev=>prev+1)
      } else{
        setInitialValue(
          {
            Contact_Person_Name:  '',
            Salutation:  '',
            Email: '',
            Phone_number: newContactNumber,
            WhatsappNo : "",
            OtherNo: "",
            Default: false,
          })
          initialValue.Phone_number = newContactNumber
        setmobileNo(newContactNumber)
        setEdit(false)
      }
    }, [EditContact, newContactNumber])

  
    const handleSubmit = async (values, { setSubmitting, resetForm, setErrors }) => {
        let proccess_saved_data = {}
        if(values['Contact_Person_Name'] !== ''){
          proccess_saved_data['contactPersonName'] = values['Contact_Person_Name'] 
        }
        if(values['Salutation'] !== ''){
          proccess_saved_data['salutation'] = values['Salutation']?.toUpperCase(); 
        }
        if(values['Email'] !== ''){
          proccess_saved_data['email'] = values['Email'] 
        }
        if(values['Phone_number'] !== ''){
          proccess_saved_data['phoneNumber'] = String(values['Phone_number'])
        }
        if(values['WhatsappNo'] !== '' && values['WhatsappNo']){
          proccess_saved_data['whatsappNo'] = String(values['WhatsappNo'])
        }
        if(values['OtherNo'] !== '' && values['OtherNo']){
          proccess_saved_data['otherNo'] = String(values['OtherNo'])
        }
        proccess_saved_data['default'] = isDefault
        if(isDefault){
          let address_ids = contanctPost?.map(item => item?.id)
          try{
            if(EditContact !== "" && EditContact !== undefined){
              address_ids = address_ids.filter(id => id !== EditContact?.['id'])
            }
            address_ids = address_ids?.map(id => parseInt(id))
            let contact_bulk_update_query = `mutation contactFormBulkUpdate{
              supplierSubFormDefaultUpdateMutation(name: "ContactDetalis", idList: [${address_ids}]){
                success
                errors
              }
            }
            `
            await axiosInstance.post("/itemmaster/graphql", {query: contact_bulk_update_query})
            setContanctPost((prevData) => {return prevData.map((item) =>  ({...item, 'default':  false, 'Default': false}))});
          }catch(e){
            console.log(e)
          }

        }
        if(EditContact !== "" && EditContact !== undefined){
          try{
            proccess_saved_data['id'] = EditContact['id'] 
            let update_mutate_query = `mutation createContactData{
              contactDetailsCreateMutation(`
              update_mutate_query += QueryFilter(proccess_saved_data, ['default'], ['id'])
              update_mutate_query +=`){
                success
                errors
                contactDetailsItem{
                  id
                  contactPersonName
                  email
                  default
                  phoneNumber
                  salutation
                  otherNo
                  whatsappNo
                }
              }
            }`
            const response = await axiosInstance.post("/itemmaster/graphql", {query: update_mutate_query})
            const responseData = response?.data?.data?.contactDetailsCreateMutation
            if (responseData.success){
              
              
              let data = response?.data?.data?.contactDetailsCreateMutation?.contactDetailsItem
              let updated_res_data = {...data} 
              updated_res_data['Contact_Person_Name'] = updated_res_data['contactPersonName']
              updated_res_data['Email'] = updated_res_data['email']
              updated_res_data['Default'] = updated_res_data['default']
              updated_res_data['Phone_number'] = updated_res_data['phoneNumber']
              updated_res_data['Salutation'] = convertToTitleCase(updated_res_data['salutation'])
              updated_res_data['OtherNo'] = updated_res_data['otherNo']
              updated_res_data['WhatsappNo'] = updated_res_data['whatsappNo']
                setContanctPost((prevData) => {
                    return prevData.map((item) => {
                      return item.id === updated_res_data.id ? updated_res_data : item;
                    });
                })
                setcontactdata((prevData) => {
                  return prevData.map((item) => {
                    return item.id === updated_res_data.id ? updated_res_data : item;
                  });
                })
                setEditContact('')
                if (setLoad){
                  setLoad()
                }
                setEdit(false)
                showErrorToast(true, "success" , "", `Updated`) 
                reset_Form()
                setcontactFromAdd(false)
            } else{
              showErrorToast(true, "warn" , responseData?.errors)
            }
          } catch(error){
            let DetailsError = ShowErrorNoties(error)
            showErrorToast(true, "error" , DetailsError)
          } 
        } else{
          try{
            let update_mutate_query = `mutation createContactData{
              contactDetailsCreateMutation(`
            update_mutate_query += QueryFilter(proccess_saved_data, ['default'], ['id'])
            update_mutate_query +=`){
                success
                errors
                contactDetailsItem{
                  id
                  contactPersonName
                  email
                  default
                  phoneNumber
                  salutation
                  otherNo
                  whatsappNo
                }
              }
            }`
            const response = await axiosInstance.post("/itemmaster/graphql", {query: update_mutate_query})
            const responseData = response?.data?.data?.contactDetailsCreateMutation
       
            
            if (responseData.success){
               
              let data = response?.data?.data?.contactDetailsCreateMutation?.contactDetailsItem
              let updated_res_data = {...data}
              updated_res_data['Contact_Person_Name'] = updated_res_data['contactPersonName']
              updated_res_data['Email'] = updated_res_data['email']
              updated_res_data['Default'] = updated_res_data['default']
              updated_res_data['Phone_number'] = updated_res_data['phoneNumber']
              updated_res_data['Salutation'] = convertToTitleCase(updated_res_data['salutation'])
              updated_res_data['OtherNo'] = updated_res_data['otherNo']
              updated_res_data['WhatsappNo'] = updated_res_data['whatsappNo']
              setContanctPost(prevData => [ updated_res_data, ...prevData]);
              setcontactdata(prevData => [ updated_res_data, ...prevData]);
              setcontactFromAdd(false)
              resetForm()
              if (setLoad){
                setLoad()
              }
              showErrorToast(true, "success" , "", `Saved`)
             
              reset_Form()
            } else{  
              console.log(responseData?.errors);
              showErrorToast(true, "error" , `${responseData?.errors}`)
           
            }
        
          }catch(errors){
            let DetailsError = ShowErrorNoties(errors)
            showErrorToast(true, "error" , `${DetailsError}`)
          }
        }
    }
    
    const Salutationoption =[
      { value: "MR", label: "Mr" },
      { value: "MS", label: "Ms" },
      { value: "MRS", label: "Mrs" },
      { value: "DR", label: "Dr" },
    ]

    function reset_Form( ) {
      
      setInitialValue(
        {
          Contact_Person_Name:  '',
          Salutation:  '',
          Email: '',
          Phone_number: '',
        }
      )
      setPersonName('')
      setSalutation('')
      setEmail('')
      setmobileNo('')
      setEditContact('')
      setotherNo('')
    setWhatsappNo('')
    if (setNewContactNumber){
      setNewContactNumber()
    }
    }

    const [personName, setPersonName]  = useState('')
    const handleChangePersonName = (value)=>{
      setPersonName(value)
    }

    const [salutation, setSalutation] = useState('')
    const handleChangeSalutation = (selectedOption)=>{
      
      setSalutation({ value: selectedOption.value , label: convertToTitleCase(selectedOption.label) })
    }

    const [email, setEmail] = useState('')
    const handleChangeEmail = (value)=>{
      setEmail(value)
    }

    const [mobileNo, setmobileNo] = useState('')
    const handleChangemobileno = (value)=>{
      setmobileNo(value)
    }

    const [whatsappNo, setWhatsappNo] = useState('')
    const handleChangeWhatsappNo = (value)=>{
      setWhatsappNo(value)
    }

    const [otherNo, setotherNo] = useState('')
    const handleChangeOtherNo = (value)=>{
      setotherNo(value)
    }
    function DeleteContact(params) {
      if (params){

      }
      
    }
    
  return (
    <>
      <ToastContainer  />
      <Modal show={ContactFromAdd} >
        <div className='row mx-1'>
            <div className='col-5 m-3' style={{color:"#5CADA9"}}>
                <Modal.Title>
                    <i className='fa-solid fa-arrow-left fa-sm me-2' onClick={()=>{setcontactFromAdd(false)
                                      reset_Form(); setEdit(false) }}>
                    </i>Contact Form  
                </Modal.Title> 
            </div>
            <div className="col-6 mt-4 text-end">
               {condition ? <>
                    <i className="fa-solid fa-trash text-danger fs-5 pe-3 ps-3  " 
                     onClick={()=>{setWaringModel(true)}} 
                     ></i> 
               </> : ""}
            </div>

            <hr/>
              

        </div>
        <Modal.Body>
          <Formik
            initialValues={initialValue}
            validationSchema={ContectSchema}
            onSubmit={handleSubmit}
            enableReinitialize
            key={formKey}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                {/* UI Change Starts */}
                <div>
                  <div className='row'>
                      <div className="col-6 staan-input-group">
                        <Field
                          type="text"
                          name="Contact_Person_Name"
                          placeholder=""
                          disabled={condition? true : false}
                          value={personName}
                          onChange={(e)=>{
                            setFieldValue("Contact_Person_Name",  e.target.value)
                            handleChangePersonName(e.target.value)
                          }}
                          className="w-100 staan-form-input"
                        />
                        <label htmlFor="Contact_Person_Name" className="staan-form-input-label pt-2 px-1 mx-2">Person Name</label>
                        {touched.Contact_Person_Name && errors.Contact_Person_Name && (
                          <small>{errors.Contact_Person_Name}</small>
                        )}
                      </div>
                      <div className="col-6 staan-input-group">
                        <Select
                          name="Salutation"
                          options={Salutationoption}
                          isSearchable={true} 
                          isDisabled={condition? true : false}
                          value={salutation}  // Use value prop to bind the value
                          onChange={(selectedOption) =>{ ;
                            handleChangeSalutation(selectedOption)
                                  setFieldValue("Salutation", selectedOption.value)
                          }}
                          className='my-3'
                          styles={customSelectStyle}
                         />
                        <label htmlFor="Salutation" className="staan-form-input-label pt-1 px-1 mx-2">Salutation</label>
                      </div>
                  </div>
                  <div className='row my-2'>
                      <div className="col-6  staan-input-group">
                        <Field
                          type="email"
                          name="Email"
                          placeholder=""
                          disabled={condition? true : false}
                          value={email}
                          className="w-100 staan-form-input"
                          onChange={(e)=>{
                            handleChangeEmail(e.target.value)
                            setFieldValue("Email", e.target.value)
                          }}
                          
                        />
                        <label htmlFor="Email" className="staan-form-input-label pt-2 px-1 mx-2">Email</label>
                        {touched.Email && errors.Email && (
                          <small>{errors.Email}</small>
                        )}
                      </div>
                      <div className="col-6  staan-input-group">
                        <label htmlFor="Phone_number" className="staan-form-input-label pt-2 px-1 mx-2">
                        Mobile Number
                        </label>
                        <Field
                          type="number"
                          name="Phone_number"
                          // disabled={condition? true : false}
                          disabled
                          placeholder=""
                          className="w-100 staan-form-input"
                          value={mobileNo}
                          onChange={(e)=>{
                            handleChangemobileno(e.target.value)
                            setFieldValue("Phone_number", e.target.value)
                          }}
                        />
                        {touched.Phone_number && errors.Phone_number && (
                          <small>{errors.Phone_number}</small>
                        )}
                     </div>
                  </div>
                  <div className='row mt-2'>
                      <div className="col-6  staan-input-group">
                        <label htmlFor="Phone_number" className="staan-form-input-label pt-2 px-1 mx-2">
                        Whatsapp Number
                        </label>
                        <Field
                          type="number"
                          name="whatsappNo"
                          disabled={condition? true : false}
                          placeholder=""
                          className="w-100 staan-form-input"
                          value={whatsappNo}
                          onChange={(e)=>{
                            handleChangeWhatsappNo(e.target.value)
                            setFieldValue("WhatsappNo", e.target.value)
                          }}
                        />
                        {touched.WhatsappNo && errors.WhatsappNo && (
                          <small>{errors.WhatsappNo}</small>
                        )}
                        {!edit ? <p style={{cursor:"pointer", fontSize: '.7rem'}} onClick={()=>{handleChangeWhatsappNo(mobileNo)  ; setFieldValue("WhatsappNo", mobileNo)}} className='text-success p-2'><i className="fa-solid fa-clone"></i> Same as Mobile Number</p>: "" }
                        
                      </div>
                      <div className="col-6 staan-input-group">
                        <Field
                          type="number"
                          name="OtherNo"
                          placeholder=""
                          disabled={condition? true : false}
                          className="w-100 staan-form-input"
                          value={otherNo}
                          onChange={(e)=>{
                            handleChangeOtherNo(e.target.value)
                            setFieldValue("OtherNo", e.target.value)
                          }}
                        />
                        <label htmlFor="otherNo" className="staan-form-input-label pt-2 px-1 mx-2">Other Number</label>
                        {touched.OtherNo && errors.OtherNo && (
                          <small>{errors.OtherNo}</small>
                        )}
                      </div>
                  </div>
                  <div className="row mt-2">
                  <div className='col-3 staan-input-group d-flex align-items-center'>
                        <div>
                          <Field type="checkbox" name="Default" disabled={condition? true : false}  className='me-2' checked={isDefault} onChange={(e) => {handleDefaultChange(e)}} />
                            <label style={{fontSize: '.8rem'}} className='pb-2'>
                            Default
                            </label>
                        </div>
                    </div>
                    <div className='col-6'></div>
                    <div className="text-end col-2 ms-4">
                      <button type="submit" disabled={condition? true : false} className="px-3 btn btn-sm shadow-sm btn-outline-success">Save</button>
                    </div>
                  </div> 
                </div>
                {/* UI Change ends */}
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {waringModel && (
         <WaringModel
          state={waringModel}
          setstate={setWaringModel}
          title={"Confirm"}
          text={'Confirm To Delete Data?'}
          Confirmtion={value => {
            DeleteContact(value)
          }}

         />

      )}
    </>
  );
};

export default ContactFrom;
