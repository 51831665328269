// returns clipboard with copied text; 
export async function copyTextToClipboard(event) {
  /**
   * on double click this function called with event 
   *  it updates the clipboard with target text
   */
    let text_content = event.target.textContent
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text_content);
    } else {
      return document.execCommand('copy', true, text_content);
    }
}

// returns array of widths; [20, 30, 50]
export const calculateColumnWidth = (flexList, totalColumns) => {
  /**
   * flexlist => list of items of given flex values in header config
   * totalColumns => length of total columns
   */
    // Calculate the total sum of flex values
    const totalFlexSum = flexList.reduce((sum, flex) => sum + flex, 0);
  
    // Calculate the width of each flex unit
    const flexUnitWidth = 100 / totalFlexSum;
  
    // Create an array to store the widths of each column
    const columnWidths = [];
  
    // Assign widths to columns based on the flex list
    for (let i = 0; i < totalColumns; i++) {
      const flex = flexList[i % flexList.length]; 
      const width = flex * flexUnitWidth + '%';
      columnWidths.push(width);
    }
  
    return columnWidths;
}

// returns default page size configured;
export const defaultPageSize = 50;

// returns style config of react select dropdown;
export const customSelectStyle = {
  control: (provided) => ({
    ...provided,
    fontSize: '.8rem',
    width: '100',
    innerHeight: '.7rem',
    margin: 0,
  }),
  option: (provided) => ({
    ...provided,
    fontSize: '.8rem !important', 
    textAlign: 'left'
  })
}

// returns style config of react select dropdown;
export const customMultiSelectStyle = {
  control: (provided) => ({
    ...provided,
    fontSize: '.8rem',
    width: '100',
    innerHeight: '.7rem',
    margin: 0,
    maxHeight: '.7rem',
    overflowY: 'auto'

  }),
  option: (provided) => ({
    ...provided,
    fontSize: '.8rem !important', 
    textAlign: 'left'
  }),
}

// returns range of numbers for given n; if n=2, then it returns [1,2]
export const customRange = (n) => {
  return Array.from({ length: n }, (_, index) => index + 1);
}


// returns value of object in any level
export const traverseObjectToFetchData = (data, key) => {
    const keys = key.split('.');
    let result = data;
    for (const k of keys) {
        if (result && typeof result === 'object' && k in result) {
            result = result[k];
        } else {
            result = null;
            break;
        }
    }
    
    return result;
};

// returns value of array if default else returns first object value
export const traverseArrayToFetchData = (data, key) => {
  if(data?.length > 0){
    let result = data.filter(item => item.default === true)
    if(result.length > 0){
      result = result[0]?.[key]
    }else{
      result = data?.[0]?.[key]
    }
    return result;
  }else{
    return ''
  }
};


export const convertDateTimeToDateString = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate
}

 